import React from 'react'

import { Button, Col, Row, Popconfirm, Typography } from 'antd'

const { Title, Text } = Typography

const ConfirmData = (props) => {
    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} style={{ marginBottom: '15px' }}>
                    <Title level={2} style={{ paddingTop: '15px' }}>
                        Confirm Data Upload
                    </Title>
                    <Text>Are you sure you want to upload these referrals? They will appear on dashboard of the assigned agencies for the appropriate project</Text>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Popconfirm title="Are you sure you want to upload?" onConfirm={props.handleUploadData}>
                        <Button type="primary" style={{ marginRight: '5px' }}>
                            Confirm and Upload
                        </Button>
                    </Popconfirm>
                    <Popconfirm title="Are you sure you want to start over?" onConfirm={props.clear}>
                        <Button type="secondary" disabled={!props.project}>
                            Start Over
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ConfirmData
