import React, { Component } from 'react'
import axios from 'axios'

import { HomeOutlined } from '@ant-design/icons'

import ProjectHeader from './project-header/project-header'
import ReferralCards from './referral-cards/referral-cards'
import ReferralTableFilter from './referral-table-filter/referral-table-filter'
import ReferralTable from './referral-table/referral-table'
import AdblockDetect from '../../../utils/adBlockDetect'
import Loader from './skeleton-loader/loader'
import './dashboard.scss'

import { Alert, BackTop, Breadcrumb, Card, Typography } from 'antd'

import LIGHT_THEME from '../../../styles/themes/light.json'
import HCBS_THEME from '../../../styles/themes/HCBS.json'
import TOC_THEME from '../../../styles/themes/TOC.json'
import CMO_THEME from '../../../styles/themes/CMO.json'
import COVID_THEME from '../../../styles/themes/COVID.json'
import CBHC_THEME from '../../../styles/themes/CBHC.json'
import SHCARE_THEME from '../../../styles/themes/SHCARE.json'

const { Title } = Typography
class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clearFilterHooks: [],
            currentProject: null,
            loading: true,
            preparedCsvData: {},
            referrals: {},
            status: 'all',
            selectedRowKeys: [],
            searchExecutedCounter: 0,
            twofactor_reminder: false,
        }
    }

    async componentDidMount() {
        await this.fetchReferrals()
        this.setTheme(localStorage.getItem('currentProject'))

        if (this.props.auth.user.is_twofactorauth_enabled === false && this.props.auth.user.email !== 'admin@cbhs.com') {
            this.setState({ twofactor_reminder: true })
        }

        if (this.props.auth.user.first_login_twofactor === 0) {
            this.goToSecurityPage()
        }
    }

    goToSecurityPage = () => {
        this.props.history.push({ pathname: '/account', state: { tab: 'security' } })
    }

    fetchReferrals = async () => {
        this.setState({ loading: true })
        const response = await axios.post('/api/referrals/referrals_list', {
            user_type: this.props.auth.user.user_type,
            project: localStorage.getItem('currentProject'),
        })
        this.setState({
            currentProject: localStorage.getItem('currentProject'),
            loading: false,
            preparedCsvData: response.data[this.state.status],
            referrals: response.data,
        })
    }

    setTheme = async (project) => {
        this.props.setCurrentProject(project)

        let themeVars = LIGHT_THEME
        let themeSecondaryVars = {}
        switch (localStorage.getItem('currentProject')) {
            case 'HCBS':
                themeSecondaryVars = HCBS_THEME
                document.documentElement.style.setProperty('--sider-color', '#20151b')
                document.documentElement.style.setProperty('--sider-color_dark', '#130b0b')
                document.documentElement.style.setProperty('--secondary-color', '#b5536f')
                document.documentElement.style.setProperty('--card-color', '#712f42')
                break
            case 'TOC':
                themeSecondaryVars = TOC_THEME
                document.documentElement.style.setProperty('--sider-color', '#1b1f24')
                document.documentElement.style.setProperty('--sider-color_dark', '#0d0f12')
                document.documentElement.style.setProperty('--secondary-color', '#78879c')
                document.documentElement.style.setProperty('--card-color', '#45505f')
                break
            case 'CMO':
                themeSecondaryVars = CMO_THEME
                document.documentElement.style.setProperty('--sider-color', '#142719')
                document.documentElement.style.setProperty('--sider-color_dark', '#0d170f')
                document.documentElement.style.setProperty('--secondary-color', '#4d945e')
                document.documentElement.style.setProperty('--card-color', '#204228')
                break
            case 'COVID':
                themeSecondaryVars = COVID_THEME
                document.documentElement.style.setProperty('--sider-color', '#432e25')
                document.documentElement.style.setProperty('--sider-color_dark', '#1e110c')
                document.documentElement.style.setProperty('--secondary-color', '#AA745E')
                document.documentElement.style.setProperty('--card-color', '#6E493A')
                break
            case 'CBHC-MVP':
                themeSecondaryVars = CBHC_THEME
                document.documentElement.style.setProperty('--sider-color', '#8A1E1E')
                document.documentElement.style.setProperty('--sider-color_dark', '#4f1919')
                document.documentElement.style.setProperty('--secondary-color', '#D23232')
                document.documentElement.style.setProperty('--card-color', '#8A1E1E')
                break
            case 'SHARED-CARE':
                themeSecondaryVars = SHCARE_THEME
                document.documentElement.style.setProperty('--sider-color', '#002238')
                document.documentElement.style.setProperty('--sider-color_dark', '#05151f')
                document.documentElement.style.setProperty('--secondary-color', '#1890ff')
                document.documentElement.style.setProperty('--card-color', '#044268')
                break
            default:
                themeSecondaryVars = HCBS_THEME
                break
        }
        themeVars = {
            ...themeVars,
            ...themeSecondaryVars,
            '@white': '#fff',
            '@black': '#000',
        }
        localStorage.setItem('theme-vars', JSON.stringify(themeVars))
        window.less.modifyVars(themeVars).catch((error) => {})
    }

    clearFilters = async () => {
        const { clearFilterHooks, searchExecutedCounter } = this.state
        // loop as many as search has been executed and iterate through all the column filters
        for (let i = 0; i < searchExecutedCounter; i++) {
            await Object.values(clearFilterHooks).forEach((run) => {
                run()
            })
        }
        this.setState({ clearFilterHooks: {}, searchText: '' })
    }

    statusChanger = (status) => {
        this.clearFilters()
        this.setState({
            status,
            preparedCsvData: this.state.referrals[status],
        })
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys })
    }

    onChange = (key, value) => {
        this.setState({ [key]: value })
    }

    render() {
        const { currentProject, referrals, selectedRowKeys, status, preparedCsvData } = this.state
        const hasSelected = selectedRowKeys.length > 0
        return (
            <React.Fragment>
                <BackTop />
                {!this.state.loading ? (
                    <div>
                        <div>
                            <AdblockDetect>
                                <Alert message="You are using adblock, please turn it off for this site to download csv" type="warning" showIcon closable banner style={{ marginBottom: '10px' }} />
                            </AdblockDetect>
                        </div>

                        <Card style={{ margin: '20px 16px', width: '97%', borderRadius: '10px' }}>
                            <div style={{ marginBottom: '16px', width: '100%' }}>
                                <Breadcrumb>
                                    <HomeOutlined /> <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <Title level={2}>Welcome, {this.props.auth.user.name} </Title>
                            <p>Select a project in the dropdown to view its referrals. Click a card to filter by status.</p>
                        </Card>
                        <ProjectHeader
                            currentProject={currentProject}
                            preparedCsvData={preparedCsvData}
                            userProjects={this.props.auth.user.projects}
                            userType={this.props.auth.user.user_type}
                            setCurrentProject={async (project) => {
                                await this.setTheme(project)
                                await this.fetchReferrals()
                            }}
                        />
                        <ReferralCards currentProject={currentProject} referrals={this.state.referrals} statusChanger={(status) => this.statusChanger(status)} loading={this.state.loading} />
                        {currentProject !== 'SHARED-CARE' && (
                            <ReferralTableFilter
                                currentProject={currentProject}
                                userType={this.props.auth.user.user_type}
                                fetchReferrals={() => this.fetchReferrals()}
                                hasSelected={hasSelected}
                                onChange={(key, val) => this.onChange(key, val)}
                                preparedCsvData={preparedCsvData}
                                statusChanger={(status) => this.statusChanger(status)}
                                referrals={referrals}
                                selectedRowKeys={selectedRowKeys}
                                status={status}
                            />
                        )}

                        <ReferralTable
                            currentProject={currentProject}
                            userType={this.props.auth.user.user_type}
                            clearFilters={() => this.clearFilters()}
                            fetchReferrals={() => this.fetchReferrals()}
                            hasSelected={hasSelected}
                            history={this.props.history}
                            onSelectChange={(selectedRowKeys) => this.onSelectChange(selectedRowKeys)}
                            referrals={referrals}
                            status={status}
                            selectedRowKeys={selectedRowKeys}
                            statusChanger={(status) => this.statusChanger(status)}
                        />
                    </div>
                ) : (
                    <Loader auth={this.props.auth} />
                )}
            </React.Fragment>
        )
    }
}

export default Dashboard
