import React, { Component } from 'react'
import { Card, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import { ExclamationCircleTwoTone } from '@ant-design/icons';

import { insurance_types, insurance_plans } from '../../../../../utils/constants'

const { Option } = Select

class InsuranceInformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Uninsured: false,
        }
    }

    render() {
        return (
            <Card title="Insurance Information" bordered={false} style={{ width: '-webkit-fill-available', marginTop: '1em' }}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={7} >
                        <Form.Item
                            name="insurance_1"
                            rules={[
                                {
                                    required: true,
                                    message: 'Insurance 1 is required',
                                },
                            ]}
                            tooltip={{
                                title: 'This is a required field',
                                icon: <ExclamationCircleTwoTone twoToneColor="#eb2f96" />,
                            }}
                            label="Insurance Type 1"
                            labelAlign="left"
                            style={{ flexDirection: 'column', paddingBottom: '5px' }} >
                            <Select
                                showSearch
                                placeholder="Select insurance type"
                                optionFilterProp="children"
                                onChange={(option) => {
                                    if (option === 'Uninsured') {
                                        this.setState({
                                            Uninsured: true,
                                        })
                                    } else {
                                        this.setState({
                                            Uninsured: false,
                                        })
                                    }
                                }}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {insurance_types.sort().map((insurance) => (
                                    <Option key={insurance}>{insurance}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg ={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_1_plan"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Insurance Plan 1"
                            labelAlign="left"
                            style={{ flexDirection: 'column', paddingBottom: '5px' }} >
                            <Select
                                showSearch
                                disabled={this.state.Uninsured}
                                placeholder="Select insurance plan"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {insurance_plans.sort().map((plan) => (
                                    <Option key={plan}>{plan}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg ={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_1_effective_date"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Effective Date"
                            labelAlign="left"
                            style={{ flexDirection: 'column', paddingBottom: '5px' }} >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={7}>
                        <Form.Item
                            name="insurance_1_expiration_date"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Expiration Date"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg ={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_1_policy_number"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Policy Number"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <Input
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault()
                                }}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg ={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_1_group_number"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Group Number"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <Input
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault()
                                }}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <br />

                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={7} >
                        <Form.Item
                            name="insurance_2"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Insurance Type 2"
                            labelAlign="left"
                            style={{ flexDirection: 'column', paddingBottom: '5px' }} >
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                showSearch
                                placeholder="Select insurance type"
                                optionFilterProp="children"
                                onChange={(option) => {
                                    if (option === 'Uninsured') {
                                        this.setState({
                                            Uninsured: true,
                                        })
                                    } else {
                                        this.setState({
                                            Uninsured: false,
                                        })
                                    }
                                }}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {insurance_types.sort().map((insurance) => (
                                    <Option key={insurance}>{insurance}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_2_plan"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Insurance Plan 2"
                            labelAlign="left"
                            style={{ flexDirection: 'column', paddingBottom: '5px' }} >
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                showSearch
                                disabled={this.state.Uninsured}
                                placeholder="Select insurance plan"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {insurance_plans.sort().map((plan) => (
                                    <Option key={plan}>{plan}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_2_effective_date"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Effective Date"
                            labelAlign="left"
                            style={{ flexDirection: 'column', paddingBottom: '5px' }} >
                            <DatePicker
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={7} >
                        <Form.Item
                            name="insurance_2_expiration_date"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Expiration Date"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <DatePicker
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_2_policy_number"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Policy Number"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <Input
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault()
                                }}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_2_group_number"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Group Number"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <Input
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault()
                                }}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                   
                </Row>

                <br />

                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={7} >
                        <Form.Item
                            name="insurance_3"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Insurance Type 3"
                            labelAlign="left"
                            style={{ flexDirection: 'column', paddingBottom: '5px' }} >
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                showSearch
                                placeholder="Select insurance type"
                                optionFilterProp="children"
                                onChange={(option) => {
                                    if (option === 'Uninsured') {
                                        this.setState({
                                            Uninsured: true,
                                        })
                                    } else {
                                        this.setState({
                                            Uninsured: false,
                                        })
                                    }
                                }}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {insurance_types.sort().map((insurance) => (
                                    <Option key={insurance}>{insurance}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_3_plan"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Insurance Plan 3: "
                            labelAlign="left"
                            style={{ flexDirection: 'column', paddingBottom: '5px' }} >
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                showSearch
                                disabled={this.state.Uninsured}
                                placeholder="Select insurance plan"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {insurance_plans.sort().map((plan) => (
                                    <Option key={plan}>{plan}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                        <Form.Item
                            name="insurance_3_effective_date"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Effective Date"
                            labelAlign="left"
                            style={{ flexDirection: 'column', paddingBottom: '5px' }} >
                            <DatePicker
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={7} >
                        <Form.Item
                            name="insurance_3_expiration_date"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Expiration Date"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <DatePicker
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_3_policy_number"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Policy Number"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <Input
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault()
                                }}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }} >
                        <Form.Item
                            name="insurance_3_group_number"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Group Number"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <Input
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault()
                                }}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                    
                </Row>
            </Card>
        )
    }
}

export default InsuranceInformation
