import React, { Component } from 'react'
import { Breadcrumb, Card, Col, Row, Layout } from 'antd'

const { Content } = Layout

class Terms extends Component {
    render() {
        return (
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Terms and Conditions</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Row>
                        <Col>
                            <p>
                                The terms and conditions provided below (the
                                &ldquo;Terms&rdquo;) are applicable to the
                                websites, CBHSCARECONNECTIONS mobile
                                application, online services and all other
                                interactive features, services, and
                                communications (individually, a &ldquo;Site and
                                collectively, the &ldquo;Sites&rdquo;), however
                                accessed and/or used, that are operated, made
                                available or produced and maintained by CBHS,
                                LLC, aka CBHS (&ldquo;CBHS&rdquo;,
                                &ldquo;we&rdquo; and &ldquo;us&rdquo;), unless
                                otherwise indicated by a specific Site.
                                <br />
                                <br />
                                This is a legal agreement between you
                                (&ldquo;you&rdquo; or &ldquo;user&rdquo;) and
                                CBHS that states the material terms and
                                conditions that govern your use of the Sites.
                                This agreement, together with all updates,
                                supplements, additional terms, and all of
                                CBHS&rsquo;s policies collectively constitute
                                this &ldquo;Agreement&rdquo; between you and
                                CBHS. In addition to the Content on the Sites,
                                the Sites may provide you with various
                                opportunities to submit content, activity
                                information and participate in various research,
                                monitoring and testing activities
                                (&ldquo;Services&rdquo;). Additional terms and
                                conditions or other legal notice requirements
                                may apply to the Services, such as any
                                applicable consent agreements for use of medical
                                data in research studies or CBHS&rsquo;s
                                Business Associate Agreement for health care
                                providers. In the event of a conflict between
                                these Terms and another agreement between you
                                and CBHS, the terms of the other agreement will
                                control.
                                <br />
                                <br />
                                BY USING OUR SITES, SUBMITTING CONTENT, AND
                                USING THE SERVICES YOU ARE ACCEPTING AND
                                AGREEING TO THESE TERMS. IF YOU DO NOT AGREE TO
                                THESE TERMS, PLEASE DO NOT USE THE SITES AND
                                EXIT IMMEDIATELY. WE RESERVE THE RIGHT TO MODIFY
                                OR AMEND THESE TERMS OF USE FROM TIME TO TIME
                                WITHOUT NOTICE. YOUR CONTINUED USE OF OUR SITES
                                AND ANY AFFILIATE SITES FOLLOWING THE POSTING OF
                                CHANGES TO THESE TERMS WILL MEAN YOU ACCEPT
                                THOSE CHANGES. UNLESS WE PROVIDE YOU WITH
                                SPECIFIC NOTICE, NO CHANGES TO OUR TERMS OF USE
                                WILL APPLY RETROACTIVELY. <br />
                                <br />
                                No Medical Advice
                                <br />
                                INFORMATION PROVIDED ON THE SITES ABOUT TESTS,
                                CONDITIONS, DIAGNOSES, TREATMENTS, ALERTS OR ANY
                                OTHER INFORMATION IS NOT MEDICAL ADVICE. THE
                                SITES DO NOT PROVIDE MEDICAL OR DIAGNOSTIC
                                SERVICES OR PRESCRIBE MEDICATION. INFORMATION
                                PROVIDED DOES NOT CONSTITUTE A DIAGNOSIS,
                                TREATMENT, OR PROTOCOL FOR ANY DISEASE OR
                                CONDITION.
                                <br />
                                DO NOT USE THE SITES OR SERVICES FOR EMERGENCY
                                MEDICAL NEEDS. IF YOU ARE EXPERIENCING A MEDICAL
                                EMERGENCY, INCLUDING THOUGHTS OF HARM TO
                                YOURSELF OR OTHERS, PLEASE IMMEDIATELY CONTACT
                                911 OR A CRISIS HOTLINE.
                                <br />
                                Not a Substitute for Professional Judgment
                                <br />
                                USE OF THE SITES OR SERVICES IS NOT A SUBSTITUTE
                                FOR THE PROFESSIONAL JUDGMENT OF HEALTH CARE
                                PROFESSIONALS IN DIAGNOSING AND TREATING
                                PATIENTS. YOU ACKNOWLEDGE AND AGREE THAT
                                INFORMATION PROVIDED VIA THE SITES OR SERVICES
                                MAY BE INACCURATE THOUGH NO FAULT OF CBHS. YOU
                                ASSUME ALL RISKS ASSOCIATED WITH YOUR USE OF THE
                                SITES AND SERVICES. CBHS IS NOT RESPONSIBLE FOR
                                INFORMATION THAT IS PROVIDED BY HEALTH CARE
                                PROFESSIONALS THROUGH THE USE OF THE SITES OR
                                SERVICES.
                                <br />
                                Research
                                <br />
                                You agree that information obtained by CBHS in
                                conjunction with your use of the Sites or
                                Services may be used by CBHS for related
                                research purposes, including publication of
                                findings regarding the Sites&rsquo;
                                functionality.
                                <br />
                                Acceptable Use
                                <br />
                                You agree not to reproduce, publish, or
                                distribute data or content in connection with
                                the Sites that infringes any third party&rsquo;s
                                trademark, copyright, patent, trade secret,
                                publicity, privacy, or other personal or
                                proprietary right.
                                <br />
                                You agree not to copy or distribute in any
                                medium any part of the Sites without
                                CBHS&rsquo;s prior written authorization. You
                                agree not to alter or modify, or attempt to
                                alter or modify, any part of the Sites,
                                including any of CBHS&rsquo;s related
                                technologies, or create derivative works or
                                other products or services based upon the Sites.
                                <br />
                                You agree not to use any robot, spider, scraper
                                or other automated means to access the Sites for
                                any purpose without our express written
                                permission. You agree not to: (i) take any
                                action that imposes or may impose, in our sole
                                discretion, an unreasonable or
                                disproportionately large load on our
                                infrastructure; (ii) interfere or attempt to
                                interfere with the proper working of the Sites
                                or engage in any activity that disrupts,
                                diminishes the quality of, interferes with the
                                performance of, or impairs the functionality of,
                                the Sites, or (iii) bypass, circumvent, disable
                                or otherwise interfere with any measures we may
                                use to prevent or restrict access to the Sites.
                                Any unauthorized use of our computer systems is
                                a violation of these Terms.
                                <br />
                                You acknowledge that your activities using the
                                Sites may be tracked and are subject to audit.
                                <br />
                                You agree not to use the Sites in any way for
                                unlawful purposes or that constitutes or
                                encourages any unlawful activities or to take
                                any action that would render the operation or
                                use of the Sites by CBHS or any other user
                                unlawful.
                                <br />
                                Accounts, Passwords and Security
                                <br />
                                In order to access some features of the Sites,
                                including the Services, you will have to
                                register as a user and create an account with
                                CBHS. You may never use another&rsquo;s account
                                without permission. When creating your account,
                                you must provide accurate and complete
                                information, and you must maintain and promptly
                                update your account information to keep it
                                accurate, current and complete. You are solely
                                responsible for the activity that occurs on your
                                account, and you must keep your account password
                                secure. You must notify CBHS immediately of any
                                breach of security or unauthorized use of your
                                account. Although CBHS will not be liable for
                                your losses caused by any unauthorized use of
                                your account, you may be liable for the losses
                                of CBHS or others due to such unauthorized use.
                                <br />
                                <br />
                                Accuracy of Information
                                <br />
                                We attempt to ensure that information on the
                                Sites is complete, accurate and current. We may
                                make changes to information on the Sites,
                                including but not limited to the availability of
                                any information, without notice. Despite our
                                efforts, the information on the Sites may
                                occasionally be inaccurate, incomplete or out of
                                date. We make no representation as to the
                                completeness, accuracy or currency of any
                                information on the Sites. For example, data
                                included on the Sites based in part on the
                                information provided by a patient depends on the
                                accuracy or truthfulness of the patient&rsquo;s
                                inputs. Additionally, malfunctions with or
                                inaccuracies in the data collected by the
                                accelerometer in the patient&rsquo;s mobile
                                device on which the Sites are installed may
                                affect the accuracy of data available on the
                                Sites.
                                <br />
                                Service Limitations <br />
                                We reserve the right to change, suspend or
                                discontinue any aspect of the Sites at any time,
                                without notice and at our sole discretion. We
                                will not be liable to you or to any third party
                                for any modification, suspension or
                                discontinuance of the Sites or any portion
                                thereof.
                                <br />
                                <br />
                                We are not responsible for maintaining any data
                                for you or for any lost data resulting from the
                                use, operation or discontinuation of any aspect
                                of the Sites. Unless otherwise expressly agreed
                                in writing, you are solely responsible for
                                maintaining records of any information generated
                                or stored through the use of the Sites.
                                <br />
                                <br />
                                We are not responsible or liable to you or any
                                other person for our cooperation with any law
                                enforcement authorities, court order, subpoena
                                or governmental authority requesting or
                                directing us to disclose information, including
                                personally identifiable information regarding
                                you or your use of the Sites. We are not
                                responsible and will not be liable to you or any
                                other person for use or disclosure of user
                                information, including protected health
                                information, consistent with our privacy policy
                                and applicable law, to protect and defend our
                                rights, clients, business, to comply with court
                                orders, law enforcement requests, subpoenas, or
                                other governmental requests, or to act in
                                emergency circumstances.
                                <br />
                                <br />
                                Legal Compliance
                                <br />
                                CBHS offers no assurance that the use of
                                CBHS&rsquo;s Sites under these Terms will ensure
                                your compliance with any applicable laws. You
                                will be solely responsible for ensuring that
                                your use of the Sites complies with all
                                applicable laws. You shall be solely responsible
                                for your use of the Sites and any use of your
                                user credentials.
                                <br />
                                <br />
                                Copyright, Trademark, Ownership <br />
                                All of the content displayed on the Sites,
                                including without limitation text, graphics,
                                photographs, images, moving images, sound, and
                                illustrations (&ldquo;Content&rdquo;), is owned
                                by CBHS, its licensors, agents or its Content
                                providers. All elements of the Sites, including,
                                without limitation, the Sites&rsquo; general
                                design, CBHS&rsquo;s trademarks, service marks,
                                trade names (including CBHS&rsquo;s name, logos,
                                the Sites&rsquo; name(s), and the Sites&rsquo;
                                design), and other Content, are protected by
                                trade dress, copyright, moral rights, trademark
                                and other laws relating to intellectual property
                                rights. The Sites may only be used for the
                                intended purpose for which it is being made
                                available. Except as may be otherwise indicated
                                on the Sites, you are authorized to view, print
                                and download documents, audio and video found on
                                our Sites for personal, informational, and
                                non-commercial purposes only. You may not use,
                                copy, reproduce, republish, upload, post,
                                transmit, distribute, or modify the Content or
                                CBHS&rsquo;s trademarks in any way, including in
                                advertising or publicity pertaining to
                                distribution of materials on the Sites, without
                                CBHS&rsquo;s prior written consent. The use of
                                CBHS&rsquo;s trademarks on any other Sites is
                                not allowed. CBHS prohibits the use of
                                CBHS&rsquo;s trademarks as a &ldquo;hot&rdquo;
                                link on or to any other site unless
                                establishment of such a link is approved in
                                advance. The Site, its Content and all related
                                rights shall remain the exclusive property of
                                CBHS or its licensors unless otherwise expressly
                                agreed. You will not remove any copyright,
                                trademark or other proprietary notices from
                                material found on the Sites.
                                <br />
                                Links
                                <br />
                                Running or displaying the Sites or any
                                information or material displayed on the Sites
                                in frames or through similar means on another
                                site without our prior written permission is
                                prohibited.
                                <br />
                                <br />
                                <br />
                                Disclaimers; Limitation on Liability
                                <br />
                                Your use of THE SITES is at your OWN risk. THE
                                INFORMATION, MATERIALS, PRODUCTS AND SERVICES
                                PROVIDED ON, THROUGH, OR IN CONNECTION WITH THE
                                SITES OR SERVICES OR OTHERWISE PROVIDED BY US
                                ARE PROVIDED &ldquo;AS IS&rdquo; WITHOUT ANY
                                WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
                                INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
                                MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                PURPOSE, SECURITY, NON-INFRINGEMENT OF
                                INTELLECTUAL PROPERTY, FREEDOM FROM COMPUTER
                                VIRUS, OR WARRANTIES ARISING FROM COURSE OF
                                DEALING OR COURSE OF PERFORMANCE. TO THE FULLEST
                                EXTENT PERMITTED BY APPLICABLE LAW, WE HEREBY
                                DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER
                                EXPRESS OR IMPLIED, WITH RESPECT TO THE SITES
                                AND ANY PRODUCTS AND SERVICES LISTED OR
                                DESCRIBED ON THE SITES. WE DO NOT REPRESENT OR
                                WARRANT THAT THE SITES OR ANY INFORMATION OR
                                PRODUCTS OR SERVICES WE PROVIDE WILL BE
                                UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL
                                BE CORRECTED, OR THAT THE SITES ARE FREE OF
                                VIRUSES OR OTHER HARMFUL COMPONENTS. WE DO NOT
                                MAKE ANY WARRANTIES OR REPRESENTATIONS REGARDING
                                THE COMPLETENESS, CORRECTNESS, ACCURACY,
                                ADEQUACY, USEFULNESS, TIMELINESS OR RELIABILITY
                                OF THE SITES OR ANY INFORMATION OR PRODUCTS OR
                                SERVICES WE PROVIDE, OR ANY OTHER WARRANTY. THE
                                INFORMATION, MATERIALS, PRODUCTS AND SERVICES
                                PROVIDED ON OR THROUGH THE SITES MAY BE OUT OF
                                DATE, AND NEITHER CBHS NOR ANY OF ITS AFFILIATES
                                MAKES ANY COMMITMENT OR ASSUMES ANY DUTY TO
                                UPDATE SUCH INFORMATION, MATERIALS, PRODUCTS OR
                                SERVICES. WITHOUT LIMITING THE GENERALITY OF THE
                                FOREGOING, WE HEREBY EXPRESSLY DISCLAIM ALL
                                LIABILITY FOR PRODUCT DEFECT OR FAILURE,
                                INCLUDING TESTING AND TEST RESULT DEFECTS AND
                                FAILURES, CLAIMS THAT ARE DUE TO NORMAL WEAR,
                                PRODUCT MISUSE, ABUSE, PRODUCT MODIFICATION,
                                IMPROPER PRODUCT SELECTION, NON-COMPLIANCE WITH
                                ANY CODES, OR MISAPPROPRIATION. WE MAKE NO
                                WARRANTIES TO THOSE DEFINED AS
                                &ldquo;CONSUMERS&rdquo; IN THE MAGNUSON-MOSS
                                WARRANTY-FEDERAL TRADE COMMISSION IMPROVEMENTS
                                ACT. THE FOREGOING EXCLUSIONS OF IMPLIED
                                WARRANTIES DO NOT APPLY TO THE EXTENT PROHIBITED
                                BY APPLICABLE LAW.
                                <br />
                                <br />
                                THE INFORMATION PRESENTED ON THE SITES DOES NOT
                                GUARANTEE ANY INSURANCE, INCENTIVE, OR OTHER
                                BENEFITS WILL BE PAID.
                                <br />
                                <br />
                                NO ADVICE OR INFORMATION, UNLESS MADE AS PART OF
                                ANOTHER BINDING AGREEMENT THAT SUPERCEDES THE
                                TERMS OF THIS AGREEMENT, OBTAINED FROM CBHS OR
                                CBHS&rsquo;S SITE OR SERVICES WILL CREATE ANY
                                WARRANTY NOT EXPRESSLY MADE HEREIN.
                                <br />
                                <br />
                                CBHS does not assume any responsibility FOR and
                                will NOT be liable, for any damage to or any
                                virus that may infect your computer, equipment,
                                or other property caused by or arising from your
                                access to, use of, or browsing thE SITES, or
                                your downloading of OR ACCESSING any materials
                                from THE SITES. IN NO EVENT WILL CBHS, OR ANY OF
                                ITS OFFICERS, DIRECTORS, EMPLOYEES,
                                SHAREHOLDERS, AFFILIATES, AGENTS, SUCCESSORS OR
                                ASSIGNS, NOR ANY PARTY INVOLVED IN THE CREATION,
                                PRODUCTION OR TRANSMISSION OF THE SITES, BE
                                LIABLE TO YOU OR ANYONE ELSE FOR ANY INDIRECT,
                                SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
                                DAMAGES (INCLUDING, WITHOUT LIMITATION, THOSE
                                RESULTING FROM LOST PROFITS, LOST DATA OR
                                BUSINESS INTERRUPTION) ARISING OUT OF THE USE,
                                INABILITY TO USE, OR THE RESULTS OF USE OF THE
                                SITES, ANY SITES LINKED TO THE SITES, OR THE
                                MATERIALS, INFORMATION, PRODUCTS OR SERVICES
                                CONTAINED ON OR PROVIDED IN CONNECTION WITH THE
                                SITES OR SERVICES, WHETHER BASED ON WARRANTY,
                                CONTRACT, TORT OR ANY OTHER LEGAL THEORY AND
                                WHETHER OR NOT ADVISED OF THE POSSIBILITY OF
                                SUCH DAMAGES. THE FOREGOING LIMITATIONS OF
                                LIABILITY DO NOT APPLY TO THE EXTENT PROHIBITED
                                BY APPLICABLE LAW.
                                <br />
                                <br />
                                IN THE EVENT OF ANY PROBLEM WITH THE SITES OR
                                THE SERVICES, YOU AGREE THAT YOUR SOLE REMEDY IS
                                TO CEASE USING THE SITES OR SERVICES.
                                <br />
                                <br />
                                Force Majeure
                                <br />
                                CBHS is not responsible for damages, delays, or
                                failures in performance resulting from acts or
                                occurrences beyond its reasonable control,
                                including, without limitation: fire, lightning,
                                explosion, power surge or failure, water, acts
                                of God, war, revolution, civil commotion or acts
                                of civil or military authorities or public
                                enemies: any law, order, regulation, ordinance,
                                or requirement of any government or legal body
                                or any representative of any such government or
                                legal body; or labor unrest, including without
                                limitation, strikes, slowdowns, picketing, or
                                boycotts; inability to secure raw materials,
                                transportation facilities, fuel or energy
                                shortages, or acts or omissions of other common
                                carriers.
                                <br />
                                <br />
                                Indemnity
                                <br />
                                You agree to defend, indemnify and hold CBHS,
                                any parent companies, subsidiaries and
                                affiliates and the successors of each of the
                                foregoing, and the officers, directors, agents,
                                and employees of each of the foregoing, harmless
                                from any and all claims, actions, proceedings,
                                losses, liabilities, damages, obligations,
                                costs, debt, and expenses (including
                                attorney&rsquo;s fees, litigation expenses and
                                settlement amounts) arising from or related to
                                (i) your use of, or inability to use, the Sites
                                or Services; (ii) your violation of any term or
                                condition of these Terms, including without
                                limitation, your breach of any of the
                                representations and warranties; (iii) your
                                violation of any third-party rights, including
                                without limitation any right of privacy,
                                publicity rights or intellectual property
                                rights; (iv) your violation of any law, rule or
                                regulation of the United States or any other
                                country; (v) any claim or damages that arise as
                                a result of any information or material that you
                                provide to CBHS; or (vi) any negligence or
                                willful misconduct by you.
                                <br />
                                Release
                                <br />
                                In the event that you have a dispute with
                                another user or other party related to the Sites
                                or Services, you release CBHS (and our officers,
                                directors, agents, subsidiaries, joint ventures
                                and employees) from all claims, demands and
                                damages (actual and consequential) of every kind
                                and nature, known and unknown, suspected and
                                unsuspected, disclosed and undisclosed, arising
                                out of or in any way connected with such
                                disputes.
                                <br />
                                <br />
                                Termination
                                <br />
                                CBHS may suspend or terminate your use of the
                                Sites or Services at any time, for any reason or
                                for no reason, without notice. We may also block
                                your access to our Sites and Services in the
                                event that (a) you breach these Terms; (b) we
                                are unable to verify or authenticate any
                                information you provide to us; (c) we believe
                                that your actions may cause financial loss or
                                legal liability for you, our users or us. We
                                also reserve the right to terminate or
                                deactivate accounts that are inactive for an
                                extended period of time.
                                <br />
                                CBHS reserves the right to change, suspend or
                                discontinue any aspect of the Sites or Services
                                at any time, without notice and at its sole
                                discretion. CBHS will not be liable to you or to
                                any third party for any modification,
                                suspension, or discontinuance of the Sites or
                                Service, or any portion thereof.
                                <br />
                                Upon any such termination, your rights to access
                                and use the Sites and Services shall
                                automatically terminate and CBHS will have no
                                obligation to display, store or otherwise
                                maintain for you any test results or other
                                information provided through the Sites and
                                Services.
                                <br />
                                <br />
                                Representations and Warranties
                                <br />
                                You affirm, represent and warrant the following:
                                (i) you are old enough in your location to enter
                                into a binding contract with CBHS and have the
                                capacity, right and authority to enter into this
                                Agreement, and are fully able and competent to
                                satisfy the terms, conditions and obligations
                                herein; (ii) if necessary you have obtained all
                                authorizations and consents of each and every
                                individual who is the subject matter of testing,
                                patient care, or other Services offered through
                                the Sites; (iii) you have read, understood,
                                agree with, and will abide by these Terms; (iv)
                                CBHS&rsquo;s or your use of any information
                                submitted through the Sites or Services, or use
                                of any information provided through CBHS&rsquo;s
                                services or products will not infringe any
                                rights of any third party, including but not
                                limited to any intellectual property rights,
                                privacy rights and rights of publicity and will
                                not constitute, in whole or in part, any other
                                act or omission that could give rise to civil
                                and/or criminal liability.
                                <br />
                                <br />
                                Ability to Accept Terms of Use
                                <br />
                                You represent and warrant that (a) you have full
                                power and authority to enter into and perform
                                this Agreement; (b) the execution and delivery
                                of this Agreement has been duly authorized by
                                you; and (c) these Terms are valid and binding
                                obligations of you, enforceable against you in
                                accordance with its provisions.
                                <br />
                                Privacy
                                <br />
                                Information collection and use, including the
                                collection and use of personally identifiable
                                information and protected health information,
                                where applicable, is governed by our Privacy
                                Policy which is incorporated into and is a part
                                of these Terms.
                                <br />
                                <br />
                                General <br />
                                These Terms will be governed by and construed in
                                accordance with the laws of the state of New
                                York (without regard to the principles thereof
                                relating to conflicts of laws). Any action or
                                proceeding arising out of or relating to these
                                Terms shall be commenced and litigated to
                                conclusion solely in the state or federal courts
                                located in New York County, New York, and such
                                courts shall have exclusive jurisdiction and
                                venue over such action. Any and all disputes
                                directly or indirectly arising out of or related
                                to the Terms or the Sites or Services will be
                                resolved individually, without resort to any
                                form of class actions. Any and all claims,
                                judgments and awards will be limited to actual
                                out-of-pocket costs incurred, and in no event
                                shall you be entitled to recovery of
                                attorney&rsquo;s fees. Under no circumstances
                                will any claimant be permitted to obtain any
                                award for, and you hereby waive all rights to
                                claim punitive, special, incidental or
                                consequential damages and any and all rights to
                                have damages multiplied or otherwise increased
                                and any other damages, other than for actual
                                out-of-pocket expenses. Any cause of action or
                                claim you may have directly or indirectly
                                arising out of or related to the Terms or the
                                Sites or Services must be commenced within one
                                (1) year after the claim or cause of action
                                arises. A printed version of these Terms will be
                                admissible in judicial and administrative
                                proceedings based upon or relating to these
                                Terms to the same extent as other business
                                documents originally generated and maintained in
                                printed form. You shall comply with all
                                applicable domestic and international laws,
                                statutes, ordinances and regulations regarding
                                your use of the Sites or Services.
                                <br />
                                <br />
                                If any provision of these Terms is held to be
                                invalid or unenforceable, such provision shall
                                be struck and the remaining provisions shall be
                                enforced. You agree that these Terms and all
                                incorporated agreements may be assigned by CBHS
                                in our sole discretion, including upon any
                                future sale of Competitive Solutions, Inc
                                (&ldquo;CBHS&rdquo;) or any of its assets, in
                                whole or in part, to another entity. Headings
                                are for reference purposes only and in no way
                                define, limit, construe or describe the scope or
                                extent of such section. Whenever used in these
                                Terms, unless otherwise specified the terms
                                &ldquo;includes&rdquo;, &ldquo;including&rdquo;,
                                &ldquo;e.g.&rdquo;, &ldquo;for example&rdquo;,
                                &ldquo;such as&rdquo;, &ldquo;for
                                instance&rdquo; and other similar terms are
                                deemed to include the term &ldquo;without
                                limitation&rdquo; immediately thereafter. In
                                resolving any dispute or construing any
                                provision hereunder, there will be no
                                presumptions made or inferences drawn because
                                one of the parties (or its representatives)
                                drafted these Terms; and each party hereby
                                waives application of, or any rights under, any
                                law that would require the interpretation of any
                                ambiguities in these Terms against the party
                                that drafted it. Our failure to act with respect
                                to a breach by you or others does not waive our
                                right to act with respect to subsequent or
                                similar breaches. These Terms are the entire
                                agreement between you and CBHS with respects to
                                the subject matter hereof and supersede any
                                prior understandings or agreements (written or
                                oral). Any provision that must survive in order
                                to give proper effect to its intent (e.g.,
                                indemnity, general, limitations on liability,
                                disclaimers, representations and warranties,
                                etc.) shall survive the expiration or
                                termination of these Terms. Except as expressly
                                stated herein, nothing in these Terms shall be
                                deemed to confer any third-party rights or
                                benefits.
                                <br />
                                <br />
                                All rights not granted herein are expressly
                                reserved to CBHS.
                            </p>
                        </Col>
                    </Row>
                </Card>
            </Content>
        )
    }
}

export default Terms
