import React from 'react'

import { Button, Row, Col, Select, Typography } from 'antd'
const { Title, Text } = Typography

const SelectProject = (props) => {
    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Title level={2} style={{paddingTop: '15px'}}>Bulk Upload</Title>
                    <Text>
                        Upload a CSV to add many referrals to the selected project
                    </Text>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Select
                        placeholder="Select a project"
                        onChange={(val) => props.selectProj(val)}
                        style={{
                            width: '100%',
                            marginTop: '5px',
                            marginBottom: "15px",
                        }}>
                        <Select.Option key="HCBS" value="HCBS">
                            HCBS
                        </Select.Option>
                        <Select.Option key="TOC" value="TOC">
                            TOC
                        </Select.Option>
                        <Select.Option key="CMO" value="CMO">
                            CMO
                        </Select.Option>
                        <Select.Option key="CBHC-MVP" value="CBHC-MVP">
                            CBHC-MVP
                        </Select.Option>
                    </Select>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Button
                        type="primary"
                        disabled={!props.project}
                        onClick={props.next}>
                        Next
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default SelectProject
