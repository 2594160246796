import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    HomeOutlined,
    TeamOutlined,
    LinkOutlined,
    BankOutlined,
    ToolOutlined,
    FolderOpenOutlined,
    UserOutlined,
    UploadOutlined,
    FormOutlined,
    DownloadOutlined,
    DatabaseOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'

import { logoutUser } from '../../../core/actions/authActions'
import favicon from '../../../styles/img/logo.png'

const { Sider } = Layout
const { SubMenu } = Menu

const SideBar = (props) => {
    const [selectedMenu, setSelectedMenu] = useState(props.location.pathname)
    return (
        <Sider collapsed={props.collapsed} collapsible collapsedWidth="0" breakpoint="lg" trigger={null}>
            <div className="">
                <h1
                    style={{
                        fontSize: '1.5em',
                        fontFamily: 'Manrope, sans-serif',
                        color: '#ffffffdb',
                        fontWeight: '700',
                        padding: '0 34px 0 16px',
                        margin: '15px 0 ',
                    }}>
                    <img src={favicon} style={{ marginRight: '0.5em', maxHeight: '1em' }} alt="icon" />
                    ConnexRX
                </h1>
            </div>
            <Menu theme="dark" defaultOpenKeys={['referrals', 'user', 'HIE']} defaultSelectedKeys={['/dashboard']} mode="inline" onClick={(e) => setSelectedMenu(e.key)} selectedKeys={[selectedMenu]}>
                <Menu.Item key="/dashboard">
                    <Link to="/dashboard">
                        <HomeOutlined />
                        <span>Dashboard</span>
                    </Link>
                </Menu.Item>
                <SubMenu
                    key="referrals"
                    title={
                        <span>
                            <span>Referrals</span>
                        </span>
                    }>
                    <Menu.Item key={`/addreferral/${props.currentProject}`}>
                        <FormOutlined style={{ paddingRight: '10px' }}></FormOutlined>
                        <Link to={`/addreferral/${props.currentProject}`}>Add Referral</Link>
                    </Menu.Item>
                    {props.auth.user.user_type === 'CBHS' && (
                        <Menu.Item key="/uploadcsv">
                            <UploadOutlined style={{ paddingRight: '10px' }}></UploadOutlined>
                            <Link to="/uploadcsv">Upload CSV</Link>
                        </Menu.Item>
                    )}
                    {props.auth.user.user_type === 'CBHS' && (
                        <Menu.Item key="/reports">
                            <FolderOpenOutlined style={{ paddingRight: '10px' }}></FolderOpenOutlined>
                            <Link to="/reports">
                                <span>Reporting</span>
                            </Link>
                        </Menu.Item>
                    )}
                </SubMenu>
                <SubMenu
                    key="HIE"
                    title={
                        <span>
                            <span>Resources</span>
                        </span>
                    }>
                    <Menu.Item key="RHIO">
                        <LinkOutlined style={{ paddingRight: '10px' }}></LinkOutlined>
                        <a href="http://www.healtheconnections.org/" rel="noopener noreferrer" target="_blank">
                            Link to HIE
                        </a>
                    </Menu.Item>
                    {props.auth.user.user_type === 'CBHS' && (
                        <Menu.Item key="/hie">
                            <DatabaseOutlined style={{ paddingRight: '10px' }}></DatabaseOutlined>
                            <Link to="/hie/manage">HIE Files</Link>
                        </Menu.Item>
                    )}
                    <Menu.Item key="master">
                        <DownloadOutlined style={{ paddingRight: '10px' }}></DownloadOutlined>
                        <a href="assets/ConnexRX_Master_Class_Basics_for_Agencies.zip" rel="noopener noreferrer" target="_blank">
                            Master Classes
                        </a>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="user"
                    title={
                        <span>
                            <span>User</span>
                        </span>
                    }>
                    <Menu.Item key="/account">
                        <ToolOutlined style={{ paddingRight: '10px' }}></ToolOutlined>
                        <Link to="/account">Account</Link>
                    </Menu.Item>
                    {props.auth.user.user_type === 'CBHS' && (
                        <Menu.Item key="/invite">
                            <UserOutlined style={{ paddingRight: '10px' }}></UserOutlined>
                            <Link to="/invite">Invite User</Link>
                        </Menu.Item>
                    )}
                    {props.auth.user.user_type === 'CBHS' && (
                        <Menu.Item key="/list">
                            <TeamOutlined style={{ paddingRight: '10px' }}></TeamOutlined>
                            <Link to="/list">Manage Users</Link>
                        </Menu.Item>
                    )}
                    {props.auth.user.user_type === 'CBHS' && (
                        <Menu.Item key="/agencies">
                            <BankOutlined style={{ paddingRight: '10px' }}></BankOutlined>
                            <Link to="/agencies/manage">Agencies</Link>
                        </Menu.Item>
                    )}
                </SubMenu>
            </Menu>
        </Sider>
    )
}

SideBar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
}

export default connect(null, { logoutUser })(SideBar)
