import React, { Component } from 'react'
import axios from 'axios'

import { Breadcrumb, Button, Col, Card, Form, Input, Layout, Row, Typography, message } from 'antd'
import { StepBackwardOutlined } from '@ant-design/icons'

const { Title } = Typography
const { Content } = Layout

class AgencyEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            agency: {},
            loading: true,
        }
    }

    async componentDidMount() {
        await this.fetchAgency()
    }

    fetchAgency = async () => {
        const response = await axios.post('/api/agencies/fetch_agency', { id: this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1) })
        this.setState({ agency: response.data[0], loading: false })
    }

    onFinish = (form_values) => {
        const updated_agency = {
            ...form_values,
            id: this.state.agency._id,
        }
        axios
            .post('/api/agencies/update_agency', updated_agency)
            .then((response) => {
                message.success('Agency information successfully updated!')
            })
            .catch((error) => {
                this.setState({
                    err: error.response,
                })
            })
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Card style={{ margin: '20px 16px', width: '100%' }}>
                        <div style={{ marginBottom: '16px', width: '100%' }}>
                            <Breadcrumb>
                                <Breadcrumb.Item>User</Breadcrumb.Item>
                                <Breadcrumb.Item>Agencies</Breadcrumb.Item>
                                <Breadcrumb.Item>Edit</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Title level={2}>Edit Agency Information</Title>
                        <p>
                            You are currently editing the agency information for <strong>{this.state.agency.agency}</strong>
                        </p>
                    </Card>
                    <Content style={{ margin: '20px 16px 0' }}>
                        <Row>
                            <Col span={24}>
                                <Button type="primary" onClick={() => window.location.replace('/#/agencies/manage')}>
                                    <StepBackwardOutlined />
                                    Back 
                                </Button>
                            </Col>
                        </Row>
                        <br />
                        <Card>
                            <Row gutter={[12, 24]}>
                                <Col span={8}>
                                    {this.state.loading === false && (
                                        <Form
                                            onFinish={this.onFinish}
                                            layout="vertical"
                                            initialValues={{
                                                address: this.state.agency.address,
                                                city: this.state.agency.city,
                                                zip: this.state.agency.zip,
                                                contact: this.state.agency.contact,
                                            }}>
                                            <Form.Item label="Address" name="address">
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="City" name="city">
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="Zip" name="zip">
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="Contact" name="contact">
                                                <Input />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="secondary" htmlType="submit" style={{borderColor: '#044268'}}>
                                                    Update Information
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Content>
                </div>
            </React.Fragment>
        )
    }
}

export default AgencyEdit
