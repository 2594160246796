import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Menu } from 'antd'
import { LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons'
import TimeoutModal from '../timeout-modal/timeout-modal'

import jwt_decode from 'jwt-decode'
import { logoutUser, renewSession } from '../../../core/actions/authActions'

import LIGHT_THEME from '../../../styles/themes/light.json'

const CustomHeader = (props) => {
    const [showModal, setShowModal] = useState(false)
    const logoutTimer = useRef(null)
    const popUpTimer = useRef(null)

    const startSessionTimer = () => {
        if (localStorage.jwtToken) {
            const token = localStorage.jwtToken
            const decoded = jwt_decode(token)
            const currentTime = Date.now() / 1000
            // when expiry time less than 2 mins show pop up warning
            const warningTime = decoded.exp - currentTime - 60 // 120 sec = 2 mins
            // console.log('Remaining second before logged out', decoded.exp - currentTime)
            // console.log('token exp is ', decoded.exp, 'warning time is', warningTime, 'current time is', currentTime )
            if (decoded.exp > currentTime) {
                logoutTimer.current = setTimeout(() => {
                    handleLogout()
                }, (decoded.exp - currentTime) * 1000)

                popUpTimer.current = setTimeout(() => {
                    setShowModal(true)
                }, warningTime * 1000)
            } else {
                setTimeout(() => {
                    handleLogout()
                }, 1)
            }
        }
    }

    const togglePopup = () => {
        setShowModal(!showModal)
    }

    const handleStayLoggedIn = () => {
        props.renewSession()

        clearTimeout(logoutTimer.current)
        logoutTimer.current = null
        clearTimeout(popUpTimer.current)
        popUpTimer.current = null

        setTimeout(() => {
            startSessionTimer()
        }, 1000)

        setShowModal(false)
    }

    const handleLogout = () => {
        props.logoutUser()
    }

    useEffect(() => {
        startSessionTimer()
    }, [])

    const menu = (
        <Menu>
            <Menu.ItemGroup>
                <Menu.Item>
                    <Link to="/account">
                        <SettingOutlined style={{ marginRight: '0.5em' }} />
                        Account
                    </Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                    onClick={() => {
                        let themeVars = LIGHT_THEME
                        themeVars = {
                            ...themeVars,
                            '@white': '#fff',
                            '@black': '#000',
                        }
                        localStorage.setItem('theme', 'light')
                        localStorage.setItem('theme-vars', JSON.stringify(themeVars))
                        window.less.modifyVars(themeVars).catch((error) => {})

                        props.logoutUser()
                    }}>
                    <LogoutOutlined /> Sign Out
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    )
    return (
        <div className="main-header">
            {React.createElement(props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => props.setCollapsed(!props.collapsed),
            })}
            <span style={{ fontSize: '1em', letterSpacing: '2px' }}>{props.currentProject}</span>
            <div
                style={{
                    float: 'right',
                    marginRight: '16px',
                    marginTop: '16px',
                }}>
                <div>
                    <Dropdown
                        className="header-user-button"
                        overlay={menu}
                        overlayStyle={{
                            textAlign: 'left',
                        }}
                        placement="bottomLeft">
                        <Button style={{ display: 'inline-block', textAlign: 'center', borderRadius: '0.5em' }}>
                            {props.auth.user.name} <UserOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </div>
            <TimeoutModal showModal={showModal} togglePopup={togglePopup} handleStayLoggedIn={handleStayLoggedIn} />
        </div>
    )
}

CustomHeader.propTypes = {
    renewSession: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
}

export default connect(null, { renewSession, logoutUser })(CustomHeader)
