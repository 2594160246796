import React from 'react'
import { useState, useEffect } from 'react'

const INITIAL_MINUTES = 3

const Timer = (props) => {
    const { initialMinute = INITIAL_MINUTES, initialSeconds = 0, resetTimer, handleResetTimer, handleDisableResendOTP } = props
    const [minutes, setMinutes] = useState(initialMinute)
    const [seconds, setSeconds] = useState(initialSeconds)

    const handleResetLocal = () => {
        if (resetTimer) {
            setMinutes(INITIAL_MINUTES)
            handleResetTimer(false)
            handleDisableResendOTP(true)
        } else {
            handleDisableResendOTP(false)
        }
    }

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                    handleResetLocal()
                } else {
                    setMinutes(minutes - 1)
                    setSeconds(59)
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval)
        }
    })

    return (
        <>
            {minutes === 0 && seconds === 0 ? null : (
                <span>
                    {' '}
                    ({minutes}:{seconds < 10 ? `0${seconds}` : seconds})
                </span>
            )}
        </>
    )
}

export default Timer
