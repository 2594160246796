import React from 'react'
import { Row, Col, Typography } from 'antd'

import './terms-conditions.scss'

const { Title } = Typography

function TermsConditions() {
    return (
        <Row
            type="flex"
            justify="center"
            align="middle"
            style={{
                minHeight: '80vh',
                marginTop: '1em',
                marginBottom: '5em',
            }}>
            <Col xs={16}>
                <Title
                    level={1}
                    style={{
                        textAlign: 'center',
                        marginTop: '1em',
                        marginBottom: '1em',
                    }}>
                    Terms and Conditions
                </Title>
                <p>
                    By ACCESSING THE SERVICES, YOU ARE AGREEING TO BE BOUND BY THESE TERMS & CONDITIONS. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS & CONDITIONS, THEN DO NOT USE OR ACCESS THE
                    SERVICES
                </p>
                <Title level={3}>1. Definitions</Title>
                <p>
                    When we say “Services” we mean any website developed and maintained by, and services offered by CBHS LLC, including the website at{' '}
                    <a href="https://www.cbhscareconnections.com" target="_blank" rel="noopener noreferrer">
                        www.cbhscareconnections.com
                    </a>{' '}
                    a.k.a CONNEXRX. When we say “we” or “us” we mean CBHS IPA, LLC . “You,” “Your,” or “User” means any person who accesses or uses the Services. When we say this “Agreement” we are
                    referring to these Services Terms & Conditions, including the Privacy Policy and the other documents referred to in this document.
                </p>
                <Title level={3}>2. Scope of terms</Title>
                <p>The Services may be used only under this Agreement made between CBHS IPA, LLC and You.</p>
                <p>
                    CBHS IPA, LLC reserves the right to modify this Agreement at any time without notice to User. User agrees to review this Agreement periodically to ensure awareness of any
                    modifications. User’s continued access or use of the Services after modifications have become effective shall be deemed conclusive acceptance of the modified terms.
                </p>
                <p>CBHS IPA, LLC reserves the right, but shall not be obligated, to monitor use of the Services.</p>
                <p>
                    User is solely responsible for providing the equipment related to accessing the Services, including but not limited to all computer, remote communications equipment, telephone, and
                    other equipment.
                </p>
                <p>
                    When an individual has authorized You to share his or her health information with us, You are responsible for the use and disclosure of the individual’s health information obtained
                    via the Services in accordance with the Health Insurance Portability and Accountability Act of 1996, as modified by the Health Information Technology for Economic and Clinical
                    Health Act, and its implementing regulations (“HIPAA”) and any other applicable privacy and security laws or regulations.
                </p>
                <Title level={3}>3. License and Limitations</Title>
                <p>
                    You are granted a personal, revocable, limited, non-exclusive, non- transferable, non-assignable license to access and use the Services, conditioned on Your continued acceptance
                    of, and compliance with, this Agreement.
                </p>
                <p>Users may not do any of the following while using the Services:</p>
                <ul>
                    <li>interfere with or disrupt networks connected to the Services or violate the regulations, policies or procedures of such networks;</li>
                    <li>attempt to gain unauthorized access to the Services, computer systems or networks connected to the Services, through password mining or any other means;</li>
                    <li>interfere with another user’s use and enjoyment of the Services;</li>
                    <li>
                        upload any message, information, data, text, software, graphic files or other materials (“End User Content”) that is unlawful, harmful, threatening, abusive, harassing,
                        torturous, defamatory, vulgar, obscene, libelous, that may be invasive of another’s privacy, hateful, racially, sexually, ethnically or otherwise objectionable;
                    </li>
                    <li>
                        use the Services (i) in an offensive manner towards any individual or person, (ii) for political purposes, (iii) to harm or exploit minors in any way, (iv) to post, submit or
                        transmit unlawful, harmful, tortuous, defamatory, profane, libelous, hateful or otherwise offensive material, (v) in a racially, ethnically or otherwise impermissibly
                        discriminating manner, (vi) to post or reference obscene, pornographic, indecent or illegal materials, (vii) to discuss, incite or otherwise solicit illegal activity, or (viii)
                        in any manner that infringes upon the rights of any individual or other person, including, but not limited to intellectual property, publicity or privacy rights;
                    </li>
                    <li>upload End User Content that contains a virus, corrupted file, or other harmful component;</li>
                    <li>
                        impersonate any person or entity, including, but not limited to, CBHS IPA, LLC personnel, or falsely state or otherwise misrepresent any affiliation with any person or entity,
                        or upload any End User Content under a fictitious name;
                    </li>
                    <li>
                        upload any End User Content that You do not have a right to upload under law or under contractual or fiduciary relationships including but not limited to nondisclosure
                        agreements;
                    </li>
                    <li>upload any information or grant permissions to view information to any person in a manner that exceeds your contractual or other legal authority;</li>
                    <li>
                        upload any unsolicited advertising, promotional materials, junk mail, spam , chain letters, pyramid schemes, contests, surveys, or any other form of solicitation, commercial or
                        non-commercial;
                    </li>
                    <li>use any robot, spider or other automatic device or manual process to monitor or copy any web pages or any content contained on or available through the Services;</li>
                    <li>intentionally or unintentionally violate or encourage or assist another to violate any law or regulation in connection with Your use or another’s use of the Services; or</li>
                    <li>upload any End User Content that violates or infringes any patent, trademark, trade secret, copyright, privacy, or any right, of whatever nature, of anyone.</li>
                </ul>
                <p>If You violate any of the terms of this Section 3, Your permission to use the Services automatically terminates.</p>
                <p>
                    You agree to defend, indemnify and hold harmless CBHS IPA, LLC and its affiliates and their officers, directors, employees and agents, from and against any and all third-party
                    claims, liability, actions, demands, costs or expenses (including, but not limited to, reasonable legal and accounting fees) arising from or relating to Your web site. CBHS IPA,
                    LLC reserves the right to defend any such claim, and You agree to provide us with such reasonable cooperation as CBHS IPA, LLC may request.
                </p>
                <Title level={3}>4. Username and password</Title>
                <p>
                    User may be required to select a username and password when accessing certain portions of the Services. CBHS IPA, LLC may refuse to grant a username that impersonates someone else,
                    is protected by trademark or proprietary law, or is vulgar or otherwise offensive, as determined by CBHS IPA, LLC ’s sole discretion. User is solely and fully responsible for
                    maintaining the confidentiality of his/her username and password and is solely and fully responsible for all activities that occur under his/her username and password. User agrees
                    to: (a) immediately notify CBHS IPA, LLC of any unauthorized use of a username and password or any other breach of security and (b) ensure that User logs off at the end of each
                    session. <b>CBHS IPA, LLC will not be liable for any loss or damage arising from User’s failure to comply with this Section 6.</b>
                </p>
                <Title level={3}>5. Privacy Policy</Title>
                <p>
                    Please review our Privacy Policy to understand our practices relating to the collection and use of Your personal information. By accessing and using the Services, You agree to the
                    terms of our Privacy Policy, which are incorporated into this Agreement by this reference.
                </p>
                <Title level={3}>6. Your Representations and Warranties</Title>
                <p>
                    By accessing the Services, You represent and warrant that (i) You are at least eighteen (18) years of age, (ii) You have the legal ability and authority to enter into this
                    Agreement with CBHS IPA, LLC , and (iii) the information You have provided to CBHS IPA, LLC in Your registration is accurate and complete. If any information You provide to CBHS
                    IPA, LLC becomes inaccurate, incomplete or otherwise false or misleading, You will immediately notify CBHS IPA, LLC .
                </p>
                <Title level={3}>7. Disclaimer</Title>
                <p>
                    The content and information provided through the Services is for informational purposes only and is not intended as medical advice. The Services do not replace or substitute for
                    medical advice, diagnosis, or treatment for any reason. CBHS IPA, LLC also cannot answer unsolicited e- mails requesting personal medical advice. You should always consult with a
                    doctor or other health care professional for medical advice or information about diagnosis and treatment. Use of the Services does not guarantee any results and results may vary.
                </p>
                <p>
                    Although we try to provide accurate information, we do not guarantee or expressly or impliedly warrant the reliability, accuracy, timeliness, or completeness of the information.
                    NEITHER CBHS IPA, LLC NOR ANY OF ITS AFFILIATES, AGENTS, EMPLOYEES, OR ASSIGNS WARRANTS THE ACCURACY, ADEQUACY, OR COMPLETENESS OF THE INFORMATION PROVIDED THROUGH THE SERVICES AND
                    EACH EXPRESSLY DISCLAIMS LIABILITY FOR ERRORS AND OMISSIONS IN THE INFORMATION.
                </p>
                <p>
                    <b>
                        YOU ACCESS AND USE THE SERVICES AT YOUR OWN RISK. THE SERVICES ARE PROVIDED ON AN “AS IS, AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND AND ANY AND ALL WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON- INFRINGEMENT OR ANY WARRANTIES ARISING OUT OF A COURSE OF DEALING, TRADE OR PERFORMANCE, ARE SPECIFICALLY DISCLAIMED.
                        NEITHER CBHS IPA, LLC NOR ITS AFFILIATES, EMPLOYEES, AGENTS, OR THIRD-PARTY CONTENT PROVIDERS SHALL BE LIABLE FOR ANY LOSS RESULTING FROM USE OR UNAVAILABILITY OF THE SERVICES,
                        INCLUDING BUT NOT LIMITED TO ANY LOST PROFITS, LOSS OR DAMAGE TO DATA, OR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, COMPENSATORY, OR INCIDENTAL DAMAGES, EVEN IF THEY HAVE
                        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    </b>{' '}
                    No advice or information, whether oral or written, obtained by You from CBHS IPA, LLC , Your provider or their respective affiliates or vendors in any manner will create any
                    warranty.
                </p>
                <Title level={3}>8. Limitation of Liability</Title>
                <p>
                    IN NO EVENT WILL CBHS IPA, LLC OR ANY OF ITS AFFILIATES, AGENTS, EMPLOYEES, OR ASSIGNS BE LIABLE FOR ANY DATA CONTAINED IN THE SERVICES OF THE CBHS IPA, LLC , DELAY, DIFFICULTY IN
                    USE, COMPUTER VIRUSES, MALICIOUS CODE OR OTHER DEFECT IN THIS SITE, ANY COMPATIBILITY BETWEEN THE SERVICES AND THE USER’S FILES, AND THE USER’S BROWSER OR OTHER SITE ACCESSING THE
                    SERVICES.
                </p>
                <p>
                    THE PROVISIONS IN THIS SECTION 10 ARE APPLICABLE TO ANY DAMAGE OR INJURY RESULTING FROM NEGLIGENCE OR OMISSION OF CBHS IPA, LLC , COMPUTER VIRUS OR OTHER SIMILAR ITEM,
                    TELECOMMUNICATIONS ERRORS, OR UNAUTHORIZED ACCESS TO, OR USE OF, USER INFORMATION THROUGH THEFT OR ANY OTHER MEANS. USER AGREES THAT CBHS IPA, LLC IS NOT LIABLE FOR CRIMINAL,
                    TORTIOUS, OR NEGLIGENT ACTIONS OR OMISSIONS OF THIRD PARTIES THAT AFFECT THE DATA OR SERVICES.
                </p>
                <p>
                    YOU ACKNOWLEDGE AND UNDERSTAND THAT THE RECORDS AND DATA WHICH ARE DISCLOSED TO YOU THROUGH THE SERVICES ARE PROTECTED BY FEDERAL CONFIDENTIALITY RULES (42 CFR PART 2). THE FEDERAL
                    RULES PROHIBIT YOU FROM MAKING ANY FURTHER DISCLOSURE OF THIS RECORD UNLESS FURTHER DISCLOSURE IS EXPRESSLY PERMITTED BY THE WRITTEN CONSENT OF THE INDIVIDUAL WHOSE INFORMATION IS
                    BEING DISCLOSED IN THIS RECORD OR, IS OTHERWISE PERMITTED BY 42 CFR PART 2. A GENERAL AUTHORIZATION FOR THE RELEASE OF MEDICAL OR OTHER INFORMATION IS NOT SUFFICIENT FOR THIS
                    PURPOSE (SEE § 2.31). THE FEDERAL RULES RESTRICT ANY USE OF THE INFORMATION TO INVESTIGATE OR PROSECUTE WITH REGARD TO A CRIME ANY PATIENT WITH A SUBSTANCE USE DISORDER, EXCEPT AS
                    PROVIDED AT §§ 2.12(C)(5) AND 2.65
                </p>
                <p>
                    USER AGREES NOT TO SEEK TO HOLD CBHS IPA, LLC OR ANY OF ITS AFFILIATES, AGENTS, EMPLOYEES, ASSIGNS, OR THIRD-PARTY CONTENT PROVIDERS LIABLE FOR ANY TORTIOUS OR ILLEGAL CONDUCT OF
                    OTHER USERS.
                </p>
                <p>
                    USER AGREES NOT TO SEEK TO HOLD CBHS IPA, LLC OR ANY OF ITS AFFILIATES, AGENTS, EMPLOYEES, OR ASSIGNS LIABLE FOR ANY DAMAGE TO EQUIPMENT, HARDWARE, OR OTHER PROPERTY OF USER OR
                    PERSONAL INJURY THAT ARISES IN CONNECTION WITH USE OF THE SERVICES.
                </p>
                <p>YOUR SOLE AND EXCLUSIVE REMEDY IN THE EVENT OF SUCH DAMAGE IS TO DISCONTINUE YOUR ACCESS TO AND USE OF THE SERVICES.</p>
                <p>
                    NOTWITHSTANDING THE FOREGOING OR ANYTHING IN THE AGREEMENT TO THE CONTRARY, IN NO EVENT WILL THE AGGREGATE LIABILITY OF CBHS IPA, LLC AND ITS AFFILIATES AND VENDORS FOR ANY REASON
                    EXCEED $50.00 US DOLLARS, REGARDLESS OF WHETHER THE FOREGOING REMEDIES SHOULD FAIL OF THEIR ESSENTIAL PURPOSE. YOU UNDERSTAND THAT THIS PROVISION IS A MATERIAL TERM OF THIS
                    AGREEMENT, WITHOUT WHICH CBHS IPA, LLC WOULD NOT PROVIDE ACCESS TO THE SERVICES.
                </p>
                <p>
                    USER AGREES THAT, REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR ACTION ARISING OUT OF OR REGARDING THIS AGREEMENT MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH
                    CLAIM OR CAUSE OF ACTION AROSE OR BE FOREVER BARRED.
                </p>
                <p>THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU TO THE EXTENT SUCH LIMITATION IS PROHIBITED BY APPLICABLE LAW.</p>
                <Title level={3}>9. Indemnification</Title>
                <p>
                    User understands and agrees that he or she is personally responsible for his or her behavior while accessing or using the Services. User agrees to indemnify, defend, and hold
                    harmless CBHS IPA, LLC , its directors, officers, employees, agents, and assigns from and against all losses, expenses, damages, costs (including, but not limited to, direct,
                    incidental, consequential, exemplary, punitive, and direct damages), reasonable attorneys’ fees, resulting from or arising out of the User’s use, misuse, or inability to use the
                    Services or any violation by the User of this Agreement. NOTWITHSTANDING THE FOREGOING, CBHS IPA, LLC AND ITS VENDORS RESERVE THE RIGHT TO PARTICIPATE IN THE DEFENSE OF ANY SUCH
                    CLAIM SUBJECT TO YOUR INDEMNIFICATION OBLIGATION.
                </p>
                <Title level={3}>10. Termination of Agreement</Title>
                <p>
                    Either CBHS IPA, LLC or User may terminate this Agreement at its discretion. In addition to CBHS IPA, LLC ’s other rights, CBHS IPA, LLC may terminate access to the Services if
                    User breaches this Agreement in any way or engages in conduct that CBHS IPA, LLC deems inappropriate. In the event of termination of this Agreement, Your right to use the Services
                    shall immediately terminate and the provisions in this Section and the provisions found in Sections 4, 6, 7, and 9-14 shall survive.
                </p>
                <Title level={3}>11. Governing laws</Title>
                <p>
                    The Services are based in the United States. Access to the Services may not be legal by certain persons or in certain countries. If You access the Services from outside the United
                    States, You do so at Your own risk and are responsible for compliance with the laws of Your jurisdiction.
                </p>
                <p>
                    This Agreement will be governed by the internal laws of the State of Illinois, without regard to its choice of law provisions. You agree that any action arising out of or relating
                    to this Agreement may be brought only in a court of competent jurisdiction in Cook County, Illinois, and You hereby consent to the jurisdiction, venue, and convenience of such
                    courts.
                </p>
                <Title level={3}>12. Miscellaneous</Title>
                <p>
                    Upon User’s breach of this Agreement, CBHS IPA, LLC may pursue any legal or equitable remedy available, including but not limited to, direct, consequential, and punitive damages as
                    well as injunctive relief. CBHS IPA, LLC ’s remedies are cumulative and not exclusive. Failure of CBHS IPA, LLC to exercise any remedy or enforce any portion of this Agreement at
                    any time shall not operate as a waiver of any remedy or of the right to enforce any portion of the Agreement at any time thereafter.
                </p>
                <p>
                    In the event of any dispute, the prevailing party will be entitled to recovery of its reasonable attorneys’ fees and costs. If a court or arbitrator deems any provision of this
                    Agreement unenforceable or invalid, then the court or arbitrator will modify such provision to the minimum extent necessary to make such provision enforceable and valid. If such
                    modification proves impossible or impracticable then the provision will be severed and the remaining terms of this Agreement interpreted and read to give them maximum
                    enforceability.
                </p>
                <p>
                    This Agreement, together with the Privacy Policy constitute the entire agreement between You and CBHS IPA, LLC with respect to the subject matter discussed above. Any waiver of any
                    provision of this Agreement will be effective only if in writing and signed by CBHS IPA, LLC . This Agreement supersedes and replaces all prior or contemporaneous understandings or
                    agreements, written or oral, regarding this subject matter. This Agreement will inure to the benefit of CBHS IPA, LLC ’s successors, assigns, and licensees. Print a copy of this
                    Agreement for Your records.
                </p>
                <p style={{ textAlign: 'center', fontSize: '1.2em', marginTop: '3em' }}>
                    Back to{' '}
                    <a type="link" href="/#/login">
                        Login
                    </a>
                </p>
            </Col>
        </Row>
    )
}

export default TermsConditions
