import React, { Component } from 'react'
import { Card, Col, Statistic, Row, Descriptions, Tag, List } from 'antd'

class DocsHistory extends Component {
    historyFormatter = (item) => {
        let listItem = []
        if (item.created) {
            for (var [key, value] of Object.entries(item.created)) {
                if (typeof value === 'string') {
                    if (key === 'tab' || key === 'category')
                    listItem.push(
                        <li key={key + value} style={{ paddingTop: '5px' }}>
                            <Tag color="lightskyblue">ADDED: </Tag>
                            <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>{value}</span>
                        </li>,
                    )
                }
                if (key === 'recipients') {
                    if ( Object.keys(value).length > 0) {
                        let rec = value.map((ele, index) => {
                            if (index < value.length - 1) {
                                return ele + ', '
                            } else {
                                return ele
                            }
                        })
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">ADDED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>{rec}</span>
                            </li>,
                        ) 
                    } else {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">ADDED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>Notification not sent</span>
                            </li>,
                        )
                    }
                }
                if (key === 'message') {
                    if (value !== null) {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">ADDED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>{value}</span>
                            </li>,
                        )
                    } else {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">ADDED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>No message added</span>
                            </li>,
                        )
                    }
                }
                if (key === 'priority') {
                    if (value) {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">ADDED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>High Priority</span>
                            </li>,
                        )
                    } else {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">ADDED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>Low Priority</span>
                            </li>,
                        )
                    }
                }
            }
        }
        if (item.added) {
            for (var [key, value] of Object.entries(item.added)) {
                if (typeof value === 'string') {
                    if (key === 'tab' || key === 'category')
                    listItem.push(
                        <li key={key + value} style={{ paddingTop: '5px' }}>
                            <Tag color="lightskyblue">SAVED: </Tag>
                            <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>{value}</span>
                        </li>,
                    )
                }
                if (key === 'recipients') {
                    if ( Object.keys(value).length > 0) {
                        for (const ele in value) {
                            listItem.push(
                                <li key={key + value} style={{ paddingTop: '5px' }}>
                                    <Tag color="lightskyblue">SAVED: </Tag>
                                    <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>{value[ele]}</span>
                                </li>,
                            )
                        }
                    } else {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>Notification not sent</span>
                            </li>,
                        )
                    }
                }
                if (key === 'message') {
                    if (value !== null) {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>{value}</span>
                            </li>,
                        )
                    } else {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>No message added</span>
                            </li>,
                        )
                    }
                }
                if (key === 'priority') {
                    if (value) {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>High Priority</span>
                            </li>,
                        )
                    } else {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>Low Priority</span>
                            </li>,
                        )
                    }
                }
            }
        }
        if (item.updated) {
            for (var [key, value] of Object.entries(item.updated)) {
                if (typeof value === 'string') {
                    if (key === 'tab' || key === 'category')
                    listItem.push(
                        <li key={key + value} style={{ paddingTop: '5px' }}>
                            <Tag color="lightskyblue">SAVED: </Tag>
                            <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>{value}</span>
                        </li>,
                    )
                }
                if (key === 'recipients') {
                    if ( Object.keys(value).length > 0) {
                        let rec = value.map((ele, index) => {
                            if (index < value.length - 1) {
                                return ele + ', '
                            } else {
                                return ele
                            }
                        })
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>{rec}</span>
                            </li>,
                        ) 
                    } else {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>Notification not sent</span>
                            </li>,
                        )
                    }
                }
                if (key === 'message') {
                    if (value !== null) {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>{value}</span>
                            </li>,
                        )
                    } else {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>No message added</span>
                            </li>,
                        )
                    }
                }
                if (key === 'priority') {
                    if (value) {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>High Priority</span>
                            </li>,
                        )
                    } else {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">SAVED: </Tag>
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> <span>Low Priority</span>
                            </li>,
                        )
                    }
                }
            }
        }
        return listItem
    }
    render() {
        const { row } = this.props
        return (
            <Col span={24}>
                <Descriptions title="Document History Log" bordered={true} layout={'vertical'} column={24}>
                    <Descriptions.Item>
                        <div
                            style={{
                                height: '30em',
                                overflow: 'scroll',
                            }}>
                            <List
                                dataSource={row.history}
                                renderItem={(item) => (
                                    <List.Item>
                                        <ul
                                            style={{
                                                listStyle: 'none',
                                                padding: 0,
                                            }}>
                                            <p>
                                                [{item.timestamp}] {item.user}:
                                            </p>
                                            {this.historyFormatter(item)}
                                        </ul>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Descriptions.Item>
                </Descriptions>
            </Col>
        )
    }
}

export default DocsHistory