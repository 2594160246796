import moment from 'moment'
import { time_format } from './constants'

/**
 * To check if the given value is number data type
 * Return Bool
 * @param {value} any
 */
export function isNumeric(n) {
    return !isNaN(parseInt(n)) && isFinite(n)
}

/**
 * To check the browser is mobile or not
 * Return Bool
 * @param {} boolean
 */
export function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? true : false
}

/**
 * To check the phone min digit of phone number
 * Return Bool
 * @param {value} number
 */
export function isValidPhone(value = '') {
    /* if length number == 10 or value is empty then valid */
    try {
        if (value === '' || value.match(/\d/g).length === 10) {
            return true
        }
    } catch (err) {
        console.log('Phone validator error')
    }
}

/**
 * To normalize phone number from digit to US format
 * Return String
 * @param {value} string
 * formatPhoneNumber('+12345678900') // => "+1 (234) 567-8900"
 * formatPhoneNumber('2345678900')   // => "(234) 567-8900"
 */
export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        // var intlCode = match[1] ? '+1 ' : ''
        // return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        // Remove +1 from phone number
        return ['(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
}

/**
 * To remove last comma from string with regex
 * Return String
 * @param {value} string
 */
export function removeLastComma(str) {
    return str.replace(/,(\s+)?$/, '')
}

/**
 * To render Boolean value type to string with Yes or No
 * Return String
 * @param {value} string
 */
export function renderBooleanValue(value) {
    if (value === '' || value === undefined || value === null) return ''
    else return typeof value === 'boolean' ? (value === true ? 'Yes' : 'No') : value
}

/**
 * Convert
 * Return String to moment object
 * @param {value} string
 */
export function checkMomentDate(date) {
    if (date === undefined || date === '' || date === 'Invalid date') {
        return ''
    } else {
        return moment(date)
    }
}

/**
 * Convert
 * Return String to moment object
 * @param {value} string
 */
export function checkMomentTime(time) {
    if (time === undefined || time === '' || time === 'Invalid time') {
        return ''
    } else {
        return moment(time, time_format)
    }
}

/**
 * Sanitize string from regex
 * Return String
 * @param {value} string
 */
export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
