import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Layout, Breadcrumb, Row, Col, Card, Steps, message as antMessage } from 'antd'

import { date_format } from '../../../utils/constants'

import ChooseCategoryEdit from './steps/edit/1-choose-category-edit'
import NotificationSettingsEdit from './steps/edit/2-notification-settings-edit'
import ConfirmDataEdit from './steps/edit/3-confirm-edit'

const { Content } = Layout
const { Step } = Steps

const steps = [
    {
        title: 'About',
        content: 'category-tab',
    },
    {
        title: 'Notification',
        content: 'notification-settings',
    },
    {
        title: 'Confirm',
        content: 'confirm',
    },
]

class EditDocs extends Component {
    constructor() {
        super()
        this.state = {
            current: null,
            doc_id: null,
            category: null,
            tab: null,
            recipients: [],
            message : null, 
            priority: false,
        }
    }

    componentDidMount() {
        const doc_id = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        this.setState({
            doc_id
        })
        axios
            .post('/api/referrals/docs_info', { doc_id })
            .then((res) => {
                const data = res.data[0]
                this.setState({
                    original: data
                })
            })
            .then(() => {
                const { original } = this.state
                this.setState({
                    current: 0,
                    referral_id: original.referral[0],
                    category: original.category,
                    tab: original.tab,
                    recipients: original.recipients
                })
            })   
    }

    onFinish = () => {
        const { tab, category, recipients, message, priority, original, doc_id } = this.state
        const data = {
            category: category,
            tab: tab,
            recipients: recipients,
            message: message,
            priority: priority,
        }

        let changes = {}
        changes.updated = {...data}
        changes.timestamp = moment().format(date_format)
        changes.user = this.props.auth.user.name + ' <' + this.props.auth.user.email + '>'

        original.history.unshift(changes)
        const metadata = {...data}
        metadata.history = original.history

        axios
            .post('/api/referrals/docs_edit', { metadata, doc_id })
            .then((res) => {
                antMessage.success(res.data.message)
                this.props.history.push(`/editreferral/shared-care/${this.state.referral_id}`)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    handleCategoryChange = (e) => {
        this.setState({
            category: e
        })
    }

    handleTabChange = (e) => {
        this.setState({
            tab: e
        })
    }

    handleAddRecipients = (e) => {
        this.setState({
            recipients: e
        })
    }

    handleMessageChange = (e) => {
        this.setState({
            message: e.target.value
        })
    }

    handlePriorityChange = (e) => {
        this.setState({
            priority: e.target.checked
        })
    }

    next = () => {
        if(this.state.current === 0) {
            const current = this.state.current + 1
            this.setState({ current })
        }

        if(this.state.current === 1) {
            const current = this.state.current + 1
            this.setState({ current })
        }
    }

    back = () => {
        const current = this.state.current - 1
        this.setState({ current })
    }

    backToReferral = () => {
        this.props.history.push(`/editreferral/shared-care/${this.state.referral_id}`)
    }

    render() {
        const { current } = this.state
        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <Content style={{ width: '100%' }}>
                    <div style={{ marginBottom: '16px', width: '100%' }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>Referrals</Breadcrumb.Item>
                            <Breadcrumb.Item>Edit Documentation</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Card
                        bodyStyle={{
                            padding: 26,
                            width: '100%',
                            marginBottom: '16px'
                        }}>
                        <Row gutter={[24, 24]}>
                            <Col xs={{ span: 24 }}>
                                <Steps current={current} size="small">
                                    {steps.map((item) => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col xs={{ span: 24 }}>
                                <div>
                                    {current === 0 && 
                                        <ChooseCategoryEdit
                                        handleTabChange={this.handleTabChange} 
                                        tab={this.state.tab}
                                        handleCategoryChange={this.handleCategoryChange}
                                        category={this.state.category}
                                        next={this.next} 
                                        backToReferral={this.backToReferral} 
                                        />
                                    }
                                    {current === 1 && 
                                        <NotificationSettingsEdit
                                        next={this.next} 
                                        back={this.back} 
                                        referral_id={this.state.referral_id}
                                        handleAddRecipients={this.handleAddRecipients}
                                        recipients={this.state.recipients}
                                        handleMessageChange={this.handleMessageChange}
                                        message={this.state.message}
                                        handlePriorityChange={this.handlePriorityChange}
                                        priority={this.state.priority}
                                        />
                                    }
                                    {current === 2 && 
                                        <ConfirmDataEdit
                                        onFinish={this.onFinish} 
                                        back={this.back} 
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Content>
            </Content>
        )
    }
}

export default EditDocs
