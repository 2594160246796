import React, { Component } from 'react'
import { Card, Col, Statistic, Row, Descriptions, Tag, List } from 'antd'
import moment from 'moment'
import './modal-referral.scss'
import { PdfReferral } from '../pdf-referral/pdf-referral'
import { date_format, time_format, referral_keys_readable } from '../../../../utils/constants'

import { removeLastComma, renderBooleanValue } from '../../../../utils/helpers'

export class ModalReferral extends Component {
    historyFormatter = (item) => {
        // console.log('item', item)
        var listItem = []

        // Loop through every addition to the referral
        if (item.added) {
            for (var [key, value] of Object.entries(item.added)) {
                // ID gets added / updated during additions but we don't want to log those
                if (key === 'user_access' || key === 'date_received' || key === 'user_id' || key === 'id') {
                    continue
                }
                // Check if the value is an string
                if (typeof value === 'string') {
                    // If we have non empty string we can just render the text
                    // if (value !== '') {
                    // Patch-fix: if we receive string invalid date - its a date thats been removed
                    if (value === 'Invalid date' && value) {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">MODIFIED: </Tag>
                                <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> <span>Removed</span>
                            </li>,
                        )
                    } else {
                        if (value) {
                            listItem.push(
                                <li key={key + value} style={{ paddingTop: '5px' }}>
                                    <Tag color="lightskyblue">MODIFIED: </Tag>
                                    <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> <span>{value}</span>
                                </li>,
                            )
                        }
                        //  }
                    }
                }

                // List of nested objects
                // when the entire program is added
                if (typeof value === 'object' && value !== null) {
                    Object.keys(value).forEach((item) => {
                        if (typeof value[item] === 'object' && value !== 'programs') {
                            const program = value[item]['program']
                            const county = value[item]['county']
                            if (value) {
                                listItem.push(
                                    <li key={key + value} style={{ paddingTop: '5px' }}>
                                        <Tag color="lightskyblue">PROGRAM:</Tag>
                                        <strong>
                                            {program} {'in'} {county} {' County'}
                                        </strong>
                                        {Object.keys(value[item]).map((key) => {
                                            if (key !== 'key') {
                                                // when there is an appointment time first added
                                                if (key === 'appointment_time' && value[item][key] !== '') {
                                                    return (
                                                        <li style={{ paddingTop: '5px' }}>
                                                            <Tag color="#D3D3D3">MODIFIED:</Tag>
                                                            <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong>
                                                            {' ' + moment(value[item][key]).format(time_format)}
                                                        </li>
                                                    )
                                                }
                                                // when there isn't an appointment time added
                                                if (key === 'appointment_time' && value[item][key] === '') {
                                                    return (
                                                        <li style={{ paddingTop: '5px' }}>
                                                            <Tag color="#D3D3D3">MODIFIED:</Tag>
                                                            <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> None added
                                                        </li>
                                                    )
                                                }
                                                // same for appointment date
                                                if (key === 'appointment_date' && value[item][key] === '') {
                                                    return (
                                                        <li style={{ paddingTop: '5px' }}>
                                                            <Tag color="#D3D3D3">MODIFIED:</Tag>
                                                            <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> None added
                                                        </li>
                                                    )
                                                }
                                                if (key === 'version_number' || key === 'version' || key === 'agency_options') {
                                                    return
                                                }
                                                if (key === 'agency_options') {
                                                    return 
                                                }
                                                return (
                                                    <li style={{ paddingTop: '5px' }}>
                                                        <Tag color="#D3D3D3">MODIFIED:</Tag>
                                                        <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong>
                                                        {' ' + value[item][key]}
                                                    </li>
                                                )
                                            }
                                        })}
                                    </li>,
                                )
                            }
                        }
                    })
                }

                // If its an object we render each value separated by commas
                if (typeof value === 'object' && value !== null) {
                    var object_keys = ''
                    var nested_esc = false

                    Object.keys(value).forEach((item) => {
                        if (typeof value[item] === 'object') {
                            nested_esc = true
                        } else {
                            object_keys += value[item] + ', '
                        }
                    })
                    if (nested_esc === false) {
                        if (value) {
                            listItem.push(
                                <li key={key + value} style={{ paddingTop: '5px' }}>
                                    <Tag color="lightskyblue">MODIFIED: </Tag>
                                    <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> {removeLastComma(object_keys)}
                                </li>,
                            )
                        }
                    }
                }

                // For booleans we can say whether its been checked or unchecked
                if (typeof value === 'boolean') {
                    if (value) {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">MODIFIED: </Tag>
                                <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> <span>{value === true ? 'Checked' : 'Unchecked'}</span>
                            </li>,
                        )
                    }
                }
            }
        }

        // Loop through every update to the referral
        if (item.updated) {
            //console.log('updated', item.updated)
            for (var [key, value] of Object.entries(item.updated)) {
                // ID gets added/updated during additions but we don't want to log those
                // if (key === 'id' || key === 'user_access' || key === 'user_id' || key === 'date_received') break
                if (key === 'user_access' || key === 'date_received' || key === 'user_id' || key === 'id') {
                    continue
                }
                // Check if the value is an string
                if (typeof value === 'string') {
                    // If we have non empty string we can just render the text
                    // if (value !== '') {
                    // Patch-fix: if we receive string invalid date - its a date thats been removed.
                    if (value === 'Invalid date') {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">MODIFIED: </Tag>
                                <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> <span>Removed</span>
                            </li>,
                        )
                    } else if (value === '') {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">MODIFIED: </Tag>
                                <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> <span>Removed</span>
                            </li>,
                        )
                    } else {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">MODIFIED: </Tag>
                                <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> <span>{value}</span>
                            </li>,
                        )
                    }
                    // }
                }

                // List of nested objects
                // when the program is edited without adding the entire thing
                if (typeof value === 'object' && value !== null && value === 'programs') {
                    Object.keys(value).forEach((item) => {
                        if (typeof value[item] === 'object') {
                            const program = value[item]['version'].split('-')
                            listItem.push(
                                <li key={key + value} style={{ paddingTop: '5px' }}>
                                    <Tag color="lightskyblue">PROGRAM: </Tag>
                                    <strong>
                                        {program[1]} {'in'} {program[0]} {' County'}
                                    </strong>

                                    {Object.keys(value[item]).map((key) => {
                                        if (key !== 'key') {
                                            if (key === 'appointment_time' && value[item][key] !== '') {
                                                return (
                                                    <li style={{ paddingTop: '5px' }}>
                                                        <Tag color="#D3D3D3">MODIFIED: </Tag>
                                                        <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong>
                                                        {' ' + moment(value[item][key]).format(time_format)}
                                                    </li>
                                                )
                                            }
                                            if (key === 'version_number' || key === 'version' || key === 'agency_options') {
                                                return
                                            }
                                            if (value[item][key] === 'Invalid date') {
                                                return (
                                                    <li style={{ paddingTop: '5px' }}>
                                                        <Tag color="#D3D3D3">MODIFIED: </Tag>
                                                        <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> Removed
                                                    </li>
                                                )
                                            }
                                            // handle when appointment date is deleted
                                            if (value[item][key] === '') {
                                                return (
                                                    <li style={{ paddingTop: '5px' }}>
                                                        <Tag color="#D3D3D3">MODIFIED: </Tag>
                                                        <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> Removed
                                                    </li>
                                                )
                                            }
                                            return (
                                                <li style={{ paddingTop: '5px' }}>
                                                    <Tag color="#D3D3D3">MODIFIED: </Tag>
                                                    <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong>
                                                    {' ' + value[item][key]}
                                                </li>
                                            )
                                        }
                                    })}
                                </li>,
                            )
                        }
                    })
                }

                // If its an object we render each value separated by commas
                if (typeof value === 'object' && value !== null) {
                    var object_keys = ''
                    var nested_esc = false

                    Object.keys(value).forEach((item) => {
                        if (typeof value[item] === 'object') {
                            nested_esc = true
                        } else {
                            object_keys += value[item] + ', '
                        }
                    })
                    if (nested_esc === false) {
                        listItem.push(
                            <li key={key + value} style={{ paddingTop: '5px' }}>
                                <Tag color="lightskyblue">MODIFIED: </Tag>
                                <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> {removeLastComma(object_keys)}
                            </li>,
                        )
                    }
                }

                // For booleans we can say whether its been checked or unchecked
                if (typeof value === 'boolean') {
                    listItem.push(
                        <li key={key + value} style={{ paddingTop: '5px' }}>
                            <Tag color="lightskyblue">MODIFIED: </Tag>
                            <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> <span>{value === true ? 'Checked' : 'Unchecked'}</span>
                        </li>,
                    )
                }

                // Null values mean the field was deleted
                if (value === null) {
                    listItem.push(
                        <li style={{ paddingTop: '5px' }}>
                            <Tag color="lightskyblue">MODIFIED: </Tag>
                            <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> <span>Removed</span>
                        </li>,
                    )
                }
            }
        }

        // Deleted programs are stored as deleted
        if (item.deleted) {
            for (var [key, value] of Object.entries(item.deleted)) {
                listItem.push(
                    <li key={key + value} style={{ paddingTop: '5px' }}>
                        <Tag color="lightskyblue">PROGRAM: </Tag>
                        <strong>{referral_keys_readable[key] ? referral_keys_readable[key] : key}:</strong> <span>{value}</span>
                    </li>,
                )
            }
        }

        // If there is a referral history log but no changes
        if (listItem.length === 0) {
            return (
                <span>
                    <strong>Referral saved without changes</strong>
                </span>
            )
        }

        return listItem
    }

    renderCareManagement = () => {
        const { contact_needs, contact_type, project, contact_date, contact_details, number_of_contacts } = this.props.row

        if (project !== 'TOC' || project !== 'COVID') return ''

        const contact_needs_list = contact_needs !== 0 ? <List size="small" dataSource={contact_needs} renderItem={(item, i) => <List.Item key={i}>{item}</List.Item>} /> : ''
        const contact_type_list = contact_type !== 0 ? <List size="small" dataSource={contact_type} renderItem={(item, i) => <List.Item key={i}>{item}</List.Item>} /> : ''

        return (
            <Row gutter={[16, 24]} className="section-margin">
                <Col>
                    <Descriptions title={project === 'COVID' ? 'Referral Follow-up' : 'Care Management'} bordered layout="vertical">
                        <Descriptions.Item label="Contact Needs">{contact_needs_list}</Descriptions.Item>
                        <Descriptions.Item label="Contact Type">{contact_type_list}</Descriptions.Item>
                        <Descriptions.Item label="Number of Contacts">{number_of_contacts}</Descriptions.Item>
                        <Descriptions.Item label="Contact Date">{contact_date && moment(contact_date).format(date_format)}</Descriptions.Item>
                        <Descriptions.Item label="Contact Details">{contact_details}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        )
    }

    render() {
        const { row } = this.props
        return (
            <React.Fragment>
                <Row gutter={[16, 40]}>
                    <Col xs={24} sm={24}>
                        <PdfReferral row={row} />
                    </Col>
                    <Col></Col>
                </Row>

                {row.project === 'HCBS' || row.project === 'TOC' || row.project === 'COVID' || row.project === 'CMO' || row.project === 'CBHC-MVP' ? (
                    <Row gutter={[16, 24]}>
                        <Col xs={24} sm={12} md={6}>
                            <Card bordered={true}>
                                <Statistic title="Referral Agency" value={row.referral_agency} />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Card bordered={true}>
                                <Statistic title="Referral Status" value={row.referral_status} />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Card bordered={true}>
                                <Statistic title="Number of Cancellations" precision={0} value={row.number_cancelled} />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Card bordered={true}>
                                <Statistic title="Number of No Shows" precision={0} value={row.number_noshows} />
                            </Card>{' '}
                        </Col>
                        <Col span={24}>
                            <Descriptions title="Referral History Log" bordered={true} layout={'vertical'} column={24}>
                                <Descriptions.Item>
                                    <div
                                        style={{
                                            height: '30em',
                                            overflow: 'scroll',
                                        }}>
                                        <List
                                            dataSource={row.history}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <ul
                                                        style={{
                                                            listStyle: 'none',
                                                            padding: 0,
                                                        }}>
                                                        <p>
                                                            [{item.timestamp}] {item.user}:
                                                        </p>
                                                        {this.historyFormatter(item)}
                                                    </ul>
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                ) : (
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <Descriptions title="Referral History Log" bordered={true} column={24}>
                                <Descriptions.Item>
                                    <div
                                        style={{
                                            height: '30em',
                                            overflow: 'scroll',
                                        }}>
                                        <List
                                            dataSource={row.history}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <ul
                                                        style={{
                                                            listStyle: 'none',
                                                            padding: 0,
                                                        }}>
                                                        <p>
                                                            [{item.timestamp}] {item.user}:
                                                        </p>
                                                        {this.historyFormatter(item)}
                                                    </ul>
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                )}

                {row.project !== 'CBHC-MVP' ||
                    ('SHARED-CARE' && (
                        <Row gutter={[16, 24]} className="section-margin">
                            <Col span={24}>
                                <Descriptions title="Referral Information" bordered layout="vertical">
                                    <Descriptions.Item label="Source">{row.referral_source}</Descriptions.Item>
                                    <Descriptions.Item label="Received">{row.date}</Descriptions.Item>
                                    <Descriptions.Item label="Referral Source Email">{row.referral_source_email}</Descriptions.Item>
                                    <Descriptions.Item label="Referral Source Phone">{row.referral_source_phone}</Descriptions.Item>
                                    <Descriptions.Item label="Referral Details">{row.additional_details}</Descriptions.Item>
                                    <Descriptions.Item label="Assigned">{row.date_assigned}</Descriptions.Item>
                                    <Descriptions.Item label="Days Lapsed">{row.time_lapsed}</Descriptions.Item>
                                    <Descriptions.Item label="Need" span={2}>
                                        {row.referral_need.length > 1 ? row.referral_need.map((item) => <Tag>{item}</Tag>) : <Tag>{row.referral_need}</Tag>}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={row.project === 'COVID' ? 'Title' : 'Referral Source Title'}>{row.referral_title}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    ))}

                {this.renderCareManagement()}

                {row.project === 'CBHC-MVP' && (
                    <Row gutter={[16, 24]} className="section-margin">
                        <Col span={24}>
                            <Descriptions title="Health Home" bordered layout="vertical">
                                <Descriptions.Item label="Health Home Enrolled">{renderBooleanValue(row.health_home_enrolled)}</Descriptions.Item>
                                <Descriptions.Item label="Health Home Facility">{row.health_home_facility}</Descriptions.Item>
                                <Descriptions.Item label="Health Home Enrollment Date">{row.health_home_enrollment_date}</Descriptions.Item>
                                <Descriptions.Item label="Care Management Enrolled">{renderBooleanValue(row.care_management_enrolled)}</Descriptions.Item>
                                <Descriptions.Item label="Care Management Facility">{row.care_management_facility}</Descriptions.Item>
                                <Descriptions.Item label="Care Management Enrollment Date">{row.care_management_enrollment_date}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                )}

                {row.project === 'CBHC-MVP' && (
                    <Row gutter={[16, 24]} className="section-margin">
                        <Col span={24}>
                            <Descriptions title="Outreach and Engagement" bordered layout="vertical">
                                <Descriptions.Item label="Contact Type">{row.engagement_contact_type}</Descriptions.Item>
                                <Descriptions.Item label="Contact Date">{row.engagement_date}</Descriptions.Item>
                                <Descriptions.Item label="Contact Time">{row.engagement_time}</Descriptions.Item>
                                <Descriptions.Item label="Number of Contacts">{row.engagement_number_of_contacts}</Descriptions.Item>
                                <Descriptions.Item label="Contact Details">{row.engagement_contact_details}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                )}

                {row.project === 'CBHC-MVP' && (
                    <Row gutter={[16, 24]} className="section-margin">
                        <Col span={24}>
                            <Descriptions title={row.project === 'CBHC-MVP' ? 'PCP Appointment Date and Time' : 'Appointment Date'} bordered layout="vertical">
                                <Descriptions.Item label="Upcoming PCP Appt. Date">{row.appointment_date && moment(row.appointment_date).format(date_format)}</Descriptions.Item>
                                <Descriptions.Item label="Last Annual Physical Date">{row.last_annual_physical_date && moment(row.last_annual_physical_date).format(date_format)}</Descriptions.Item>

                                <Descriptions.Item label="Last PCP Follow-up Date">{row.last_pcp_followup_date && moment(row.last_pcp_followup_date).format(date_format)}</Descriptions.Item>
                                <Descriptions.Item label="Notes">{row.notes || ''}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                )}

                {row.project !== 'CBHC-MVP' ||
                    ('SHARED-CARE' && (
                        <Row gutter={[16, 24]} className="section-margin">
                            <Col span={24}>
                                <Descriptions title="Appointment Date" bordered layout="vertical">
                                    <Descriptions.Item label="Appt. Date">{row.appointment_date && moment(row.appointment_date).format(date_format)}</Descriptions.Item>
                                    <Descriptions.Item label="Appt. Time">{row.appointment_time && moment(row.appointment_time).format(time_format)}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    ))}
                {row.project === 'COVID' && row.is_guardian === 'Y' && (
                    <Row gutter={[16, 24]} className="section-margin">
                        <Col span={24}>
                            <Descriptions title="Guardian Information" bordered layout="vertical">
                                <Descriptions.Item label="Guardian First Name">{row.guardian_first_name || ''}</Descriptions.Item>
                                <Descriptions.Item label="Guardian Last Name">{row.guardian_last_name || ''}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                )}
                <Row gutter={[16, 24]} className="section-margin">
                    <Col span={24}>
                        <Descriptions title="Client Information" bordered layout="vertical">
                            <Descriptions.Item label="ID">{row.nano_id}</Descriptions.Item>
                            <Descriptions.Item label="Date of Birth">{moment(row.dob).format(date_format)}</Descriptions.Item>
                            <Descriptions.Item label="Name">
                                {row.first_name} {row.middle_name} {row.last_name}
                            </Descriptions.Item>
                            <Descriptions.Item label="CIN">{row.cin}</Descriptions.Item>
                            <Descriptions.Item label="Gender">{row.gender}</Descriptions.Item>
                            <Descriptions.Item label="Language">{row.language}</Descriptions.Item>
                            <Descriptions.Item label="Ethnicity">{row.ethnicity}</Descriptions.Item>
                            <Descriptions.Item label="Email Address">{row.email_client}</Descriptions.Item>
                            <Descriptions.Item label="Address Type">{row.address_type}</Descriptions.Item>
                            <Descriptions.Item label="Address">
                                {row.address_1} {row.address_2}
                            </Descriptions.Item>
                            <Descriptions.Item label="County">{row.county}</Descriptions.Item>
                            <Descriptions.Item label="City">{row.city}</Descriptions.Item>
                            <Descriptions.Item label="State">{row.state}</Descriptions.Item>
                            <Descriptions.Item label="ZIP">{row.zip}</Descriptions.Item>
                            <Descriptions.Item label="Phone">{row.phone}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row gutter={[16, 24]} className="section-margin">
                    {row.insurance_1 ? (
                        <Col span={24}>
                            <Descriptions title="Insurance Information" bordered layout="vertical">
                                <Descriptions.Item label="Insurance 1" span={3}>
                                    <b>Type:</b> {row.insurance_1} <br />
                                    {row.insurance_1_plan && (
                                        <React.Fragment>
                                            <b>Plan:</b> {row.insurance_1_plan} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_1_group_number && (
                                        <React.Fragment>
                                            <b>Group Number:</b> {row.insurance_1_group_number} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_1_policy_number && (
                                        <React.Fragment>
                                            <b>Policy Number:</b> {row.insurance_1_policy_number} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_1_effective_date && (
                                        <React.Fragment>
                                            <b>Effective Date:</b> {row.insurance_1_effective_date} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_1_expiration_date && (
                                        <React.Fragment>
                                            <b>Expiration Date:</b> {row.insurance_1_expiration_date} <br />
                                        </React.Fragment>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    ) : (
                        ''
                    )}
                    {row.insurance_2 && (
                        <Col span={24}>
                            <Descriptions bordered layout="vertical">
                                <Descriptions.Item label="Insurance 2" span={3}>
                                    <b>Type:</b> {row.insurance_2} <br />
                                    {row.insurance_2_plan && (
                                        <React.Fragment>
                                            <b>Plan:</b> {row.insurance_2_plan} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_2_group_number && (
                                        <React.Fragment>
                                            <b>Group Number:</b> {row.insurance_2_group_number} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_2_policy_number && (
                                        <React.Fragment>
                                            <b>Policy Number:</b> {row.insurance_2_policy_number} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_2_effective_date && (
                                        <React.Fragment>
                                            <b>Effective Date:</b> {row.insurance_2_effective_date} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_2_expiration_date && (
                                        <React.Fragment>
                                            <b>Expiration Date:</b> {row.insurance_2_expiration_date} <br />
                                        </React.Fragment>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    )}
                    {row.insurance_3 && (
                        <Col span={24}>
                            <Descriptions bordered layout="vertical">
                                <Descriptions.Item label="Insurance 3" span={3}>
                                    <b>Type:</b> {row.insurance_3} <br />
                                    {row.insurance_3_plan && (
                                        <React.Fragment>
                                            <b>Plan:</b> {row.insurance_3_plan} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_3_group_number && (
                                        <React.Fragment>
                                            <b>Group Number:</b> {row.insurance_3_group_number} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_3_policy_number && (
                                        <React.Fragment>
                                            <b>Policy Number:</b> {row.insurance_3_policy_number} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_3_effective_date && (
                                        <React.Fragment>
                                            <b>Effective Date:</b> {row.insurance_3_effective_date} <br />
                                        </React.Fragment>
                                    )}
                                    {row.insurance_3_expiration_date && (
                                        <React.Fragment>
                                            <b>Expiration Date:</b> {row.insurance_3_expiration_date} <br />
                                        </React.Fragment>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    )}
                    {row.project === 'COVID' && (
                        <Col span={24}>
                            <Descriptions bordered layout="vertical">
                                <Descriptions.Item label="HARP" span={3}>
                                    {row.assessment_harp}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    )}
                </Row>

                {row.project === 'HCBS' || row.project === 'CMO' ? (
                    <Row gutter={[16, 24]} className="section-margin">
                        <Col span={24}>
                            <Descriptions title="Assessment Info" bordered layout="vertical">
                                <Descriptions.Item label="HARP">{row.assessment_harp}</Descriptions.Item>
                                <Descriptions.Item label="Assessment Contact Date" span={2}>
                                    {row.assessment_contact_date && moment(row.assessment_contact_date).format(date_format)}
                                </Descriptions.Item>
                                {row.project === 'HCBS' && (
                                    <React.Fragment>
                                        <Descriptions.Item label="LOSD Date Completed">{row.assessment_date_completed && moment(row.assessment_date_completed).format(date_format)}</Descriptions.Item>
                                        <Descriptions.Item label="Assessment Date Submitted" span={2}>
                                            {row.assessment_date_submitted && moment(row.assessment_date_submitted).format(date_format)}
                                        </Descriptions.Item>
                                    </React.Fragment>
                                )}
                            </Descriptions>
                        </Col>
                    </Row>
                ) : (
                    ''
                )}

                {row.project === 'CMO' && (
                    <React.Fragment>
                        <Row gutter={[16, 24]} className="section-margin">
                            <Col span={24}>
                                <Descriptions title="Treatment Contact Information" bordered layout="vertical">
                                    <Descriptions.Item label="Agency Therapist" span={1}>
                                        {row.agency_therapist}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Phone Number" span={1}>
                                        {row.phone_agency_therapist}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Email" span={1}>
                                        {row.email_agency_therapist}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Agency Prescribing Clinician" span={1}>
                                        {row.agency_prescribing_clinician}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Phone Number" span={1}>
                                        {row.phone_agency_prescribing_clinician}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Email" span={1}>
                                        {row.email_agency_prescribing_clinician}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Primary Care Provider" span={1}>
                                        {row.primary_care_provider}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Phone Number" span={1}>
                                        {row.phone_primary_care_provider}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Email" span={1}>
                                        {row.email_primary_care_provider}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Row gutter={[16, 24]} className="section-margin">
                            <Col span={24}>
                                <Descriptions title="Referral/Encounter Note" bordered layout="vertical">
                                    <Descriptions.Item label="Referral/Encounter Note">{row.referral_encounter_note}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {row.project === 'CBHC-MVP' && (
                    <React.Fragment>
                        <Row gutter={[16, 24]} className="section-margin">
                            <Col span={24}>
                                <Descriptions title="Quality Metrics" bordered layout="vertical">
                                    <Descriptions.Item
                                        label="Diabetes Screen
                                        for people with
                                        Schizophrenia or
                                        Bipolar Disorder"
                                        span={1}>
                                        {row.qm_1_date || ''}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Potential Avoidable ED Utilization" span={1}>
                                        {row.qm_2_date || ''}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Cervical Cancer Screening (CCS)" span={1}>
                                        {row.qm_3_date || ''}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Comprehensive Diabetes Care Eye Exam (CDC-Eye)" span={1}>
                                        {row.qm_4_date || ''}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Asthma Medication Ratio (AMR)" span={1}>
                                        {row.qm_5_date || ''}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Colorectal Cancer Screening (COL)" span={1}>
                                        {row.qm_6_date || ''}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {row.project === 'SHARED-CARE' && (
                    <React.Fragment>
                        <Row gutter={[16, 24]} className="section-margin">
                            <Col span={24}>
                                <Descriptions title="Outcomes" bordered layout="vertical">
                                    <Descriptions.Item label="Outcome and Effective Date" span={24}>
                                        {row.outcomes.map((out) => (
                                            <ul>
                                                <li>Outcome Name: {out.outcomeName}</li>
                                                <li>Effective Date: {out.outcomeEffectiveDate}</li>
                                            </ul>
                                        ))}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Row gutter={[16, 24]} className="section-margin">
                            <Col span={24}>
                                <Descriptions title="Programs" bordered layout="vertical">
                                    <Descriptions.Item label="Program and County">
                                        {row.programs.map((out) => (
                                            <React.Fragment>
                                                <p>
                                                    <b>
                                                        {out.county} - {out.program}
                                                    </b>
                                                </p>
                                                <ul>
                                                    <li>Agency: {out.agency ? out.agency : 'Empty'}</li>
                                                    <li>County: {out.county ? out.county : 'Empty'}</li>
                                                    <li>Program: {out.program ? out.program : 'Empty'}</li>
                                                    <li>Need: {out.referral_needs ? out.referral_needs : 'Empty'}</li>
                                                    <li>Status: {out.referral_status ? out.referral_status : 'Empty'}</li>
                                                    <li>Appointment Date: {out.appointment_date ? out.appointment_date : 'Empty'}</li>
                                                    <li>Appointment Time: {out.appointment_time ? out.appointment_time : 'Empty'}</li>
                                                </ul>
                                            </React.Fragment>
                                        ))}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default ModalReferral
