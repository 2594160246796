import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import { detailedDiff } from 'deep-object-diff'
import { Alert, BackTop, Breadcrumb, Button, Card, Col, DatePicker, Form, Layout, Modal, Row, Select, Tabs, TimePicker, Typography, Result, message } from 'antd'
import { DeleteOutlined, StepBackwardOutlined, BankOutlined } from '@ant-design/icons'
import ClientInformation from './client-information/client-information'
import InsuranceInformation from './insurance-information/insurance_information'
import ReferralSource from './referral-source/referral-source'
import ApptAvailability from './appointment-availability/appointment-availability'
import Consent from './consent/consent'
import CareManagementSCF from './tabs/care-management/care-management'
import PeerServices from './tabs/peer-services/peer-services'
import PrimaryCare from './tabs/primary-care/primary-care'
import MentalHealth from './tabs/outpatient/mentalhealth'
import SubstanceUse from './tabs/outpatient/substanceuse'
import { date_format, referral_statuses, referral_needs, scf_counties, time_format } from '../../../../utils/constants'

const { Content } = Layout
const { confirm } = Modal
const { Option } = Select
const { Title } = Typography
const { TabPane } = Tabs

function showConfirm() {
    confirm({
        title: 'Do you want to return to dashboard?',
        content: 'Unsaved input will be lost.',
        onOk() {
            window.location.replace('')
        },
        onCancel() {},
    })
}

class SharedCare extends Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            currentProject: null,
            submit_button_loading: false,
            submit_button_disabled: false,
            selected_group: null,
            selected_county: null,
            selected_program: null,
            selected_agency: null,
            selected_mco: null,
            add_disabled: true,
            possible_programs: [],
            possible_counties: [],
            program_responses: [],
            possible_agencies: [],
            possible_mcos: [],
            final_programs_added: [],
            err: null,
            duplicate_skip: false,
            referral_submitted: false,
            referral_response: {},
            addButtonDisabled: false,
            consent: false,
            skip_consent: false,
        }
    }

    componentDidMount() {
        this.validateProject()
    }

    validateProject = () => {
        const url_project = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        const user_project = this.props.auth.user.projects

        // if addreferral/:project url empty then redirect to the index 0 project of user
        if (url_project === 'addreferral') {
            if (typeof user_project[0] === 'undefined') {
                return this.props.history.push('/dashboard')
            } else {
                return this.props.history.push('/addreferral/' + user_project[0])
            }
        }

        // if url project is not in user allowed project then throw out
        if (!user_project.includes(url_project)) {
            this.props.history.push('/nomatch')
        }

        this.setState({ currentProject: url_project })
    }

    handleConsentChange = (e) => {
        this.setState({
            consent: e.target.checked
        })
    }

    handleSkipConsentChange = (e) => {
        this.setState({
            skip_consent: e.target.checked
        })
    }

    findAgenciesByCountyAndProgram = (program) => {
        this.setState({ selected_program: program })
        axios
            .post('/api/scf/agencies', {
                program,
                county: this.state.selected_county,
            })
            .then((response) => {
                this.setState({
                    possible_agencies: response.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
        axios
            .post('/api/scf/mco', {
                program,
                county: this.state.selected_county,
            })
            .then((response) => {
                this.setState({
                    possible_mcos: response.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    findProgramsByCounty = (county) => {
        this.setState({ selected_county: county })
        axios
            .post('/api/scf/programs', {
                county: county,
            })
            .then((response) => {
                this.setState({
                    possible_programs: response.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    filterAgenciesMoreByMco = (mco) => {
        this.setState({ selected_mco: mco })
        axios
            .post('/api/scf/agencies/mco', {
                mco: mco,
                county: this.state.selected_county,
                program: this.state.selected_program,
            })
            .then((response) => {
                this.setState({
                    possible_agencies: response.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    findAddressByAgency = async (agency_name) => {
        const agency_details = await axios.post('/api/scf/agency/address', {
            agency: agency_name,
        })
        return agency_details.data
    }

    addProgram = async () => {
        if (this.state.addButtonDisabled === true) {
            return message.error('Save before adding another program')
        }

        var current_programs = this.state.final_programs_added
        var key = this.state.selected_county + this.state.selected_program + this.state.selected_agency
        const version = 1

        // include initial values for referral status, need, apt date and time
        current_programs.push({
            county: this.state.selected_county,
            program: this.state.selected_program,
            referral_agency: this.state.selected_agency,
            key: key,
            version: this.state.selected_county + '-' + this.state.selected_program + '-' + version,
            version_number: version,
            referral_status: 'Not Scheduled',
            referral_needs: 'Unknown',
            appointment_date: '',
            appointment_time: '',
            agency_options: this.state.possible_agencies,
        })

        this.setState({
            final_programs_added: current_programs,
            selected_county: null,
            selected_program: null,
            selected_agency: null,
            selected_group: null,
            seected_mco: null,
            possible_programs: [],
            possible_counties: [],
            program_responses: [],
            possible_agencies: [],
            possible_mcos: [],
            addButtonDisabled: true,
        })
    }

    onFinish = (form_values) => {
        var outcomes_formatted = []
        if (form_values['outcomes']) {
            outcomes_formatted = form_values['outcomes'].map((outcome) => ({ ...outcome, outcomeEffectiveDate: outcome.outcomeEffectiveDate.format(date_format) }))
        }

        if (typeof form_values['guardian_last_name'] == 'undefined' && typeof form_values['guardian_first_name'] == 'undefined') {
            form_values['is_guardian'] = 'N'
        } else {
            form_values['is_guardian'] = 'Y'
        }

        var user_access_set = new Set()
        if (this.state.final_programs_added) {
            this.state.final_programs_added.forEach((program) => {
                user_access_set.add(program.referral_agency)
            })
        }
        const user_access = Array.from(user_access_set)

        const programs_formatted = this.state.final_programs_added.map((program) => ({
            ...program,
            appointment_date: program.appointment_date && moment(program.appointment_date).format(date_format),
            appointment_time: program.appointment_time && moment(program.appointment_time).format(),
        }))

        const dateFields = [
            'dob',
            'insurance_1_effective_date',
            'insurance_1_expiration_date',
            'insurance_2_effective_date',
            'insurance_2_expiration_date',
            'insurance_3_effective_date',
            'insurance_3_expiration_date',
            'care_management_contact_date',
            'care_management_appointment_date',
            'peer_services_contact_date',
            'peer_services_appointment_date',
            'primary_care_contact_date',
            'primary_care_pcp_appointment_date',
            'primary_care_pcp_followup_date',
            'primary_care_pcp_annual_physical_date',
            'mental_contact_date',
            'mental_appointment_date',
            'mental_grpa_date_completed',
            'mental_noms_date_completed',
            'substance_contact_date',
            'substance_appointment_date',
            'substance_grpa_date_completed',
            'substance_noms_date_completed',
            'scf_antipsych_med_schizo_date',
            'scf_adult_bmi_date',
            'scf_ambulance_date',
            'scf_antidepressant_date',
            'scf_amr_date',
            'scf_breast_cancer_screening_date',
            'scf_cardiovascular_date',
            'scf_cervical_cancer_screening_date',
            'scf_col_date',
            'scf_csc_eye_date',
            'scf_continuity_date',
            'scf_diabetes_monitoring_date',
            'scf_diabetes_screening_schizo_bipolar_date',
            'scf_diverted_date',
            'scf_emergency_dept_follow_up_date',
            'scf_health_home_date',
            'scf_seven_day_hospital_follow_up_date',
            'scf_thirty_day_hospital_follow_up_date',
            'scf_adhd_date',
            'scf_engagement_date',
            'scf_mat_date',
            'scf_tobacco_date',
            'scf_anti_psych_date',
            'scf_phq9_date',
            'scf_psychosocial_date',
            'scf_well_child_visit_3_18_date',
            'scf_well_child_first_30_date',
        ]
        const formatDates = (form_values, dateField, date_format) => {
            dateField.forEach((val) => {
                if (form_values[val]) {
                    form_values[val] = moment(form_values[val]).format(date_format)
                }
            })
            return form_values
        }
        formatDates(form_values, dateFields, date_format)

        const timeFields = [
            'care_management_contact_time',
            'care_management_appointment_time',
            'peer_services_contact_time',
            'peer_services_appointment_time',
            'primary_care_contact_time',
            'primary_care_pcp_appointment_time',
            'primary_care_pcp_followup_time',
            'mental_contact_time',
            'mental_appointment_time',
            'substance_contact_time',
            'substance_appointment_time',
        ]
        const formatTimes = (form_values, timeField, time_format) => {
            timeField.forEach((val) => {
                if (form_values[val]) {
                    form_values[val] = moment(form_values[val]).format(time_format)
                }
            })
            return form_values
        }
        formatTimes(form_values, timeFields, time_format)

        const contactFields = ['care_management_', 'peer_services_', 'primary_care_', 'mental_', 'substance_']
        const setNumberOfContacts = (form_values, contactFields) => {
            contactFields.forEach((field) => {
                if (form_values[`${field}contact_made`] === true) {
                    form_values[`${field}number_of_contacts`] = 0
                    form_values[`${field}number_of_contacts`] = form_values[`${field}number_of_contacts`] + 1
                }
            })
            return form_values
        }
        setNumberOfContacts(form_values, contactFields)

        var values = { ...form_values }
        values['outcomes'] = outcomes_formatted
        values['project'] = [this.state.currentProject]
        values['programs'] = programs_formatted
        values['date_received'] = moment().format(date_format)
        values['user_id'] = this.props.auth.user.id
        values['user_access'] = user_access
        values['duplicate_skip'] = this.state.duplicate_skip

        if (this.state.consent === true) {
            values['consented_agencies'] = ['CBHS', this.props.auth.user.user_type]
        } else {
            values['consented_agencies'] = ['CBHS']
        }

        let normalizedOriginal = {}
        normalizedOriginal['outcomes'] = []
        normalizedOriginal['programs'] = []
        contactFields.forEach((field) => {
            if (values[`${field}contact_made`] === 'No') {
                normalizedOriginal[`${field}contact_made`] = 'No'
            }
        })

        var changes = detailedDiff(normalizedOriginal, values)

        changes['timestamp'] = moment().format(date_format)
        changes['user'] = this.props.auth.user.name + ' <' + this.props.auth.user.email + '>'
        values['history'] = [changes]

        axios
            .post('/api/referrals/create', values)
            .then((response) => {
                this.setState({
                    referral_submitted: true,
                    referral_response: response.data,
                })
            })
            .catch((error) => {
                this.setState({
                    err: error.response,
                })
            })
    }

    findAndUpdateProgram = (value, updateKey, fieldToUpdate) => {
        let all_programs = this.state.final_programs_added
        const programToUpdate = all_programs.find(({ key }) => key === updateKey)

        var vers_num = 1
        programToUpdate[fieldToUpdate] = value
        programToUpdate['version_number'] = vers_num
        programToUpdate['version'] = programToUpdate['county'] + '-' + programToUpdate['program'] + '-' + vers_num

        this.setState({
            final_programs_added: all_programs,
        })
    }

    showAgencyDetails = async (agency) => {
        const agencyInfo = await this.findAddressByAgency(agency)

        Modal.info({
            title: `${agency}`,
            content: (
                <Row style={{ marginBottom: '1em', marginTop: '1em' }}>
                    <Col>
                        <div>
                            <p>Address: {agencyInfo.address}</p>
                            <p>City: {agencyInfo.city}</p>
                            <p>ZIP: {agencyInfo.zip}</p>
                            <p>Contact: {agencyInfo.contact ? agencyInfo.contact : 'None Specified'}</p>
                        </div>
                    </Col>
                </Row>
            ),
        })
    }

    findAndDeleteProgram = async (key) => {
        let all_programs = this.state.final_programs_added
        let idx_to_delete = null

        all_programs.find((o, i) => {
            if (o.key === key) {
                idx_to_delete = i
            }
            return o
        })

        all_programs.splice(idx_to_delete, 1)

        this.setState({
            final_programs_added: all_programs,
            addButtonDisabled: false,
        })
    }

    handleDataErrorMessage = (err) => {
        if (err) {
            if (err.data.type === 'duplicate') {
                return (
                    <React.Fragment>
                        <Row gutter={[16, 16]} style={{ marginTop: '1em' }}>
                            <Col xs={24}>
                                <Alert message={err.data.message} description=" " type="error" showIcon />
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <Row gutter={[16, 16]} style={{ marginTop: '1em' }}>
                            <Col xs={24}>
                                <Alert message={err.data.message} description="There was an error processing you request." type="error" showIcon />
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            }
        }
    }

    addProgramFields = () => {
        const programs = this.state.final_programs_added.map((program) => (
            <Card title={<span>{`${program.program} in ${program.county} County `}</span>} bordered={false} style={{ width: '-webkit-fill-available', marginTop: '1em' }} key={program.key}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={7}>
                        <p>Agency Assigned</p>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select an agency"
                            optionFilterProp="children"
                            defaultValue={program.referral_agency}
                            onChange={(value) => this.findAndUpdateProgram(value, program.key, 'referral_agency')}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {program.agency_options.sort().map((agency) => (
                                <Option key={agency}>{agency}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={13}>
                        <p> </p>
                        <Button
                            type="secondary"
                            shape="round"
                            size="medium"
                            style={{ float: 'right' }}
                            onClick={() => {
                                this.showAgencyDetails(program.referral_agency, program.key)
                            }}>
                            <BankOutlined /> Agency Details
                        </Button>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={7}>
                        <p>Referral Status</p>
                        <Select
                            style={{
                                width: '100%',
                            }}
                            showSearch
                            placeholder="Select a referral status"
                            optionFilterProp="children"
                            onChange={(value) => this.findAndUpdateProgram(value, program.key, 'referral_status')}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {referral_statuses.map((referral) => (
                                <Option key={referral}>{referral}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} md={24} lg={5}>
                        <p>Referral Need</p>
                        <Select
                            style={{
                                width: '100%',
                            }}
                            showSearch
                            placeholder="Select a referral need"
                            optionFilterProp="children"
                            onChange={(value) => this.findAndUpdateProgram(value, program.key, 'referral_needs')}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {referral_needs.map((referral) => (
                                <Option key={referral}>{referral}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} md={24} lg={4}>
                        <p>Appointment Date</p>
                        <DatePicker onChange={(value) => this.findAndUpdateProgram(value.format(date_format), program.key, 'appointment_date')} style={{ width: '100%' }} />
                    </Col>
                    <Col xs={24} md={24} lg={4}>
                        <p>Appointment Time</p>
                        <TimePicker onChange={(value) => this.findAndUpdateProgram(value, program.key, 'appointment_time')} use12Hours format="h:mm a" style={{ width: '100%' }} />
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]} style={{ marginTop: '1em' }}>
                    <Col xs={24} md={24} lg={2}>
                        <Button
                            type="primary"
                            onClick={() => {
                                this.findAndDeleteProgram(program.key)
                            }}
                            danger>
                            Delete
                        </Button>
                    </Col>
                </Row>
            </Card>
        ))
        return programs.reverse()
    }

    onFinishFailed = (err) => {
        console.log(err)
    }

    render() {
        const { err, referral_submitted, referral_response, consent } = this.state
        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <BackTop />
                <div style={{ marginBottom: '16px', width: '100%' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Referrals</Breadcrumb.Item>
                        <Breadcrumb.Item>Add Referral</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {referral_submitted ? (
                    <React.Fragment>
                        <Row
                            style={{
                                border: 'solid 1px #e4e4e4',
                                background: '#ffffffd6',
                                borderRadius: '5px',
                            }}>
                            <Col xs={24}>
                                <Result
                                    status="success"
                                    title="Successfully Added New Referral!"
                                    subTitle={
                                        <span>
                                            Name: {referral_response.first_name} {referral_response.last_name} <br />
                                            ID number: {referral_response.nano_id}
                                        </span>
                                    }
                                    extra={[
                                        <Link to="/dashboard" key="dash">
                                            <Button type="primary" key="dashboard">
                                                Go to Dashboard
                                            </Button>
                                        </Link>,
                                        <Button
                                            type="primary"
                                            key="editPage"
                                            onClick={() =>
                                                this.props.history.push(`/editreferral/shared-care/${referral_response._id}`, {
                                                    id: referral_response._id,
                                                })
                                            }>
                                            Edit this Referral
                                        </Button>,
                                        <Button
                                            type="primary"
                                            key="editPage"
                                            onClick={() =>
                                                this.props.history.push(`/documentation/${referral_response._id}`)
                                            }>
                                            Add Documentation
                                        </Button>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {err && this.handleDataErrorMessage(err)}

                        <Title level={2}>Add Shared Care Referral</Title>
                        <p>Input values and submit to create a new shared care referral</p>
                        <Row style={{ marginBottom: '1em' }}>
                            <Col span={24}>
                                <Button type="primary" onClick={showConfirm}>
                                    <StepBackwardOutlined />
                                    Back to Dashboard
                                </Button>
                                <Button type="primary" onClick={() => window.location.reload()} style={{ float: 'right' }} danger>
                                    <DeleteOutlined />
                                    Clear Form
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            <Col span={24} style={{ width: '100%' }}>
                                <Form layout="inline" onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} ref={this.formRef} style={{ width: '100%' }} requiredMark={false}>
                                    <Tabs defaultActiveKey="client_information" style={{ width: '100%' }} tabBarStyle={{ backgroundColor: '#FFF', paddingLeft: '1em' }}>
                                        <TabPane forceRender={true} tab="Demographics" key="client_information">
                                            <ClientInformation formRef={this.formRef} />
                                            <InsuranceInformation />
                                            <ReferralSource />
                                            <ApptAvailability />
                                            <Card title="Select a Program and Assign an Agency" bordered={false} style={{ width: '-webkit-fill-available', marginTop: '1em' }}>
                                                <Row gutter={[16, 24]} style={{ justifyContent: 'center' }}>
                                                    <Col xs={24} md={24} lg={10}>
                                                        <Select
                                                            placeholder="Select a county"
                                                            optionFilterProp="children"
                                                            onChange={(value) => this.findProgramsByCounty(value)}
                                                            value={this.state.selected_county}
                                                            style={{ marginBottom: '15px' }}>
                                                            {scf_counties.sort().map((program_county) => (
                                                                <Option key={program_county}>{program_county}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                    <Col xs={24} md={24} lg={10}>
                                                        <Select
                                                            placeholder="Select a program"
                                                            optionFilterProp="children"
                                                            onChange={(val) => {
                                                                this.findAgenciesByCountyAndProgram(val)
                                                            }}
                                                            disabled={this.state.selected_county === null}
                                                            value={this.state.selected_program}
                                                            style={{ marginBottom: '15px' }}>
                                                            {this.state.possible_programs.sort().map((program_county) => (
                                                                <Option key={program_county}>{program_county}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16, 24]} style={{ justifyContent: 'center' }}>
                                                    <Col xs={24} md={24} lg={10}>
                                                        <Select
                                                            placeholder="Filter by MCO (optional)"
                                                            optionFilterProp="children"
                                                            onChange={(val) => this.filterAgenciesMoreByMco(val)}
                                                            disabled={this.state.selected_program === null}
                                                            value={this.state.selected_mco}
                                                            style={{ marginBottom: '15px' }}>
                                                            {this.state.possible_mcos.sort().map((mco) => (
                                                                <Option key={mco}>{mco}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                    <Col xs={24} md={24} lg={10}>
                                                        <Select
                                                            placeholder="Select an agency"
                                                            optionFilterProp="children"
                                                            onChange={(val) => this.setState({ selected_agency: val })}
                                                            disabled={this.state.selected_program === null}
                                                            value={this.state.selected_agency}
                                                            style={{ marginBottom: '15px' }}>
                                                            {this.state.possible_agencies.sort().map((agency) => (
                                                                <Option key={agency}>{agency}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16, 24]} style={{ justifyContent: 'center' }}>
                                                    <Col xs={24} md={24} lg={5}>
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    selected_county: null,
                                                                    selected_program: null,
                                                                    selected_agency: null,
                                                                    selected_group: null,
                                                                    selected_mco: null,
                                                                    possible_programs: [],
                                                                    possible_counties: [],
                                                                    program_responses: [],
                                                                    possible_agencies: [],
                                                                    possible_mcos: [],
                                                                })
                                                            }}
                                                            danger
                                                            style={{ width: '100%' }}>
                                                            Clear
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} md={24} lg={5}>
                                                        <Button type="primary" onClick={() => this.addProgram()} style={{ width: '100%' }} disabled={this.state.selected_agency === null}>
                                                            Add
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                            {this.addProgramFields()}
                                            <Consent 
                                                consent={this.state.consent} 
                                                skip_consent={this.state.skip_consent}
                                                handleConsentChange={this.handleConsentChange}
                                                handleSkipConsentChange={this.handleSkipConsentChange}
                                            />
                                        </TabPane>
                                        {consent && (
                                            <TabPane forceRender={true} tab="Care Management" key="care_management">
                                                <CareManagementSCF />
                                            </TabPane>
                                        )}
                                        {consent && (
                                            <TabPane forceRender={true} tab="Peer Services" key="peer_services">
                                                <PeerServices />
                                            </TabPane>
                                        )}
                                        {consent && (
                                            <TabPane forceRender={true} tab="Primary Care" key="primary_care">
                                                <PrimaryCare />
                                            </TabPane>
                                        )}
                                        {consent && (
                                            <TabPane forceRender={true} tab="Mental Health" key="mental_health">
                                                <MentalHealth />
                                            </TabPane>
                                        )}
                                        {consent && (
                                            <TabPane forceRender={true} tab="Substance Use" key="substance_use">
                                                <SubstanceUse />
                                            </TabPane>
                                        )}
                                    </Tabs>
                                    <Row style={{ width: '100%' }}>
                                        <Col xs={24} md={24} lg={24} style={{ width: '100%' }}>
                                            <Form.Item style={{ margin: '1em 0 5em 0' }}>
                                                <Button 
                                                    type="primary" 
                                                    htmlType="submit" 
                                                    style={{ width: '100%' }} 
                                                    size="large" 
                                                    disabled={this.state.consent === false && this.state.skip_consent === false} 
                                                >
                                                Save
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </Content>
        )
    }
}

export default SharedCare
