import React from 'react'

import { Button, Row, Col, Popconfirm, Typography, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

const { Title, Text } = Typography
const { Dragger } = Upload

const UploadCsvFile = (props) => {
    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} style={{marginBottom: '5px'}}>
                    <Title level={2} style={{paddingTop: '15px'}}>Upload CSV for Parsing</Title>
                    <Text>
                        Please upload the created CSV below for parsing and
                        analysis.
                    </Text>
                </Col>
            </Row>

            
            <Dragger {...props.draggerProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                    Support for a single .CSV upload.
                </p>
            </Dragger>
          

            <br />

            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Button
                        type="primary"
                        disabled={!props.project || props.fileList.length === 0}
                        onClick={props.handleFormProcess}
                        style={{marginRight: '5px'}}>
                        Parse
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to start over?"
                        onConfirm={props.clear}>
                        <Button type="secondary" disabled={!props.project} >
                            Start Over
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default UploadCsvFile
