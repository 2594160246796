import React, { Component } from 'react'
import axios from 'axios'

import { Alert, Breadcrumb, Button, Col, Card, Layout, Modal, Row, Space, Table, Typography } from 'antd'
import { DiffOutlined, DownloadOutlined, RedoOutlined } from '@ant-design/icons'

import { CSVLink } from 'react-csv'

const { Title } = Typography
const { Content } = Layout
class ManageHie extends Component {
    csvLink = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            csvData: [],
            snapshotList: [],
            loadingFetchLogs: false,
            loadingDownload: false,
            finishedDownloading: false,
            downloadCsvData: [],
            csvNameDate: '',
        }
    }

    componentDidMount = async () => {
        await this.fetchGenerationLogs()
    }

    fetchGenerationLogs = async () => {
        this.setState({
            loadingFetchLogs: true,
        })
        axios
            .post('/api/hie/list', {})
            .then((response) => {
                this.setState({
                    snapshotList: response.data.all_snapshots,
                    loadingFetchLogs: false,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    onSubmit = () => {
        this.setState({ loadingDownload: true })
        axios
            .post('/api/hie/generate', {})
            .then((response) => {
                this.setState({
                    loadingDownload: false,
                    finishedDownloading: true,
                })
            })
            .catch((error) => {
                this.setState({
                    err: error.response,
                })
            })
    }

    showDownloadConfirm = (record) => {
        Modal.info({
            title: 'Do you want to Download CSV?',
            content: (
                <Row style={{ marginBottom: '1em', marginTop: '1em', textAlign: 'center' }}>
                    <Col>
                        <div>
                            <p style={{ paddingTop: '1em' }}>
                                <b>By clicking this button, you understand that once you download ConnexRX data, we cannot ensure it will be kept secure.</b> You are attempting to download a file
                                which includes protected health information (“PHI”). We secure the data on ConnexRX servers using encryption and other technical, physical, and procedural safeguards.
                                You understand that once you download the data, we cannot guarantee the security of that downloaded data file.
                            </p>
                            <p>
                                You must have a valid Use and Disclose reason for accessing and downloading this PHI. IF YOU DO NOT have a valid, legal reason for downloading this information, you
                                make liable for civil penalties and/or criminal charges. PROCEED WITH CAUTION AND UNDER NOTICE.
                            </p>
                            <br />
                            <Button type="primary" icon={<DownloadOutlined />} title="Please disable your ads blocker to download the file" size="large" onClick={() => this.downloadCsv(record)}>
                                Continue Download
                            </Button>
                        </div>
                    </Col>
                </Row>
            ),
            okText: 'Close',
            maskClosable: true,
            destroyOnClose: true,
        })
    }

    downloadCsv = (record) => {
        this.setState({ csvNameDate: record.created_date })
        axios
            .post('/api/hie/download', { id: record._id })
            .then((response) => {
                this.setState({
                    downloadCsvData: response.data,
                })
                this.csvLink.current.link.click()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: '_id',
                key: '_id',
            },
            {
                title: 'Created Date',
                dataIndex: 'created_date',
                key: 'created_date',
            },
            {
                title: 'Download',
                key: 'download',
                render: (text, record) => (
                    <Space size="middle">
                        <Button onClick={() => this.showDownloadConfirm(record)}>Download</Button>
                    </Space>
                ),
            },
        ]

        return (
            <React.Fragment>
                <div>
                    <Card style={{ margin: '20px 16px', width: '100%' }}>
                        <div style={{ marginBottom: '16px', width: '100%' }}>
                            <Breadcrumb>
                                <Breadcrumb.Item>Resources</Breadcrumb.Item>
                                <Breadcrumb.Item>HIE</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Title level={2}>HIE Control Center</Title>
                        <p>Use the below tools to generate HIE attribution files, view past exports, and upload file contents to server</p>
                    </Card>
                    <Content style={{ margin: '20px 16px 0' }}>
                        <Row gutter={[12, 24]}>
                            {this.state.finishedDownloading === true && (
                                <Col span={24}>
                                    <Alert message={<span>HIE attribution file successfully generated! </span>} type="success" showIcon />
                                </Col>
                            )}
                            <Col span={24}>
                                <Card>
                                    <Title level={3}>File Generation Logs</Title>
                                    <p>Generated files available for download </p>
                                    <Button type="default" style={{ marginBottom: '2em' }} icon={<RedoOutlined />} loading={this.state.loadingFetchLogs} onClick={this.fetchGenerationLogs}>
                                        Refresh
                                    </Button>
                                    <Table columns={columns} dataSource={this.state.snapshotList} scroll={{ x: 100 }} rowKey="_id" />
                                    <CSVLink data={this.state.downloadCsvData} filename={'attribution_file_' + this.state.csvNameDate + '.csv'} className="hidden" ref={this.csvLink} target="_blank" />
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card>
                                    <Title level={3}>Generate HIE Attribution File</Title>
                                    <p>Generate an attribution file to be sent to HIE for alerts. The file will also be available for download </p>
                                    <Button type="primary" onClick={() => this.onSubmit()} loading={this.state.loadingDownload} icon={<DiffOutlined />} >
                                        Generate HIE Attribution File
                                    </Button>
                                </Card>
                            </Col>
                            {/* <Col span={24}>
                                <Card>
                                    <Title level={3}>Upload Latest HIE File Contents</Title>
                                    <p>Click the below to take the latest HIE attribution file and upload its contents to the server for pickup. </p>
                                    <Button type="primary">Upload File Contents to Server</Button>
                                    <Button type="danger" disabled>
                                        Confirm
                                    </Button>
                                </Card>
                            </Col> */}
                        </Row>
                    </Content>
                </div>
            </React.Fragment>
        )
    }
}

export default ManageHie
