export const referral_statuses = [
    'Not Scheduled',
    'Scheduled',
    'Rescheduled',
    'Cancelled',
    'No Show',
    'Completed',
    'Left Message',
    'Individual Declined Services',
    'Referral Request',
    'Did Not Respond to Outreach',
    'Not Eligible For Services',
]

export const referral_statuses_cbhc_mvp = [
    'Left Message',
    'Individual Declined Services',
    'Did Not Respond to Outreach',
    'Not Eligible For Services',
    'Declined',
    'Outreach Attempted',
    'Outreach Completed',
    'No Outreach',
]

export const agencies = [
    'None',
    'Access: Supports for Living',
    'Andrus - Julia Dyckman Andrus Memorial, Inc.',
    'Arms Acres',
    'Astor Services for Children and Families',
    'Bikur Cholim',
    'Catholic Charities',
    'CHOICE of New Rochelle',
    'CLUSTER',
    'CBHS',
    'CoveCare Center',
    'Cornerstone of Rhinebeck',
    'Family Services, Inc.',
    'Family Services of Westchester',
    'Gateway Community Industries',
    'HONORehg',
    'Hudson Valley Community Services',
    'Human Development Services of Westchester',
    'Innovative Health Systems',
    'Independent Living',
    'Jawonio',
    'Lexington Center for Recovery',
    'Mental Health America of Dutchess County',
    'Mental Health Association of Ulster County',
    'Mental Health Association of Orange County',
    'Mental Health Association of Rockland County',
    'Mental Health Association of Westchester County',
    'Montefiore',
    'Regional Economic Community Action Program',
    'Rehabilitation Support Services',
    'Restorative Management Corp',
    'Search for Change',
    'St. Christopher’s Inn',
    'Student Assistance Services',
    "St. Dominic's Home",
    'The Guidance Center of Westchester',
    'Westchester Jewish Community Services',
]

export const referral_sources = [
    'Highland Medical',
    'Montefiore',
    'MVP',
    'Affinity',
    'United Health',
    'Montefiore CMO',
    'Cross Valley Health & Medical',
    'Fidelis',
    'CBHS',
    'CBHS Website',
    'CBHS Referral Line',
    'Access: Supports for Living',
    'Mental Health America of Dutchess County',
    'Andrus - Julia Dyckman Andrus Memorial, Inc.',
    'Arms Acres',
    'Astor Services for Children and Families',
    'Bikur Cholim',
    'Catholic Charities',
    'CHOICE of New Rochelle',
    'CLUSTER',
    'ConnexRX Texter',
    'CoveCare Center',
    'Cornerstone of Rhinebeck',
    'Family Services, Inc.',
    'Family Services of Westchester',
    'Gateway Community Industries',
    'HONORehg',
    'Hudson Valley Community Services',
    'Human Development Services of Westchester',
    'Innovative Health Systems',
    'Indepedent Living',
    'Jawonio',
    'Lexington Center for Recovery',
    'Mental Health Association of Ulster County',
    'Mental Health Association of Orange County',
    'Mental Health Association of Rockland County',
    'Mental Health Association of Westchester County',
    'Regional Economic Community Action Program',
    'Rehabilitation Support Services',
    'Restorative Management Corp',
    'Search for Change',
    'St. Christopher’s Inn',
    'Student Assistance Services',
    "St. Dominic's Home",
    'The Guidance Center of Westchester',
    'Westchester Jewish Community Services',
    'Other',
]

export const referral_needs = [
    'Care Management',
    'HCBS',
    'MH',
    'SDOH',
    'SU',
    'Transportation',
    'Childcare',
    'Food',
    'Housing',
    'Employment',
    'Finances',
    'Medical',
    'Social',
    'Safety',
    'Behavioral Health',
]

export const genders = ['Male', 'Female', 'Other', 'Non-Binary', 'Transgender Woman/Female', 'Transgender Man/Male', 'Choose Not to Disclose']

export const languages_list = [
    'Afrikanns',
    'Albanian',
    'Arabic',
    'Armenian',
    'Bengali',
    'Bulgarian',
    'Chinese (Mandarin)',
    'Croation',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Finnish',
    'French',
    'German',
    'Greek',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Korean',
    'Latin',
    'Latvian',
    'Lithuanian',
    'Malay',
    'Mongolian',
    'Norwegian',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Romanian',
    'Russian',
    'Serbian',
    'Slovenian',
    'Spanish',
    'Swahili',
    'Swedish ',
    'Thai',
    'Tibetan',
    'Turkish',
    'Ukranian',
    'Vietnamese',
]

export const countys_list = ['Dutchess', 'Orange', 'Putnam', 'Rockland', 'Sullivan', 'Ulster', 'Westchester']

export const insurance_types = ['Commercial', 'Dual', 'Medicaid', 'Managed Medicaid', 'Medicare', 'Uninsured']

export const insurance_plans = [
    'Fidelis Care New York(All)',
    'MVP Health Plan, Inc. (All)',
    'United Healthcare (All)',
    'Affinity Health Plan, Inc. (All)',
    'Wellcare(All)',
    'Blue Cross, Blue Shield Health Plan(All)',
    'Emblem Health(All)',
    'HealthPlus(All)',
    "Capital District Physician's Health Plan, Inc.",
    'Crystal Run',
    'Healthfirst',
    'Beacon Health(All)',
    'MetroPlus Health Plan',
    'Blue Cross, Blue Shield of Northeastern New York',
    'Blue Cross, Blue Shield of Western New York',
    'Aetna(All)',
    '1199 National Benefit Fund',
    'HealthNow of New York, Inc.dba Community Blue',
    'Medicaid(Straight)',
    'Medicare(Straight)',
    'HealthNow',
    'Healthfirst(All)',
    'GHI',
    'Value Options(All)',
    'Optum',
    'OSCAR',
    'Oxford(All)',
    'Amida Care',
    'Anthem BCBS',
    'CDPHP',
    'Independent Health Association',
    'VNS Choice',
    'Cigna(All)',
    'YourCare',
    'AARP Medicare Supplement Plan',
    'AIG',
    'Alliance National Insurance Company',
    'American Plan Administrators',
    'Auto Injury Solutions',
    'Bankers Conseco Life Ins Co',
    'Beacon Health Strategies(All)',
    'Benistar Trust Mercer Consumer',
    'Berkshire Farm Center and Services for Youth',
    'Broadspire',
    'CareGuard Claims',
    'Carmel CSD c / o INDECS',
    'Centennial Ins Co C / O NCA Comp',
    'ChampVA',
    'Child Health Plus - BCBS',
    'Child Health Plus - Fidelis',
    'Child Health Plus - MVP',
    'Chubb Workers Comp',
    'ComPsych',
    'Core Source',
    'CTC Benefits',
    'Elderplan',
    'Empire Pathways',
    'Evercare Choice',
    'Fidelis Community',
    'Fidelis Medicaid',
    'Fidelis Medicare',
    'GEHA',
    'GEICO',
    'Global Life Ins Co of NY',
    'Hamaspik',
    'HealthSmart Benefit Solutions',
    'HIP CMO Montefiore',
    'Humana Inc.Medicare',
    'JRN CONSULTING LLC',
    'Local 137 Welfare Fund',
    'Local 147 Welfare Fund',
    'Local 15 Welfare Fund',
    'Lower Hudson Valley EAP',
    'Magellan',
    'MagnaCare',
    'Meritain Health',
    'MHC CONSULTANTs',
    'Montfiore NGS',
    'Mutual of Omaha',
    'Nippon Life Insurance Co of America - P',
    'Northeast Carpenters Health Fund',
    'NY Central Mutual Fire Insurance Company',
    'NY State Insurance Fund',
    'NYS Medicaid',
    'PMG MANAGEMENT CORP',
    'Pomco',
    'Progressive Insurance',
    'QualCare',
    'The Hartford',
    'Travelers Insurance',
    'Travelers Readers Digest',
    'TriCare for Life',
    'Tufts Health Plan MA',
    'United Healthcare Medicare',
    'United Healthcare Medicaid',
    'Utica National Insurance Group',
    'Workforce Assistance Programs',
    'Wright Risk Management',
    'Excellus Health',
    'Upstate HMO and Univera Healthcare HMO',
    'United Behavioral Health',
    'Molina Healthcare of New York, Inc.',
    'Community Blue',
]

export const harp_values = ['Eligible/Not Enrolled', 'Enrolled', 'Not Eligible']

const availablility_times = (day) => [
    {
        title: day + ' Morning (8:00 AM - 11:00 AM)',
        value: day + ' Morning (8:00 AM - 11:00 AM)',
        key: day + ' Morning (8:00 AM - 11:00 AM)',
    },
    {
        title: day + ' Afternoon (11:00 AM - 2:00PM)',
        value: day + ' Afternoon (11:00 AM - 2:00PM)',
        key: day + ' Afternoon (11:00 AM - 2:00PM)',
    },
    {
        title: day + ' Evening (2:00PM - 5:00PM)',
        value: day + ' Evening (2:00PM - 5:00PM)',
        key: day + ' Evening (2:00PM - 5:00PM)',
    },
    {
        title: day + ' Night (5:00PM - 8:00PM)',
        value: day + ' Night (5:00PM - 8:00PM)',
        key: day + ' Night (5:00PM - 8:00PM)',
    },
]

export const availablility = [
    {
        title: 'Sunday',
        value: 'Sunday',
        key: 'Sunday',
        children: availablility_times('Sunday'),
    },
    {
        title: 'Monday',
        value: 'Monday',
        key: 'Monday',
        children: availablility_times('Monday'),
    },
    {
        title: 'Tuesday',
        value: 'Tuesday',
        key: 'Tuesday',
        children: availablility_times('Tuesday'),
    },
    {
        title: 'Wednesday',
        value: 'Wednesday',
        key: 'Wednesday',
        children: availablility_times('Wednesday'),
    },
    {
        title: 'Thursday',
        value: 'Thursday',
        key: 'Thursday',
        children: availablility_times('Thursday'),
    },
    {
        title: 'Friday',
        value: 'Friday',
        key: 'Friday',
        children: availablility_times('Friday'),
    },
    {
        title: 'Saturday',
        value: 'Saturday',
        key: 'Saturday',
        children: availablility_times('Saturday'),
    },
]

export const referral_titles = ['Care Manager', 'Clinician', 'Client', 'Family Member', 'Office Staff', 'Peer', 'Self-Referral', 'Other']

export const requirements = [
    'Steps:',
    '* Download the sample upload workbook',
    '* Insert referral data from row 2 onwards',
    '* Validate row data. See allowed field values in second sheet.',
    '* File > Save as > File Format: > CSV UTF-8  (Comma delimited)(.csv)',
    '* Upload and parse CSV.',
]

export const warnings = [
    'Notes: ',
    '• File type extension must be .csv and have file type encoding UTF-8',
    '• The number of referrals parsed must match number of rows of referrals (right-click on excel delete empty rows)',
    '• Cell values must match expected values from dropdowns (see add ALLOWED FIELD VALUES workbook).',
]

export const ignoredColumns = [
    'address_1',
    'address_2',
    'referral_source_phone',
    'referral_source_email',
    'language',
    'phone',
    'county',
    'city',
    'state',
    'zip',
    'referral_source_phone',
    'referral_source_email',
]

export const status_viewer = [
    { key: 'all', value: 'Total Referrals' },
    { key: 'not_assigned', value: 'Not Assigned' },
    { key: 'unscheduled', value: 'Unscheduled' },
    { key: 'scheduled', value: 'Scheduled' },
    { key: 'rescheduled', value: 'Rescheduled' },
    { key: 'completed', value: 'Completed' },
    { key: 'cancelled', value: 'Cancelled' },
    { key: 'no_shows', value: 'No Shows' },
    { key: 'left_message', value: 'Left Message' },
    {
        key: 'unsuccessful_connection',
        value: 'Unsuccessful Connection',
    },
    {
        key: 'assessment_not_contacted',
        value: 'Assessment Contact Not Made',
    },
    {
        key: 'assessment_not_completed',
        value: 'Assessments Not Completed',
    },
]

export const status_viewer_cbhc_mvp = [
    { key: 'all', value: 'Total Referrals' },
    { key: 'not_assigned', value: 'Not Assigned' },
    { key: 'left_message', value: 'Left Message' },
    {
        key: 'unsuccessful_connection',
        value: 'Unsuccessful Connection',
    },
    {
        key: 'assessment_not_contacted',
        value: 'Declined',
    },
    {
        key: 'assessment_not_completed',
        value: 'Outreach Attempted',
    },
    {
        key: 'assessment_not_completed',
        value: 'Outreach Completed',
    },
    {
        key: 'assessment_not_completed',
        value: 'No Outreach',
    },
]

export const referral_keys_readable = {
    nano_id: 'ID',
    referral_status: 'Referral Status',
    referral_agency: 'Referral Agency',
    date_assigned: 'Date Assigned',
    referral_source: 'Referral Source',
    referral_source_email: 'Referral Source Email',
    referral_source_name: 'Referral Source Name',
    referral_title: 'Referral Title',
    referral_source_phone: 'Referral Source Phone',
    referral_need: 'Referral Need',
    additional_details: 'Additional Details',
    dob: 'Date of Birth',
    dob_string: 'DOB',
    time_lapsed: 'Days Lapsed',
    last_name: 'Last Name',
    first_name: 'First Name',
    middle_name: 'Middle Name',
    gender: 'Gender',
    language: 'Language',
    address_1: 'Address 1',
    address_2: 'Address 2',
    city: 'City',
    state: 'State',
    county: 'County',
    zip: 'ZIP',
    phone: 'Phone Number',
    primary_phone_valid: 'Primary Phone Unsuccessful',
    phone_secondary: 'Secondary Phone Number',
    secondary_phone_valid: 'Secondary Phone Unsuccessful',
    leave_message: 'Leave Message',
    insurance_1: 'Insurance Type 1',
    insurance_1_plan: 'Insurance Type 1 Plan',
    insurance_1_group_number: 'Insurance 1 Group Number',
    insurance_1_policy_number: 'Insurance 1 Policy Number',
    insurance_2: 'Insurance Type 2',
    insurance_2_plan: 'Insurance Type 2 Plan',
    insurance_2_group_number: 'Insurance Type 2 Group Number',
    insurance_2_policy_number: 'Insurance Type 2 Policy Number',
    insurance_3: 'Insurance Type 3',
    insurance_3_plan: 'Insurance Type 3 Plan',
    insurance_3_group_number: 'Insurance Type 3 Group Number',
    insurance_3_policy_number: 'Insurance Type 3 Policy Number',
    appointment_availability: 'Appointment Availability',
    preferred_times: 'Preferred Times',
    appointment_date: 'Appointment Date',
    appointment_time: 'Appointment Time',
    assessment_harp: 'Assessment Harp',
    assessment_contact_date: 'First Contact Date',
    assessment_date_completed: 'Assessment Date Completed',
    assessment_date_submitted: 'Assessment Date Submitted',
    assessment_date_approved: 'Assessment Date Approved',
    cin: 'CIN Number',
    date_received: 'Date Received',
    history: 'History',
    consent: 'Client Consent Received',
    consent_date: 'Client Consent Date',
    skip_consent: 'Client Consent Skipped',
    id: 'ID Update',
    contact_needs: 'Contact Needs',
    contact_type: 'Contact Type',
    contact_date: 'Contact Date',
    contact_time: 'Contact Time',
    number_of_contacts: 'Number of Contacts',
    contact_details: 'Contact Details',
    address_type: 'Address Type',
    shelter_name: 'Shelter Name',
    address_valid: 'Address Unsuccessful',
    notes: 'Notes',
    agency_therapist: 'Agency Therapist',
    phone_agency_therapist: 'Phone Agency Therapist',
    email_agency_therapist: 'Email Agency Therapist',
    agency_prescribing_clinician: 'Agency Prescribing Clinician',
    phone_agency_prescribing_clinician: 'Phone Agency Prescribing Clinician',
    email_agency_prescribing_clinician: 'Email Agency Prescribing Clinician',
    primary_care_provider: 'Primary Care Provider',
    phone_primary_care_provider: 'Phone Primary Care Provider',
    email_primary_care_provider: 'Email Primary Care Provider',
    email_client: 'Client Email',
    referral_encounter_note: 'Referral/Encounter Note',
    original_filename: 'Original Filename',
    protected_filename: 'Protected Filename',
    health_home_enrolled: 'Health Home Enrolled',
    health_home_facility: 'Health Home Facility',
    health_home_enrollment_date: 'Health Home Enrollment Date',
    care_management_enrolled: 'Care Management Enrolled',
    care_management_facility: 'Care Management Facility',
    care_management_enrollment_date: 'Care Management Enrollment Date',
    last_annual_physical_date: 'Last Annual Physical Date',
    last_pcp_followup_date: 'Last PCP Follow Up Date',
    last_annual_physical_time: 'Last Annual Physical Time',
    last_pcp_followup_time: 'Last PCP Follow Up Time',
    insurance_1_effective_date: 'Insurance 1 Effective Date',
    insurance_1_expiration_date: 'Insurance 1 Expiration Date',
    insurance_2_effective_date: 'Insurance 2 Effective Date',
    insurance_2_expiration_date: 'Insurance 2 Expiration Date',
    insurance_3_effective_date: 'Insurance 3 Effective Date',
    insurance_3_expiration_date: 'Insurance 3 Expiration Date',
    qm_1_checkbox: 'Diabetes Screen',
    qm_1_date: 'Diabetes Screen Date',
    qm_2_checkbox: 'Potential Avoidable ED Utilization',
    qm_2_date: 'Potential Avoidable ED Utilization Date',
    qm_3_checkbox: 'CCS',
    qm_3_date: 'CCS Date',
    qm_4_checkbox: 'CDC-Eye',
    qm_4_date: 'CDC-Eye Date',
    qm_5_checkbox: 'AMR',
    qm_5_date: 'AMR Date',
    qm_6_checkbox: 'COL',
    qm_6_date: 'COL Date',
    engagement_contact_type: 'O&E Contact Type',
    engagement_date: 'O&E Date',
    engagement_time: 'O&E Time',
    engagement_number_of_contacts: 'O&E Number of Contacts',
    engagement_contact_details: 'O&E Contact Details',
    is_guardian: 'Guardian',
    guardian_last_name: 'Guardian Last Name',
    guardian_first_name: 'Guardian First Name',
    project: 'Project',
    user_id: 'User ID',
    mailing_address_1: 'Mailing Address 1',
    mailing_address_2: 'Mailing Address 2',
    mailing_city: 'Mailing City',
    mailing_county: 'Mailing County',
    mailing_state: 'Mailing State',
    mailing_zip: 'Mailing ZIP',
    active_duty: 'Active Duty',
    veteran: 'Veteran',
    outcomes: 'Outcome',
    programs: 'Program',
    program: 'Program',
    agency: 'Agency',
    agency_address: 'Agency Address',
    agency_city: 'Agency City',
    agency_contact: 'Agency Contact',
    agency_zip: 'Agency ZIP',
    referral_needs: 'Referral Needs',
    outcomeName: 'Outcome Name',
    outcomeEffectiveDate: 'Outcome Effective Date',
    ethnicity: 'Ethnicity',
    quality_metrics: 'Quality Metrics',
    hospital_site_address: 'Hospital Site Address',
    hospital_site_name: 'Hospital Site Name',
    hospital_site_npi: 'Hospital Site NPI',
    hospital_site_phone: 'Hospital Site Phone',
    pcp_site_name: 'PCP Site Name',
    pcp_name: 'PCP Name',
    pcp_npi: 'PCP NPI',
    pcp_phone: 'PCP Phone',
    pcp_address: 'PCP Address',
    emergency_contact_first_name: 'Emergency Contact First Name',
    emergency_contact_last_name: 'Emergency Contact Last Name',
    emergency_contact_phone: 'Emergency Contact Phone Number',
    emergency_contact_relationship: 'Emergency Contact Relationshp',
    scf_cm_first: 'Shared Care CM First Name',
    scf_cm_last: 'Shared Care CM Last Name',
    scf_cm_phone: 'Share Care CM Phone',
    scf_cm_email: 'Share Care CM Email',
    scf_cm_agency: 'Share Care CM Agency',
    toc_cm_first: 'TOC Care Manager First Name',
    toc_cm_last: 'TOC Care Manager Last Name',
    toc_cm_phone: 'TOC Care Manager Phone',
    toc_cm_email: 'TOC Care Manager Email',
    toc_cm_agency: 'TOC Care Manager Agency',
    // Care Management
    care_management_contact_needs: 'Care Management Contact Needs',
    care_management_contact_type: 'Care Management Contact Type',
    care_management_number_of_contacts: 'Care Management Number Of Contacts',
    care_management_appointment_date: 'Care Management Appointment Date',
    care_management_appointment_time: 'Care Management Appointment Time',
    care_management_contact_date: 'Care Management Contact Date',
    care_management_contact_time: 'Care Management Contact Time',
    care_management_contact_details: 'Care Management Contact Details',
    care_management_contact_duration: 'Care Management Contact Duration',
    care_management_outcome_of_intervention: 'Care Management Outcome of Intervention/Service',
    care_management_contact_made: 'Care Management Contact Made',
    care_management_left_message: 'Care Management Left Message',
    // Peer Services
    peer_services_outcome_of_intervention: 'Peer Services Outcome Of Intervention',
    peer_services_contact_needs: 'Peer Services Contact Needs',
    peer_services_contact_type: 'Peer Services Contact Type',
    peer_services_number_of_contacts: 'Peer Services Number Of Contacts',
    peer_services_appointment_date: 'Peer Services Appointment Date',
    peer_services_appointment_time: 'Peer Services Appointment Time',
    peer_services_contact_date: 'Peer Services Contact Date',
    peer_services_contact_time: 'Peer Services Contact Time',
    peer_services_contact_details: 'Peer Services Contact Details',
    peer_services_contact_duration: 'Peer Services Contact Duration',
    peer_services_psychosocial_date: 'Peer Services Use of First Line Psychosocial Care Date',
    peer_services_outcome_of_intervention: 'Peer Services Outcome of Invervention/Service',
    peer_services_contact_made: 'Peer Services Contact Made',
    peer_services_left_message: 'Peer Services Left Message',
    // Primary Care
    primary_care_contact_needs: 'Primary Care Contact Needs',
    primary_care_contact_type: 'Primary Care Contact Type',
    primary_care_number_of_contacts: 'Primary Care Number Of Contacts',
    primary_care_contact_date: 'Primary Care Contact Date',
    primary_care_contact_time: 'Primary Care Contact Time',
    primary_care_contact_details: 'Primary Care Contact Details',
    primary_care_contact_duration: 'Primary Care Contact Duration',
    primary_care_hospital_site_address: 'Primary Care Hospital Site Address',
    primary_care_hospital_site_name: 'Primary Care Hospital Site Name',
    primary_care_hospital_site_npi: 'Primary Care Hospital Site NPI',
    primary_care_hospital_site_phone: 'Primary Care Hospital Site Phone',
    primary_care_pcp_site_name: 'Primary Care PCP Site Name',
    primary_care_pcp_name: 'Primary Care PCP Name',
    primary_care_pcp_npi: 'Primary Care PCP NPI',
    primary_care_pcp_phone: 'Primary Care PCP Phone',
    primary_care_pcp_address: 'Primary Care PCP Address',
    primary_care_appointment_date: 'Primary Care Health PCP Appointment Date',
    primary_care_appointment_time: 'Primary Care PCP Appointment Time',
    primary_care_last_pcp_followup_date: 'Primary Care Last PCP Followup Date',
    primary_care_last_pcp_followup_time: 'Primary Care Last PCP Followup Time',
    primary_care_last_annual_physical_date: 'Primary Care Last Annual Physical Date',
    primary_care_last_annual_physical_time: 'Primary Care Last Annual Physical Time',
    primary_care_outcome_of_intervention: 'Primary Care Outcome of Intervention/Service',
    primary_care_contact_made: 'Primary Care Contact Made',
    primary_care_left_message: 'Primary Care Left Message',
    primary_care_diagnosis: 'Primary Care Diagnosis',
    // Mental Health
    mental_contact_needs: 'Mental Health Contact Needs',
    mental_contact_type: 'Mental Health Contact Type',
    mental_number_of_contacts: 'Mental Health Number Of Contacts',
    mental_appointment_date: 'Mental Health Appointment Date',
    mental_appointment_time: 'Mental Health Appointment Time',
    mental_contact_date: 'Mental Health Contact Date',
    mental_contact_time: 'Mental Health Contact Time',
    mental_contact_details: 'Mental Health Contact Details',
    mental_contact_duration: 'Mental Health Contact Duration',
    mental_type_of_appointment: 'Mental Health Type Of Appointment',
    mental_diagnosis: 'Mental Health Diagnosis',
    mental_grpa: 'Mental Health GRPA',
    mental_grpa_date_completed: 'Mental Health GRPA Date Completed',
    mental_grpa_frequency: 'Mental Health GRPA Frequency',
    mental_noms: 'Mental Health NOMS',
    mental_noms_date_completed: 'Mental Health NOMS Date Completed',
    mental_noms_frequency: 'Mental Health NOMS Frequency',
    mental_outcome_of_intervention: 'Mental Health Outcome of Intervention/Service',
    mental_contact_made: 'Mental Health Contact Made',
    mental_left_message: 'Mental Health Left Message',
    // Substance Use
    substance_contact_needs: 'Substance Use Contact Needs',
    substance_contact_type: 'Substance Use Contact Type',
    substance_number_of_contacts: 'Substance Use Number Of Contacts',
    substance_appointment_date: 'Substance Use Appointment Date',
    substance_appointment_time: 'Substance Use Appointment Time',
    substance_contact_date: 'Substance Use Contact Date',
    substance_contact_time: 'Substance Use Contact Time',
    substance_contact_details: 'Substance Use Contact Details',
    substance_contact_duration: 'Substance Use Contact Duration',
    substance_type_of_appointment: 'Substance Use Type Of Appointment',
    substance_diagnosis: 'Substance Use Diagnosis',
    substance_grpa: 'Substance Use GRPA',
    substance_grpa_date_completed: 'Substance Use GRPA Date Completed',
    substance_grpa_frequency: 'Substance Use GRPA Frequency',
    substance_noms: 'Substance Use NOMS',
    substance_noms_date_completed: 'Substance Use NOMS Date Completed',
    substance_noms_frequency: 'Substance Use NOMS Frequency',
    substance_contact_made: 'Substance Use Contact Made',
    substance_left_message: 'Substance Use Left Message',
    // SCF Quality Metrics
    scf_antipsych_med_schizo: 'QM Adherence to Antipsychotic Medication for Person with Schizophrenia',
    scf_antipsych_med_schizo_date: ' QM Adherence to Antipsychotic Medication for Person with Schizophrenia Date',
    scf_adult_bmi: 'QM Adult BMI Assessment',
    scf_adult_bmi_date: 'QM Adult BMI Assessment Date',
    scf_ambulance: 'QM Adult Access to Preventative/Ambulatory Health Services',
    scf_ambulance_date: 'QM Adult Access to Preventative/Ambulatory Health Services Date',
    scf_antidepressant: 'QM Antidepressant Medication Management',
    scf_antidepressant_date: 'QM Antidepressant Medication Management Date',
    scf_amr: 'QM Asthma Medication Ratio',
    scf_amr_date: 'QM Asthma Medication Ratio Date',
    scf_breast_cancer_screening: 'QM Breast Cancer Screening',
    scf_breast_cancer_screening_date: 'QM Breast Cancer Screening Date',
    scf_cardiovascular: 'QM Cardiovascular Monitoring for People with Cardiovascular Disease and Schizophrenia',
    scf_cardiovascular_date: 'QM Cardiovascular Monitoring for People with Cardiovascular Disease and Schizophrenia Date',
    scf_cervical_cancer_screening: 'QM Cervical Cancer Screening',
    scf_cervical_cancer_screening_date: 'QM Cervical Cancer Screening Date',
    scf_col: 'QM Colorectal Cancer Screening',
    scf_col_date: 'QM Colorectal Cancer Screening Date',
    scf_csc_eye: 'QM Comprehensive Diabetes Care Eye Exam',
    scf_csc_eye_date: 'QM Comprehensive Diabetes Care Eye Exam Date',
    scf_continuity: 'QM Continuity of Care for Inpatient Detox or Rehabilitation to Lower Level of Care',
    scf_continuity_date: 'QM Continuity of Care for Inpatient Detox or Rehabilitation to Lower Level of Care Date',
    scf_diabetes_monitoring: 'QM Diabetes Monitoring for People with Diabetes and Schizophrenia',
    scf_diabetes_monitoring_date: 'QM Diabetes Monitoring for People with Diabetes and Schizophrenia Date',
    scf_diabetes_screening_schizo_bipolar: 'QM Diabetes Screening For People with Schizophrenia or Bipolar Disorder on Antipsychotic Medication',
    scf_diabetes_screening_schizo_bipolar_date: 'QM Diabetes Screening For People with Schizophrenia or Bipolar Disorder on Antipsychotic Medication Date',
    scf_diverted: 'QM Diverted from Emergency Department Services',
    scf_diverted_date: 'QM Diverted from Emergency Department Services Date',
    scf_emergency_dept_follow_up: 'QM Emergency Department Follow-up',
    scf_emergency_dept_follow_up_date: 'QM Emergency Department Follow-up Date',
    scf_health_home: 'QM Enrolled in a Health Home',
    scf_health_home_date: 'QM Enrolled in a Health Home Date',
    scf_seven_day_hospital_follow_up: 'QM Follow-up After Hospitalization for Mental Illness (Within 7 Days)',
    scf_seven_day_hospital_follow_up_date: 'QM Follow-up After Hospitalization for Mental Illness (Within 7 Days) Date',
    scf_thirty_day_hospital_follow_up: 'QM Follow-up After Hospitalization for Mental Illness (Within 30 Days)',
    scf_thirty_day_hospital_follow_up_date: 'QM Follow-up After Hospitalization for Mental Illness (Within 30 Days) Date',
    scf_adhd: 'QM Follow-up Care for Children Prescribed ADHD',
    scf_adhd_date: 'QM Follow-up Care for Children Prescribed ADHD Date',
    scf_engagement: 'QM Initiation & Engagement of Alcohol and Other Drug Dependence Treatment',
    scf_engagement_date: 'QM Initiation & Engagement of Alcohol and Other Drug Dependence Treatment Date',
    scf_mat: 'QM Initation and Utilization of MAT for OUD',
    scf_mat_date: 'QM Initation and Utilization of MAT for OUD Date',
    scf_tobacco: 'QM Medical Assistance with Smoking and Tobacco Use Cessation',
    scf_tobacco_date: 'QM Medical Assistance with Smoking and Tobacco Use Cessation Date',
    scf_anti_psych: 'QM Metabolic Monitoring on Anti-Psychotic Medication',
    scf_anti_psych_date: 'QM Metabolic Monitoring on Anti-Psychotic Medication Date',
    scf_phq9: 'QM PHQ9 to Monitor Depression Symptoms for Adolescents and Adults',
    scf_phq9_date: 'QM PHQ9 to Monitor Depression Symptoms for Adolescents and Adults Date',
    scf_psychosocial: 'QM Use of First Line Psychosocial Care',
    scf_psychosocial_date: 'QM Use of First Line Psychosocial Care Date',
    scf_well_child_visit_3_18: 'QM Well Child Visit 3-18 Months',
    scf_well_child_visit_3_18_date: 'QM Well Child Visit 3-18 Months Date',
    scf_well_child_first_30: 'QM Well Child First 30 Months',
    scf_well_child_first_30_date: 'QM Well Child First 30 Months Date',

    deleted_program: 'Program Deleted',
    agency_options: 'Agency Options',
    consented_agencies: 'Consented Agencies',
}

export const report_keys_readable = {
    project: 'Project',
    referralAgency: 'Agency',
    county: 'County',
    needs: 'Needs',
    selectedFields: 'Columns',
    maxLimit: 'Limit',
    startDate: 'Start received date',
    endDate: 'End received date',
    firstContactStartDate: 'First contact start date',
    firstContactEndDate: 'First contact end date ',
}

export const report_projection_options = [
    {
        Demographics: {
            nano_id: 'ID',
            last_name: 'Last Name',
            middle_name: 'Middle Name',
            first_name: 'First Name',
            dob: 'DOB',
            date_assigned: 'Date Assigned',
            address_1: 'Address',
            city: 'City',
            state: 'State',
            county: 'County',
            zip: 'ZIP',
            phone: 'Phone Number',
            email: 'Email Address',
            language: 'Language',
            active_duty: 'Active Duty',
            veteran: 'Veteran',
            ethnicity: 'Ethnicity',
        },
        Referral: {
            referral_status: 'Referral Status',
            referral_agency: 'Referral Agency',
            referral_source: 'Referral Source',
            referral_source_email: 'Referral Source Email',
            referral_source_name: 'Referral Source Name',
            referral_title: 'Referral Source Title',
            referral_source_phone: 'Referral Source Phone Number',
            referral_need: 'Referral Source Need',
        },
        Insurance: {
            insurance_1: 'Insurance Type 1',
            insurance_2: 'Insurance Type 2',
            insurance_3: 'Insurance Type 3',
        },
        Assessments: {
            assessment_contact_date: 'Contact Date',
            assessment_date_completed: 'Date Completed',
            assessment_date_submitted: 'Date Submitted',
            assessment_date_approved: 'Date Approved',
        },

        'Client Consent': {
            consent_date: 'Date Completed',
            consent: 'Skipped (Check/Uncheck)',
        },

        'Contact Information': {
            contact_needs: 'Contact Need',
            contact_type: 'Contact Type',
            contact_date: 'Contact Date',
            number_of_contacts: '# of Contacts',
            contact_details: 'Contact Details',
        },
        Therapist: {
            agency_therapist: 'Therapist Agency',
            phone_agency_therapist: 'Therapist Phone Number',
            email_agency_therapist: 'Therapist Email Address',
        },
        'Prescribing Clinician': {
            agency_prescribing_clinician: 'Clinician Agency',
            phone_agency_prescribing_clinician: 'Clinician Phone Number',
            email_agency_prescribing_clinician: 'Clinician Email Address',
        },
        'Primary Care Provider': {
            pcp_site_name: 'PCP Site Name',
            pcp_npi: 'PCP NPI',
            pcp_phone_number: 'PCP Phone Number',
            pcp_address: 'PCP Address',
            pcp_email_address: 'PCP Email Address',
        },
        'Care Management': {
            care_management_enrolled: 'Enrollment Status',
            care_management_facility: 'Facility',
            care_management_enrollment_date: 'Enrollment Date',
            health_home_enrolled: 'HealthHome Enrolled',
            assessment_harp: 'HARP Status',
        },
        Physical: {
            last_annual_physical_date: 'Last Physical Date',
            last_pcp_followup_date: 'Last PCP Follow-Up Date',
        },
        'Quality Metrics': {
            qm_1_checkbox: '[Status QM] Diabetes Screen',
            qm_1_date: '[Date QM] Diabetes Screen',
            qm_2_checkbox: '[Status] Potential Avoidable ED Utilization',
            qm_2_date: '[Date QM] Potential Avoidable ED Utilization',
            qm_3_checkbox: '[Status QM] CCS',
            qm_3_date: '[Date QM] CCS',
            qm_4_checkbox: '[Status] CDC-Eye',
            qm_4_date: '[Date QM] CDC-Eye',
            qm_5_checkbox: '[Status QM] AMR',
            qm_5_date: '[Date QM] AMR',
            qm_6_checkbox: '[Status QM] COL',
            qm_6_date: '[Date QM] COL',
        },
        'Outreach & Engagement': {
            engagement_contact_type: 'O & E Contact Type',
            engagement_date: 'O & E Date',
            engagement_time: 'O & E Time',
            engagement_number_of_contacts: 'O & E Number of Contacts',
            engagement_contact_details: 'O & E Contact Details',
        },
        'Guardian Information': {
            guardian_last_name: 'Last Name',
            guardian_first_name: 'First Name',
        },
        Hospital: {
            hospital_site_name: 'Hospital Name',
            hospital_site_address: 'Hospital Address',
            hospital_site_npi: 'Hospital NPI#',
            hospital_site_phone: 'Hospital Phone Number',
        },
    },
]

export const csv_upload_columns = [
    {
        title: 'Referral Need',
        width: 100,
        dataIndex: 'referral_need',
        key: 'referral_need',
    },
    {
        title: 'Insurance',
        width: 150,
        dataIndex: 'insurance_1',
        key: 'insurance_1',
    },
    {
        title: 'Referral Agency',
        width: 150,
        dataIndex: 'referral_agency',
        key: 'referral_agency',
    },
    {
        title: 'HARP',
        width: 100,
        dataIndex: 'assessment_harp',
        key: 'assessment_harp',
    },
    {
        title: 'CIN',
        width: 100,
        dataIndex: 'cin',
        key: 'cin',
    },
    {
        title: 'Last Name',
        width: 100,
        dataIndex: 'last_name',
        key: 'last_name',
    },
    {
        title: 'First Name',
        width: 100,
        dataIndex: 'first_name',
        key: 'first_name',
    },
    {
        title: 'Middle Name',
        width: 100,
        dataIndex: 'middle_name',
        key: 'middle_name',
    },
    {
        title: 'DOB',
        width: 100,
        dataIndex: 'dob',
        key: 'dob',
    },
    {
        title: 'Address 1',
        width: 150,
        dataIndex: 'address_1',
        key: 'address_1',
    },
    {
        title: 'Address 2',
        width: 150,
        dataIndex: 'address_2',
        key: 'address_2',
    },
    {
        title: 'Gender',
        width: 100,
        dataIndex: 'gender',
        key: 'gender',
    },
    {
        title: 'City',
        width: 150,
        dataIndex: 'city',
        key: 'city',
    },
    {
        title: 'State',
        width: 100,
        dataIndex: 'state',
        key: 'state',
    },
    {
        title: 'ZIP',
        width: 100,
        dataIndex: 'zip',
        key: 'zip',
    },
    {
        title: 'County',
        width: 100,
        dataIndex: 'county',
        key: 'county',
    },
    {
        title: 'Phone',
        width: 150,
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Referral Source',
        width: 150,
        dataIndex: 'referral_source',
        key: 'referral_source',
    },
    {
        title: 'Referral Source Email',
        width: 200,
        dataIndex: 'referral_source_email',
        key: 'referral_source_email',
    },
    {
        title: 'Referral Source Phone',
        width: 200,
        dataIndex: 'referral_source_phone',
        key: 'referral_source_phone',
    },
    {
        title: 'Referral Status',
        width: 150,
        dataIndex: 'referral_status',
        key: 'referral_status',
    },
    {
        title: 'Contact Needs (TOC Only)',
        width: 200,
        dataIndex: 'contact_needs',
        key: 'contact_needs',
    },
    {
        title: 'Contact Type (TOC Only)',
        width: 150,
        dataIndex: 'contact_type',
        key: 'contact_type',
    },
]

export const county_zip_codes = {
    12047: 'Albany',
    12202: 'Albany',
    12203: 'Albany',
    12204: 'Albany',
    12205: 'Albany',
    12206: 'Albany',
    12208: 'Albany',
    12209: 'Albany',
    12210: 'Albany',
    14810: 'Allegany',
    14895: 'Allegany',
    10451: 'Bronx',
    10452: 'Bronx',
    10453: 'Bronx',
    10454: 'Bronx',
    10455: 'Bronx',
    10456: 'Bronx',
    10457: 'Bronx',
    10458: 'Bronx',
    10459: 'Bronx',
    10460: 'Bronx',
    10461: 'Bronx',
    10462: 'Bronx',
    10463: 'Bronx',
    10466: 'Bronx',
    10467: 'Bronx',
    10468: 'Bronx',
    10470: 'Bronx',
    10471: 'Bronx',
    10473: 'Bronx',
    13790: 'Broome',
    13901: 'Broome',
    13903: 'Broome',
    13904: 'Broome',
    13905: 'Broome',
    14070: 'Cattaraugus',
    14706: 'Cattaraugus',
    14760: 'Cattaraugus',
    14779: 'Cattaraugus',
    13021: 'Cayuga',
    13024: 'Cayuga',
    13203: 'Cayuga',
    14048: 'Chautauqua',
    14063: 'Chautauqua',
    14081: 'Chautauqua',
    14207: 'Chautauqua',
    14701: 'Chautauqua',
    14716: 'Chautauqua',
    14757: 'Chautauqua',
    14871: 'Chemung',
    14901: 'Chemung',
    14902: 'Chemung',
    14903: 'Chemung',
    14904: 'Chemung',
    13746: 'Chenango',
    13815: 'Chenango',
    13820: 'Chenango',
    12901: 'Clinton',
    12903: 'Clinton',
    12929: 'Clinton',
    12962: 'Clinton',
    12534: 'Columbia',
    12565: 'Columbia',
    13045: 'Cortland',
    13856: 'Delaware',
    12508: 'Dutchess',
    12522: 'Dutchess',
    12524: 'Dutchess',
    12533: 'Dutchess',
    12538: 'Dutchess',
    12545: 'Dutchess',
    12572: 'Dutchess',
    12582: 'Dutchess',
    12603: 'Dutchess',
    12601: 'Dutchess',
    12501: 'Dutchess',
    12504: 'Dutchess',
    12506: 'Dutchess',
    12507: 'Dutchess',
    12510: 'Dutchess',
    12511: 'Dutchess',
    12512: 'Dutchess',
    12514: 'Dutchess',
    12527: 'Dutchess',
    12531: 'Dutchess',
    12537: 'Dutchess',
    12540: 'Dutchess',
    12546: 'Dutchess',
    12564: 'Dutchess',
    12567: 'Dutchess',
    12569: 'Dutchess',
    12570: 'Dutchess',
    12571: 'Dutchess',
    12574: 'Dutchess',
    12578: 'Dutchess',
    12580: 'Dutchess',
    12581: 'Dutchess',
    12583: 'Dutchess',
    12585: 'Dutchess',
    12590: 'Dutchess',
    12592: 'Dutchess',
    12594: 'Dutchess',
    12602: 'Dutchess',
    12604: 'Dutchess',
    14004: 'Erie',
    14034: 'Erie',
    14043: 'Erie',
    14047: 'Erie',
    14068: 'Erie',
    14111: 'Erie',
    14127: 'Erie',
    14150: 'Erie',
    14201: 'Erie',
    14202: 'Erie',
    14203: 'Erie',
    14204: 'Erie',
    14206: 'Erie',
    14208: 'Erie',
    14209: 'Erie',
    14210: 'Erie',
    14211: 'Erie',
    14212: 'Erie',
    14213: 'Erie',
    14214: 'Erie',
    14215: 'Erie',
    14218: 'Erie',
    14220: 'Erie',
    14221: 'Erie',
    14222: 'Erie',
    14225: 'Erie',
    14226: 'Erie',
    14227: 'Erie',
    12932: 'Essex',
    12977: 'Essex',
    12993: 'Essex',
    12853: 'Franklin',
    12937: 'Franklin',
    12953: 'Franklin',
    12983: 'Franklin',
    13655: 'Franklin',
    12010: 'Fulton',
    12078: 'Fulton',
    12095: 'Fulton',
    14020: 'Genesee',
    14569: 'Genesee',
    12051: 'Greene',
    12413: 'Greene',
    12414: 'Greene',
    12842: 'Hamilton',
    13350: 'Herkimer',
    13501: 'Herkimer',
    13601: 'Jefferson',
    13607: 'Jefferson',
    13619: 'Jefferson',
    13673: 'Jefferson',
    11201: 'Kings',
    11203: 'Kings',
    11205: 'Kings',
    11206: 'Kings',
    11207: 'Kings',
    11208: 'Kings',
    11209: 'Kings',
    11210: 'Kings',
    11211: 'Kings',
    11212: 'Kings',
    11213: 'Kings',
    11214: 'Kings',
    11215: 'Kings',
    11216: 'Kings',
    11217: 'Kings',
    11219: 'Kings',
    11220: 'Kings',
    11221: 'Kings',
    11223: 'Kings',
    11224: 'Kings',
    11225: 'Kings',
    11226: 'Kings',
    11228: 'Kings',
    11229: 'Kings',
    11230: 'Kings',
    11232: 'Kings',
    11233: 'Kings',
    11234: 'Kings',
    11235: 'Kings',
    11236: 'Kings',
    11237: 'Kings',
    11238: 'Kings',
    13367: 'Lewis',
    13473: 'Lewis',
    13667: 'Lewis',
    14414: 'Livingston',
    14437: 'Livingston',
    14454: 'Livingston',
    14510: 'Livingston',
    14556: 'Livingston',
    14830: 'Livingston',
    13163: 'Madison',
    13168: 'Madison',
    13421: 'Madison',
    14580: 'Monroe',
    14604: 'Monroe',
    14605: 'Monroe',
    14606: 'Monroe',
    14607: 'Monroe',
    14608: 'Monroe',
    14609: 'Monroe',
    14610: 'Monroe',
    14611: 'Monroe',
    14614: 'Monroe',
    14616: 'Monroe',
    14617: 'Monroe',
    14618: 'Monroe',
    14620: 'Monroe',
    14621: 'Monroe',
    14623: 'Monroe',
    14624: 'Monroe',
    14642: 'Monroe',
    12068: 'Montgomery',
    13410: 'Montgomery',
    11021: 'Nassau',
    11040: 'Nassau',
    11042: 'Nassau',
    11501: 'Nassau',
    11507: 'Nassau',
    11510: 'Nassau',
    11520: 'Nassau',
    11530: 'Nassau',
    11542: 'Nassau',
    11550: 'Nassau',
    11552: 'Nassau',
    11553: 'Nassau',
    11554: 'Nassau',
    11561: 'Nassau',
    11570: 'Nassau',
    11572: 'Nassau',
    11575: 'Nassau',
    11577: 'Nassau',
    11580: 'Nassau',
    11590: 'Nassau',
    11701: 'Nassau',
    11704: 'Nassau',
    11710: 'Nassau',
    11783: 'Nassau',
    11791: 'Nassau',
    11793: 'Nassau',
    11801: 'Nassau',
    11803: 'Nassau',
    11804: 'Nassau',
    10001: 'New York',
    10002: 'New York',
    10003: 'New York',
    10004: 'New York',
    10006: 'New York',
    10007: 'New York',
    10009: 'New York',
    10010: 'New York',
    10011: 'New York',
    10013: 'New York',
    10014: 'New York',
    10016: 'New York',
    10018: 'New York',
    10019: 'New York',
    10020: 'New York',
    10021: 'New York',
    10022: 'New York',
    10023: 'New York',
    10024: 'New York',
    10025: 'New York',
    10026: 'New York',
    10027: 'New York',
    10028: 'New York',
    10029: 'New York',
    10030: 'New York',
    10031: 'New York',
    10032: 'New York',
    10034: 'New York',
    10035: 'New York',
    10036: 'New York',
    10037: 'New York',
    10038: 'New York',
    10039: 'New York',
    10040: 'New York',
    10041: 'New York',
    10065: 'New York',
    10075: 'New York',
    10119: 'New York',
    10128: 'New York',
    10314: 'New York',
    10465: 'New York',
    11416: 'New York',
    11418: 'New York',
    11427: 'New York',
    14094: 'Niagara',
    14120: 'Niagara',
    14301: 'Niagara',
    14302: 'Niagara',
    14303: 'Niagara',
    14304: 'Niagara',
    14305: 'Niagara',
    13403: 'Oneida',
    13413: 'Oneida',
    13424: 'Oneida',
    13440: 'Oneida',
    13442: 'Oneida',
    13502: 'Oneida',
    13503: 'Oneida',
    13057: 'Onondaga',
    13202: 'Onondaga',
    13204: 'Onondaga',
    13205: 'Onondaga',
    13210: 'Onondaga',
    13211: 'Onondaga',
    13215: 'Onondaga',
    13224: 'Onondaga',
    13148: 'Ontario',
    14424: 'Ontario',
    14432: 'Ontario',
    14456: 'Ontario',
    14544: 'Ontario',
    10924: 'Orange',
    10940: 'Orange',
    10941: 'Orange',
    12550: 'Orange',
    12553: 'Orange',
    12586: 'Orange',
    12771: 'Orange',
    19040: 'Orange',
    10910: 'Orange',
    10912: 'Orange',
    10914: 'Orange',
    10915: 'Orange',
    10916: 'Orange',
    10917: 'Orange',
    10918: 'Orange',
    10919: 'Orange',
    10921: 'Orange',
    10922: 'Orange',
    10925: 'Orange',
    10926: 'Orange',
    10928: 'Orange',
    10930: 'Orange',
    10932: 'Orange',
    10933: 'Orange',
    10949: 'Orange',
    10950: 'Orange',
    10953: 'Orange',
    10958: 'Orange',
    10959: 'Orange',
    10963: 'Orange',
    10969: 'Orange',
    10973: 'Orange',
    10975: 'Orange',
    10979: 'Orange',
    10981: 'Orange',
    10985: 'Orange',
    10987: 'Orange',
    10988: 'Orange',
    10990: 'Orange',
    10992: 'Orange',
    10996: 'Orange',
    10997: 'Orange',
    10998: 'Orange',
    12518: 'Orange',
    12520: 'Orange',
    12543: 'Orange',
    12549: 'Orange',
    12551: 'Orange',
    12552: 'Orange',
    12555: 'Orange',
    12566: 'Orange',
    12575: 'Orange',
    12577: 'Orange',
    12584: 'Orange',
    12729: 'Orange',
    12746: 'Orange',
    12780: 'Orange',
    14411: 'Orleans',
    13069: 'Oswego',
    13114: 'Oswego',
    13126: 'Oswego',
    13326: 'Otsego',
    10509: 'Putnam',
    10512: 'Putnam',
    10516: 'Putnam',
    10524: 'Putnam',
    10537: 'Putnam',
    10541: 'Putnam',
    10542: 'Putnam',
    10579: 'Putnam',
    12563: 'Putnam',
    11004: 'Queens',
    11101: 'Queens',
    11102: 'Queens',
    11103: 'Queens',
    11104: 'Queens',
    11351: 'Queens',
    11355: 'Queens',
    11358: 'Queens',
    11362: 'Queens',
    11364: 'Queens',
    11370: 'Queens',
    11372: 'Queens',
    11373: 'Queens',
    11374: 'Queens',
    11375: 'Queens',
    11377: 'Queens',
    11385: 'Queens',
    11414: 'Queens',
    11420: 'Queens',
    11423: 'Queens',
    11426: 'Queens',
    11428: 'Queens',
    11432: 'Queens',
    11433: 'Queens',
    11434: 'Queens',
    11435: 'Queens',
    11691: 'Queens',
    11692: 'Queens',
    11693: 'Queens',
    11694: 'Queens',
    12144: 'Rensselaer',
    12180: 'Rensselaer',
    12182: 'Rensselaer',
    12198: 'Rensselaer',
    10301: 'Richmond',
    10302: 'Richmond',
    10304: 'Richmond',
    10305: 'Richmond',
    10309: 'Richmond',
    10310: 'Richmond',
    10901: 'Rockland',
    10913: 'Rockland',
    10923: 'Rockland',
    10952: 'Rockland',
    10956: 'Rockland',
    10960: 'Rockland',
    10965: 'Rockland',
    10970: 'Rockland',
    10977: 'Rockland',
    10989: 'Rockland',
    10994: 'Rockland',
    10962: 'Rockland',
    10911: 'Rockland',
    10920: 'Rockland',
    10927: 'Rockland',
    10931: 'Rockland',
    10954: 'Rockland',
    10964: 'Rockland',
    10968: 'Rockland',
    10974: 'Rockland',
    10976: 'Rockland',
    10980: 'Rockland',
    10982: 'Rockland',
    10983: 'Rockland',
    10984: 'Rockland',
    10986: 'Rockland',
    10993: 'Rockland',
    12020: 'Saratoga',
    12065: 'Saratoga',
    12118: 'Saratoga',
    12304: 'Saratoga',
    12801: 'Saratoga',
    12866: 'Saratoga',
    12157: 'Schenectady',
    12302: 'Schenectady',
    12305: 'Schenectady',
    12307: 'Schenectady',
    12308: 'Schenectady',
    12043: 'Schoharie',
    12149: 'Schoharie',
    14891: 'Schuyler',
    13165: 'Seneca',
    14541: 'Seneca',
    14588: 'Seneca',
    13617: 'St. Lawrence',
    13642: 'St. Lawrence',
    13662: 'St. Lawrence',
    13669: 'St. Lawrence',
    13676: 'St. Lawrence',
    11703: 'Suffolk',
    11706: 'Suffolk',
    11716: 'Suffolk',
    11717: 'Suffolk',
    11722: 'Suffolk',
    11726: 'Suffolk',
    11727: 'Suffolk',
    11738: 'Suffolk',
    11739: 'Suffolk',
    11741: 'Suffolk',
    11743: 'Suffolk',
    11746: 'Suffolk',
    11749: 'Suffolk',
    11751: 'Suffolk',
    11763: 'Suffolk',
    11769: 'Suffolk',
    11772: 'Suffolk',
    11776: 'Suffolk',
    11777: 'Suffolk',
    11779: 'Suffolk',
    11782: 'Suffolk',
    11787: 'Suffolk',
    11788: 'Suffolk',
    11794: 'Suffolk',
    11798: 'Suffolk',
    11901: 'Suffolk',
    11910: 'Suffolk',
    11937: 'Suffolk',
    11944: 'Suffolk',
    11950: 'Suffolk',
    11952: 'Suffolk',
    11967: 'Suffolk',
    11978: 'Suffolk',
    11980: 'Suffolk',
    12701: 'Sullivan',
    12733: 'Sullivan',
    12742: 'Sullivan',
    12745: 'Sullivan',
    12754: 'Sullivan',
    12788: 'Sullivan',
    12719: 'Sullivan',
    12720: 'Sullivan',
    12721: 'Sullivan',
    12722: 'Sullivan',
    12723: 'Sullivan',
    12724: 'Sullivan',
    12725: 'Sullivan',
    12726: 'Sullivan',
    12727: 'Sullivan',
    12732: 'Sullivan',
    12734: 'Sullivan',
    12736: 'Sullivan',
    12737: 'Sullivan',
    12738: 'Sullivan',
    12740: 'Sullivan',
    12741: 'Sullivan',
    12743: 'Sullivan',
    12747: 'Sullivan',
    12748: 'Sullivan',
    12749: 'Sullivan',
    12750: 'Sullivan',
    12751: 'Sullivan',
    12752: 'Sullivan',
    12758: 'Sullivan',
    12759: 'Sullivan',
    12760: 'Sullivan',
    12762: 'Sullivan',
    12763: 'Sullivan',
    12764: 'Sullivan',
    12765: 'Sullivan',
    12766: 'Sullivan',
    12767: 'Sullivan',
    12768: 'Sullivan',
    12769: 'Sullivan',
    12770: 'Sullivan',
    12775: 'Sullivan',
    12776: 'Sullivan',
    12777: 'Sullivan',
    12778: 'Sullivan',
    12779: 'Sullivan',
    12781: 'Sullivan',
    12783: 'Sullivan',
    12784: 'Sullivan',
    12785: 'Sullivan',
    12786: 'Sullivan',
    12787: 'Sullivan',
    12789: 'Sullivan',
    12790: 'Sullivan',
    12791: 'Sullivan',
    12792: 'Sullivan',
    13827: 'Tioga',
    14892: 'Tioga',
    14850: 'Tompkins',
    12401: 'Ulster',
    12402: 'Ulster',
    12449: 'Ulster',
    12458: 'Ulster',
    12466: 'Ulster',
    12477: 'Ulster',
    12561: 'Ulster',
    12589: 'Ulster',
    12404: 'Ulster',
    12409: 'Ulster',
    12410: 'Ulster',
    12411: 'Ulster',
    12412: 'Ulster',
    12416: 'Ulster',
    12417: 'Ulster',
    12419: 'Ulster',
    12420: 'Ulster',
    12428: 'Ulster',
    12429: 'Ulster',
    12432: 'Ulster',
    12433: 'Ulster',
    12435: 'Ulster',
    12440: 'Ulster',
    12441: 'Ulster',
    12443: 'Ulster',
    12446: 'Ulster',
    12448: 'Ulster',
    12453: 'Ulster',
    12456: 'Ulster',
    12457: 'Ulster',
    12461: 'Ulster',
    12464: 'Ulster',
    12465: 'Ulster',
    12471: 'Ulster',
    12472: 'Ulster',
    12475: 'Ulster',
    12480: 'Ulster',
    12481: 'Ulster',
    12483: 'Ulster',
    12484: 'Ulster',
    12486: 'Ulster',
    12487: 'Ulster',
    12489: 'Ulster',
    12490: 'Ulster',
    12491: 'Ulster',
    12493: 'Ulster',
    12494: 'Ulster',
    12495: 'Ulster',
    12498: 'Ulster',
    12515: 'Ulster',
    12525: 'Ulster',
    12528: 'Ulster',
    12542: 'Ulster',
    12547: 'Ulster',
    12548: 'Ulster',
    12568: 'Ulster',
    12588: 'Ulster',
    12804: 'Warren',
    12821: 'Washington',
    12839: 'Washington',
    14489: 'Wayne',
    14513: 'Wayne',
    10507: 'Westchester',
    10510: 'Westchester',
    10522: 'Westchester',
    10523: 'Westchester',
    10528: 'Westchester',
    10530: 'Westchester',
    10532: 'Westchester',
    10533: 'Westchester',
    10535: 'Westchester',
    10536: 'Westchester',
    10543: 'Westchester',
    10547: 'Westchester',
    10549: 'Westchester',
    10550: 'Westchester',
    10552: 'Westchester',
    10553: 'Westchester',
    10562: 'Westchester',
    10566: 'Westchester',
    10573: 'Westchester',
    10577: 'Westchester',
    10591: 'Westchester',
    10601: 'Westchester',
    10603: 'Westchester',
    10604: 'Westchester',
    10605: 'Westchester',
    10606: 'Westchester',
    10607: 'Westchester',
    10701: 'Westchester',
    10703: 'Westchester',
    10705: 'Westchester',
    10801: 'Westchester',
    10805: 'Westchester',
    10548: 'Westchester',
    10595: 'Westchester',
    10501: 'Westchester',
    10502: 'Westchester',
    10503: 'Westchester',
    10504: 'Westchester',
    10505: 'Westchester',
    10506: 'Westchester',
    10511: 'Westchester',
    10514: 'Westchester',
    10517: 'Westchester',
    10518: 'Westchester',
    10519: 'Westchester',
    10520: 'Westchester',
    10521: 'Westchester',
    10526: 'Westchester',
    10527: 'Westchester',
    10538: 'Westchester',
    10540: 'Westchester',
    10545: 'Westchester',
    10546: 'Westchester',
    10551: 'Westchester',
    10560: 'Westchester',
    10567: 'Westchester',
    10570: 'Westchester',
    10576: 'Westchester',
    10578: 'Westchester',
    10580: 'Westchester',
    10583: 'Westchester',
    10587: 'Westchester',
    10588: 'Westchester',
    10589: 'Westchester',
    10590: 'Westchester',
    10594: 'Westchester',
    10596: 'Westchester',
    10597: 'Westchester',
    10598: 'Westchester',
    10602: 'Westchester',
    10610: 'Westchester',
    10702: 'Westchester',
    10704: 'Westchester',
    10706: 'Westchester',
    10707: 'Westchester',
    10708: 'Westchester',
    10709: 'Westchester',
    10710: 'Westchester',
    10802: 'Westchester',
    10803: 'Westchester',
    10804: 'Westchester',
    14011: 'Wyoming',
    14527: 'Yates',
}

export const date_format = 'MM/DD/YYYY'
export const date_time_format = 'MM/DD/YYYY hh:mm a'
export const time_format = 'hh:mm a'

export const registered_project = ['HCBS', 'TOC', 'CMO', 'COVID', 'CBHC-MVP', 'SHARED-CARE']

export const registered_project_reports = ['HCBS', 'TOC', 'CMO', 'COVID', 'CBHC-MVP', 'SHARED-CARE']

export const shared_care_access = ['Care Management', 'Peer Services', 'Primary Care', 'Mental Health', 'Substance Use']

export const registered_project_fullname = {
    HCBS: 'HCBS',
    TOC: 'TOC',
    CMO: 'Montefiore CMO',
    COVID: 'SAMHSA COVID',
    'CBHC-MVP': 'CBHCare & MVP',
    'SHARED-CARE': 'Shared Care',
}

export const contact_needs = [
    'Care Management',
    'HCBS',
    'Substance Use',
    'Mental Health',
    'Medication',
    'Medical',
    'Health Coaching',
    'Psychoeducation',
    'SDOH - Housing',
    'SDOH - Food',
    'SDOH - Education',
    'SDOH - Employment',
    'SDOH - Transportation',
    'SDOH - Safety',
    'SDOH - Social',
    'SDOH - Finances',
    'SDOH - Spiritual',
]

export const contact_type = ['Phone Call', 'Telehealth', 'Unit Visit', 'Home Visit', 'Community Visit', 'Text/Email', 'Transport', 'Collateral Contact']

export const number_of_contacts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const contact_duration = ['<15 min', '15-30 min', '30 min-1 hr', '1-1.5 hrs', '1.5-2 hrs', '2-3 hrs', '3-4 hrs', '4+ hrs']

export const outcome_of_intervention = [
    'Referred to Outpatient Treatment (SUD)',
    'Engaged in Outpatient Treatment (SUD)',
    'Admitted to Inpatient Treatment (SUD)',
    'Referred to Outpatient Treatment (MH)',
    'Engaged in Outpatient Treatment (MH)',
    'Admitted to Inpatient Treatment (MH)',
    'Admitted to Emergency Department/Hospital',
    'Diverted from admission to Inpatient Mental Health Treatment',
    'Referred to Primary Care',
    'Engaged in Primary Care',
    'Diverted from Emergency Department',
    'Referred to Peer Services',
    'Engaged in Peer Services',
    'Referred to Health Home Care Management',
    'Engaged in Health Home Care Management',
    'Accepted Harm Reduction interventions (no treatment and did not engage in peer services)',
    'Declined Services',
]

export const mental_type_of_appointment = ['Individual Therapy', 'Group Therapy', 'Crisis Intervention']

export const substance_type_of_appointment = ['Psychiatric Assessment', 'Medication Management', 'Crisis Intervention']

export const grpa = ['GRPA', 'NOMS', 'Other']

export const grpa_response = ['Yes', 'No', 'Declined']

export const samhsa_frequency = ['Initial', '3 Months', '6 Months', '1 Year']

export const address_type = {
    'Primary Residence': {
        required_fields: ['all'],
    },
    'Temporary Residence': {
        required_fields: ['all'],
    },
    Homeless: {
        required_fields: [],
    },
    Shelter: {
        required_fields: ['city', 'state', 'zip'],
    },
}

export const health_home_facility = [
    "Children's Health Home of Upstate NY (CHHUNY)",
    'Community Health Care Collaborative',
    'Hudson Valley Care',
    'Collaborative for Children & Families',
    'Institute for Family Health',
    'Unknown',
]

export const care_management_facility = [
    'Access: Supports for Living',
    'Andrus - Julia Dyckman Andrus Memorial, Inc',
    'Astor Services for Children and Families',
    'CHOICE of New Rochelle',
    'Cove Care Center',
    'HONORehg',
    'Human Development Services of Westchester',
    'Hudson Valley Community Services',
    'Mental Health America of Dutchess County, Inc.',
    'Mental Health Association of Orange County, Inc.',
    'Mental Health Association of Rockland County, Inc.',
    'Mental Health Association of Ulster County, Inc.',
    'Mental Health Association of Westchester County, Inc.',
    'Rehabilitation Support Services',
    "St. Christoper's Inn",
    'The Guidance Center of Westchester',
    'Westchester of Jewish Community Services',
    'Unknown',
]

export const engagement_contact_type = ['Collateral Contact', 'Community Visit', 'Home Visit', 'Phone Call', 'Telehealth', 'Text/Email', 'Transport', 'Unit Visit']

export const engagement_number_of_contacts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const shared_care_counties = ['Dutchess', 'Orange', 'Putnam', 'Rockland', 'Sullivan', 'Ulster', 'Westchester']

export const shared_care_programs = [
    'Comprehensive PROS with Clinical Treatment',
    'Supported/Single Room Occupancy (SRO)',
    'Health Home Non-Medicaid Care Management',
    'Supported Housing Community Services',
    'Congregate/Treatment',
    'Clinic Treatment',
    'CFTSS: Community Psychiatric Support and Treatment (CPST)',
    'Crisis Intervention',
    'Crisis/Respite Beds',
    'CFTSS: Family Peer Support Services (FPSS)',
    'Family Support Services - Children And Family',
    'Advocacy/Support Services',
    'CFTSS: Mobile Crisis Intervention (CI)',
    'Non-Medicaid Care Coordination',
    'Health Home Care Management',
    'CFTSS: Other Licensed Practitioner (OLP)',
    'Outreach',
    'Apartment/Treatment',
    'CFTSS: Psychosocial Rehabilitation (PSR)',
    'Transition Management Services',
    'Transportation',
    'CFTSS: Youth Peer Support and Training (YPST)',
    'Early Recognition Coordination and Screening Services',
    'Day Treatment',
    'Partial Hospitalization',
    'Home Based Crisis Intervention',
    'Residential Treatment Facility - Children And Youth',
    'Assisted Competitive Employment',
    'Self-Help Programs',
    'Geriatric Demo Gatekeeper',
    'Respite Services',
    'Multi-Cultural Initiative',
    'Children And Youth Community Residence',
    'Adult Home Supportive Case Management',
    'Psychosocial Club',
    'Supported Education',
    'Recovery Center',
    'Ongoing Integrated Supported Employment Services',
    'Assertive Community Treatment (ACT)',
    'Drop In Centers',
    'Child And Youth HCBS Consolidated Waiver Community Self-Advocay Training and Supports',
    'Child And Youth HCBS Consolidated Waiver Caregiver/Family Supports And Services',
    'Affirmative Business/Industry',
    'SRO Community Residence',
    'Work Program',
    'Recreation and/or Fitness',
    'Comprehensive PROS without Clinical Treatment',
    'Vocational Services - Children And Family (C And F)',
    'Transitional Employment Placement (TEP)',
    'School Mental Health Program',
]

export const outcomesList = [
    'Follow up care for children prescribed ADHD (ADD)',
    'Metabolic monitoring on anti-psychotic medications (APM)',
    'Use of first line psychosocial care (APP)',
    'Antidepressant Medication Management (AMM)',
    'Adherence to Antipsychotic medication for persons with Schizophrenia (SSA)',
    'Adult BMI Assessment (ABA)',
    'Cardiovascular monitoring for people with cardiovascular disease and Schizophrenia (SMC)',
    'Diabetes monitoring for people with diabetes and Schizophrenia (SMD)',
    'Diabetes screening for people with Schizophrenia or Bi-polar disorder on antipsychotic medication (SSD)',
    'PHQ9 to Monitor Depression Symptoms for Adolescents & Adults (DMS)',
    'Follow up after hospitalization for mental illness (Within 7 days) (FUH)',
    'Follow up after hospitalization for mental illness (Within 30 days) (FUH)',
    'High utilization of emergency department services',
    'Adult’s Access to Preventative/Ambulatory Health Services (AAP)',
    'Medical Assistance with Smoking and Tobacco Use Cessation (MSC)',
    'Initiation and engagement of alcohol and other drug dependence treatment (IET) - Initiation / Engagement',
    'Initiation and Utilization of MAT for OUD (New)',
    'Percentage of members enrolled in a Health Home',
    'Continuity of Care for Inpatient Detox or Rehabilitation to Lower Level of Care. (COR & COD)',
]

export const scf_groups = ['OASAS', 'OMH', 'HCBS']

export const ethnicity = ['American Indian or Alaska Native', 'Asian', 'African American', 'Hispanic or Latino', 'Native Hawaiian or other Pacific Islander', 'Caucasian', 'Other']

export const scf_counties = ['Dutchess', 'Orange', 'Putnam', 'Rockland', 'Sullivan', 'Ulster', 'Westchester']

export const emergency_contact_relationship = ['Parent/Legal Guardian', 'Spouse', 'Significant Other', 'Sibling', 'Friend', 'Relative', 'Peer', 'Sponsor/Coach']
