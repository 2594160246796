import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
    en: {
        translation: {
            select_option: "Select an option",
            guardian_question: "Are you answering on behalf of a minor or as a legal guardian?",
            guardian_first_name_question: "Enter the guardian's first name",
            guardian_first_name_placeholder: "Guardian's First Name",
            guardian_last_name_question: "Enter the guardian's last name",
            guardian_last_name_placeholder: "Guardian's Last Name",
            first_name_question: "Enter your first name",
            first_name_placeholder: "First Name",
            last_name_question: "Enter your last name",
            last_name_placeholder: "Last Name",
            guardian_phone_question: "Enter the guardian's phone number",
            phone_question: "Enter your phone number",
            phone_invalid: "Enter phone number seperated by dashes (123-456-7890)",
            phone_placeholder: "123-456-7890",
            guardian_email_question: "Enter the guardian's email address",
            guardian_message: "Answer the remaining questions on behalf of the individual in your care.  You may be contacted for further information",
            email_question: "Enter your email address",
            email_invalid: "Emails should be in the following format: email@provider.com",
            email_placeholder: "email@provider.com",
            birthdate_question: "Select your date of birth",
            birthdate_invalid: "Invalid input",
            birthdate_placeholder: "MM/DD/YYYY",
            city_question: "Enter your city",
            city_placeholder: "city/town",
            zipcode_question: "Enter your ZIP code",
            county_question: "Enter your county",
            zipcode_placeholder: "12345",
            gender_question: "Enter the gender you identify with",
            gender_male: "Male",
            gender_female: "Female",
            gender_nb: "Non-Binary",
            yes: "Yes",
            no: "No",
            skip: "Skip",
            submit: "Submit",
            transportation_question: "Has COVID impacted your transportation and ability to get to where you need to go?",
            childcare_question: "Do challenges with childcare make it hard for you to obtain food, medicine, healthcare, or other needs due to COVID?",
            food_question: "Since the COVID pandemic began, did you ever have less food than you needed because you could not access or afford it?",
            housing_question: "Have you lost your home or place to live, or have you worried about losing it due to COVID?",
            employment_question: "Have you lost a job, been furloughed, or are working less due to COVID?",
            finances_question: "Has a primary caregiver or immediate family member died due to COVID?",
            medical_question: "Have you delayed getting any medical, mental health or substance use services during the COVID outbreak?",
            relationships_question: "Have you experienced hardships or increased conflicts in your relationships during the COVID outbreak?",
            isolation_question: "Do you ever feel like isolation is making you lonely or sad because of COVID?",
            safety_question: "Do you feel unsafe or like you may be in any physical danger or threatened emotionally?",
            depressed_question: "Do you feel depressed or anxious due to COVID related issues?",
            substances_question: "Has drinking alcohol or using drugs become a problem for you during the COVID epidemic?",
            easy_question: "Did you find this survey form easy to use?"
        }
    },
    es: {
        translation: {
            select_option: "Por favor seleccione una opción",
            guardian_question: "Esta Respondiendo en nombre de un menor o como tutor legal?",
            guardian_first_name_question: "Por favor, escribe el nombre del tutor",
            guardian_first_name_placeholder: "Nombre del tutor",
            guardian_last_name_question: "Por favor, escribe el apellido del tuto",
            guardian_last_name_placeholder: "Apellido del tuto",
            first_name_question: "Por favor escriba su nombre",
            first_name_placeholder: "Nombre de pila",
            last_name_question: "Por favor escriba su apellido",
            last_name_placeholder: "Apellido",
            guardian_phone_question: "Por favor, escriba el número de teléfono del tutor",
            phone_question: "Por favor díganos su número de teléfono",
            phone_invalid: "Por favor ingrese su respuesta en el formato correcto: 123-456-7890",
            phone_placeholder: "123-456-7890",
            guardian_email_question: "Por favor, escriba la dirección de correo electrónico del tutor",
            guardian_message: "Por favor responda las preguntas restantes en nombre de la persona bajo su cuidado. Nos comunicaremos con usted para obtener más información.",
            email_question: "Por favor díganos su dirección de correo electrónico",
            email_invalid: "Por favor ingrese su respuesta en el formato correcto",
            email_placeholder: "john.doe@gmail.com",
            birthdate_question: "Por favor díganos su fecha de nacimiento",
            birthdate_invalid: "Por favor ingrese su respuesta en el formato correcto: MM/DD/AAAA",
            birthdate_placeholder: "MM/DD/AAAA",
            city_question: "Por favor díganos dónde vive",
            city_placeholder: "ciudad/pueblo",
            zipcode_question: "Por favor díganos su código postal",
            zipcode_placeholder: "12345",
            gender_question: "Díganos el género con el que se identifica",
            gender_male: "Masculino",
            gender_female: "Hembra",
            gender_nb: "No-Binario",
            yes: "Si",
            no: "No",
            skip: "Omitir",
            submit: "Enviar",
            transportation_question: "COVID ha afectado su transporte y su capacidad para llegar a donde necesita ir?",
            childcare_question: "Los desafíos con el cuidado de niños le dificultan obtener alimentos, medicamentos, atención médica u otras necesidades debido a COVID?",
            food_question: "Desde que comenzó la pandemia de COVID, ¿alguna vez tuvo menos alimentos de los que necesitaba porque no podía acceder o pagarlos?",
            housing_question: "Ha perdido su hogar o lugar para vivir, o le preocupa perderlo debido a COVID?",
            employment_question: "Ha perdido un trabajo, ha sido suspendido o está trabajando menos debido a COVID?",
            finances_question: "Ha fallecido un cuidador principal o un familiar inmediato debido a COVID?",
            medical_question: "Ha retrasado obtener algún servicio médico, de salud mental o de uso de sustancias durante el brote de COVID?",
            relationships_question: "Has experimentado dificultades o conflictos crecientes en tus relaciones durante el brote de COVID?",
            isolation_question: "Alguna vez has sentido que el aislamiento te está haciendo sentir solo o triste por COVID?",
            safety_question: "Te sientes inseguro o como si estuvieras en peligro físico o emocionalmente amenazado?",
            depressed_question: "Te sientes deprimido o ansioso debido a problemas relacionados con COVID?",
            substances_question: "Beber alcohol o usar drogas se ha convertido en un problema para usted durante la epidemia de COVID?",
            easy_question: "¿Le resultó fácil usar este formulario de encuesta?"
        }
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    })

export default i18n