import React from 'react'
import { Col, Layout, Row, Spin, Space } from 'antd'

const { Content } = Layout

const Loader = (props) => {
    return (
        <div>
            <Content
                style={{
                    margin: '10em 16px 0',
                    flex: 'none',
                    height: '100vh',
                    textAlign: 'center',
                }}>
                <Row gutter={[12, 16]}>
                    <Col span={24}>
                        <Space>
                            <Spin size="large" />
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Space>
                            <p>Fetching Data ...</p>
                        </Space>
                    </Col>
                </Row>
            </Content>
        </div>
    )
}

export default Loader
