import React from 'react'
import { Modal, Button } from 'antd'

const TimeoutModal = ({ showModal, handleStayLoggedIn }) => {
    return (
        <Modal
            title="Session Timeout"
            visible={showModal}
            onOk={handleStayLoggedIn}
            closable={false}
            maskClosable={false}
            footer={[
                <Button key="submit" type="primary" onClick={handleStayLoggedIn}>
                    Stay Logged In
                </Button>,
            ]}>
            <p>Your session will expire in 1 minute</p>
        </Modal>
    )
}

export default TimeoutModal
