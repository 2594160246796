import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Col, DatePicker } from 'antd'

import { date_format } from '../../../../utils/constants'

class Losd extends Component {
    render() {
        const { getFieldDecorator, state, disabledDate } = this.props
        return (
            <>
                <Col xs={24} lg={8}>
                    <Form.Item label={`Date LOSD Submitted to MCO`}>
                        {getFieldDecorator(`assessment_date_submitted`, {
                            rules: [
                                {
                                    required: false,
                                    message: 'Input something!',
                                },
                            ],
                        })(
                            <DatePicker
                                disabled={state.firstContactCheck}
                                disabledDate={disabledDate}
                                style={{ width: '100%' }}
                                placeholder="select date"
                                format={date_format}
                            />,
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item label={`Date LOSD Approved by MCO`}>
                        {getFieldDecorator(`assessment_date_approved`, {
                            rules: [
                                {
                                    required: false,
                                    message: 'Input something!',
                                },
                            ],
                        })(
                            <DatePicker
                                disabled={state.firstContactCheck}
                                disabledDate={disabledDate}
                                style={{ width: '100%' }}
                                placeholder="select date"
                                format={date_format}
                            />,
                        )}
                    </Form.Item>
                </Col>
            </>
        )
    }
}

export default Losd
