import React, { Component } from 'react'
import { CSVLink } from 'react-csv'
import Moment from 'moment'
import axios from 'axios'
import { DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Breadcrumb, Col, Card, Typography, Layout, Row, Tag, Divider, Table, Tooltip, Popconfirm, message, Empty, Menu, Dropdown } from 'antd'
import { isMobile } from '../../../utils/helpers'
const Content = Layout
const { Title } = Typography
class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            csvColumns: [
                { label: 'FULL NAME', key: 'name' },
                { label: 'USER LOG IN', key: 'user' },
                { label: 'AGENCY', key: 'agency' },
                { label: 'DATE/TIME OF LOG IN', key: 'loggedInOn' },
            ],
            csvData: [],
            loading: true,
        }
    }

    async componentDidMount() {
        await this.fetchUsers()
        await this.fetchUserLoggedIn()
    }

    fetchUserLoggedIn = async () => {
        try {
            const APIresponse = await axios.post('/api/users/userloggedin')
            console.log(APIresponse.data)
            this.setState({ csvData: APIresponse.data })
        } catch (error) {
            this.setState({
                err: error.response,
            })
        }
    }

    fetchUsers = async () => {
        const response = await axios.post('/api/users/manage', {
            user_type: this.props.auth.user.user_type,
        })
        this.setState({ users: response.data.users, loading: false })
        //console.log(this.state.users)
    }

    async deleteUser(id) {
        await axios
            .post('/api/users/delete_user', {
                user_id: id,
            })
            .then(async (resp) => {
                message.loading('Action in progress..')
            })
            .then(() => {
                this.fetchUsers()
                message.success('User deleted!', 2.5)
            })
            .catch((err) => {
                message.error('Error Occured')
            })
    }

    async toggleActivate(id) {
        await axios
            .post('/api/users/activate_status', {
                user_id: id,
            })
            .then(async (resp) => {
                message.loading('Action in progress..', 0.5)
            })
            .then(() => {
                this.fetchUsers()
                message.success('User status updated!', 2)
            })
            .catch((err) => {
                message.error('Error Occured')
            })
    }

    async toggleHandleNotification(id) {
        await axios
            .post('/api/users/activate_notification', {
                user_id: id,
            })
            .then(async (resp) => {
                message.loading('Action in progress..', 0.5)
            })
            .then(() => {
                this.fetchUsers()
                message.success('User notification status updated!', 2)
            })
            .catch((err) => {
                message.error('Error Occured')
            })
    }

    async handleResetTwoFactor(id) {
        await axios
            .post('/api/users/reset_twofactor', {
                user_id: id,
            })
            .then(async (resp) => {
                message.loading('Action in progress..', 0.5)
            })
            .then(() => {
                this.fetchUsers()
                message.success('User two-factor authenticated has been resetted! The user now can login without two-factor checking.', 2)
            })
            .catch((err) => {
                message.error('Error Occured')
            })
    }

    mobileActionMenu = (record) => (
        <Menu>
            <Menu.Item
                onClick={() =>
                    this.props.history.push(`/useredit/${record._id}`, {
                        id: record._id,
                    })
                }>
                Edit
            </Menu.Item>
            <Menu.Item>
                <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteUser(record._id)}>
                    Delete
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    render() {
        const columns = [
            {
                title: 'No.',
                dataIndex: 'row_id',
                key: 'row_id',
                width: 100,
                sorter: (a, b) => a.row_id - b.row_id,
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: 250,
                sorter: (a, b) => a.email.localeCompare(b.email),
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 200,
                sorter: (a, b) => (a.hasOwnProperty('name') ? a.name.localeCompare(b.name) : true),
            },
            {
                title: 'Agency',
                dataIndex: 'user_type',
                key: 'user_type',
                width: 200,
                sorter: (a, b) => a.user_type.localeCompare(b.user_type),
            },
            {
                title: 'Projects',
                dataIndex: 'projects',
                key: 'projects',
                width: 150,
                render: (value, record) => {
                    const List = value.map((item) => <Tag key={item}>{item}</Tag>)
                    return <>{List}</>
                },
            },
            {
                title: 'Two-Factor',
                key: 'is_twofactorauth_enabled',
                dataIndex: 'is_twofactorauth_enabled',
                width: 150,
                sorter: (a, b) => {
                    var res_a = a.is_twofactorauth_enabled === true ? 0 : 1
                    var res_b = b.is_twofactorauth_enabled === true ? 0 : 1
                    return res_a - res_b
                },
                render: (value, record) => {
                    if (value === true) {
                        return (
                            <span>
                                <Tooltip title="Click to reset user two-factor authentication">
                                    <Tag onClick={() => this.handleResetTwoFactor(record._id)} color="green" key={value} style={{ cursor: 'pointer' }}>
                                        On
                                    </Tag>
                                </Tooltip>
                            </span>
                        )
                    } else {
                        return (
                            <span>
                                <Tooltip>
                                    <Tag title="Only two-factor with status on can be reset" color="red" key={value}>
                                        Off
                                    </Tag>
                                </Tooltip>
                            </span>
                        )
                    }
                },
            },
            {
                title: 'Notification',
                key: 'is_notified',
                dataIndex: 'is_notified',
                width: 100,
                sorter: (a, b) => {
                    var res_a = a.is_notified === true ? 0 : 1
                    var res_b = b.is_notified === true ? 0 : 1
                    return res_a - res_b
                },
                render: (value, record) => (
                    <span>
                        <Tooltip title={value === true ? 'Click to turn off email notification' : 'Click to turn on email notification'}>
                            <Tag onClick={() => this.toggleHandleNotification(record._id)} color={value === true ? 'green' : 'red'} key={value} style={{ cursor: 'pointer' }}>
                                {value ? 'On' : 'Off'}
                            </Tag>
                        </Tooltip>
                    </span>
                ),
            },
            {
                title: 'Active',
                key: 'active',
                dataIndex: 'active',
                width: 150,
                sorter: (a, b) => {
                    var res_a = a.active === true ? 0 : 1
                    var res_b = b.active === true ? 0 : 1
                    return res_a - res_b
                },
                render: (value, record) => (
                    <span>
                        <Tooltip title={value === true ? 'Click to deactivate' : 'Click to activate'}>
                            <Tag onClick={() => this.toggleActivate(record._id)} color={value === true ? 'green' : 'red'} key={value} style={{ cursor: 'pointer' }}>
                                {value ? 'Active' : 'Not Active'}
                            </Tag>
                        </Tooltip>
                    </span>
                ),
            },
            {
                title: 'Action',
                key: 'action',
                fixed: isMobile() ? false : 'right',
                width: 120,
                render: (text, record) =>
                    isMobile() ? (
                        <Dropdown overlay={this.mobileActionMenu(record)} trigger={['click', 'hover']}>
                            <p style={{ color: '#1890ff' }} className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                Actions <DownOutlined />
                            </p>
                        </Dropdown>
                    ) : (
                        <span>
                            <Tooltip title="Edit">
                                <EditOutlined
                                    onClick={() =>
                                        this.props.history.push(`/useredit/${record._id}`, {
                                            id: record._id,
                                        })
                                    }
                                />
                            </Tooltip>
                            <Divider type="vertical" />
                            <Tooltip title="Activity">
                                <SearchOutlined
                                    onClick={() => {
                                        this.props.history.push(`/useractivity/${record.email}`, {
                                            email: record.email,
                                        })
                                    }}
                                />
                            </Tooltip>
                            <Divider type="vertical" />
                            <Tooltip title="Delete">
                                <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteUser(record._id)}>
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Tooltip>
                        </span>
                    ),
            },
        ]

        return (
            <React.Fragment>
                <div>
                    <Card
                        style={{ margin: '20px 16px' }}
                        extra={
                            <CSVLink
                                headers={this.state.csvColumns}
                                data={this.state.csvData}
                                filename={'logins-report-' + Moment().format('MM-DD-YYYY') + '-' + Math.floor(Math.random() * 1000 + 1) + '.csv'}
                                target="_blank">
                                <Button type="primary" icon={<DownloadOutlined />} title="Please disable your ads blocker to download the file" size="large">
                                    30 Days Log-ins Report
                                </Button>
                            </CSVLink>
                        }>
                        <div style={{ marginBottom: '16px', width: '100%' }}>
                            <Breadcrumb>
                                <Breadcrumb.Item>User</Breadcrumb.Item>
                                <Breadcrumb.Item>Manage</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Title level={2}>Manage Users</Title>
                        <p>Use the actions to edit an user's information or delete it from the list</p>
                    </Card>
                    <Content style={{ margin: '20px 16px 0' }}>
                        <Row gutter={[12, 24]}>
                            {this.props.auth.user.user_type === 'CBHS' ? (
                                <Col span={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={this.state.users}
                                        pagination={{
                                            pageSize: 50,
                                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        }}
                                        scroll={{ x: 1000, y: 500 }}
                                        size="default"
                                        rowKey="row_id"
                                    />
                                </Col>
                            ) : (
                                <Empty />
                            )}
                        </Row>
                    </Content>
                </div>
            </React.Fragment>
        )
    }
}

export default List
