import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Layout, Breadcrumb, Row, Col, Card, message, Steps } from 'antd'

import { date_format } from '../../../utils/constants'


import ChooseCategory from './steps/add/1-choose-category'
import UploadDocumentation from './steps/add/2-upload-documentation'
import NotificationSettings from './steps/add/3-notification-settings'
import Confirm from './steps/add/4-confirm-upload'

const { Content } = Layout
const { Step } = Steps

const steps = [
    {
        title: 'About',
        content: 'category-tab',
    },
    {
        title: 'Upload',
        content: 'upload-file',
    },
    {
        title: 'Notification',
        content: 'notification-settings',
    },
    {
        title: 'Confirm',
        content: 'confirm',
    },
]

class UploadDocs extends Component {
    constructor() {
        super()
        this.state = {
            current: 0,
            fileList: [],
            referral_id: null,
            category: null,
            tab: null,
            recipients: [],
            message : null, 
            priority: false,
            loading: false,
            error: false,
        }
    }

    componentDidMount() {
        const referral_id = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        this.setState({
            referral_id: referral_id
        })
    }

    onFinish = async () => {
        const { referral_id, tab, category, recipients, message, priority, fileList : [{ name }] } = this.state
        const { user } = this.props.auth
        const uniqueKey = `${referral_id}-${name}` 
        const data = {
            uniqueKey,
            fileName: name,
            category,
            tab,
            recipients,
            message,
            priority,
            addedOn: new Date().toLocaleString("en-US", { timeZone: "America/New_York" }),
            addedBy: `${user.name}, ${user.email}`
        }
        let metadata = { ...data }
        metadata.history = [{created: data, timestamp: moment().format(date_format), user: this.props.auth.user.name + ' <' + this.props.auth.user.email + '>'}]
        try {
            await this.handleS3Upload(uniqueKey)
            if (!this.state.error) {
                await axios
                    .post('/api/referrals/docs_create', {
                        referral_id: referral_id,
                        metadata: metadata, 
                    })
                    .then(() => {
                        axios.defaults.headers.common['Authorization'] = localStorage.jwtToken
                        this.props.history.push(`/editreferral/shared-care/${referral_id}`)
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            } 
        } catch(err) {
            console.error(err)
        }
    }

    checkIfFileExists = async () => {
        const { referral_id, fileList : [{ name }] } = this.state
        const uniqueKey = `${referral_id}-${name}`
        const request = { referral_id, uniqueKey } 

        await axios.post("/api/referrals/docs_check", request)
                   .then((res) => {
                       if (!res.data.duplicate) {
                            const current = this.state.current + 1
                            this.setState({ current })
                        }
                   })
                   .catch((err) => {
                        console.error(err)
                        message.error(err.response.data.message)
                    })
    }

    handleS3Upload = async (uniqueKey) => {
        const file = this.state.fileList[0]
        const options = {
            params: {
                Key: uniqueKey,
                ContentType: file.type,
            },
            headers: {
                'Content-Type': file.type,
            },
        }
        this.setState({ loading: true})

        delete axios.defaults.headers.common['Authorization']

        await axios
                .get('/api/uploader/generate-put-url-docs', options)
                .then(async (res) => {
                    const { putURL } = res.data
                    await axios 
                        .put(putURL, file, options)
                        .then(() => {
                            this.setState({ loading: false})
                            message.success('Upload successful')
                        })
                        .catch((err) => {
                            this.setState({ error: true, loading: false})
                            message.error('Failed to upload')
                            console.error(err)
                        })
                })
                .catch((err) => console.error(err))
    }

    handleCategoryChange = (e) => {
        this.setState({
            category: e
        })
    }

    handleTabChange = (e) => {
        this.setState({
            tab: e
        })
    }

    handleAddRecipients = (e) => {
        this.setState({
            recipients: e
        })
    }

    handleMessageChange = (e) => {
        this.setState({
            message: e.target.value
        })
    }

    handlePriorityChange = (e) => {
        this.setState({
            priority: e.target.checked
        })
    }

    next = () => {
        if(this.state.current === 0) {
            if (this.state.tab === null || this.state.category === null) {
                return message.error("These fields are required")
            }
            const current = this.state.current + 1
            this.setState({ current })
        }
        
        if(this.state.current === 1) {
            if (!this.state.fileList.length > 0) {
                return message.error("Upload a document to continue")
            }
            if(this.state.fileList.length > 1) {
                return message.warning("Upload one file at a time")
            } 
            this.checkIfFileExists()
        }

        if(this.state.current === 2) {
            const current = this.state.current + 1
            this.setState({ current })
        }
    }

    back = () => {
        const current = this.state.current - 1
        this.setState({ current })
    }

    preview = () => {
        const objectURL = URL.createObjectURL(this.state.fileList[0])
        window.open(objectURL)
    }

    reset = () => {
        this.setState({
            current: 0,
            fileList: [],
            referral_id: null,
            category: null,
            tab: null,
            recipients: [],
            message : null, 
            priority: false,
            error: false,
        })
    }

    backToReferral = () => {
        this.props.history.push(`/editreferral/shared-care/${this.state.referral_id}`)
    }

    render() {
        const { current, fileList } = this.state
        const draggerProps = {
            name: 'file',
            accept: '.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file)
                    const newFileList = state.fileList.slice()
                    newFileList.splice(index, 1)
                    return {
                        fileList: newFileList,
                    }
                })
            },
            beforeUpload: (file) => {
                this.setState((state) => ({
                    fileList: [...state.fileList, file],
                }))
                return false
            },
            fileList,
        }
        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <Content style={{ width: '100%' }}>
                    <div style={{ marginBottom: '16px', width: '100%' }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>Referrals</Breadcrumb.Item>
                            <Breadcrumb.Item>Upload Documentation</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Card
                        bodyStyle={{
                            padding: 26,
                            width: '100%',
                            marginBottom: '16px'
                        }}>
                        <Row gutter={[24, 24]}>
                            <Col xs={{ span: 24 }}>
                                <Steps current={current} size="small">
                                    {steps.map((item) => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col xs={{ span: 24 }}>
                                <div>
                                    {current === 0 && <ChooseCategory 
                                        handleTabChange={this.handleTabChange} 
                                        tab={this.state.tab}
                                        handleCategoryChange={this.handleCategoryChange}
                                        category={this.state.category}
                                        next={this.next} 
                                        backToReferral={this.backToReferral} 
                                        reset={this.reset}
                                        />}
                                    {current === 1 && <UploadDocumentation 
                                        next={this.next} 
                                        back={this.back} 
                                        reset={this.reset} 
                                        draggerProps={draggerProps} 
                                        preview={this.preview} 
                                        fileList={this.state.fileList}
                                        />}
                                    {current === 2 && <NotificationSettings 
                                        next={this.next} 
                                        back={this.back} 
                                        referral_id={this.state.referral_id}
                                        handleAddRecipients={this.handleAddRecipients}
                                        recipients={this.state.recipients}
                                        handleMessageChange={this.handleMessageChange}
                                        message={this.state.message}
                                        handlePriorityChange={this.handlePriorityChange}
                                        priority={this.state.priority}
                                        reset={this.reset}
                                        />}
                                    {current === 3 && <Confirm 
                                        onFinish={this.onFinish} 
                                        back={this.back} 
                                        loading={this.state.loading} 
                                        />}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Content>
            </Content>
        )
    }
}

export default UploadDocs
