import React, { Component } from 'react'
import moment from 'moment'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { detailedDiff } from 'deep-object-diff'
import { Alert, BackTop, Breadcrumb, Button, notification, Card, Col, DatePicker, Form, Layout, Modal, Row, Select, Tabs, TimePicker, Typography, Tooltip, message } from 'antd'
import { DeleteOutlined, StepBackwardOutlined, BankOutlined } from '@ant-design/icons'
import ClientInformation from './client-information/client-information'
import InsuranceInformation from './insurance-information/insurance_information'
import ReferralSource from './referral-source/referral-source'
import ApptAvailability from './appointment-availability/appointment-availability'
import Consent from './consent/consent'
import { checkMomentDate } from '../../../../utils/helpers'
import CareManagementSCF from './tabs/care-management/care-management'
import PeerServices from './tabs/peer-services/peer-services'
import PrimaryCare from './tabs/primary-care/primary-care'
import MentalHealth from './tabs/outpatient/mentalhealth'
import SubstanceUse from './tabs/outpatient/substanceuse'
import Documentation from './tabs/documentation/documentation'
import { date_format, referral_statuses, referral_needs, scf_counties, time_format } from '../../../../utils/constants'

const { Content } = Layout
const { confirm } = Modal
const { Option } = Select
const { Title } = Typography
const { TabPane } = Tabs

function showConfirm() {
    confirm({
        title: 'Do you want to return to dashboard?',
        content: 'Unsaved input will be lost',
        onOk() {
            window.location.replace('')
        },
        onCancel() {},
    })
}

class SharedCare extends Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            currentProject: null,
            submit_button_loading: false,
            submit_button_disabled: false,
            selected_county: null,
            selected_program: null,
            selected_agency: null,
            selected_mco: null,
            possible_programs: [],
            possible_counties: [],
            program_responses: [],
            possible_agencies: [],
            possible_mcos: [],
            final_programs_added: [],
            err: null,
            id: null,
            nano_id: null,
            addButtonDisabled: false, // this is for the pdf download of the referral - it will only display correctly if one program is saved at a time
            deleteButtonDisabled: false, // this is for gathering the data on the program deleted to show on the history log - it also will only function correctly if one program is deleted at a time
            consented_agencies: [],
            this_agency_consents: false,
        }
    }

    async componentDidMount() {
        const [og_referral, referral_id, nano_id, referral_source, consented_agencies] = await this.fetchOriginalReferral()

        if (consented_agencies.includes(this.props.auth.user.user_type)) {
            this.setState({
                this_agency_consents: true,
            })
        }

        this.setState({
            original: og_referral,
            id: referral_id,
            nano_id: nano_id,
            referral_source: referral_source,
            consent: this.state.this_agency_consents,
            skip_consent: false,

            currentProject: localStorage.getItem('currentProject'),
            final_programs_added: og_referral.programs,
            consented_agencies: consented_agencies,
        })
        const outcomesFormatted = og_referral.outcomes.map((out) => ({ ...out, outcomeEffectiveDate: checkMomentDate(out.outcomeEffectiveDate) }))
        this.formRef.current.setFieldsValue({
            outcomes: outcomesFormatted,
        })
    }


    // only let either CBHS or the referral source reassign program agencies
    allowedToReassignAgency = () => {
        if (this.props.auth.user.user_type === 'CBHS' || this.state.original.referral_source === this.props.auth.user.user_type) {
            return false
        } else {
            return true
        }
    }

    handleConsentChange = (e) => {
        this.setState({
            consent: e.target.checked
        })
    }

    handleSkipConsentChange = (e) => {
        this.setState({
            skip_consent: e.target.checked
        })
    }

    fetchOriginalReferral = async () => {
        const referral_id = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        const response = await axios.post('/api/referrals/find_referral', {
            referral_id: referral_id,
        })
        response.data[0].veteran = response.data[0].veteran === 'true'
        response.data[0].active_duty = response.data[0].active_duty === 'true'
        return [response.data[0], referral_id, response.data[0].nano_id, response.data[0].referral_source, response.data[0].consented_agencies]
    }

    postSave = async () => {
        const [og_referral,,, referral_source, consented_agencies] = await this.fetchOriginalReferral()
        this.setState({
            original: og_referral,
            consented_agencies: consented_agencies,
            referral_source: referral_source,
        })
        if (consented_agencies.includes(this.props.auth.user.user_type)) {
            this.setState({
                this_agency_consents: true,
                consent: true,
            })
        } else { 
            this.setState({
                this_agency_consents: false,
                consent: false,
            })
        }
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    findAgenciesByCountyAndProgram = (program) => {
        this.setState({ selected_program: program })
        axios
            .post('/api/scf/agencies', {
                program,
                county: this.state.selected_county,
            })
            .then((response) => {
                this.setState({
                    possible_agencies: response.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
        axios
            .post('/api/scf/mco', {
                program,
                county: this.state.selected_county,
            })
            .then((response) => {
                this.setState({
                    possible_mcos: response.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    findProgramsByCounty = (county) => {
        this.setState({ selected_county: county })
        axios
            .post('/api/scf/programs', {
                county: county,
            })
            .then((response) => {
                this.setState({
                    possible_programs: response.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    filterAgenciesMoreByMco = (mco) => {
        this.setState({ selected_mco: mco })
        axios
            .post('/api/scf/agencies/mco', {
                mco: mco,
                county: this.state.selected_county,
                program: this.state.selected_program,
            })
            .then((response) => {
                this.setState({
                    possible_agencies: response.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    findAddressByAgency = async (agency_name) => {
        const agency_details = await axios.post('/api/scf/agency/address', {
            agency: agency_name,
        })
        return agency_details.data
    }

    addProgram = async () => {
        if (this.state.addButtonDisabled === true) {
            return message.error('Save before adding another program')
        }

        var current_programs = this.state.final_programs_added
        var key = uuidv4()
        const version = 1

        current_programs.push({
            county: this.state.selected_county,
            program: this.state.selected_program,
            referral_agency: this.state.selected_agency,
            key: key,
            version: this.state.selected_county + '-' + this.state.selected_program + '-' + version,
            version_number: version,
            agency_options: this.state.possible_agencies,
        })

        this.setState({
            final_programs_added: current_programs,
            selected_county: null,
            selected_program: null,
            selected_agency: null,
            selected_mco: null,
            possible_programs: [],
            possible_counties: [],
            program_responses: [],
            possible_agencies: [],
            possible_mcos: [],
            addButtonDisabled: true,
        })
    }

    onFinish = async (form_values) => {
        if (typeof form_values['guardian_last_name'] == 'undefined' && typeof form_values['guardian_first_name'] == 'undefined') {
            form_values['is_guardian'] = 'N'
        } else {
            form_values['is_guardian'] = 'Y'
        }

        var user_access_set = new Set()
        if (this.state.final_programs_added) {
            this.state.final_programs_added.forEach((program) => {
                user_access_set.add(program.referral_agency)
            })
        }
        const user_access = Array.from(user_access_set)

        const programs_formatted = this.state.final_programs_added.map((program) => ({
            ...program,
            appointment_date: program.appointment_date && moment(program.appointment_date).format(date_format),
            appointment_time: program.appointment_time && moment(program.appointment_time).format(),
        }))

        const dateFields = [
            'dob',
            'insurance_1_effective_date',
            'insurance_1_expiration_date',
            'insurance_2_effective_date',
            'insurance_2_expiration_date',
            'insurance_3_effective_date',
            'insurance_3_expiration_date',
            'care_management_contact_date',
            'care_management_appointment_date',
            'peer_services_contact_date',
            'peer_services_appointment_date',
            'primary_care_contact_date',
            'primary_care_appointment_date',
            'primary_care_last_pcp_followup_date',
            'primary_care_followup_date',
            'primary_care_last_annual_physical_date',
            'mental_contact_date',
            'mental_appointment_date',
            'mental_grpa_date_completed',
            'mental_noms_date_completed',
            'substance_contact_date',
            'substance_appointment_date',
            'substance_grpa_date_completed',
            'substance_noms_date_completed',
            'scf_antipsych_med_schizo_date',
            'scf_adult_bmi_date',
            'scf_ambulance_date',
            'scf_antidepressant_date',
            'scf_amr_date',
            'scf_breast_cancer_screening_date',
            'scf_cardiovascular_date',
            'scf_cervical_cancer_screening_date',
            'scf_col_date',
            'scf_csc_eye_date',
            'scf_continuity_date',
            'scf_diabetes_monitoring_date',
            'scf_diabetes_screening_schizo_bipolar_date',
            'scf_diverted_date',
            'scf_emergency_dept_follow_up_date',
            'scf_health_home_date',
            'scf_seven_day_hospital_follow_up_date',
            'scf_thirty_day_hospital_follow_up_date',
            'scf_adhd_date',
            'scf_engagement_date',
            'scf_mat_date',
            'scf_tobacco_date',
            'scf_anti_psych_date',
            'scf_phq9_date',
            'scf_psychosocial_date',
            'scf_well_child_visit_3_18_date',
            'scf_well_child_first_30_date',
        ]
        const formatDates = (form_values, dateField, date_format) => {
            dateField.forEach((val) => {
                if (form_values[val]) {
                    form_values[val] = moment(form_values[val]).format(date_format)
                }
            })
            return form_values
        }
        formatDates(form_values, dateFields, date_format)

        const timeFields = [
            'care_management_contact_time',
            'care_management_appointment_time',
            'peer_services_contact_time',
            'peer_services_appointment_time',
            'primary_care_contact_time',
            'primary_care_appointment_time',
            'primary_care_followup_time',
            'mental_contact_time',
            'mental_appointment_time',
            'substance_contact_time',
            'substance_appointment_time',
            'primary_care_last_pcp_followup_time',
        ]
        const formatTimes = (form_values, timeField, time_format) => {
            timeField.forEach((val) => {
                if (form_values[val]) {
                    form_values[val] = moment(form_values[val]).format(time_format)
                }
            })
            return form_values
        }
        formatTimes(form_values, timeFields, time_format)

        // Reset/fetch original from database
        const [original] = await this.fetchOriginalReferral()

        const contactFields = ['care_management_', 'peer_services_', 'primary_care_', 'mental_', 'substance_']
        const setNumberOfContacts = (form_values, contactFields, original) => {
            contactFields.forEach((field) => {
                form_values[`${field}number_of_contacts`] = original[`${field}number_of_contacts`]
                if (form_values[`${field}contact_made`] === true) {
                    form_values[`${field}number_of_contacts`] += 1
                }
            })
            return form_values
        }
        setNumberOfContacts(form_values, contactFields, original)

        // keep consistent with data structure on other edit form
        let original_values = {}
        original_values['original_address'] = {
            address_1: this.state.original.address_1,
            city: this.state.original.city,
            state: this.state.original.state,
            zip: this.state.original.zip
        }
        original_values['validAddress'] = this.state.original.validAddress

        var values = { ...form_values }
        values['project'] = [this.state.currentProject]
        values['programs'] = programs_formatted
        values['date_received'] = this.state.original.date_received
        values['user_id'] = this.props.auth.user.id
        values['user_access'] = user_access
        values['duplicate_skip'] = this.state.duplicate_skip
        values['id'] = this.state.id
        values['nano_id'] = this.state.nano_id
        values['consented_agencies'] = this.state.consented_agencies
        values['documents'] = this.state.original.documents
        values['original_values'] = original_values

        // correct for when quality metric date is cleared the value becomes null
        for (const prop in values) {
            if (values[prop] === null) {
                values[prop] = ''
            }
        }

        // correct for when program appointment date is cleared the value becomes null
        for (const prop in values) {
            if (prop === 'programs') {
                for (const nested in values[prop]) {
                    for (const deep in values[prop][nested]) {
                        if (values[prop][nested][deep] === null) {
                            values[prop][nested][deep] = ''
                        }
                    }
                }
            }
        }

        // give the original referral properties for the values it doesn't already have so an apples to apples comparison can be made
        let originalWithValues = { ...original }
        for (const prop in values) {
            if (!Object.getOwnPropertyNames(originalWithValues).includes(prop) && values[prop] === undefined) {
                originalWithValues[prop] = undefined
            } else if (!Object.getOwnPropertyNames(originalWithValues).includes(prop) && values[prop] === 'No') {
                originalWithValues[prop] = 'No'
            } else if (!Object.getOwnPropertyNames(originalWithValues).includes(prop)) {
                originalWithValues[prop] = ''
            }
        }

        var changes = detailedDiff(originalWithValues, values)

        changes['timestamp'] = moment().format(date_format)
        changes['user'] = this.props.auth.user.name + ' <' + this.props.auth.user.email + '>'

        // record when a program is deleted
        let indexOfDeletedProgram
        let programThatWasDeleted
        if (changes['deleted']['programs']) {
            indexOfDeletedProgram = Object.keys(changes['deleted']['programs'])
            indexOfDeletedProgram = [indexOfDeletedProgram]
            programThatWasDeleted = original['programs'][indexOfDeletedProgram]['program']
            changes['deleted']['deleted_program'] = programThatWasDeleted
        }

        if (this.state.consent === true) {
            if (!values['consented_agencies'].includes(this.props.auth.user.user_type)) { 
                values['consented_agencies'].push(this.props.auth.user.user_type)
            }
            changes.updated.consent = true
            changes.updated.skip_consent = false
        } else if (this.state.consent === false && values['consented_agencies'].includes(this.props.auth.user.user_type)) {
            const index = values['consented_agencies'].indexOf(this.props.auth.user.user_type)
            const updatedAgenciesConsent = [...values['consented_agencies'].slice(0, index), ...values['consented_agencies'].slice(index + 1, values['consented_agencies'].length)]
            values['consented_agencies'] = updatedAgenciesConsent
        } 
        if (!this.state.consent) {
            changes.updated.consent = false 
            changes.updated.skip_consent = true
        }

        if (this.state.original.history) {
            this.state.original.history.unshift(changes)
            values['history'] = this.state.original.history
        } else {
            values['history'] = [changes]
        }

        axios
            .post('/api/referrals/update', values)
            .then(async (response) => {
                this.setState({
                    referral_response: response.data,
                    err: null,
                    addButtonDisabled: false,
                    deleteButtonDisabled: false,
                })
                await this.postSave()
            })
            .catch((error) => {
                this.setState({
                    err: error.response,
                })
            })

        this.openNotificationWithIcon()
    }

    openNotificationWithIcon = () => {
        const props = this.props

        notification['success']({
            message: 'Referral saved',
            description: 'Click to return to dashboard',
            duration: 2.5,
            style: { cursor: 'pointer' },
            onClick() {
                redirectToDashboard()
            },
        })

        function redirectToDashboard() {
            props.history.push('/dashboard')
        }
    }

    findAndUpdateProgram = (value, updateKey, fieldToUpdate) => {
        let all_programs = this.state.final_programs_added
        const programToUpdate = all_programs.find(({ key }) => key === updateKey)

        var vers_num = (programToUpdate['version_number'] += 1)
        programToUpdate[fieldToUpdate] = value
        programToUpdate['version_number'] = vers_num
        programToUpdate['version'] = programToUpdate['county'] + '-' + programToUpdate['program'] + '-' + vers_num

        this.setState({
            final_programs_added: all_programs,
        })
        return
    }

    showAgencyDetails = async (agency) => {
        const agencyInfo = await this.findAddressByAgency(agency)

        Modal.info({
            title: `${agency}`,
            content: (
                <Row style={{ marginBottom: '1em', marginTop: '1em' }}>
                    <Col>
                        <div>
                            <p>Address: {agencyInfo.address}</p>
                            <p>City: {agencyInfo.city}</p>
                            <p>ZIP: {agencyInfo.zip}</p>
                            <p>Contact: {agencyInfo.contact ? agencyInfo.contact : 'None Specified'}</p>
                        </div>
                    </Col>
                </Row>
            ),
        })
    }

    findAndDeleteProgram = async (updateKey) => {
        let all_programs = this.state.final_programs_added
        let indexToDelete = null

        const programToUpdate = all_programs.find(({ key }) => key === updateKey)
        indexToDelete = all_programs.indexOf(programToUpdate)

        all_programs.splice(indexToDelete, 1)

        this.setState({
            final_programs_added: all_programs,
            addButtonDisabled: false,
            deleteButtonDisabled: true,
        })
    }

    handleDataErrorMessage = (err) => {
        if (err.data) {
            if (err.data.message) {
                return (
                    <React.Fragment>
                        <Row gutter={[16, 16]} style={{ marginTop: '1em' }}>
                            <Col xs={24}>
                                <Alert message={err.data.message} description="There was an error processing your request." type="error" showIcon />
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            }
        }
    }

    addProgramFields = () => {
        const { final_programs_added, consented_agencies } = this.state

        // only show all programs if the agency provided consent (or it's CBHS), otherwise, only show them programs assigned to them
        let programsToShow = []
        final_programs_added.forEach((program) => {
            if (consented_agencies.includes(this.props.auth.user.user_type)) {
                programsToShow.push(program)
            } else if (this.props.auth.user.user_type === 'CBHS') {
                programsToShow.push(program)
            } else if (program.referral_agency === this.props.auth.user.user_type) {
                programsToShow.push(program)
            }
        })

        const programs = programsToShow.map((program) => (
            <Card title={<span>{`${program.program} in ${program.county} County `}</span>} bordered={false} style={{ width: '-webkit-fill-available', marginTop: '1em' }} key={program.key}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={7}>
                        <p>Agency Assigned</p>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select an agency"
                            optionFilterProp="children"
                            onChange={(value) => this.findAndUpdateProgram(value, program.key, 'referral_agency')}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            defaultValue={program.referral_agency}
                            disabled={this.allowedToReassignAgency()}>
                            {program.agency_options.sort().map((agency) => (
                                <Option key={agency}>{agency}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={13}>
                        <p> </p>
                        <Button
                            type="secondary"
                            shape="round"
                            size="medium"
                            style={{ float: 'right' }}
                            onClick={() => {
                                this.showAgencyDetails(program.referral_agency)
                            }}>
                            <BankOutlined /> Agency Details
                        </Button>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={7}>
                        <p>Referral Status</p>
                        <Select
                            style={{
                                width: '100%',
                            }}
                            showSearch
                            placeholder="Select a referral status"
                            optionFilterProp="children"
                            onChange={(value) => this.findAndUpdateProgram(value, program.key, 'referral_status')}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            defaultValue={program.referral_status}>
                            {referral_statuses.map((referral) => (
                                <Option key={referral}>{referral}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} md={24} lg={5}>
                        <p>Referral Need</p>
                        <Select
                            style={{
                                width: '100%',
                            }}
                            showSearch
                            placeholder="Select a referral need"
                            optionFilterProp="children"
                            onChange={(value) => this.findAndUpdateProgram(value, program.key, 'referral_needs')}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            defaultValue={program.referral_needs}>
                            {referral_needs.map((referral) => (
                                <Option key={referral}>{referral}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} md={24} lg={4}>
                        <p>Appointment Date</p>
                        <DatePicker
                            onChange={(value) => this.findAndUpdateProgram(value, program.key, 'appointment_date')}
                            defaultValue={checkMomentDate(program.appointment_date)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col xs={24} md={24} lg={4}>
                        <p>Appointment Time</p>
                        <TimePicker
                            onChange={(value) => this.findAndUpdateProgram(value, program.key, 'appointment_time')}
                            use12Hours
                            defaultValue={program.appointment_time && moment(program.appointment_time)}
                            style={{ width: '100%' }}
                            format="h:mm a"
                        />
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]} style={{ marginTop: '1em' }}>
                    <Col xs={24} md={24} lg={2}>
                        <Button
                            type="primary"
                            onClick={() => {
                                this.confirmDelete(program.key)
                            }}
                            danger
                            disabled={this.allowedToReassignAgency()}>
                            Delete
                        </Button>
                    </Col>
                </Row>
            </Card>
        ))
        return programs.reverse()
    }

    confirmDelete = (key) => {
        if (this.state.deleteButtonDisabled === true) {
            return message.error('Save before deleting another program')
        }

        const findAndDeleteProgram = this.findAndDeleteProgram

        Modal.confirm({
            title: 'Are you sure?',
            content: (
                <Row style={{ marginBottom: '1em', marginTop: '1em' }}>
                    <Col>
                        <div>
                            <p>This action will take effect when the referral is saved, after which point it cannot be undone</p>
                        </div>
                    </Col>
                </Row>
            ),
            okText: 'Yes, Delete',
            onOk() {
                findAndDeleteProgram(key)
            },
            onCancel() {
                destroyAll()
            },
            cancelText: 'Cancel',
        })

        function destroyAll() {
            Modal.destroyAll()
        }
    }

    onFinishFailed = (err) => {
        console.log(err)
    }

    render() {
        const { err, original, nano_id, this_agency_consents } = this.state
        const { user } = this.props.auth
        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <BackTop />
                <Card style={{ marginBottom: '16px', width: '100%' }}>
                    <div style={{ marginBottom: '16px', width: '100%' }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>Shared Care Form</Breadcrumb.Item>
                            <Breadcrumb.Item>Update Shared Care Form</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Title level={2}>Edit Shared Care Form</Title>
                    <p>
                        You are currently editing form for{' '}
                        {original && (
                            <span>
                                <strong>
                                    {original.first_name} {original.last_name} (Id: {nano_id})
                                </strong>
                            </span>
                        )}{' '}
                    </p>
                </Card>
                <React.Fragment>
                    <Row style={{ marginBottom: '1em' }}>
                        <Col span={24}>
                            <Button type="primary" onClick={showConfirm}>
                                <StepBackwardOutlined />
                                Back to Dashboard
                            </Button>
                            <Button type="primary" onClick={() => window.location.reload()} style={{ float: 'right' }} danger>
                                <DeleteOutlined />
                                Reset Form
                            </Button>
                        </Col>
                    </Row>
                    {err && this.handleDataErrorMessage(err)}
                    <Row>
                        <Col span={24}>
                            <Form layout="inline" onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} ref={this.formRef} requiredMark={false}>
                                {original && (
                                    <Tabs defaultActiveKey="client_information" style={{ width: '100%' }} tabBarStyle={{ backgroundColor: '#FFF', paddingLeft: '1em' }}>
                                        <TabPane forceRender={true} tab="Demographics" key="client_information">
                                            <ClientInformation formRef={this.formRef} original={original} />
                                            <InsuranceInformation original={original} />
                                            <ReferralSource original={original} />
                                            <ApptAvailability original={original} />
                                            <Card title="Select a Program and Assign an Agency" bordered={false} style={{ width: '-webkit-fill-available', marginTop: '1em' }}>
                                                <Row gutter={[16, 24]} style={{ justifyContent: 'center' }}>
                                                    <Col xs={24} md={24} lg={10}>
                                                        <Select
                                                            placeholder="Select a county"
                                                            optionFilterProp="children"
                                                            onChange={(value) => this.findProgramsByCounty(value)}
                                                            value={this.state.selected_county}
                                                            style={{ marginBottom: '15px' }}>
                                                            {scf_counties.sort().map((program_county) => (
                                                                <Option key={program_county}>{program_county}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                    <Col xs={24} md={24} lg={10}>
                                                        <Select
                                                            placeholder="Select a program"
                                                            optionFilterProp="children"
                                                            onChange={(val) => {
                                                                this.findAgenciesByCountyAndProgram(val)
                                                            }}
                                                            disabled={this.state.selected_county === null}
                                                            value={this.state.selected_program}
                                                            style={{ marginBottom: '15px' }}>
                                                            {this.state.possible_programs.sort().map((program_county) => (
                                                                <Option key={program_county}>{program_county}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16, 24]} style={{ justifyContent: 'center' }}>
                                                    <Col xs={24} md={24} lg={10}>
                                                        <Select
                                                            placeholder="Filter by MCO (optional)"
                                                            optionFilterProp="children"
                                                            onChange={(val) => this.filterAgenciesMoreByMco(val)}
                                                            disabled={this.state.selected_program === null}
                                                            value={this.state.selected_mco}
                                                            style={{ marginBottom: '15px' }}>
                                                            {this.state.possible_mcos.sort().map((mco) => (
                                                                <Option key={mco}>{mco}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                    <Col xs={24} md={24} lg={10}>
                                                        <Select
                                                            placeholder="Select an agency"
                                                            optionFilterProp="children"
                                                            onChange={(val) => this.setState({ selected_agency: val })}
                                                            disabled={this.state.selected_program === null}
                                                            value={this.state.selected_agency}
                                                            style={{ marginBottom: '15px' }}>
                                                            {this.state.possible_agencies.sort().map((agency) => (
                                                                <Option key={agency}>{agency}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16, 24]} style={{ justifyContent: 'center' }}>
                                                    <Col xs={24} md={24} lg={5}>
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    selected_county: null,
                                                                    selected_program: null,
                                                                    selected_agency: null,
                                                                    possible_programs: [],
                                                                    possible_counties: [],
                                                                    program_responses: [],
                                                                    possible_agencies: [],
                                                                })
                                                            }}
                                                            danger
                                                            style={{ width: '100%' }}>
                                                            Clear
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} md={24} lg={5}>
                                                        <Button type="primary" onClick={() => this.addProgram()} disabled={this.state.selected_agency === null} style={{ width: '100%' }}>
                                                            Add
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                            {this.addProgramFields()}
                                            <Consent 
                                                this_agency_consents={this.state.this_agency_consents} 
                                                consent={this.state.consent}
                                                skip_consent={this.state.skip_consent}
                                                handleConsentChange={this.handleConsentChange} 
                                                handleSkipConsentChange={this.handleSkipConsentChange}
                                            />
                                        </TabPane>
                                        {this_agency_consents && (
                                            <TabPane forceRender={true} tab="Care Management" key="care_management">
                                                <CareManagementSCF original={original} />
                                            </TabPane>
                                        )}
                                        {this_agency_consents && (
                                            <TabPane forceRender={true} tab="Peer Services" key="peer_services">
                                                <PeerServices original={original} />
                                            </TabPane>
                                        )}
                                        {this_agency_consents && (
                                            <TabPane forceRender={true} tab="Primary Care" key="primary_care">
                                                <PrimaryCare original={original} />
                                            </TabPane>
                                        )}
                                        {this_agency_consents && (
                                            <TabPane forceRender={true} tab="Mental Health" key="mental_health">
                                                <MentalHealth original={original} />
                                            </TabPane>
                                        )}
                                        {this_agency_consents && (
                                            <TabPane forceRender={true} tab="Substance Use" key="substance_use">
                                                <SubstanceUse original={original} />
                                            </TabPane>
                                        )}
                                        <TabPane forceRender={true} tab="Documentation" key="documentation">
                                            <Documentation original={original} history={this.props.history} user={user} consentedAgencies={this.state.consented_agencies} />
                                        </TabPane>
                                    </Tabs>
                                )}
                                <Row style={{ width: '100%' }}>
                                    <Col xs={24} md={24} lg={24} style={{ width: '100%' }}>
                                        <Form.Item style={{ margin: '1em 0 5em 0' }}>
                                            <Button type="primary" htmlType="submit" style={{ width: '100%' }} size="large" disabled={this.state.consent === false && this.state.skip_consent === false} >
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </React.Fragment>
            </Content>
        )
    }
}

export default SharedCare
