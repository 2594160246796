import React, { useEffect, useRef } from 'react'
import moment from 'moment-timezone'
import { Provider } from 'react-redux'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { AnimatedSwitch, spring } from 'react-router-transition'

import jwt_decode from 'jwt-decode'
import setAuthToken from '../utils/setAuthToken'
import { setCurrentUser, logoutUser } from '../core/actions/authActions'
import store from '../core/store'

import Login from './pages/login/login'
import ForgotPassword from './pages/forgot-password/forgot-password'
import ResetPassword from './pages/reset-password/reset-password'
import Register from './pages/register/register'
import Dashboard from './pages/dashboard/dashboard'
import ModalReferral from './pages/dashboard/modal-referral/modal-referral'
import AddReferral from './pages/add-referral/add-referral'
import SharedCareForm from './pages/add-referral/shared-care/shared-care'
import EditReferral from './pages/edit-referral/edit-referral'
import SharedCareFormEdit from './pages/edit-referral/shared-care/shared-care-edit'
import UploadCSV from './pages/upload-csv/upload-csv'
import ManageAgencies from './pages/manage-agencies/agencies'
import AgencyEdit from './pages/manage-agencies/agencies-edit'
import ManageHie from './pages/manage-hie/hie'
import Reports from './pages/reports/reports'
import Account from './pages/user-settings/account'
import Invite from './pages/user-manage/user-invite'
import List from './pages/user-manage/list'
import UserEdit from './pages/user-manage/user-edit'
import UserActivity from './pages/user-manage/user-activity'
import Privacy from './pages/privacy/privacy'
import Terms from './pages/terms/terms'
import NoMatch from './pages/no-match/no-match'
import CovidSurvey from './pages/covid-survey/covid-survey'
import TermsConditions from './pages/terms-conditions/terms-conditions'
import PatientHealthQuestionnaire from './pages/patient-health-questionnaire/patient-health-questionnaire'
import UploadDocs from './pages/upload-docs/upload-docs'
import EditDocs from './pages/upload-docs/edit-docs'

import ProtectedRoute from '../components/shared/protected-route/protected-route'

import LIGHT_THEME from '../styles/themes/light.json'
import HCBS_THEME from '../styles/themes/HCBS.json'
import TOC_THEME from '../styles/themes/TOC.json'
import CMO_THEME from '../styles/themes/CMO.json'
import COVID_THEME from '../styles/themes/COVID.json'
import CBHC_THEME from '../styles/themes/CBHC.json'
import SHCARE_THEME from '../styles/themes/SHCARE.json'

function mapStyles(styles) {
    return {
        opacity: styles.opacity,
    }
}

function bounce(val) {
    return spring(val, {
        stiffness: 330,
        damping: 22,
    })
}

const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
        opacity: 0,
        scale: 1.2,
    },
    // leave in a transparent, downscaled state
    atLeave: {
        opacity: bounce(0),
        scale: bounce(0.8),
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
        opacity: bounce(1),
        scale: bounce(1),
    },
}

if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken
    setAuthToken(token)
    // Decode token and get user info and exp
    const decoded = jwt_decode(token)
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded))
    // Check for expired token
    const currentTime = Date.now() / 1000 // to get in milliseconds
    if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser())
        // Redirect to login
        window.location.href = './login'
    }
}

function App() {
    moment.tz.setDefault('America/New_York')
    useEffect(() => {
        setTheme(localStorage.getItem('currentProject'))
    })

    const setTheme = async (project) => {
        let themeVars = LIGHT_THEME
        let themeSecondaryVars = {}
        switch (localStorage.getItem('currentProject')) {
            case 'HCBS':
                themeSecondaryVars = HCBS_THEME
                document.documentElement.style.setProperty('--sider-color', '#20151b')
                document.documentElement.style.setProperty('--sider-color_dark', '#130b0b')
                document.documentElement.style.setProperty('--secondary-color', '#b5536f')
                document.documentElement.style.setProperty('--card-color', '#712f42')
                break
            case 'TOC':
                themeSecondaryVars = TOC_THEME
                document.documentElement.style.setProperty('--sider-color', '#1b1f24')
                document.documentElement.style.setProperty('--sider-color_dark', '#0d0f12')
                document.documentElement.style.setProperty('--secondary-color', '#78879c')
                document.documentElement.style.setProperty('--card-color', '#45505f')
                break
            case 'CMO':
                themeSecondaryVars = CMO_THEME
                document.documentElement.style.setProperty('--sider-color', '#142719')
                document.documentElement.style.setProperty('--sider-color_dark', '#0d170f')
                document.documentElement.style.setProperty('--secondary-color', '#4d945e')
                document.documentElement.style.setProperty('--card-color', '#204228')
                break
            case 'COVID':
                themeSecondaryVars = COVID_THEME
                document.documentElement.style.setProperty('--sider-color', '#432e25')
                document.documentElement.style.setProperty('--sider-color_dark', '#1e110c')
                document.documentElement.style.setProperty('--secondary-color', '#AA745E')
                document.documentElement.style.setProperty('--card-color', '#6E493A')
                break
            case 'CBHC-MVP':
                themeSecondaryVars = CBHC_THEME
                document.documentElement.style.setProperty('--sider-color', '#8A1E1E')
                document.documentElement.style.setProperty('--sider-color_dark', '#4f1919')
                document.documentElement.style.setProperty('--secondary-color', '#D23232')
                document.documentElement.style.setProperty('--card-color', '#8A1E1E')
                break
            case 'SHARED-CARE':
                themeSecondaryVars = SHCARE_THEME
                document.documentElement.style.setProperty('--sider-color', '#002238')
                document.documentElement.style.setProperty('--sider-color_dark', '#05151f')
                document.documentElement.style.setProperty('--secondary-color', '#1890ff')
                document.documentElement.style.setProperty('--card-color', '#044268')
                break
            default:
                themeSecondaryVars = HCBS_THEME
                break
        }
        themeVars = {
            ...themeVars,
            ...themeSecondaryVars,
            '@white': '#fff',
            '@black': '#000',
        }
        localStorage.setItem('theme-vars', JSON.stringify(themeVars))
        window.less.modifyVars(themeVars).catch((error) => {})
    }

    return (
        <Provider store={store}>
            <Router>
                <AnimatedSwitch atEnter={bounceTransition.atEnter} atLeave={bounceTransition.atLeave} atActive={bounceTransition.atActive} mapStyles={mapStyles} className="switch-wrapper">
                    <Route exact path="/" component={Login} />
                    <Route exact path="/register/:id" component={Register} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/reset-password/:hash/:id" component={ResetPassword} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/patient-health-questionnaire" component={PatientHealthQuestionnaire} />
                    <Route exact path="/covid-survey" component={CovidSurvey} />
                    <Route exact path="/terms-conditions" component={TermsConditions} />
                    <Route exact path="/modal" component={ModalReferral} />
                    <Route exact path="/nomatch" component={NoMatch} />
                    <Switch>
                        <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                        <ProtectedRoute exact path="/addreferral/SHARED-CARE" component={SharedCareForm} />
                        <ProtectedRoute exact path="/addreferral/:project?" component={AddReferral} />
                        <ProtectedRoute exact path="/editreferral/:id" component={EditReferral} />
                        <ProtectedRoute exact path="/editreferral/shared-care/:id" component={SharedCareFormEdit} />
                        <ProtectedRoute exact path="/uploadcsv" component={UploadCSV} />
                        <ProtectedRoute exact path="/agencies/manage" component={ManageAgencies} />
                        <ProtectedRoute exact path="/agencies/:id" component={AgencyEdit} />
                        <ProtectedRoute exact path="/hie/manage" component={ManageHie} />
                        {<ProtectedRoute exact path="/reports" component={Reports} />}
                        <ProtectedRoute exact path="/account" component={Account} />
                        <ProtectedRoute exact path="/invite" component={Invite} />
                        <ProtectedRoute exact path="/list" component={List} />
                        <ProtectedRoute exact path="/useredit/:id" component={UserEdit} />
                        <ProtectedRoute exact path="/useractivity/:id" component={UserActivity} />
                        <ProtectedRoute exact path="/privacy" component={Privacy} />
                        <ProtectedRoute exact path="/terms" component={Terms} />
                        <ProtectedRoute exact path="/documentation/:id" component={UploadDocs} />
                        <ProtectedRoute exact path="/edit-documentation/:id" component={EditDocs} />
                    </Switch>
                    <Route component={NoMatch} />
                </AnimatedSwitch>
            </Router>
        </Provider>
    )
}

export default App
