import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Checkbox, Layout, Input, Button, Row, Result, Col, Card, notification } from 'antd'
import { PasswordInput } from 'antd-password-input-strength'

import { logoutUser } from '../../../core/actions/authActions'

import './register.scss'

const { Header, Content } = Layout

const openNotificationSuccess = (user, agency) => {
    notification.success({
        message: 'Valid Registration Key',
        duration: 3,
        description: (
            <p style={{ marginTop: '1em' }}>
                <b>Key:</b> {user.hash}
                <br />
                <b>Agency assigned: </b>
                {user.user_type}
                <br />
                <br />
                Your registration token is valid.
            </p>
        ),
        style: {
            width: 450,
            marginLeft: 390 - 450,
        },
    })
}

class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validUser: null,
            confirmDirty: false,
            user: null,
        }
    }

    componentDidMount() {
        const user_hash = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        axios
            .post('/api/users/invite/validate', {
                hash: user_hash,
            })
            .then((resp) => {
                this.setState({ validUser: true, user: resp.data.user })
                openNotificationSuccess(resp.data.user, resp.data.user_type)
            })
            .catch((err) => {
                this.setState({ validUser: false })
                console.log(err)
            })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                values['agency'] = this.state.user.user_type
                values['email'] = this.state.user.email.toLowerCase()
                // console.log(values)
                const response = await axios.post('/api/users/register', values)
                if (response.data.success === true) {
                    this.props.logoutUser()
                    this.props.history.push('/login')
                }
            }
        })
    }

    handleConfirmBlur = (e) => {
        const { value } = e.target
        this.setState({ confirmDirty: this.state.confirmDirty || !!value })
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!')
        } else {
            callback()
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true })
        }
        callback()
    }

    validatePasswordStrength = (rule, value, callback) => {
        const passwordStrengthRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$'
        if (!value.match(passwordStrengthRegex)) {
            return callback('Password must be at least 8 characters total and include an upper case letter, lower case letter, number, and special character. The bar is an indicator of overall strength.')
        } else {
            callback()
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Layout
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#1652f0',
                    margin: '0 ',
                }}>
                <Content style={{ padding: '0 100px' }}>
                    <Header
                        style={{
                            backgroundColor: '#1652f0',
                            marginBottom: '5em',
                        }}>
                        <p
                            style={{
                                fontSize: '24px',
                                fontFamily: 'Manrope, sans-serif',
                                color: 'white',
                                fontWeight: '800',
                                marginBottom: '1.5em',
                            }}>
                        </p>
                    </Header>
                    {this.state.validUser === true && (
                        <Row type="flex" justify="center">
                            <Col xs={20} sm={20} md={15} lg={12} xl={10} xxl={7}>
                                <div
                                    style={{
                                        fontSize: '26px',
                                        textAlign: 'center',
                                        fontFamily: 'Manrope, sans-serif',
                                        color: 'white',
                                        letterSpacing: '2px',
                                        fontWeight: '400',
                                        marginBottom: '1.5em',
                                    }}>
                                    <p style={{ marginBottom: '1em' }}>You've been invited to join ConnexRX</p>
                                    <p>Create an account</p>
                                </div>
                                <Card
                                    style={{
                                        borderRadius: '4px',
                                        padding: 0,
                                        boxShadow: '0 1px 46px -4px rgba(0,0,0,.28)',
                                    }}
                                    bordered={false}>
                                    <Row>
                                        <Col span={24}>
                                            <Form onSubmit={this.handleSubmit}>
                                                <input type="hidden" value="autocompleteoff" />

                                                <Form.Item>
                                                    {getFieldDecorator('name', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Please input your name!',
                                                                whitespace: true,
                                                            },
                                                        ],
                                                    })(<Input size="large" placeholder="Name" autoComplete="name" />)}
                                                </Form.Item>
                                                <Form.Item>
                                                    {getFieldDecorator('password', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                            {
                                                                validator: this.validateToNextPassword,
                                                            },
                                                            {
                                                                message: 'Password must be at least 8 characters total and include an upper case letter, lower case letter, number, and special character. The bar is an indicator of overall strength.',
                                                                validator: this.validatePasswordStrength,
                                                            },
                                                        ],
                                                    })(<PasswordInput inputProps={{ size: 'large', placeholder: 'Password', autoComplete: 'new-password' }} />)}
                                                </Form.Item>
                                                <Form.Item>
                                                    {getFieldDecorator('password2', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Please confirm your password!',
                                                            },
                                                            {
                                                                validator: this.compareToFirstPassword,
                                                            },
                                                        ],
                                                    })(<Input.Password size="large" onBlur={this.handleConfirmBlur} placeholder="Confirm Password" autoComplete="confirm-password" />)}
                                                </Form.Item>
                                                <Form.Item>
                                                    {getFieldDecorator('agreement', {
                                                        valuePropName: 'checked',
                                                    })(
                                                        <Checkbox>
                                                            I agree to the{' '}
                                                            <Button
                                                                type="link"
                                                                href="/#/terms-conditions"
                                                                style={{
                                                                    padding: 0,
                                                                }}>
                                                                <span style={{textDecoration: 'underline'}}>terms and conditions</span>
                                                            </Button>
                                                        </Checkbox>,
                                                    )}
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        size="large"
                                                        style={{
                                                            width: '100%',
                                                        }}>
                                                        Register
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    {this.state.validUser === false && (
                        <Row type="flex" justify="center">
                            <Col xs={20} sm={20} md={15} lg={12} xl={10} xxl={10}>
                                <div
                                    style={{
                                        fontSize: '26px',
                                        textAlign: 'center',
                                        fontFamily: 'Manrope, sans-serif',
                                        color: 'white',
                                        letterSpacing: '2px',
                                        fontWeight: '400',
                                        marginBottom: '1.5em',
                                    }}></div>
                                <Result
                                    status="403"
                                    title={
                                        <span
                                            style={{
                                                color: 'white',
                                                fontFamily: 'Manrope, sans-serif',
                                            }}>
                                            Invalid Registration Token
                                        </span>
                                    }
                                    subTitle={
                                        <span
                                            style={{
                                                color: 'white',
                                                fontFamily: 'Manrope, sans-serif',
                                                fontSize: 18,
                                            }}>
                                            Sorry, we were unable to authenticate your invite token. Please request another invite or contact &nbsp;
                                            <a
                                                href="mailto:support@cbhscareconnections.com"
                                                style={{
                                                    textDecoration: 'underline',
                                                    color: '#9cd0ff',
                                                }}>
                                                support@cbhscareconnections.com
                                            </a>{' '}
                                            for assistance.
                                        </span>
                                    }
                                />
                            </Col>
                        </Row>
                    )}

                    {this.state.validUser !== null && (
                        <>
                            <Row
                                type="flex"
                                justify="center"
                                style={{
                                    marginTop: '2em',
                                    color: '#fff',
                                    fontFamily: 'Manrope, sans-serif',
                                }}>
                                <Col>
                                    <Link to="/login" style={{ color: '#fff' }}>
                                        Already have an account?
                                    </Link>
                                </Col>
                            </Row>
                            <Row
                                type="flex"
                                justify="center"
                                style={{
                                    marginTop: '1em',
                                    color: '#ffffffad',
                                    fontFamily: 'Manrope, sans-serif',
                                }}>
                                <Col>
                                    <p style={{ fontSize: 12 }} href="#">
                                        CBHS Care Connections © 2021
                                    </p>
                                </Col>
                            </Row>
                        </>
                    )}
                </Content>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
})

export default connect(mapStateToProps, { logoutUser })(Form.create({ name: 'register' })(Register))
