import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Alert, Input, Button, Row, Col, Card, Checkbox } from 'antd'
import TwoFactorAuth from '../two-factor-auth/two-factor-auth'
import { loginUser, loginUserWithOTP } from '../../../core/actions/authActions'

import './login.scss'

const Login = (props) => {
    const [user, setUser] = useState({})
    const [showOtp, setShowOtp] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [termsCheckbox, setTermsCheckbox] = useState(false)

    if (props.auth.isAuthenticated) {
        return <Redirect to={{ pathname: '/dashboard' }} />
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault()
        props.form.validateFields(async (err, values) => {
            if (!err) {
                values['email'] = values['email'].toLowerCase()
                const user = {
                    email: values['email'],
                    password: values['password'],
                }
                setErrorMessage('')
                setUser(user)
                props.loginUser(values, setShowOtp, setErrorMessage)
            }
        })
    }

    const handleResendOTP = (e) => {
        e.preventDefault()
        setErrorMessage('A new code has been sent')
        const userData = user
        props.loginUser(userData, setShowOtp, setErrorMessage)
    }

    const handleOTPSubmit = (otpcode) => {
        props.loginUserWithOTP(user, otpcode, setErrorMessage)
    }

    const { getFieldDecorator } = props.form

    return (
        <React.Fragment>
            <Row
                type="flex"
                justify="center"
                align="middle"
                style={{
                    minHeight: '80vh',
                }}>
                <Col xs={24} sm={24} md={12} lg={10} xl={6}>
                    <Card
                        style={{
                            borderRadius: '10px',
                            padding: '10px 15px',
                        }}
                        bordered={false}>
                        <Row>
                            <Col span={24}>
                                <Card
                                    // title="CBHS Care Connections"
                                    bordered={false}
                                    headStyle={{
                                        fontSize: 25,
                                        paddingLeft: 0,
                                    }}
                                    style={{
                                        width: '75%',
                                        margin: 'auto',
                                    }}
                                    cover={<img alt="CBHS" src="/assets/img/logo-connex.png" />}
                                    bodyStyle={{
                                        paddingBottom: 5,
                                    }}
                                />

                                {errorMessage !== '' && <Alert message="Attention" description={errorMessage} type="error" style={{ marginBottom: 15 }} />}

                                {showOtp ? (
                                    <TwoFactorAuth handleOTPSubmit={handleOTPSubmit} handleResendOTP={handleResendOTP} />
                                ) : (
                                    <>
                                        <p className="text-red">{props.errors.email}</p>
                                        <p className="text-red"> {props.errors.emailnotfound}</p>
                                        <p className="text-red">{props.errors.password}</p>
                                        <p className="text-red">{props.errors.passwordincorrect}</p>

                                        <Form onSubmit={handleLoginSubmit} className="login-form">
                                            <Form.Item>
                                                {getFieldDecorator('email', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please enter your username',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        prefix={
                                                            <UserOutlined
                                                                style={{
                                                                    fontSize: 13,
                                                                }}
                                                            />
                                                        }
                                                        placeholder="Email"
                                                        size="large"
                                                    />,
                                                )}
                                            </Form.Item>

                                            <Form.Item>
                                                {getFieldDecorator('password', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please enter your password',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        prefix={
                                                            <LockOutlined
                                                                style={{
                                                                    fontSize: 13,
                                                                }}
                                                            />
                                                        }
                                                        type="password"
                                                        placeholder="Password"
                                                        size="large"
                                                    />,
                                                )}
                                            </Form.Item>
                                            <Form.Item>
                                                <Checkbox
                                                    checked={termsCheckbox}
                                                    style={{paddingBottom: '3px'}}
                                                    onClick={() => {
                                                        setTermsCheckbox(!termsCheckbox)
                                                    }}>
                                                 </Checkbox>
                                                <span> I understand and agree to the <a type="link" target="_blank" href="/#/terms-conditions" style={{ textDecoration: 'underline '}}> terms and conditions of service </a></span>                                       
                                               
                                            </Form.Item>
                                            <Form.Item style={{ marginBottom: '0' }}>
                                                <Button disabled={!termsCheckbox} type='primary' htmlType="submit" className={!termsCheckbox ? 'login-form-button-disabled': 'login-form-button'} size="large">
                                                    Log in
                                                </Button>
                                                <div style={{ marginTop: 10 }}>
                                                    <span>Forgot your password?</span>
                                                    <a
                                                        type="link"
                                                        href="/#/forgot-password"
                                                        style={{
                                                            padding: '0 0 0 5px',
                                                        }}>
                                                        Click here
                                                    </a>
                                                </div>

                                                <div style={{ marginTop: 10 }}>
                                                    <span>Here for the COVID-19 survey?</span>
                                                    <a type="link" href="/#/covid-survey" style={{ padding: '0 0 0 5px' }}>
                                                        Click here
                                                    </a>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
})

export default connect(mapStateToProps, { loginUser, loginUserWithOTP })(Form.create({ name: 'normal_login' })(Login))
