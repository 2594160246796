import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Button, Card, Col, message, Row, Popconfirm, Select, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { agencies, date_format, status_viewer, status_viewer_cbhc_mvp } from '../../../../utils/constants'

const { Option } = Select
const { Text, Title } = Typography

const ReferralTableFilter = (props) => {
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [selectedAgency, setSelectedAgency] = useState('None')
    const [isUpdateAgencyLoading, setIsUpdateAgencyLoading] = useState(false)

    const status_viewer_selected = props.currentProject === 'CBHC-MVP' ? status_viewer_cbhc_mvp : status_viewer

    const deleteMultipleRecord = async () => {
        setDeleteLoading(true)
        const selectedRows = props.selectedRowKeys
        const allReferrals = props.referrals['all']
        let selectedIds = []

        await selectedRows.forEach((_id) => {
            const data = allReferrals.find((obj) => obj['_id'] === _id)
            selectedIds.push(data._id)
        })

        await axios
            .post('/api/referrals/delete/bulk_delete_referral', {
                ids: selectedIds,
            })
            .then(async () => {
                message.loading('Action in progress..')
                await props.fetchReferrals()
            })
            .then(() => {
                message.success('Delete Success!', 2.5)
            })
            .catch((err) => {
                message.error('Error Occured', err)
            })

        setTimeout(() => {
            setDeleteLoading(false)
            props.onChange(props.selectedRowKeys, [])
            props.onChange(props.selectedAgency, null)
            // return to total referral view
            props.statusChanger('all')
        }, 1000)
    }

    const agencyChanger = async () => {
        if (selectedAgency === null) {
            message.error('Please select the agency')
            return
        }

        let date_assigned = null
        if (selectedAgency !== 'None' && selectedAgency !== 'CBHS') {
            date_assigned = moment().format(date_format)
        }

        setIsUpdateAgencyLoading(true)
        const selectedRows = props.selectedRowKeys
        const allReferrals = props.referrals['all']
        const project = props.currentProject
        let selectedIds = []

        await selectedRows.forEach((_id) => {
            const data = allReferrals.find((referral) => referral['_id'] === _id)
            selectedIds.push(data._id)
        })

        await axios.post('/api/referrals/update/bulk_assign_agency', {
            ids: selectedIds,
            selectedAgency: selectedAgency,
            date_assigned: date_assigned,
            project: project,
        })

        message.loading('Action in progress..')
        await props.fetchReferrals()

        message.success('Update Success!', 2.5)
        setTimeout(() => {
            setIsUpdateAgencyLoading(false)
            setSelectedAgency(null)
            props.onChange('selectedRowKeys', [])
        }, 1000)
    }

    return (
        <Card className="site-layout-background" style={{ margin: '20px 16px 0' }}>
            <Row gutter={[12, 12]}>
                <Col span={24}>
                    <Title level={4}>Bulk Actions</Title>
                    <Text>Select one or more referrals in the table to update their agencies or delete. (Left is a status filter; Right chooses an agency.) </Text>
                </Col>
            </Row>

            <Row gutter={[12, 12]} align="bottom">
                <Col xs={24} sm={4}>
                    <p>
                        {/* <Tooltip title="Selecting the above cards will also apply the status filter to the table">
                            <InfoCircleOutlined />
                        </Tooltip>{' '} 
                        &nbsp;Referral Status Filter */}
                    </p>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select referral status to view"
                        optionFilterProp="children"
                        onChange={(status) => {
                            props.statusChanger(status)
                        }}
                        value={props.status}>
                        {status_viewer_selected.map((referral) => (
                            <Option key={referral.key}>{referral.value}</Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={6}>
                    {/* <p>Update Selected Referral Agency</p> */}
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select to update agency"
                        onChange={(value) => {
                            setSelectedAgency(value)
                        }}
                        value={selectedAgency}>
                        {agencies.map((agency) => (
                            <Option key={agency}>{agency}</Option>
                        ))}
                    </Select>
                </Col>
                <Col>
                    <Button type="primary" onClick={() => agencyChanger()} disabled={!props.hasSelected} loading={isUpdateAgencyLoading} style={{ marginRight: '5px', borderRadius: '12px' }}>
                        Update Agency
                    </Button>
                    <Popconfirm title="Sure to delete?" disabled={!props.hasSelected} onConfirm={() => deleteMultipleRecord()}>
                        <Button type="danger" disabled={!props.hasSelected} loading={deleteLoading} style={{ borderRadius: '12px' }}>
                            Delete Record(s)
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
        </Card>
    )
}

export default ReferralTableFilter
