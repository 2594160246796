import React, { Component } from 'react'
import { Col, Card, Radio, Row, Table, Typography } from 'antd'
import './patient-health-questionnaire.scss'

const { Title, Paragraph, Text } = Typography

class PatientHealthQuestionaire extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: {},
        }
    }

    onRadioChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        this.setState({
            selected: { ...this.state.selected, [name]: value },
        })
    }

    result = () => Object.values(this.state.selected).reduce((a, b) => a + b, 0)

    render() {
        const questions = [
            'Little interest or pleasure in doing things',
            'Feeling down, depressed or hopeless',
            'Trouble falling asleep, staying asleep, or sleeping too much',
            'Feeling tired or having little energy',
            'Poor appetite or overeating',
            "Feeling bad about yourself - or that you're a failure or have let yourself or your family down",
            'Trouble concentrating on things, such as reading the newspaper or watching television',
            'Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual',
            'Thoughts that you would be better off dead or of hurting yourself in some way',
        ]
        const prompt = (
            <Text>
                Over the <Text underline>last 2 weeks</Text>, how often have you
                been bothered by the following problems?
            </Text>
        )
        const options = [0, 1, 2, 3]
        const optionText = [
            'Not at all',
            'Several days',
            'More than half the days',
            'Nearly every day',
        ]
        const optionDisplay = ['0', '+1', '+2', '+3']
        let columns = []
        columns.push({
            title: prompt,
            dataIndex: 'question',
            key: 'id',
            width: '36%',
        })
        options.forEach((option) => {
            columns.push({
                title: optionText[option],
                key: option,
                align: 'center',
                width: '16%',
                render: (row) => {
                    return (
                        <Radio
                            checked={this.state.selected[row.id] === option}
                            onChange={this.onRadioChange}
                            name={row.id}
                            value={option}>
                            {optionDisplay[option]}
                        </Radio>
                    )
                },
            })
        })
        let data = []
        questions.forEach((question, i) => {
            data.push({
                question: i + 1 + '. ' + question,
                id: i,
            })
        })
        return (
            <React.Fragment>
                <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{
                        minHeight: '60vh',
                    }}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <Card bordered={false}>
                            <Row>
                                <Col span={24}>
                                    <Card
                                        bordered={false}
                                        style={{
                                            width: '30%',
                                            margin: 'auto',
                                        }}
                                        cover={
                                            <img
                                                alt="CBHS"
                                                src="/assets/img/logo-cbhs.png"
                                            />
                                        }
                                    />
                                    <Title level={3} align="left">
                                        Patient Health Questionnaire-9 (PHQ-9)
                                    </Title>
                                    <Paragraph>
                                        The PHQ-9 is a multipurpose instrument
                                        for screening, diagnosing, monitoring
                                        and measuring the severity of
                                        depression.
                                    </Paragraph>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        bordered={true}
                                        pagination={false}
                                        scroll={{ x: 600 }}></Table>
                                    <Card>
                                        <Paragraph>
                                            PHQ-9 score obtained by adding score
                                            for each question (total points)
                                        </Paragraph>
                                        <Text strong>
                                            {this.result()} points
                                        </Text>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default PatientHealthQuestionaire
