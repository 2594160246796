import React, { Component } from 'react'
import moment from 'moment'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { DownloadOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row } from 'antd'
import { Document, Page, View, Text, Font, StyleSheet } from '@react-pdf/renderer'
import { date_format, referral_keys_readable, time_format } from '../../../../utils/constants'
import { removeLastComma } from '../../../../utils/helpers'

const styles = StyleSheet.create({
    body: {
        flexGrow: 1,
        fontFamily: 'Oswald',
        justifyContent: 'space-between',
        padding: 20,
    },
    row: {
        flexGrow: 1,
        flexDirection: 'row',
        padding: '0',
        marginBottom: '10px',
    },
    col1: { width: '95%', margin: 'auto' },
    col2: { width: '45%', margin: 'auto' },
    col3: { width: '28%', margin: 'auto' },
    title: {
        fontSize: 10,
    },
    content: {
        fontSize: 12,
        backgroundColor: '#f2f2f2',
    },
    contentSmall: {
        fontSize: 10,
        backgroundColor: '#f2f2f2',
    },
    section: {
        width: '95%',
        margin: 'auto',
        padding: '3px 0 3px 3px',
        fontSize: 12,
        backgroundColor: '#000',
        textTransform: 'uppercase',
        color: '#fff',
    },
    log: {
        width: '95%',
        padding: '3px 0 3px 3px',
        fontSize: 6,
        marginBottom: '5px',
    },
})

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
})

const RenderDocument = ({ row }) => (
    <Document>
        <Page size="LETTER" style={styles.body}>
            <View style={styles.row}>
                <View
                    render={() => {
                        if (row.project === 'CBHC-MVP') {
                            return (
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Referral Agency</Text>
                                    <Text style={styles.content}>{row.referral_agency || ' '}</Text>
                                </View>
                            )
                        } else if (row.project === 'HCBS' || row.project === 'TOC' || row.project === 'COVID' || row.project === 'CMO') {
                            return (
                                <React.Fragment>
                                    <View style={styles.col2}>
                                        <Text style={styles.title}>Referral Agency</Text>
                                        <Text style={styles.content}>{row.referral_agency || ' '}</Text>
                                    </View>
                                    <View style={styles.col2}>
                                        <Text style={styles.title}>Referral Status</Text>
                                        <Text style={styles.content}>{row.referral_status || ' '}</Text>
                                    </View>
                                </React.Fragment>
                            )
                        } else {
                            return
                        }
                    }}
                />
            </View>
            <View
                render={() => {
                    if (row.project === 'HCBS' || row.project === 'TOC' || row.project === 'COVID' || row.project === 'CMO') {
                        return (
                            <View style={styles.row}>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Number of Cancellations</Text>
                                    <Text style={styles.content}>{row.number_cancelled.toString() || ' '}</Text>
                                </View>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Number of No Shows</Text>
                                    <Text style={styles.content}>{row.number_noshows.toString() || ' '}</Text>
                                </View>
                            </View>
                        )
                    }
                }}
            />
            <View
                render={() => {
                    if (row.project === 'COVID' && row.is_guardian === 'Y') {
                        return (
                            <>
                                <View style={styles.section}>
                                    <Text>Guardian Information</Text>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col2}>
                                        <Text style={styles.title}>Guardian First Name</Text>
                                        <Text style={styles.content}>{row.guardian_first_name || ' '}</Text>
                                    </View>
                                    <View style={styles.col2}>
                                        <Text style={styles.title}>Guardian Last Name</Text>
                                        <Text style={styles.content}>{row.guardian_last_name || ' '}</Text>
                                    </View>
                                </View>
                            </>
                        )
                    }
                }}
            />
            <View style={styles.section}>
                <Text>Client Information</Text>
            </View>
            <View style={styles.row}>
                <View style={styles.col2}>
                    <Text style={styles.title}>ID</Text>
                    <Text style={styles.content}>{row.nano_id || ' '}</Text>
                </View>
                <View style={styles.col2}>
                    <Text style={styles.title}>Leave Message</Text>
                    <Text style={styles.content}>{row.leave_message || ' '}</Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.col1}>
                    <Text style={styles.title}>Name</Text>
                    <Text style={styles.content}>
                        {row.first_name} {row.middle_name} {row.last_name}
                    </Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.col3}>
                    <Text style={styles.title}>Gender</Text>
                    <Text style={styles.content}>{row.gender || ' '}</Text>
                </View>
                <View style={styles.col3}>
                    <Text style={styles.title}>Date of Birth</Text>
                    <Text style={styles.content}>{row.dob_string || ' '}</Text>
                </View>
                <View style={styles.col3}>
                    <Text style={styles.title}>Language</Text>
                    <Text style={styles.content}>{row.language || ' '}</Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.col1}>
                    <Text style={styles.title}>Ethnicity</Text>
                    <Text style={styles.content}>{row.ethnicity}</Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.col1}>
                    <Text style={styles.title}>Address</Text>
                    <Text style={styles.content}>
                        {row.address_1} {row.address_2}
                    </Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.col3}>
                    <Text style={styles.title}>County</Text>
                    <Text style={styles.content}>{row.county || ' '}</Text>
                </View>
                <View style={styles.col3}>
                    <Text style={styles.title}>City</Text>
                    <Text style={styles.content}>{row.city || ' '}</Text>
                </View>
                <View style={styles.col3}>
                    <Text style={styles.title}>State</Text>
                    <Text style={styles.content}>{row.state || ' '}</Text>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.col3}>
                    <Text style={styles.title}>Zip</Text>
                    <Text style={styles.content}>{row.zip || ' '}</Text>
                </View>
                <View style={styles.col3}>
                    <Text style={styles.title}>Phone</Text>
                    <Text style={styles.content}>{row.phone || ' '}</Text>
                </View>
                <View style={styles.col3}>
                    <Text style={styles.title}>Phone Secondary</Text>
                    <Text style={styles.content}>{row.phone_secondary || ' '}</Text>
                </View>
            </View>
            <View
                render={() =>
                    row.project === 'COVID' && (
                        <View style={styles.col3}>
                            <Text style={styles.title}>Email Address</Text>
                            <Text style={styles.content}>{row.email_client || ' '}</Text>
                        </View>
                    )
                }
            />
            <View
                render={() =>
                    row.project === 'CBHC-MVP' && (
                        <>
                            <View style={styles.section}>
                                <Text>Health Home</Text>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Health Home Enrolled</Text>
                                    <Text style={styles.content}>{row.health_home_enrolled && row.health_home_enrolled.toString() === 'true' ? 'Yes' : 'No'}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Health Home Facility</Text>
                                    <Text style={styles.content}>{row.health_home_facility || ' '}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Health Home Enrollment Date</Text>
                                    <Text style={styles.content}>{row.health_home_enrollment_date || ' '}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Care Management Enrolled</Text>
                                    <Text style={styles.content}>{row.care_management_enrolled && row.care_management_enrolled.toString() === 'true' ? 'Yes' : 'No'}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Care Management Facility</Text>
                                    <Text style={styles.content}>{row.care_management_facility || ' '}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Care Management Enrollment Date</Text>
                                    <Text style={styles.content}>{row.care_management_enrollment_date || ' '}</Text>
                                </View>
                            </View>
                        </>
                    )
                }
            />
            <View
                render={() =>
                    row.project === 'CBHC-MVP' && (
                        <>
                            <View style={styles.section}>
                                <Text>Outreach and Engagement</Text>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Contact Type</Text>
                                    <Text style={styles.content}>{row.engagement_contact_type || ' '}</Text>
                                </View>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Number of Contacts</Text>
                                    <Text style={styles.content}>{row.engagement_number_of_contacts || ' '}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Contact Date</Text>
                                    <Text style={styles.content}>{row.engagement_date || ' '}</Text>
                                </View>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Contact Time</Text>
                                    <Text style={styles.content}>{row.engagement_time || ' '}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col1}>
                                    <Text style={styles.title}>Contact Details</Text>
                                    <Text style={styles.content}>{row.engagement_contact_details || ' '}</Text>
                                </View>
                            </View>
                        </>
                    )
                }
            />
            <View
                render={() =>
                    (row.project !== 'CBHC-MVP' || row.project !== 'SHARED-CARE') && (
                        <>
                            <View style={styles.section}>
                                <Text>Referral Information</Text>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Source</Text>
                                    <Text style={styles.content}>{row.referral_source || ' '}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Received</Text>
                                    <Text style={styles.content}>{row.date || ' '}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Referral Source Email</Text>
                                    <Text style={styles.content}>{row.referral_source_email || ' '}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Referral Source Phone</Text>
                                    <Text style={styles.content}>{row.referral_source_phone || ' '}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Additional Details</Text>
                                    <Text style={styles.content}>{row.additional_details || ' '}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Assigned</Text>
                                    <Text style={styles.content}>{row.date_assigned || ' '}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Days Lapsed</Text>
                                    <Text style={styles.content}>{row.time_lapsed ? row.time_lapsed.toString() : ' '}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Need</Text>
                                    <Text style={styles.content}>{row.referral_need.length > 1 ? row.referral_need.join(', ') : row.referral_need}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>{row.project === 'COVID' ? 'Title' : 'Referral Source Title'}</Text>
                                    <Text style={styles.content}>{row.referral_title || ' '}</Text>
                                </View>
                            </View>
                        </>
                    )
                }
            />
            <View style={styles.section}>
                <Text>Insurance Information</Text>
            </View>
            <View
                render={() => {
                    if (row.insurance_1) {
                        if (row.project === 'CBHC-MVP') {
                            return (
                                <View style={styles.row}>
                                    <View style={styles.col1}>
                                        <Text style={styles.title}>Insurance 1</Text>
                                        <Text style={styles.content}>Type: {row.insurance_1 || ' '}</Text>
                                        <Text style={styles.content}>Plan: {row.insurance_1_plan || ' '}</Text>
                                        <Text style={styles.content}>Group: {row.insurance_1_group_number || ' '}</Text>
                                        <Text style={styles.content}>Policy: {row.insurance_1_policy_number || ' '}</Text>
                                        <Text style={styles.content}>Effective Date: {row.insurance_1_effective_date || ' '}</Text>
                                        <Text style={styles.content}>Expiration Date: {row.insurance_1_expiration_date || ' '}</Text>
                                    </View>
                                </View>
                            )
                        } else {
                            return (
                                <View style={styles.row}>
                                    <View style={styles.col1}>
                                        <Text style={styles.title}>Insurance 1</Text>
                                        <Text style={styles.content}>Type: {row.insurance_1 || ' '}</Text>
                                        <Text style={styles.content}>Plan: {row.insurance_1_plan || ' '}</Text>
                                        <Text style={styles.content}>Group: {row.insurance_1_group_number || ' '}</Text>
                                        <Text style={styles.content}>Policy: {row.insurance_1_policy_number || ' '}</Text>
                                    </View>
                                </View>
                            )
                        }
                    }
                }}
            />
            <View
                render={() => {
                    if (row.insurance_2) {
                        if (row.project === 'CBHC-MVP') {
                            return (
                                <View style={styles.row}>
                                    <View style={styles.col1}>
                                        <Text style={styles.title}>Insurance 2</Text>
                                        <Text style={styles.content}>Type: {row.insurance_2 || ' '}</Text>
                                        <Text style={styles.content}>Plan: {row.insurance_2_plan || ' '}</Text>
                                        <Text style={styles.content}>Group: {row.insurance_2_group_number || ' '}</Text>
                                        <Text style={styles.content}>Policy: {row.insurance_2_policy_number || ' '}</Text>
                                        <Text style={styles.content}>Effective Date: {row.insurance_2_effective_date || ' '}</Text>
                                        <Text style={styles.content}>Expiration Date: {row.insurance_2_expiration_date || ' '}</Text>
                                    </View>
                                </View>
                            )
                        } else {
                            return (
                                <View style={styles.row}>
                                    <View style={styles.col1}>
                                        <Text style={styles.title}>Insurance 2</Text>
                                        <Text style={styles.content}>Type: {row.insurance_2 || ' '}</Text>
                                        <Text style={styles.content}>Plan: {row.insurance_2_plan || ' '}</Text>
                                        <Text style={styles.content}>Group: {row.insurance_2_group_number || ' '}</Text>
                                        <Text style={styles.content}>Policy: {row.insurance_2_policy_number || ' '}</Text>
                                    </View>
                                </View>
                            )
                        }
                    }
                }}
            />
            <View
                render={() => {
                    if (row.insurance_3) {
                        if (row.project === 'CBHC-MVP') {
                            return (
                                <View style={styles.row}>
                                    <View style={styles.col1}>
                                        <Text style={styles.title}>Insurance 3</Text>
                                        <Text style={styles.content}>Type: {row.insurance_3 || ' '}</Text>
                                        <Text style={styles.content}>Plan: {row.insurance_3_plan || ' '}</Text>
                                        <Text style={styles.content}>Group: {row.insurance_3_group_number || ' '}</Text>
                                        <Text style={styles.content}>Policy: {row.insurance_3_policy_number || ' '}</Text>
                                        <Text style={styles.content}>Effective Date: {row.insurance_3_effective_date || ' '}</Text>
                                        <Text style={styles.content}>Expiration Date: {row.insurance_3_expiration_date || ' '}</Text>
                                    </View>
                                </View>
                            )
                        } else {
                            return (
                                <View style={styles.row}>
                                    <View style={styles.col1}>
                                        <Text style={styles.title}>Insurance 3</Text>
                                        <Text style={styles.content}>Type: {row.insurance_3 || ' '}</Text>
                                        <Text style={styles.content}>Plan: {row.insurance_3_plan || ' '}</Text>
                                        <Text style={styles.content}>Group: {row.insurance_3_group_number || ' '}</Text>
                                        <Text style={styles.content}>Policy: {row.insurance_3_policy_number || ' '}</Text>
                                    </View>
                                </View>
                            )
                        }
                    }
                }}
            />
            <View
                render={() =>
                    row.project === 'COVID' && (
                        <View style={styles.col1}>
                            <Text style={styles.title}>HARP</Text>
                            <Text style={styles.content}>{row.assessment_harp || ' '}</Text>
                        </View>
                    )
                }
            />
            <View
                render={() => {
                    if (row.project === 'CBHC-MVP') {
                        return (
                            <React.Fragment>
                                <View style={styles.section}>
                                    <Text>PCP Appointment Date and Time</Text>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col3}>
                                        <Text style={styles.title}>Upcoming PCP Appointment Date</Text>
                                        <Text style={styles.content}>{row.appointment_date || ' '}</Text>
                                    </View>
                                    <View style={styles.col3}>
                                        <Text style={styles.title}>Last Annual Physical Date</Text>
                                        <Text style={styles.content}>{row.last_annual_physical_date || ' '}</Text>
                                    </View>
                                    <View style={styles.col3}>
                                        <Text style={styles.title}>Last PCP Follow-up Date</Text>
                                        <Text style={styles.content}>{row.last_pcp_followup_date || ' '}</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col1}>
                                        <Text style={styles.title}>Notes</Text>
                                        <Text style={styles.content}>{row.notes || ' '}</Text>
                                    </View>
                                </View>
                            </React.Fragment>
                        )
                    } else if (row.project === 'SHARED-CARE') {
                        return
                    } else {
                        return (
                            <React.Fragment>
                                <View style={styles.section}>
                                    <Text>Appointment Date</Text>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col2}>
                                        <Text style={styles.title}>Date</Text>
                                        <Text style={styles.content}>{row.appointment_date || ' '}</Text>
                                    </View>
                                    <View style={styles.col2}>
                                        <Text style={styles.title}>Time</Text>
                                        <Text style={styles.content}>{row.appointment_time || ' '}</Text>
                                    </View>
                                </View>
                            </React.Fragment>
                        )
                    }
                }}
            />
            <View
                render={() => {
                    const { contact_needs, contact_type, project, contact_date, contact_details, number_of_contacts } = row

                    if (project !== 'TOC' || project !== 'COVID') return ''

                    const contact_needs_list = contact_needs.length > 1 ? contact_needs.join(', ') : { contact_needs }

                    const contact_type_list = contact_type.length > 1 ? contact_type.join(', ') : { contact_type }

                    return (
                        <>
                            <View style={styles.section}>
                                <Text>{project === 'COVID' ? 'Referral Follow-up' : 'Care Management'}</Text>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Contact Needs</Text>
                                    <Text style={styles.content}>{contact_needs_list}</Text>
                                </View>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Contact Type</Text>
                                    <Text style={styles.content}>{contact_type_list}</Text>
                                </View>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Number of Contacts</Text>
                                    <Text style={styles.content}>{number_of_contacts}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Contact Date</Text>
                                    <Text style={styles.content}>{contact_date && moment(contact_date).format(date_format)}</Text>
                                </View>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Contact Details</Text>
                                    <Text style={styles.content}>{contact_details}</Text>
                                </View>
                            </View>
                        </>
                    )
                }}
            />
            <View
                render={() =>
                    row.project !== 'CMO' &&
                    row.project !== 'COVID' &&
                    row.project !== 'SHARED-CARE' &&
                    row.project !== 'CBHC-MVP' && (
                        <>
                            <View style={styles.section}>
                                <Text>Assessment Information</Text>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>HARP</Text>
                                    <Text style={styles.content}>{row.assessment_harp}</Text>
                                </View>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Contact Date</Text>
                                    <Text style={styles.content}>{row.assessment_contact_date_string}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Date Completed</Text>
                                    <Text style={styles.content}>{row.assessment_date_completed_string}</Text>
                                </View>
                                <View style={styles.col2}>
                                    <Text style={styles.title}>Date Submitted</Text>
                                    <Text style={styles.content}>{row.assessment_date_submitted_string}</Text>
                                </View>
                            </View>
                        </>
                    )
                }
            />
            <View
                render={() =>
                    row.project === 'CMO' && (
                        <>
                            <View style={styles.section}>
                                <Text>Treatment Contact Information</Text>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Agency Therapist</Text>
                                    <Text style={styles.content}>{row.agency_therapist}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Phone Number</Text>
                                    <Text style={styles.content}>{row.phone_agency_therapist}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Email</Text>
                                    <Text style={styles.content}>{row.email_agency_therapist}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Agency Prescribing Clinician</Text>
                                    <Text style={styles.content}>{row.agency_prescribing_clinician}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Phone Number</Text>
                                    <Text style={styles.content}>{row.phone_agency_prescribing_clinician}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Email</Text>
                                    <Text style={styles.content}>{row.email_agency_prescribing_clinician}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Primary Care Provider</Text>
                                    <Text style={styles.content}>{row.primary_care_provider}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Phone Number</Text>
                                    <Text style={styles.content}>{row.phone_primary_care_provider}</Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Email</Text>
                                    <Text style={styles.content}>{row.email_primary_care_provider}</Text>
                                </View>
                            </View>
                            <View style={styles.section}>
                                <Text>Referral/Encounter Note</Text>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col1}>
                                    <Text style={styles.title}>Referral/Encounter Note</Text>
                                    <Text style={styles.content}>{row.referral_encounter_note}</Text>
                                </View>
                            </View>
                        </>
                    )
                }
            />
            <View
                render={() =>
                    row.project === 'CBHC-MVP' && (
                        <>
                            <View style={styles.section}>
                                <Text>Quality Metrics</Text>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Diabetes Screen for people with Schizophrenia or Bipolar Disorder</Text>
                                    <Text style={styles.content}>
                                        {row.qm_1_checkbox && row.qm_1_checkbox.toString() === 'true' ? 'Checked on' : ' '} {row.qm_1_date || ' '}
                                    </Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Potential Avoidable ED Utilization</Text>
                                    <Text style={styles.content}>
                                        {row.qm_2_checkbox && row.qm_2_checkbox.toString() === 'true' ? 'Checked on' : ' '} {row.qm_2_date || ' '}
                                    </Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Cervical Cancer Screening (CCS)</Text>
                                    <Text style={styles.content}>
                                        {row.qm_3_checkbox && row.qm_3_checkbox.toString() === 'true' ? 'Checked on' : ' '} {row.qm_3_date || ' '}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Comprehensive Diabetes Care Eye Exam (CDC-Eye)</Text>
                                    <Text style={styles.content}>
                                        {row.qm_4_checkbox && row.qm_4_checkbox.toString() === 'true' ? 'Checked on' : ' '} {row.qm_4_date || ' '}
                                    </Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Asthma Medication Ratio (AMR)</Text>
                                    <Text style={styles.content}>
                                        {row.qm_5_checkbox && row.qm_5_checkbox.toString() === 'true' ? 'Checked on' : ' '} {row.qm_5_date || ' '}
                                    </Text>
                                </View>
                                <View style={styles.col3}>
                                    <Text style={styles.title}>Colorectal Cancer Screening (COL)</Text>
                                    <Text style={styles.content}>
                                        {row.qm_6_checkbox && row.qm_6_checkbox.toString() === 'true' ? 'Checked on' : ' '} {row.qm_6_date || ' '}
                                    </Text>
                                </View>
                            </View>
                        </>
                    )
                }
            />
            <View style={styles.section}>
                <Text>Referral History</Text>
            </View>
            <View style={styles.row}>
                <View style={styles.col1}>
                    {row.history &&
                        row.history.map((log) => {
                            return (
                                <View style={styles.log}>
                                    <Text style={styles.contentSmall}>
                                        {log.user} {log.timestamp}
                                    </Text>
                                    {log.added &&
                                        Object.keys(log.added).map((key) => {
                                            const value = log.added[key]

                                            if (key === 'id' || key === 'user_access' || key === 'user_id' || key === 'date_received') return null

                                            if (typeof value === 'string') {
                                                if (value !== '') {
                                                    if (value === 'Invalid date') {
                                                        return <Text style={styles.contentSmall}>MODIFIED: {referral_keys_readable[key]} Removed</Text>
                                                    } else {
                                                        return (
                                                            <Text style={styles.contentSmall}>
                                                                MODIFIED: {referral_keys_readable[key]} {value}
                                                            </Text>
                                                        )
                                                    }
                                                }
                                            }

                                            // List of nested objects
                                            if (typeof value === 'object') {
                                                for (item in value) {
                                                    if (typeof value[item] === 'object') {
                                                        return (
                                                            <Text style={styles.contentSmall}>
                                                                MODIFIED: {' '}
                                                                {Object.keys(value[item]).map((key) => {
                                                                    if (key !== 'key') {
                                                                        if (key === 'appointment_time') {
                                                                            return (
                                                                                <Text>
                                                                                    {referral_keys_readable[key]}: {moment(value[item][key]).format(time_format)}
                                                                                </Text>
                                                                            )
                                                                        }
                                                                        if (key === 'version_number' || key === 'version') {
                                                                            return
                                                                        }
                                                                        return (
                                                                            <Text>
                                                                                {referral_keys_readable[key]}: {value[item][key]},{' '}
                                                                            </Text>
                                                                        )
                                                                    }
                                                                    return null
                                                                })}
                                                            </Text>
                                                        )
                                                    }
                                                }
                                            }
                                        
                                                    

                                            if (typeof value === 'object') {
                                                var object_keys = ''
                                                for (var item in value) {
                                                    object_keys += value[item] + ', '
                                                }
                                                return (
                                                    <Text style={styles.contentSmall}>
                                                        MODIFIED: {referral_keys_readable[key]} {removeLastComma(object_keys)}
                                                    </Text>
                                                )
                                            }

                                            if (typeof value === 'boolean') {
                                                // don't show all unchecked SCF Quality metrics when first added
                                                if (value === true) {
                                                    return (
                                                        <Text style={styles.contentSmall}>
                                                            MODIFIED: {referral_keys_readable[key]} {'Checked'}
                                                        </Text>
                                                    )
                                                }
                                            }

                                            return null
                                        })}

                                    {log.updated &&
                                        Object.keys(log.updated).map((key) => {
                                            const value = log.updated[key]

                                            if (key === 'id' || key === 'user_access' || key === 'user_id' || key === 'date_received') return null

                                            if (typeof value === 'string') {
                                                if (value !== '') {
                                                    if (value === 'Invalid date') {
                                                        return <Text style={styles.contentSmall}>MODIFIED: {referral_keys_readable[key]} Removed</Text>
                                                    } else {
                                                        return (
                                                            <Text style={styles.contentSmall}>
                                                                MODIFIED: {referral_keys_readable[key]} {value}
                                                            </Text>
                                                        )
                                                    }
                                                }
                                            }

                                            // List of nested objects
                                            if (typeof value === 'object') {
                                                for (item in value) {
                                                    if (typeof value[item] === 'object') {
                                                        const program = value[item]['version'].split('-')
                                                        return (
                                                            <Text style={styles.contentSmall}>
                                                                MODIFIED:
                                                                <Text>
                                                                    {referral_keys_readable[key]}: {program[0]} {'>'} {program[1]}
                                                                </Text>
                                                                {Object.keys(value[item]).map((key) => {
                                                                    if (key !== 'key') {
                                                                        if (key === 'appointment_time') {
                                                                            return (
                                                                                <Text>
                                                                                    {referral_keys_readable[key]}: {moment(value[item][key]).format(time_format)}
                                                                                </Text>
                                                                            )
                                                                        }
                                                                        if (key === 'version_number' || key === 'version') {
                                                                            return
                                                                        }
                                                                        return (
                                                                            <Text>
                                                                                {referral_keys_readable[key]}: {value[item][key]}{' '}
                                                                            </Text>
                                                                        )
                                                                    }
                                                                    return null
                                                                })}
                                                            </Text>
                                                        )
                                                    }
                                                }
                                            }

                                            if (typeof value === 'object') {
                                                var object_keys = ''
                                                for (var item in value) {
                                                    object_keys += value[item] + ', '
                                                }
                                                return (
                                                    <Text style={styles.contentSmall}>
                                                        MODIFIED: {referral_keys_readable[key]} {removeLastComma(object_keys)}
                                                    </Text>
                                                )
                                            }

                                            if (typeof value === 'boolean') {
                                                return (
                                                    <Text style={styles.contentSmall}>
                                                        MODIFIED: {referral_keys_readable[key]} {value === true ? 'Checked' : 'Unchecked'}
                                                    </Text>
                                                )
                                            }

                                            return null
                                        })}
                                </View>
                            )
                        })}
                </View>
            </View>
        </Page>
    </Document>
)

export class PdfReferral extends Component {

    showDownloadConfirm = (row) => {
        Modal.info({
            title: 'Do you want to Download PDF?',
            content:
                <Row style={{ marginBottom: '1em', marginTop: '1em', textAlign: 'center' }}>
                    <Col>
                        <div>
                            <p style={{ paddingTop: '1em' }}>
                                <b>By clicking this button, you understand that once you download ConnexRX data, we cannot ensure it will be kept secure.</b> You are attempting to download a file which includes protected health information (“PHI”). We secure the data on ConnexRX servers using encryption and other technical, physical, and procedural safeguards. You understand that once you download the data, we cannot guarantee the security of that downloaded data file.
                            </p>
                            <p>
                                You must have a valid Use and Disclose reason for accessing and downloading this PHI. IF YOU DO NOT have a valid, legal reason for downloading this information, you make liable for civil penalties and/or criminal charges. PROCEED WITH CAUTION AND UNDER NOTICE.
                            </p>
                            <br />
                            <PDFDownloadLink document={<RenderDocument row={row} />} fileName={`${row.last_name.toLowerCase()}_${row.first_name.toLowerCase()}.pdf`}>
                                {({ blob, url, loading, error }) =>
                                    loading ? (
                                        'Loading document...'
                                    ) : (
                                        <Button
                                            type="primary"
                                            icon={<DownloadOutlined />}
                                            title="Please disable your ads blocker to download the file"
                                            size="large">
                                            Continue Download
                                        </Button>
                                    )
                                }
                            </PDFDownloadLink>
                        </div>
                    </Col>
                </Row>,
            okText: 'Close',
            maskClosable: true,
            destroyOnClose: true,
        });
    }
    render() {
        const { row } = this.props
        return (
            Object.keys(row).length !== 0 && (
                <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    ghost 
                    size="large"
                    onClick={() => this.showDownloadConfirm(row)}>
                    Download PDF
                </Button>
            )
        )
    }
}

export default PdfReferral
