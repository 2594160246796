import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { StepBackwardOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import MaskedInput from 'antd-mask-input'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { Breadcrumb, Form, Button, Card, Col, Descriptions, Input, Layout, Row, message, Select, Switch } from 'antd'
import { registered_project, shared_care_access } from '../../../utils/constants'

const isEmpty = require('is-empty')
const { Content } = Layout
const { Option } = Select

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { md: 24, lg: 6 },
}
class UserEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: {},
            currentName: null,
            selectedProjects: [],
            loading: true,
        }
    }

    async componentDidMount() {
        const user_id = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        const response = await axios.post('/api/users/find_user', {
            user_id: user_id,
        })
        this.setState({
            userData: response.data[0],
            currentName: response.data[0]['name'] || null,
            phoneNumber: response.data[0]['phone'] || null,
            is_twofactorauth_enabled: response.data[0]['is_twofactorauth_enabled'],
            twofactor_method: response.data[0]['twofactor_method'],
            phone: response.data[0]['phone'] | null,
        })
        this.setState({ loading: false })
    }

    onFinishChangeNameForm = async (form_values) => {
        const { name } = form_values

        if (isEmpty(name)) {
            message.error('Enter a new name in the field..', 2.5)
        } else {
            const response = await axios.post('/api/users/updatename', {
                id: this.state.userData._id,
                name: name,
            })
            if (response.status === 200 && response.data.message === 'Success') {
                this.setState({ currentName: name })
                message.success('Name successfuly changed!', 2.5)
            } else {
                message.error('Updating name failed', 2.5)
            }
        }
    }

    onFinishChangePasswordForm = async (form_values) => {
        const { new_password, confirm_password } = form_values

        if (new_password !== confirm_password) {
            message.error('The passwords do not match')
            return
        }

        if (isEmpty(confirm_password) || isEmpty(new_password)) {
            message.error('Enter a password')
            return
        }

        try {
            const response = await axios.post('/api/users/changepassword', {
                id: this.state.userData._id,
                password: new_password,
                password2: confirm_password,
            })

            if (response.status === 200 && response.data.message === 'Success') {
                message.success('Password has successfuly changed!', 2.5)
            } else {
                message.error('Update password failed!', 2.5)
            }
        } catch (error) {
            message.error('Password must be at least 6 characters')
        }
    }

    handleProjectChange = (selectedProjects) => {
        this.setState({ selectedProjects })
    }

    handle2FAStatusChange = (selectedStatus) => {
        this.setState({ twoFactorStatus: selectedStatus === 'enabled' ? true : false })
    }

    handle2FAMethodChange = (selectedMethod) => {
        this.setState({ twofactor_method: selectedMethod })
    }

    onFinishTwoFactorForm = async (form_values) => {
        const { is_twofactorauth_enabled, twofactor_method, phone } = form_values
        if (twofactor_method === 'sms' && isEmpty(phone)) {
            message.error('Enter phone number..', 2.5)
        } else {
            let userInput = {
                id: this.state.userData._id,
                is_twofactorauth_enabled: is_twofactorauth_enabled,
                twofactor_method: twofactor_method,
            }
            if (twofactor_method === 'sms') {
                userInput['phone'] = phone
            }
            const response = await axios.post('/api/users/updatetwofactorsettings', userInput)
            if (response.status === 200 && response.data.message === 'Success') {
                message.success('Update success!', 2.5)
            } else {
                message.error('Update 2FA failed', 2.5)
            }
        }
    }

    onFinishProjectForm = async (form_values) => {
        const { projects } = form_values
        if (isEmpty(projects)) {
            message.error('Enter project(s) in the field..', 2.5)
        } else {
            const response = await axios.post('/api/users/updateproject', {
                id: this.state.userData._id,
                projects,
            })
            if (response.status === 200 && response.data.message === 'Success') {
                message.success('Project successfuly changed!', 2.5)
            } else {
                message.error('Update project failed', 2.5)
            }
        }
    }

    render() {
        const { selectedProjects, userData } = this.state
        const filteredOptions = registered_project.filter((o) => !selectedProjects.includes(o))

        return (
            <Content style={{ margin: '0px 16px 0' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>User</Breadcrumb.Item>
                    <Breadcrumb.Item>Manage</Breadcrumb.Item>
                    <Breadcrumb.Item>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <Row gutter={[16, 16]}>
                    <Col>
                        <Link to="/list" key="list">
                            <Button type="primary" key="list" style={{ marginBottom: '5px' }}>
                                <StepBackwardOutlined />
                                Back
                            </Button>
                        </Link>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col>
                        <Card>
                            <Descriptions title="User Info">
                                <Descriptions.Item label="Email">{userData.email}</Descriptions.Item>
                                <Descriptions.Item label="Agency">{userData.user_type}</Descriptions.Item>
                                <Descriptions.Item label="Name">{this.state.currentName}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Card title="Change Account Information">
                            {this.state.loading === false && (
                                <Card title="Change Name">
                                    <Form {...layout} name="changeNameForm" onFinish={this.onFinishChangeNameForm}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={6}>
                                                <Form.Item name="name" initialValue={this.state.userData.name || ''}>
                                                    <Input style={{ width: 200 }} placeholder="New display name" />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="secondary" htmlType="submit" style={{borderColor: '#044268'}}>
                                                        Update
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            )}

                            {this.state.loading === false && (
                                <Card title="Change Password" style={{ marginTop: '1em' }}>
                                    <Form {...layout} name="changePasswordForm" onFinish={this.onFinishChangePasswordForm}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={6}>
                                                <Form.Item name="new_password" label="New password">
                                                    <Input.Password style={{ width: 200 }} placeholder="New password" />
                                                </Form.Item>
                                                <Form.Item name="confirm_password" label="Confirm password">
                                                    <Input.Password style={{ width: 200 }} placeholder="Confirm new password" />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="secondary" htmlType="submit" style={{borderColor: '#044268'}}>
                                                        Update
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            )}

                            {this.state.loading === false && (
                                <Card title="Change 2FA Method" style={{ marginTop: '1em' }}>
                                    <Row gutter={[12, 24]}>
                                        <Col span={8}>
                                            <Form {...layout} name="twoFactorMethodForm" onFinish={this.onFinishTwoFactorForm}>
                                                <Form.Item
                                                    label="Enable Two-Factor Authentication"
                                                    name="is_twofactorauth_enabled"
                                                    initialValue={this.state.userData.is_twofactorauth_enabled}
                                                    checked={this.state.userData.is_twofactorauth_enabled}
                                                    valuePropName="checked">
                                                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                                </Form.Item>
                                                <Form.Item label="Select Method" name="twofactor_method" initialValue={this.state.userData.twofactor_method}>
                                                    <Select style={{ width: 200 }} defaultValue={this.state.userData.twofactor_method} onChange={this.handle2FAMethodChange}>
                                                        <Option value="email">Email</Option>
                                                        <Option value="sms">SMS</Option>
                                                    </Select>
                                                </Form.Item>
                                                {this.state.twofactor_method === 'sms' && (
                                                    <Form.Item label="Phone" name="phone" initialValue={this.state.userData.phone}>
                                                        <MaskedInput
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    phoneNumber: e.target.value,
                                                                })
                                                            }
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: 200,
                                                            }}
                                                            addonBefore="+1"
                                                            mask="(111) 111-1111"
                                                            placeholder="(XXX) XXXX-XXXX"
                                                        />
                                                    </Form.Item>
                                                )}
                                                <Form.Item>
                                                    <Button type="secondary" htmlType="submit" style={{borderColor: '#044268'}}>
                                                        Update
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card>
                            )}

                            {this.state.loading === false && (
                                <Card title="Change project(s)" style={{ marginTop: '1em' }}>
                                    <Form {...layout} name="projectsForm" onFinish={this.onFinishProjectForm}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={6}>
                                                <Form.Item name="projects" initialValue={this.state.userData.projects}>
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="New project access rights"
                                                        defaultValue={this.state.userData.projects}
                                                        // onChange={this.handleProjectChange}
                                                        style={{
                                                            width: 300,
                                                        }}>
                                                        {filteredOptions.sort().map((item) => (
                                                            <Select.Option key={item} value={item}>
                                                                {item}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="secondary" htmlType="submit" style={{borderColor: '#044268'}}>
                                                        Update
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Content>
        )
    }
}

export default UserEdit
