import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import Highlighter from 'react-highlight-words'

import { Button, Card, Col, Dropdown, Divider, Input, List, message, Menu, Modal, Popconfirm, Popover, Row, Table, Tooltip, Typography } from 'antd'
import { DeleteOutlined, DownOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'

import { isMobile } from '../../../../utils/helpers'
import ModalReferral from '../modal-referral/modal-referral'

import { referral_keys_readable } from '../../../../utils/constants'
import { date_format } from '../../../../utils/constants'

const { Text, Title } = Typography

class ReferralTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clearFilterHooks: {},
            selectedRowKeys: [],
            searchText: '',
        }
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        this.setState((state) => {
            return {
                searchText: selectedKeys[0] || null,
                searchedColumn: dataIndex,
                searchExecutedCounter: state.searchExecutedCounter + 1,
            }
        })
    }

    handleReset = (clearFilters) => {
        clearFilters()
        this.setState({ searchText: '' })
    }

    getColumnSearchProps = (dataIndex, clearFilterHooks, referral_keys_readable) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            // Hooks the clearfilter function to state, and only push to object for the not exists ones.
            if (Object.keys(clearFilterHooks).indexOf(dataIndex) === -1) {
                clearFilterHooks[dataIndex] = clearFilters
            }

            return (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => {
                            this.searchInput = node
                        }}
                        placeholder={`Search ${referral_keys_readable[dataIndex]}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            this.handleReset(clearFilters)
                        }}
                        size="small"
                        style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            )
        },
        filterIcon: (filtered) => <SearchOutlined />,
        onFilter: (value, record) => {
            if (record[dataIndex] === null) {
                record[dataIndex] = ''
            }
            return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select())
            }
        },
        render: (text) => (this.state.searchedColumn === dataIndex ? <Highlighter searchWords={[this.state.searchText]} autoEscape textToHighlight={' ' + text} /> : text),
    })

    renderTeaser = (text, title) => {
        // Fix the string issue
        if (text.length > 0 && text[0].indexOf(',') > 0) {
            text = text[0].split(',').map((item) => item.trim())
        }

        const content = (
            <div>
                <List
                    size="small"
                    dataSource={text}
                    renderItem={(item) => (
                        <List.Item>
                            <Text>{item}</Text>
                        </List.Item>
                    )}
                />
            </div>
        )
        let teaser = ''

        if (text.length > 1) {
            const list_items = text
                .slice(0, 1)
                .sort()
                .map((item, i) => (
                    <Button size="small" type="primary" key={`${title}-${i}-${item}`}>
                        {item}
                    </Button>
                ))
            const popover = (
                <Popover content={content} title={title} trigger="click">
                    <Button size="small">...</Button>
                </Popover>
            )
            teaser = (
                <React.Fragment>
                    {list_items} {popover}
                </React.Fragment>
            )
        } else if (text.length === 1) {
            teaser = (
                <Button size="small" type="primary" key={`${title}-0-${text[0]}`}>
                    {text[0]}
                </Button>
            )
        } else {
            teaser = ''
        }
        return teaser
    }

    info = (record) => {
        Modal.info({
            style: { paddingLeft: 0 },
            maskClosable: true,
            title: (
                <h2>
                    {record.first_name} {record.middle_name} {record.last_name}
                </h2>
            ),
            content: <ModalReferral row={record} />,
            width: '75%',
            icon: <LegacyIcon type={''} />,
            destroyOnClose: true,
        })
        // hack to scroll top the modal
        setTimeout(() => document.getElementsByClassName('ant-modal-wrap')[0].scrollTo(0, 0), 100)
    }

    mobileActionMenu = (record) => (
        <Menu>
            {this.props.currentProject !== 'SHARED-CARE' ? (
                <React.Fragment>
                    <Menu.Item
                        onClick={() => {
                            this.info(record)
                        }}>
                        Quick Glance
                    </Menu.Item>
                    <Menu.Item
                        onClick={() =>
                            this.props.history.push(`/editreferral/${record._id}`, {
                                id: record._id,
                            })
                        }>
                        Edit
                    </Menu.Item>
                    {this.props.userType === 'CBHS' && (
                        <Menu.Item>
                            <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteReferral(record._id)}>
                                Delete
                            </Popconfirm>
                        </Menu.Item>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Menu.Item
                        onClick={() =>
                            this.props.history.push(`/editreferral/shared-care/${record._id}`, {
                                id: record._id,
                            })
                        }>
                        Edit
                    </Menu.Item>
                    {this.props.userType === 'CBHS' && (
                        <Menu.Item>
                            <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteReferral(record._id)}>
                                Delete
                            </Popconfirm>
                        </Menu.Item>
                    )}
                </React.Fragment>
            )}
        </Menu>
    )

    async deleteReferral(id) {
        // delete by id
        await axios
            .post('/api/referrals/delete_referral', {
                referral_id: id,
            })
            .then(async (resp) => {
                message.loading('Action in progress..')
                await this.props.fetchReferrals()
            })
            .then(() => {
                message.success('Referral deleted!', 2.5)
            })
            .catch((err) => {
                message.error('Error Occured' + err)
            })
    }

    makeColumn = (item) => {
        const { clearFilterHooks } = this.state
        return {
            title: item[0],
            dataIndex: item[1],
            key: item[1],
            width: item[2],
            ...this.getColumnSearchProps(item[1], clearFilterHooks, referral_keys_readable),
            render: (text, record) => {
                // console.log('item', item, 'text', text, 'record', record)
                if (item[1] === 'address_valid') {
                    const validationFieldKeys = [
                        ['Address', 'address_valid'],
                        ['Primary Phone', 'primary_phone_valid'],
                        ['Secondary Phone', 'secondary_phone_valid'],
                    ]
                    const renderFields = validationFieldKeys.map((item, i) => {
                        if (record[item[1]] === true)
                            return (
                                <Button size="small" type="primary" key={`${item[1]}-${i}`}>
                                    {item[0]}
                                </Button>
                            )
                        return false
                    })
                    return <>{renderFields}</>
                } else if (item[1] === 'referral_agency' && record[item[1]] === '') {
                    return <Text>None</Text>
                } else if (item[1] === 'date_received') {
                    text = moment(text).format(date_format)
                    return <Text>{text}</Text> 
                } else {
                    text = typeof text === 'boolean' ? (text === true ? 'Yes' : 'No') : text
                    if (item[3] === true) {
                        return this.renderTeaser(text, item[0])
                    } else {
                        return <Text>{text}</Text>
                    }
                }
            },
        }
    }

    generateTableColumns = () => {
        const tableColumns = {
            HCBS: [
                ['ID', 'nano_id', 250, false],
                ['Last', 'last_name', 200, false],
                ['First', 'first_name', 150, false],
                ['Middle', 'middle_name', 150, false],
                ['DOB', 'dob_string', 150, false],
                ['County', 'county', 150, false],
                ['Source', 'referral_source', 200, false],
                ['Need', 'referral_need', 150, true],
                ['Received', 'date_received', 150, false],
                ['Date Assigned', 'date_assigned', 150, false],
                ['Agency', 'referral_agency', 150, false],
                ['HARP', 'assessment_harp', 150, false],
                ['Days Lapsed', 'time_lapsed', 150, false],
                ['Insurance', 'insurance_1', 150, false],
                ['Status', 'referral_status', 150, false],
                ['Validity: Unsuccessful', 'address_valid', 200, false],
                ['Appt. Date', 'appointment_date', 150, false],
            ],
            TOC: [
                ['ID', 'nano_id', 250, false],
                ['Last', 'last_name', 200, false],
                ['First', 'first_name', 150, false],
                ['Middle', 'middle_name', 150, false],
                ['DOB', 'dob_string', 150, false],
                ['County', 'county', 150, false],
                ['Source', 'referral_source', 200, false],
                ['Need', 'referral_need', 150, true],
                ['Received', 'date_received', 150, false],
                ['Date Assigned', 'date_assigned', 150, false],
                ['Agency', 'referral_agency', 150, false],
                ['HARP', 'assessment_harp', 150, false],
                ['Days Lapsed', 'time_lapsed', 150, false],
                ['Contact Needs', 'contact_needs', 200, true],
                ['Contact Type', 'contact_type', 200, true],
                ['Insurance', 'insurance_1', 150, false],
                ['Status', 'referral_status', 150, false],
                ['Validity: Unsuccessful', 'address_valid', 200, false],
                ['Appt. Date', 'appointment_date', 150, false],
            ],
            CMO: [
                ['ID', 'nano_id', 250, false],
                ['Last', 'last_name', 200, false],
                ['First', 'first_name', 150, false],
                ['Middle', 'middle_name', 150, false],
                ['DOB', 'dob_string', 150, false],
                ['County', 'county', 150, false],
                ['Source', 'referral_source', 200, false],
                ['Need', 'referral_need', 150, true],
                ['Received', 'date_received', 150, false],
                ['Date Assigned', 'date_assigned', 150, false],
                ['Agency', 'referral_agency', 150, false],
                ['HARP', 'assessment_harp', 150, false],
                ['Days Lapsed', 'time_lapsed', 150, false],
                ['Insurance', 'insurance_1', 150, false],
                ['Status', 'referral_status', 150, false],
                ['Validity: Unsuccessful', 'address_valid', 200, false],
                ['Appt. Date', 'appointment_date', 150, false],
            ],
            COVID: [
                ['ID', 'nano_id', 250, false],
                ['Last', 'last_name', 200, false],
                ['First', 'first_name', 150, false],
                ['Middle', 'middle_name', 150, false],
                ['DOB', 'dob_string', 150, false],
                ['County', 'county', 150, false],
                ['Source', 'referral_source', 200, false],
                ['Need', 'referral_need', 150, true],
                ['Received', 'date_received', 150, false],
                ['Date Assigned', 'date_assigned', 150, false],
                ['Agency', 'referral_agency', 150, false],
                ['HARP', 'assessment_harp', 150, false],
                ['Days Lapsed', 'time_lapsed', 150, false],
                ['Contact Needs', 'contact_needs', 200, true],
                ['Contact Type', 'contact_type', 200, true],
                ['Insurance', 'insurance_1', 150, false],
                ['Status', 'referral_status', 150, false],
                ['Validity: Unsuccessful', 'address_valid', 200, false],
                ['Appt. Date', 'appointment_date', 150, false],
            ],
            'CBHC-MVP': [
                ['ID', 'nano_id', 250, false],
                ['Last', 'last_name', 200, false],
                ['First', 'first_name', 150, false],
                ['Middle', 'middle_name', 150, false],
                ['DOB', 'dob_string', 150, false],
                ['County', 'county', 150, false],
                ['Received', 'date_received', 150, false],
                ['Date Assigned', 'date_assigned', 150, false],
                ['Agency', 'referral_agency', 150, false],
                ['Health Home Enrolled', 'health_home_enrolled', 150, false],
                ['Health Home Facility', 'health_home_facility', 150, false],
                ['Health Home Enrollment Date', 'health_home_enrollment_date', 150, false],
                ['Care Management Enrolled', 'care_management_enrolled', 150, false],
                ['Care Management Facility', 'care_management_facility', 150, false],
                ['Care Management Enrollment Date', 'care_management_enrollment_date', 150, false],
                ['Engagement Contact Type', 'engagement_contact_type', 150, false],
                ['Engagement Date', 'engagement_date', 150, false],
                ['Engagement Time', 'engagement_time', 150, false],
                ['Engagement Number of Contacts', 'engagement_number_of_contacts', 150, false],
                ['Engagement Contact Details', 'engagement_contact_details', 150, false],
                ['Insurance', 'insurance_1', 150, false],
                ['Validity: Unsuccessful', 'address_valid', 200, false],
                ['Appt. Date', 'appointment_date', 150, false],
            ],
            'SHARED-CARE': [
                ['ID', 'nano_id', 250, false],
                ['Last', 'last_name', 200, false],
                ['First', 'first_name', 150, false],
                ['Middle', 'middle_name', 150, false],
                ['DOB', 'dob_string', 150, false],
                ['County', 'county', 150, false],
                ['Received', 'date_received', 150, false],
                ['Insurance', 'insurance_1', 150, false],
            ],
        }

        const actionColumns = [
            {
                title: 'Actions',
                key: 'action',
                fixed: isMobile() ? false : 'right',
                width: 120,
                render: (text, record) =>
                    isMobile() ? (
                        <Dropdown overlay={this.mobileActionMenu(record)} trigger={['click', 'hover']}>
                            <p className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                Actions <DownOutlined />
                            </p>
                        </Dropdown>
                    ) : (
                        <span>
                            {this.props.currentProject !== 'SHARED-CARE' ? (
                                <React.Fragment>
                                    <Tooltip title="Quick glance">
                                        <SearchOutlined
                                            onClick={() => {
                                                this.info(record)
                                            }}
                                        />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Edit">
                                        <EditOutlined
                                            onClick={() =>
                                                this.props.history.push(`/editreferral/${record._id}`, {
                                                    id: record._id,
                                                })
                                            }
                                        />
                                    </Tooltip>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Tooltip title="Quick glance">
                                        <SearchOutlined
                                            onClick={() => {
                                                this.info(record)
                                            }}
                                        />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Edit">
                                        <EditOutlined
                                            onClick={() =>
                                                this.props.history.push(`/editreferral/shared-care/${record._id}`, {
                                                    id: record._id,
                                                })
                                            }
                                        />
                                    </Tooltip>
                                </React.Fragment>
                            )}
                            {this.props.userType === 'CBHS' && (
                                <>
                                    <Divider type="vertical" />
                                    <Tooltip title="Delete">
                                        <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteReferral(record._id)}>
                                            <DeleteOutlined />
                                        </Popconfirm>
                                    </Tooltip>
                                </>
                            )}
                        </span>
                    ),
            },
        ]

        let selectedColumns = tableColumns[this.props.currentProject]
        let initColumns = selectedColumns.map((col) => this.makeColumn(col))
        return [...initColumns, ...actionColumns]
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.props.selectedRowKeys,
            onChange: this.props.onSelectChange,
        }

        return (
            <Card className="site-layout-background" style={{ margin: '20px 16px 0', minHeight: '50vh' }}>
                <Row gutter={[12, 12]}>
                    <Col span={24}>
                        <Title level={4}>View / Edit Referrals</Title>
                        <Text>Search columns for referrals and use edit or quick glance actions. </Text>
                    </Col>
                    <Col span={24}>
                        {this.props.currentProject !== 'SHARED-CARE' && (
                            <React.Fragment>
                                <div style={{ marginBottom: '1em' }}>
                                    <Text>
                                        {this.props.hasSelected
                                            ? `Selected ${this.props.selectedRowKeys.length}
                                Items`
                                            : 'Selected 0 Items'}
                                    </Text>
                                </div>
                            </React.Fragment>
                        )}

                        <Table
                            rowSelection={rowSelection}
                            columns={this.generateTableColumns()}
                            dataSource={this.props.referrals[this.props.status]}
                            pagination={{
                                pageSize: 50,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            }}
                            scroll={{ x: 1500, y: 700 }}
                            size="default"
                            rowKey="_id"
                            bordered
                        />
                    </Col>
                </Row>
            </Card>
        )
    }
}

export default ReferralTable
