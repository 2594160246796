import React, { Component } from 'react'
import { Card, Col, Checkbox, DatePicker, Form, Input, Row, Select, Switch } from 'antd'
import MaskedInput from 'antd-mask-input'
import styled, { css } from 'styled-components'

import { address_type, countys_list, county_zip_codes, genders, ethnicity, languages_list, emergency_contact_relationship } from '../../../../../utils/constants'
import { checkMomentDate, isValidPhone } from '../../../../../utils/helpers'

const { Option } = Select
const CustomCheckbox = styled(Checkbox)`
    ${(props) =>
        props.backgroundColor &&
        css`
            & .ant-checkbox-checked .ant-checkbox-inner {
                background-color: ${props.backgroundColor};
                border-color: ${props.backgroundColor};
            }
        `}
`

class ClientInformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addressRequiredFields: {
                address_1: true,
                address_2: true,
                city: true,
                state: true,
                zip: true,
                county: true,
                phone: true,
            },
            currentAddressType: null,
            mailing_address: false,
            client_details_disabled: true,
            emergency_contact: false,
            is_guardian: this.props.original.is_guardian === 'Y' ? true : false,
        }
    }

    handleClientDetailsToggle = () => {
        this.setState({
            client_details_disabled: !this.state.client_details_disabled,
        })
    }

    handleAddressTypeOnChange = (e) => {
        this.setState({ currentAddressType: e })
        const requiredFields = address_type[e].required_fields

        if (e === 'Shelter') {
            this.setState({ showShelterNameField: true })
        } else {
            this.setState({ showShelterNameField: false })
        }

        if (requiredFields.length === 0) {
            this.setState({
                addressRequiredFields: {
                    address_1: false,
                    address_2: false,
                    city: false,
                    state: false,
                    zip: false,
                    county: false,
                    phone: false,
                },
            })
        } else if (requiredFields[0] === 'all') {
            this.setState({
                addressRequiredFields: {
                    address_1: true,
                    address_2: true,
                    city: true,
                    state: true,
                    zip: true,
                    county: true,
                    phone: true,
                },
            })
        } else {
            this.setState({
                addressRequiredFields: {
                    address_1: requiredFields.includes('address_1') ? true : false,
                    address_2: requiredFields.includes('address_2') ? true : false,
                    city: requiredFields.includes('city') ? true : false,
                    state: requiredFields.includes('state') ? true : false,
                    zip: requiredFields.includes('zip') ? true : false,
                    county: requiredFields.includes('county') ? true : false,
                    phone: requiredFields.includes('phone') ? true : false,
                },
            })
        }
    }

    handleZipCodeCounty = (e) => {
        e.preventDefault()
        this.props.formRef.current.setFieldsValue({
            county: county_zip_codes[e.target.value],
        })
    }

    render() {
        return (
            <React.Fragment>
                <Card
                    title="Client Information"
                    bordered={false}
                    extra={<Switch checkedChildren="Locked" unCheckedChildren="Unlocked" checked={this.state.client_details_disabled} onChange={this.handleClientDetailsToggle} />}
                    style={{ width: '-webkit-fill-available' }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <Checkbox
                                disabled={this.state.client_details_disabled}
                                checked={this.state.is_guardian}
                                style={{ paddingBottom: '16px' }}
                                onClick={() => {
                                    this.setState({ is_guardian: !this.state.is_guardian })
                                }}>
                                Check here if you are answering on behalf of a minor or as a legal guardian
                            </Checkbox>
                        </Col>
                    </Row>
                    {this.state.is_guardian && (
                        <React.Fragment>
                            <Row gutter={[16, 24]}>
                                <Col xs={24} sm={24} md={12} lg={7}>
                                    <Form.Item
                                        name="guardian_last_name"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        label="Guardian Last Name"
                                        labelAlign="left"
                                        style={{ flexDirection: 'column' }}
                                        initialValue={this.props.original.guardian_last_name}>
                                        <Input
                                            disabled={this.state.client_details_disabled}
                                            onKeyPress={(e) => {
                                                e.key === 'Enter' && e.preventDefault()
                                            }}
                                            placeholder="Guardian Last Name"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                                    <Form.Item
                                        name="guardian_first_name"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        label="Guardian First Name"
                                        labelAlign="left"
                                        style={{ flexDirection: 'column' }}
                                        initialValue={this.props.original.guardian_first_name}>
                                        <Input
                                            disabled={this.state.client_details_disabled}
                                            onKeyPress={(e) => {
                                                e.key === 'Enter' && e.preventDefault()
                                            }}
                                            placeholder="Guardian First Name"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <p>Please answer the remaining questions on behalf of the individual in your care. You will be contacted for further information.</p>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Form.Item
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Last Name Required',
                                    },
                                ]}
                                label="Last Name"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.last_name}>
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder=""
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'First Name Required',
                                    },
                                ]}
                                label="First Name"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.first_name}>
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder=""
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="middle_name"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Middle Name"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.middle_name}>
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder=" "
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Form.Item
                                name="dob"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Date of Birth Required',
                                    },
                                ]}
                                label="Date of Birth"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={checkMomentDate(this.props.original.dob)}>
                                <DatePicker disabled={this.state.client_details_disabled} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="cin"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="CIN/Medicaid #"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.cin}>
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder=" "
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Gender Required',
                                    },
                                ]}
                                label="Gender"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.gender}>
                                <Select
                                    disabled={this.state.client_details_disabled}
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {genders.sort().map((gender) => (
                                        <Option key={gender}>{gender}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Form.Item
                                name="language"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Language Required',
                                    },
                                ]}
                                label="Language"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.language}>
                                <Select
                                    disabled={this.state.client_details_disabled}
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {languages_list.sort().map((language) => (
                                        <Option key={language}>{language}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="ethnicity"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Ethnicity Required',
                                    },
                                ]}
                                label="Ethnicity"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.ethnicity}>
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch
                                    disabled={this.state.client_details_disabled}
                                    placeholder="Select an option"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {ethnicity.sort().map((ethnicity) => (
                                        <Option key={ethnicity}>{ethnicity}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="email_client"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Email"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.email_client}>
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    placeholder=" "
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item
                                name="active_duty"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Active Duty"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.active_duty}
                                checked={this.props.original.active_duty}
                                valuePropName="checked">
                                <Switch disabled={this.state.client_details_disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={{ span: 6, offset: 2 }}>
                            <Form.Item
                                name="veteran"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Veteran"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.veteran}
                                checked={this.props.original.veteran}
                                valuePropName="checked">
                                <Switch disabled={this.state.client_details_disabled} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title="Address Details" bordered={false} style={{ width: '-webkit-fill-available' }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Checkbox
                                disabled={this.state.client_details_disabled}
                                checked={this.state.mailing_address}
                                style={{ paddingBottom: '16px' }}
                                onClick={() => {
                                    this.setState({ mailing_address: !this.state.mailing_address })
                                }}>
                                Check to add a mailing address
                            </Checkbox>
                        </Col>
                    </Row>
                    {this.state.mailing_address && (
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={7}>
                                <Form.Item
                                    name="mailing_address_1"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    label="Mailing Address 1"
                                    labelAlign="left"
                                    style={{ flexDirection: 'column' }}
                                    initialValue={this.props.original.mailing_address_1}>
                                    <Input
                                        disabled={this.state.client_details_disabled}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        placeholder=""
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                                <Form.Item
                                    name="mailing_address_2"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    label="Mailing Address 2"
                                    labelAlign="left"
                                    style={{ flexDirection: 'column' }}
                                    initialValue={this.props.original.mailing_address_2}>
                                    <Input
                                        disabled={this.state.client_details_disabled}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        placeholder=""
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                                <Form.Item
                                    name="mailing_city"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    label="Mailing City"
                                    labelAlign="left"
                                    style={{ flexDirection: 'column' }}
                                    initialValue={this.props.original.mailing_city}>
                                    <Input
                                        disabled={this.state.client_details_disabled}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        placeholder=""
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={7}>
                                <Form.Item
                                    name="mailing_state"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    initialValue={this.props.original.state}
                                    label="Mailing State"
                                    labelAlign="left"
                                    style={{ flexDirection: 'column', paddingBottom: '16px' }}>
                                    <Select
                                        disabled={this.state.client_details_disabled}
                                        style={{
                                            width: '100%',
                                        }}
                                        showSearch
                                        placeholder=""
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        <Option value="New York">New York</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                                <Form.Item
                                    name="mailing_zip"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    label="Mailing ZIP"
                                    labelAlign="left"
                                    style={{ flexDirection: 'column', paddingBottom: '16px' }}
                                    initialValue={this.props.original.mailing_zip}>
                                    <Input
                                        disabled={this.state.client_details_disabled}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        placeholder=""
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                                <Form.Item
                                    name="mailing_county"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    label="Mailing County"
                                    labelAlign="left"
                                    style={{ flexDirection: 'column', paddingBottom: '16px' }}
                                    initialValue={this.props.original.mailing_county}>
                                    <Select
                                        disabled={this.state.client_details_disabled}
                                        style={{
                                            width: '100%',
                                        }}
                                        showSearch
                                        placeholder=""
                                        optionFilterProp="children"
                                        defaultActiveFirstOption={true}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {countys_list.sort().map((county) => (
                                            <Option key={county}>{county}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Form.Item
                                name="address_type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Address Required!',
                                    },
                                ]}
                                label="Address Type"
                                labelAlign="left"
                                style={{ flexDirection: 'column', paddingBottom: '12px' }}
                                initialValue={this.props.original.address_type}>
                                <Select
                                    disabled={this.state.client_details_disabled}
                                    onChange={(e) => {
                                        this.handleAddressTypeOnChange(e)
                                    }}
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch
                                    placeholder="Primary Residence"
                                    optionFilterProp="children"
                                    defaultActiveFirstOption={true}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {Object.keys(address_type).map((key) => (
                                        <Option key={key}>{key}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {this.state.showShelterNameField === true && (
                            <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                                <Form.Item
                                    name="shelter_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Shelter required!',
                                        },
                                    ]}
                                    valuePropName="checked"
                                    label="Shelter Name"
                                    labelAlign="left"
                                    style={{ flexDirection: 'column' }}
                                    initialValue={this.props.original.shelter_name}>
                                    <Input
                                        disabled={this.state.client_details_disabled}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="address_valid"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                valuePropName="checked"
                                label="Address Validity"
                                style={{ span: '12' }}
                                initialValue={this.props.original.address_valid}>
                                <CustomCheckbox disabled={this.state.client_details_disabled} backgroundColor="red">
                                    Unsuccessful
                                </CustomCheckbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} lg={7}>
                            <Form.Item
                                name="address_1"
                                rules={[
                                    {
                                        required: this.state.addressRequiredFields.address_1,
                                        message: 'Address 1 Required!',
                                    },
                                ]}
                                label="Address 1"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.address_1}>
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="address_2"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Address 2"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.address_2}>
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder=" "
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="city"
                                rules={[
                                    {
                                        required: this.state.addressRequiredFields.city,
                                        message: 'City required!',
                                    },
                                ]}
                                label="City"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.city}>
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24} lg={7}>
                            <Form.Item
                                name="state"
                                rules={[
                                    {
                                        required: this.state.addressRequiredFields.state,
                                        message: 'State required!',
                                    },
                                ]}
                                label="State"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.state}>
                                <Select
                                    disabled={this.state.client_details_disabled}
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    <Option value="New York">New York</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="zip"
                                rules={[
                                    {
                                        required: this.state.addressRequiredFields.zip,
                                        message: 'ZIP Required!',
                                    },
                                ]}
                                label="ZIP"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.zip}>
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    onChange={(e) => this.handleZipCodeCounty(e)}
                                    placeholder=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="county"
                                rules={[
                                    {
                                        required: this.state.addressRequiredFields.county,
                                        message: 'County Required!',
                                    },
                                ]}
                                label="County"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.county}>
                                <Select
                                    disabled={this.state.client_details_disabled}
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder=""
                                    optionFilterProp="children"
                                    defaultActiveFirstOption={true}>
                                    {countys_list.sort().map((county) => (
                                        <Option key={county}>{county}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title="Phone Details" bordered={false} style={{ width: '-webkit-fill-available' }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Checkbox
                                checked={this.state.emergency_contact}
                                style={{ paddingBottom: '16px' }}
                                disabled={this.state.client_details_disabled}
                                onClick={() => {
                                    this.setState({ emergency_contact: !this.state.emergency_contact })
                                }}>
                                Check to add an emergency contact
                            </Checkbox>
                        </Col>
                    </Row>
                    {this.state.emergency_contact && (
                        <React.Fragment>
                            <Row gutter={[16, 24]}>
                                <Col xs={24} sm={24} md={12} lg={7}>
                                    <Form.Item
                                        name="emergency_contact_first_name"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        label="Emergency Contact First Name"
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', paddingBottom: '12px' }}
                                        initialValue={this.props.original.emergency_contact_first_name}>
                                        <Input
                                            onKeyPress={(e) => {
                                                e.key === 'Enter' && e.preventDefault()
                                            }}
                                            placeholder=" "
                                            disabled={this.state.client_details_disabled}
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                                    <Form.Item
                                        name="emergency_contact_last_name"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        label="Emergency Contact Last Name"
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', paddingBottom: '12px' }}
                                        initialValue={this.props.original.emergency_contact_last_name}>
                                        <Input
                                            onKeyPress={(e) => {
                                                e.key === 'Enter' && e.preventDefault()
                                            }}
                                            placeholder=" "
                                            disabled={this.state.client_details_disabled}
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={24} sm={24} md={12} lg={7}>
                                    <Form.Item
                                        name="emergency_contact_phone"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        label="Emergency Contact Phone"
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', paddingBottom: '16px' }}
                                        initialValue={this.props.original.emergency_contact_phone}>
                                        <MaskedInput
                                            onKeyPress={(e) => {
                                                e.key === 'Enter' && e.preventDefault()
                                            }}
                                            style={{
                                                width: '100%',
                                            }}
                                            disabled={this.state.client_details_disabled}
                                            mask="(111) 111-1111"
                                            placeholder=" "
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                                    <Form.Item
                                        name="emergency_contact_relationship"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        label="Emergency Contact Relationship"
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', paddingBottom: '16px' }}
                                        initialValue={this.props.original.emergency_contact_relationship}>
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            showSearch
                                            placeholder=" "
                                            disabled={this.state.client_details_disabled}
                                            optionFilterProp="children"
                                            defaultActiveFirstOption={true}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {emergency_contact_relationship.sort().map((county) => (
                                                <Option key={county}>{county}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: this.state.addressRequiredFields.phone,
                                    },
                                    {
                                        validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                    },
                                ]}
                                label="Primary Phone"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.phone}>
                                <MaskedInput
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    style={{
                                        width: '100%',
                                    }}
                                    mask="(111) 111-1111"
                                    placeholder=" "
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="primary_phone_valid"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                valuePropName="checked"
                                label="Primary Phone Validity"
                                style={{ span: '12' }}
                                initialValue={this.props.original.primary_phone_valid}>
                                <CustomCheckbox disabled={this.state.client_details_disabled} backgroundColor="red">
                                    Unsuccessful
                                </CustomCheckbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Form.Item
                                name="phone_secondary"
                                rules={[
                                    {
                                        required: false,
                                    },
                                    {
                                        validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                    },
                                ]}
                                label="Secondary Phone"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.phone_secondary}>
                                <MaskedInput
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    style={{
                                        width: '100%',
                                    }}
                                    mask="(111) 111-1111"
                                    placeholder=" "
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="secondary_phone_valid"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                valuePropName="checked"
                                label="Secondary Phone Validity"
                                style={{ span: '12' }}
                                initialValue={this.props.original.secondary_phone_valid}>
                                <CustomCheckbox disabled={this.state.client_details_disabled} backgroundColor="red">
                                    Unsuccessful
                                </CustomCheckbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Form.Item
                                name="leave_message"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Okay to leave message?"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}
                                initialValue={this.props.original.leave_message}>
                                <Select
                                    disabled={this.state.client_details_disabled}
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Select an option"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </React.Fragment>
        )
    }
}

export default ClientInformation
