import React from 'react'

import { Button, Col, Row, Popconfirm, Table, Typography } from 'antd'

const { Title, Text } = Typography

const PreviewData = (props) => {
    let columns_main = [
        {
            title: 'Referral Need',
            dataIndex: 'referral_need',
            key: 'referral_need',
            width: 200,
        },
        {
            title: 'Insurance',
            dataIndex: 'insurance_1',
            key: 'insurance_1',
            width: 150,
        },
        {
            title: 'Agency',
            dataIndex: 'referral_agency',
            key: 'referral_agency',
            width: 150,
        },
        {
            title: 'HARP',
            dataIndex: 'assessment_harp',
            key: 'assessment_harp',
            width: 150,
        },
        {
            title: 'CIN',
            dataIndex: 'cin',
            key: 'cin',
            width: 150,
        },
        {
            title: 'Last',
            dataIndex: 'last_name',
            key: 'last_name',
            width: 200,
        },
        {
            title: 'First',
            dataIndex: 'first_name',
            key: 'first_name',
            width: 150,
        },
        {
            title: 'Middle',
            dataIndex: 'middle_name',
            key: 'middle_name',
            width: 150,
        },
        {
            title: 'DOB',
            dataIndex: 'dob',
            key: 'dob',
            width: 150,
        },
        {
            title: 'Address 1',
            dataIndex: 'address_1',
            key: 'address_1',
            width: 150,
        },
        {
            title: 'Address 2',
            dataIndex: 'address_2',
            key: 'address_2',
            width: 150,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: 150,
        },
        {
            title: 'Ethnicity',
            dataIndex: 'ethnicity',
            key: 'ethnicity',
            width: 150,
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
            width: 150,
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: 150,
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            width: 150,
        },
        {
            title: 'Zip',
            dataIndex: 'zip',
            key: 'zip',
            width: 150,
        },
        {
            title: 'County',
            dataIndex: 'county',
            key: 'county',
            width: 150,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: 200,
        },
        {
            title: 'Referral Source',
            dataIndex: 'referral_source',
            key: 'referral_source',
            width: 200,
        },
        {
            title: 'Referral Source Email',
            dataIndex: 'referral_source_email',
            key: 'referral_source_email',
            width: 200,
        },
        {
            title: 'Referral Source Phone',
            dataIndex: 'referral_source_phone',
            key: 'referral_source_phone',
            width: 200,
        },
        {
            title: 'Referral Status',
            dataIndex: 'referral_status',
            key: 'referral_status',
            width: 200,
        },
        {
            title: 'Received',
            dataIndex: 'date_received',
            key: 'date_received',
            width: 150,
        },
    ]

    let columns_care_management = [
        {
            title: 'Contact Needs',
            dataIndex: 'contact_needs',
            key: 'contact_needs',
            width: 150,
        },
        {
            title: 'Contact Type',
            dataIndex: 'contact_type',
            key: 'contact_type',
            width: 150,
        },
    ]

    const columns_TOC = [...columns_main, ...columns_care_management]
    const columns_HCBS = [...columns_main]
    const columns_CBHCMVP = [
        {
            title: 'Referral Need',
            dataIndex: 'referral_need',
            key: 'referral_need',
            width: 200,
        },
        {
            title: 'Insurance',
            dataIndex: 'insurance_1',
            key: 'insurance_1',
            width: 150,
        },
        {
            title: 'Assigned CMA',
            dataIndex: 'referral_agency',
            key: 'referral_agency',
            width: 150,
        },
        {
            title: 'CIN',
            dataIndex: 'cin',
            key: 'cin',
            width: 150,
        },
        {
            title: 'Last',
            dataIndex: 'last_name',
            key: 'last_name',
            width: 200,
        },
        {
            title: 'First',
            dataIndex: 'first_name',
            key: 'first_name',
            width: 150,
        },
        {
            title: 'Middle',
            dataIndex: 'middle_name',
            key: 'middle_name',
            width: 150,
        },
        {
            title: 'DOB',
            dataIndex: 'dob',
            key: 'dob',
            width: 150,
        },
        {
            title: 'Address 1',
            dataIndex: 'address_1',
            key: 'address_1',
            width: 150,
        },
        {
            title: 'Address 2',
            dataIndex: 'address_2',
            key: 'address_2',
            width: 150,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: 150,
        },
        {
            title: 'Ethnicity',
            dataIndex: 'ethnicity',
            key: 'ethnicity',
            width: 150,
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
            width: 150,
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: 150,
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            width: 150,
        },
        {
            title: 'Zip',
            dataIndex: 'zip',
            key: 'zip',
            width: 150,
        },
        {
            title: 'County',
            dataIndex: 'county',
            key: 'county',
            width: 150,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: 200,
        },
        {
            title: 'Received',
            dataIndex: 'date_received',
            key: 'date_received',
            width: 150,
        },
        {
            title: 'Health Home Facility',
            dataIndex: 'health_home_facility',
            key: 'health_home_facility',
            width: 150,
        },
        {
            title: 'Care Management Facility',
            dataIndex: 'care_management_facility',
            key: 'care_management_facility',
            width: 150,
        },
    ]

    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} style={{marginBottom: '5px'}}>
                    <Title level={2} style={{paddingTop: '15px'}}>Preview Data</Title>
                    <Text>Ensure the data is accurate before uploading</Text>
                </Col>
            </Row>

            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    {props.project === 'HCBS' && <Table columns={columns_HCBS} dataSource={props.parsedData} pagination={false} bordered scroll={{ x: 1500, y: 800 }} style={{marginBottom: '15px'}} />}
                    {props.project === 'TOC' && <Table columns={columns_TOC} dataSource={props.parsedData} pagination={false} bordered scroll={{ x: 1500, y: 800 }} style={{marginBottom: '15px'}} />}
                    {props.project === 'CMO' && <Table columns={columns_HCBS} dataSource={props.parsedData} pagination={false} bordered scroll={{ x: 1500, y: 800 }} style={{marginBottom: '15px'}} />}
                    {props.project === 'CBHC-MVP' && <Table columns={columns_CBHCMVP} dataSource={props.parsedData} pagination={false} bordered scroll={{ x: 1500, y: 800 }}  style={{marginBottom: '15px'}} />}
                </Col>
            </Row>

            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Button type="primary" disabled={!props.project} onClick={props.next} style={{marginRight: '5px'}}>
                        Next
                    </Button>
                    <Popconfirm title="Are you sure you want to start over?" onConfirm={props.clear}>
                        <Button type="secondary" disabled={!props.project} >
                            Start Over
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default PreviewData
