import React, { Component } from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { Layout, Breadcrumb, Row, Col, Card, message, Steps } from 'antd'
import Papa from 'papaparse'
import { formatPhoneNumber } from '../../../utils/helpers'

import { date_format } from '../../../utils/constants'

import SelectProject from './steps/1-select-project'
import DownloadSample from './steps/2-download-sample'
import DownloadFields from './steps/3-download-fields'
import UploadCsvFile from './steps/4-upload-csv-file'
import PreviewData from './steps/5-preview-data'
import CheckDuplicates from './steps/6-check-duplicates'
import ConfirmData from './steps/7-confirm-data'

const { Content } = Layout
const { Step } = Steps

const steps = [
    {
        title: 'Select Project',
        content: 'select-project',
    },
    {
        title: 'Get Sample',
        content: 'download-sample',
    },
    {
        title: 'Check Fields',
        content: 'download-fields',
    },
    {
        title: 'Upload File',
        content: 'upload-csv',
    },
    {
        title: 'Preview',
        content: 'preview-data',
    },
    {
        title: 'Check Dups',
        content: 'check-duplicates',
    },
    {
        title: 'Confirm',
        content: 'confirm',
    },
]

class UploadCSV extends Component {
    constructor(props) {
        super()
        this.state = {
            current: 0,
            fileList: [],
            parsedData: [],
            project: null,
            uploadFile: null,
            message: '',
            duplicates: [],
            duplicatesToBeUpdated: [],
        }
    }

    handleFormProcess = async () => {
        const { fileList } = this.state
        const formData = new FormData()
        formData.append('file', fileList[0])

        Papa.parse(fileList[0], {
            skipEmptyLines: true,
            dynamicTyping: false,
            header: true,
            encoding: 'CP1252',
            complete: this.updateData,
        })
    }

    initS3Upload = (file) => {
        const generatePutUrl = '/api/uploader/generate-put-url'
        const fileExtension = file.name.split('.')[1]
        const protectedFilename = `${uuidv4()}.${fileExtension}`
        const contentType = file.type
        const options = {
            params: {
                Key: protectedFilename,
                ContentType: contentType,
            },
            headers: {
                'Content-Type': contentType,
            },
        }

        this.setState((prevState) => ({
            uploadFile: {
                ...prevState.uploadFile,
                file: file,
                generatePutUrl: generatePutUrl,
                fileExtension: fileExtension,
                protectedFilename: protectedFilename,
                contentType: contentType,
                options: options,
            },
        }))

        return protectedFilename
    }

    handleS3Upload = async () => {
        const {
            uploadFile: { file, generatePutUrl, options },
        } = this.state
        this.setState({ message: 'Uploading...' })

        // s3 don't accept Authorization header so we must remove it (DON'T REMOVE BELOW LINE)
        delete axios.defaults.headers.common['Authorization']

        await axios.get(generatePutUrl, options).then(async (res) => {
            const {
                data: { putURL },
            } = res
            await axios
                .put(putURL, file, options)
                .then((res) => {
                    this.setState({ message: 'Upload Successful' })
                    console.log('Upload Successful')
                    setTimeout(() => {
                        this.setState({ message: '' })
                    }, 2000)
                })
                .catch((err) => {
                    this.setState({ message: 'Sorry, something went wrong' })
                    console.log('err', err)
                })
        })
    }

    updateData = async (parsedData) => {
        const { fileList } = this.state
        const protectedFilename = await this.initS3Upload(fileList[0])

        let data = parsedData.data
        data.forEach((ref) => {
            ref['phone'] = formatPhoneNumber(ref['phone'])
            ref['referral_source_phone'] = formatPhoneNumber(ref['referral_source_phone'])
            ref['dob'] = moment(ref['dob']).format(date_format)
            ref['date_received'] = moment().format(date_format)
            ref['project'] = [this.state.project]
            ref['original_filename'] = fileList[0].name
            ref['protected_filename'] = protectedFilename

            if (this.state.project === 'CBHC-MVP') {
                ref['referral_agency'] = ref['assigned_cma']
                if (ref['health_home_facility']) {
                    ref['health_home_enrolled'] = true
                }
                if (ref['care_management_facility']) {
                    ref['care_management_enrolled'] = true
                }
            }
        })
        this.setState({
            parsedData: data,
        })
        this.next()
    }

    handleUploadData = async () => {
        const { parsedData, duplicatesToBeUpdated } = this.state
        try {
            if (process.env.NODE_ENV === 'production') {
                await this.handleS3Upload()
            }
            await this.handleS3Upload()
            await axios.post('/api/referrals/upload_referrals', {
                parsedData,
                duplicatesToBeUpdated,
                user_id: this.props.auth.user.id,
            })
            message.success('File has been uploaded!')
            axios.defaults.headers.common['Authorization'] = localStorage.jwtToken
            this.props.history.push('/dashboard')
        } catch (err) {
            message.error('Failed to upload file: ' + err)
            console.log(err)
        }
    }

    checkForDuplicates = async () => {
        const { parsedData } = this.state
        var parsedDataJSON = { parsedData: parsedData }
        try {
            const response = await axios.post('/api/referrals/upload/duplicates', parsedDataJSON)
            response.data.duplicates.forEach((e) => {
                e.key = e._id
            })
            this.setState({
                duplicates: response.data.duplicates,
                numOfDuplicates: response.data.numOfDuplicates,
            })
        } catch (err) {
            message.error('Failed to upload file: ' + err)
            console.log(err)
        }
    }

    handleDuplicatesToBeUpdated = async (duplicatesToBeUpdated) => {
        this.setState({ duplicatesToBeUpdated })
    }

    setDuplicatesValues = async () => {
        var parsedDataCopy = this.state.parsedData

        var duplicatesToBeUpdatedCopy = this.state.duplicatesToBeUpdated
        var duplicatesToBeUpdatedClean = []
        var idxToRemove = []

        parsedDataCopy.forEach((parsedDataRef, idx) => {
            duplicatesToBeUpdatedCopy.forEach((dupCopy) => {
                if (
                    parsedDataRef.first_name.toLowerCase() === dupCopy.first_name.toLowerCase() &&
                    parsedDataRef.last_name.toLowerCase() === dupCopy.last_name.toLowerCase() &&
                    parsedDataRef.dob === dupCopy.dob
                ) {
                    duplicatesToBeUpdatedClean.push(parsedDataRef)
                    idxToRemove.push(idx)
                }
            })
        })

        idxToRemove.forEach((idx) => {
            delete parsedDataCopy[idx]
        })
        parsedDataCopy = parsedDataCopy.filter(function (el) {
            return el != null
        })
        this.setState({
            parsedData: parsedDataCopy,
            duplicatesToBeUpdated: duplicatesToBeUpdatedClean,
        })
    }

    next = () => {
        const current = this.state.current + 1
        this.setState({ current })
        if (current === 5) {
            this.checkForDuplicates()
        } else if (current === 6) {
            this.setDuplicatesValues()
        }
    }

    clear = () => {
        this.setState({
            current: 0,
            fileList: [],
            parsedData: [],
            project: null,
        })
    }

    render() {
        const { current, fileList, parsedData, project, duplicates } = this.state
        const draggerProps = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file)
                    const newFileList = state.fileList.slice()
                    newFileList.splice(index, 1)
                    return {
                        fileList: newFileList,
                    }
                })
            },
            beforeUpload: (file) => {
                this.setState((state) => ({
                    fileList: [...state.fileList, file],
                }))
                return false
            },
            fileList,
        }
        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <Content style={{ width: '100%' }}>
                    <div style={{ marginBottom: '16px', width: '100%' }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>Referrals</Breadcrumb.Item>
                            <Breadcrumb.Item>Upload CSV</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Card
                        bodyStyle={{
                            padding: 26,
                            width: '100%',
                        }}>
                        <Row gutter={[24, 24]}>
                            <Col xs={{ span: 24 }}>
                                <Steps current={current} size="small">
                                    {steps.map((item) => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                            </Col>
                        </Row>

                        {current === 0 && <SelectProject project={project} next={this.next} selectProj={(val) => this.setState({ project: val })} />}
                        {current === 1 && <DownloadSample project={project} next={this.next} clear={this.clear} />}
                        {current === 2 && <DownloadFields project={project} next={this.next} clear={this.clear} />}
                        {current === 3 && (
                            <UploadCsvFile project={project} fileList={fileList} handleFormProcess={this.handleFormProcess} draggerProps={draggerProps} next={this.next} clear={this.clear} />
                        )}
                        {current === 4 && <PreviewData project={project} parsedData={parsedData} next={this.next} clear={this.clear} />}
                        {current === 5 && (
                            <CheckDuplicates
                                project={project}
                                duplicates={duplicates}
                                next={this.next}
                                clear={this.clear}
                                setDupsToBeUpdated={this.handleDuplicatesToBeUpdated}
                                numOfDuplicates={this.state.numOfDuplicates}
                            />
                        )}
                        {current === 6 && <ConfirmData parsedData={parsedData} handleUploadData={this.handleUploadData} project={project} next={this.next} clear={this.clear} />}
                    </Card>
                </Content>
            </Content>
        )
    }
}

export default UploadCSV
