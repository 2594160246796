import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Button, Row, Col, Checkbox, Typography, Input, TreeSelect } from 'antd'

const { TextArea } = Input
const { Title, Text } = Typography
const { SHOW_CHILD } = TreeSelect

const NotificationSettings = (props) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = () => {
            axios 
                .post('/api/docs/users', { referral_id: props.referral_id })
                .then((res) => {
                    const { userAgencies } = res.data

                    let sourceDataTree = []
                    for (const agency of userAgencies) {
                        let parentKey = agency.agency
                        let parentObject = {
                            title: parentKey,
                            value: parentKey,
                            key: parentKey
                        }
                        let childrenTree = []
                        agency.users.forEach((user) => {
                            childrenTree.push({
                                title: user,
                                value: user,
                                key: user
                            })
                        })
                        sourceDataTree.push(Object.assign({}, parentObject, { children: childrenTree }))
                    }
                    if (isSubscribed) {
                        setUsers(sourceDataTree)
                    }
                })
        }
        fetchData();
        return () => isSubscribed = false
    }, []);

    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Title level={2} style={{paddingTop: '15px'}}>Notification Settings</Title>
                    <Text>
                        <span style={{fontStyle: 'italic'}}>(Optional) </span>Select notification recipients, enter a message, and set priority level 
                    </Text>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                <TreeSelect
                    treeData={users}
                    treeCheckable={true}
                    showCheckedStrategy={SHOW_CHILD}
                    placeholder="Choose recipients"
                    onChange={e => props.handleAddRecipients(e)}
                    defaultValue={props.recipients}
                    style={{ width: '95%', marginTop: '15px', marginBottom: "15px", }} 
                />
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <TextArea 
                        style={{ width: '95%', marginBottom: "15px", }}
                        disabled={!props.recipients.length > 0}
                        placeholder="Enter a message" 
                        onChange={e => props.handleMessageChange(e)}
                        defaultValue={props.message}
                    />
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Checkbox 
                        style={{ width: '95%', marginBottom: "30px", }} 
                        disabled={!props.recipients.length > 0}
                        onChange={e => props.handlePriorityChange(e)} 
                        checked={props.priority}
                    >
                    High priority
                    </Checkbox>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Button
                        type="primary"
                        onClick={props.next}
                        style={{marginRight: '5px'}}>
                        Next
                    </Button>
                    <Button
                        type="secondary"
                        onClick={props.back}>
                        Back
                    </Button>
                    <Button
                        type="danger"
                        onClick={props.reset}
                        style={{float: 'right', right: '5%'}}>
                        Reset
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default NotificationSettings 

