import React, { Component } from 'react'
import { Card, Col, Form, Input, Row, Select } from 'antd'
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import MaskedInput from 'antd-mask-input'

import { referral_sources, referral_titles } from '../../../../../utils/constants'
import { isValidPhone } from '../../../../../utils/helpers'

const { Option } = Select
const { TextArea } = Input

class ReferralSource extends Component {
    render() {
        return (
            <Card title="Referral Source" bordered={false} style={{ width: '-webkit-fill-available', marginTop: '1em' }}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={11}>
                        <Form.Item
                            name="referral_source"
                            rules={[
                                {
                                    required: true,
                                    message: 'Referral source is required'
                                },
                            ]}
                            tooltip={{
                                title: 'This is a required field',
                                icon: <ExclamationCircleTwoTone twoToneColor="#eb2f96" />,
                            }}
                            label="Referral Source"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                showSearch
                                placeholder="Select a referral source"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {referral_sources.sort().map((source) => (
                                    <Option key={source}>{source}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={12}>
                        <Form.Item
                            name="referral_source_email"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Referral Source Email"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <Input
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault()
                                }}
                                placeholder="email@provider.com"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={11}>
                        <Form.Item
                            name="referral_title"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Referral Title"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                showSearch
                                placeholder="Select a title"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {referral_titles.sort().map((title) => (
                                    <Option key={title}>{title}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={12}>
                        <Form.Item
                            name="referral_source_phone"
                            rules={[
                                {
                                    required: false,
                                },
                                {
                                    validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                },
                            ]}
                            label="Referral Source Phone"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <MaskedInput
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault()
                                }}
                                style={{
                                    width: '100%',
                                }}
                                mask="(111) 111-1111"
                                placeholder="Phone number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={23}>
                        <Form.Item
                            name="additional_details"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Referral Details"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <TextArea placeholder="Enter details here" rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        )
    }
}

export default ReferralSource
