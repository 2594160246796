import React from 'react'
import { Link } from 'react-router-dom'
import { Result, Button } from 'antd'
import './no-match.scss'

const NoMatch = (props) => {
    return (
        <React.Fragment>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <Link to="/">
                        <Button type="primary">Back Home</Button>
                    </Link>
                }
            />
        </React.Fragment>
    )
}

export default NoMatch
