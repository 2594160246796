import React, { Component } from 'react'
import { Breadcrumb, Card, Col, Row, Layout } from 'antd'

const { Content } = Layout

class Privacy extends Component {
    render() {
        return (
            <Content style={{ margin: '40px 16px 0' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Privacy Policy</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Row>
                        <Col>
                            <p>BUSINESS ASSOCIATE AGREEMENT</p>

                            <p>
                                THIS AGREEMENT (the &ldquo;Agreement&rdquo;),
                                effective on the ___th day of _________, 2019,
                                between &#8194;&#8194;&#8194;&#8194;&#8194;
                                (hereinafter referred to as the &ldquo;Covered
                                Entity&rdquo;) and CBHS, Inc. (hereinafter
                                referred to as the &ldquo;Business
                                Associate&rdquo;).
                            </p>

                            <p>WITNESSETH:</p>

                            <p>
                                WHEREAS, Covered Entity is part of an organized
                                health care arrangement with other covered
                                entities and Business Associate provides certain
                                services related to population health management
                                and the provision data and reports related to
                                certain grants for the organized health care
                                arrangement;
                            </p>

                            <p>
                                WHEREAS, Business Associate&rsquo;s provision of
                                such services qualifies it as a &ldquo;business
                                associate&rdquo; of Covered Entity, as defined
                                in the Privacy and Security Standards; and
                            </p>

                            <p>
                                WHEREAS, Covered Entity and Business Associate
                                do hereby desire to enter into this Agreement as
                                required under the Privacy and Security
                                Standards.
                            </p>

                            <p>
                                NOW, THEREFORE, for the reasons set forth above
                                and in consideration of the mutual promises and
                                agreements set forth herein, Covered Entity and
                                Business Associate do hereby contract and agree
                                as follows:
                            </p>

                            <p>
                                Definitions. Terms used, but not otherwise
                                defined in this Agreement, shall have the same
                                meaning as those terms in 45 CFR &sect;&sect;
                                160.103 and 164.501.
                                <br />
                                (a) Business Associate. &ldquo;Business
                                Associate&rdquo; shall mean the entity defined
                                above.
                            </p>

                            <p>
                                (b) Covered Entity. &ldquo;Covered Entity&rdquo;
                                shall mean the entity defined above.
                            </p>

                            <p>
                                (c) Data Aggregation. &ldquo;Data
                                Aggregation&rdquo; shall have the same meaning
                                as the term &ldquo;Data Aggregation&rdquo; in 45
                                CFR &sect; 164.501.
                            </p>

                            <p>
                                (d) Individual. &ldquo;Individual&rdquo; shall
                                have the same meaning as the term
                                &ldquo;individual&rdquo; in 45 CFR &sect;
                                164.501 and shall include a person who qualifies
                                as a personal representative in accordance with
                                45 CFR &sect; 164.502(g).
                            </p>

                            <p>
                                (e) HIPAA. &ldquo;HIPAA&rdquo; shall mean the
                                Health Insurance Portability and Accountability
                                Act of 1996, Pub. L. No. 104-191, and any
                                amendments thereto.
                            </p>

                            <p>
                                (f) Privacy and Security Rules. &ldquo;Privacy
                                and Security Rules&rdquo; shall mean the
                                Standards for Privacy and Security of
                                Individually Identifiable Health Information at
                                45 CFR Parts 160, 162 and 164.
                            </p>

                            <p>
                                (g) Privacy Standards. &ldquo;Privacy
                                Standards&rdquo; shall mean the Standards for
                                Privacy of Individually Identifiable Health
                                Information at 45 CFR Part 160 and Part 164,
                                Subparts A and E.
                            </p>

                            <p>
                                (h) Protected Health Information.
                                &ldquo;Protected Health Information&rdquo; shall
                                have the same meaning as the term
                                &ldquo;Protected Health Information&rdquo; in 45
                                CFR &sect; 164.501, limited to the information
                                created, received, maintained or transmitted by
                                Business Associate from or on behalf of Covered
                                Entity.
                            </p>

                            <p>
                                (i) Required by Law. &ldquo;Required by
                                Law&rdquo; shall have the same meaning as the
                                term &ldquo;required by law&rdquo; in 45 CFR
                                &sect; 164.501.
                            </p>

                            <p>
                                (j) Secretary. &ldquo;Secretary&rdquo; shall
                                mean the Secretary of the Department of Health
                                and Human Services or his or her designee.
                            </p>

                            <p>
                                Obligations and Activities of Business
                                Associate.
                                <br />
                                (a) Confidentiality. Business Associate agrees
                                to hold Protected Health Information
                                confidentially and shall not use or disclose it
                                other than as permitted or required by this
                                Agreement, 45 CFR &sect; 164.504(e), the Privacy
                                Standards or as Required by Law.
                            </p>

                            <p>
                                (b) Safeguard PHI. Business Associate agrees to
                                use appropriate safeguards to prevent use or
                                disclosure of Protected Health Information other
                                than as provided for by this Agreement. Business
                                Associate shall maintain a written information
                                privacy and security program that includes
                                administrative, technical and physical
                                safeguards appropriate to the size and
                                complexity of Business Associate&rsquo;s
                                operations and the nature and scope of its
                                activities. Business Associate shall secure all
                                Protected Health Information such that it cannot
                                be accessed or viewed by those who do not have a
                                need to know the information. Business Associate
                                shall comply with 45 CFR &sect;&sect; 164.308,
                                164.310, 164.312 and 164.316 and relevant
                                provisions of Subtitle D of the American
                                Recovery and Reinvestment Act and its related
                                regulations, as such provisions and regulations
                                become effective and as applicable to business
                                associates, as defined by 45 CFR &sect; 160.103.
                            </p>

                            <p>
                                (c) Mitigation. Business Associate agrees to
                                mitigate, to the extent commercially reasonable,
                                any harmful effect that is known to Business
                                Associate of a use or disclosure of Protected
                                Health Information by Business Associate or its
                                subcontractors or agents in violation of the
                                requirements of this Agreement, the Privacy
                                and/or Security Standards or any other relevant
                                federal or state law or regulation. Business
                                Associate shall take prompt corrective action to
                                cure any such deficiencies and any action
                                pertaining to such unauthorized disclosure
                                required by applicable federal or state laws and
                                regulations, at the direction of Covered Entity.
                                Business Associate agrees to report to Covered
                                Entity any use or disclosure of Protected Health
                                Information not provided for by this Agreement,
                                within five (5) business days of becoming aware
                                of such use or disclosure. Business Associate
                                also agrees to report to Covered Entity in
                                writing the discovery of a breach of any
                                unsecured Protected Health Information or
                                security incident within five (5) business days
                                of becoming aware of such use or disclosure.
                                Such notice shall include the identification of
                                each individual whose unsecured Protected Health
                                Information has been, or is reasonably believed
                                by the Business Associate to have been,
                                accessed, acquired, or disclosed during such
                                breach. Business Associate shall not provide any
                                breach notification required unless directed to
                                do so by Covered Entity.
                            </p>

                            <p>
                                (d) Subcontractors. Business Associate agrees to
                                take commercially reasonable steps to ensure
                                that any agent, including a subcontractor, that
                                creates, receives, maintains, or transmits
                                Protected Health Information on behalf of the
                                Business Associate agrees in writing enforceable
                                in favor of Covered Entity to the same
                                restrictions, conditions, and requirements that
                                apply throughout this Agreement to Business
                                Associate with respect to such information.
                                Business Associate shall implement and maintain
                                sanctions against agents and subcontractors that
                                violate such conditions and restrictions and
                                shall mitigate the effects of any such violation
                                of which Business Associate becomes aware, at
                                the direction of Covered Entity.
                            </p>

                            <p>
                                (e) Access. Business Associate agrees to make
                                available to Covered Entity, at the request of
                                Covered Entity, within five (5) days of any such
                                request, the Protected Health Information of
                                Individuals, within the control of Business
                                Associate or any agent or subcontractor of
                                Business Associate, to enable Covered Entity to
                                provide access to, or a copy of such Protected
                                Health Information, to an individual in order to
                                meet the requirements under 45 CFR &sect;
                                164.524.
                            </p>

                            <p>
                                (f) Amendments. Business Associate agrees to
                                make any amendment(s) to Protected Health
                                Information maintained by Business Associate or
                                its agents or subcontractors that the Covered
                                Entity directs or agrees to pursuant to 45 CFR
                                &sect; 164.526 at the request of Covered Entity
                                within five (5) days of receipt of such request.
                            </p>

                            <p>
                                (g) Internal Books and Records. Business
                                Associate agrees to make internal practices,
                                books, and records, including policies and
                                procedures, relating to the use and disclosure
                                of Protected Health Information received from,
                                or created or received by Business Associate on
                                behalf of, Covered Entity available to the
                                Secretary, in a time and manner designated by
                                the Secretary, for purposes of the Secretary
                                determining Covered Entity&rsquo;s compliance
                                with the Privacy Rule. The fact that Covered
                                Entity inspects, or fails to inspect, Business
                                Associate&rsquo;s facilities, systems, books,
                                records, agreements, policies, and procedures
                                does not relieve Business Associate of its
                                responsibility to comply with this Agreement,
                                nor does Covered Entity&rsquo;s failure to
                                detect, or detection but failure to notify
                                Business Associate or require Business
                                Associate&rsquo;s remediation of, any
                                unsatisfactory practices, constitute acceptance
                                of such practice or waiver of Covered
                                Entity&rsquo;s enforcement rights under this
                                Agreement.
                            </p>

                            <p>
                                (h) Accounting for Disclosures. Business
                                Associate agrees to document such disclosures of
                                Protected Health Information and information
                                related to such disclosures as would be required
                                for Covered Entity to respond to a request by an
                                Individual for an accounting of disclosures of
                                Protected Health Information in accordance with
                                45 CFR &sect; 164.528. Business Associate agrees
                                to provide to Covered Entity or, as directed by
                                the Covered Entity, directly to an Individual,
                                all required information in response to such
                                request for an accounting of disclosures of
                                Protected Health Information in accordance with
                                45 CFR &sect; 164.528 and relevant provisions of
                                Subtitle D of the American Recovery and
                                Reinvestment Act and its related regulations.
                            </p>

                            <p>
                                (i) Business Associate Carrying Out Covered
                                Entity Obligations. To the extent Business
                                Associate is to carry out one or more of Covered
                                Entity's obligation(s) under Subpart E of 45 CFR
                                Part 164, Business Associate shall comply with
                                the requirements of Subpart E that apply to the
                                Covered Entity in the performance of such
                                obligation(s).
                            </p>

                            <p>
                                (j) Other Privacy Laws. Business Associate
                                agrees to follow other relevant laws and
                                regulations related to protecting health
                                information, including, but not limited to,
                                requirements in New York State Mental Hygiene
                                Law &sect; 33.13 and related regulations, New
                                York State Public Health Law Article 27-F and
                                related regulations, and 42 CFR Part 2.
                            </p>

                            <p>
                                General Use and Disclosure Provisions.
                                <br />
                                Except as otherwise limited in this Agreement,
                                Business Associate may use or disclose Protected
                                Health Information to perform functions,
                                activities, or services for, or on behalf of,
                                Covered Entity, provided that such use or
                                disclosure would not violate the Privacy
                                Standards if done by Covered Entity or the
                                minimum necessary policies and procedures of the
                                Covered Entity.
                            </p>

                            <p>
                                Specific Use and Disclosure Provisions.
                                <br />
                                (a) Except as otherwise limited in this
                                Agreement, Business Associate may use Protected
                                Health Information for the proper management and
                                administration of the Business Associate or to
                                carry out the legal responsibilities of Business
                                Associate.
                            </p>

                            <p>
                                (b) Except as otherwise limited in this
                                Agreement, Business Associate may disclose
                                Protected Health Information for the proper
                                management and administration of Business
                                Associate, provided that disclosures are
                                Required by Law, or Business Associate obtains
                                reasonable assurances from the person to whom
                                the information is disclosed that it will remain
                                confidential and be used or further disclosed
                                only as Required by Law or for the purpose for
                                which it was disclosed, and the person notifies
                                Business Associate of any instances of which it
                                is aware in which the confidentiality of the
                                information has been breached.
                            </p>

                            <p>
                                (c) Except as otherwise limited in this
                                Agreement, Business Associate may use Protected
                                Health Information as specifically requested by
                                Covered Entity to provide Data Aggregation
                                services to Covered Entity as permitted by 45
                                CFR &sect; 164.504(e)(2)(i)(B).
                            </p>

                            <p>
                                (d) Except as otherwise limited in this
                                Agreement, Business Associate may de-identify
                                any and all Protected Health Information
                                provided that the de-identification conforms to
                                the requirements of the Privacy Standards. The
                                parties acknowledge and agree that de-identified
                                data does not constitute Protected Health
                                Information and is not subject to the terms of
                                this Agreement.
                            </p>

                            <p>
                                (e) Business Associate, and its agents or
                                subcontractors, shall only request, use and
                                disclose the minimum amount of Protected Health
                                Information necessary to accomplish the purpose
                                of the request, use or disclosure.
                            </p>

                            <p>
                                Obligations of Covered Entity.
                                <br />
                                (a) Covered Entity shall provide Business
                                Associate with the notice of privacy practices
                                that Covered Entity produces in accordance with
                                45 CFR &sect; 164.520, as well as any changes to
                                such notice, to the extent that such changes may
                                affect Business Associate&rsquo;s use or
                                disclosure of Protected Health Information.
                            </p>

                            <p>
                                (b) Covered Entity shall provide Business
                                Associate with any changes in, or revocation of,
                                permission by an Individual to use or disclose
                                Protected Health Information, if such changes
                                affect Business Associate&rsquo;s permitted or
                                required uses and disclosures.
                            </p>

                            <p>
                                (c) Covered Entity shall notify Business
                                Associate of any restriction to the use or
                                disclosure of Protected Health Information that
                                Covered Entity has agreed to in accordance with
                                45 CFR &sect; 164.522, to the extent that such
                                restriction may affect Business
                                Associate&rsquo;s use or disclosure of Protected
                                Health Information.
                            </p>

                            <p>
                                Permissible Requests by Covered Entity.
                                <br />
                                Covered Entity shall not request Business
                                Associate to use or disclose Protected Health
                                Information in any manner that would not be
                                permissible under the Privacy Standards if done
                                by Covered Entity, except as permitted in
                                Section 4(c) of this Agreement.
                            </p>

                            <p>
                                Term and Termination.
                                <br />
                                (a) Term. The term of this Agreement shall be
                                effective on January 1, 2019 and, unless
                                otherwise terminated as provided herein, shall
                                expire upon Covered Entity&rsquo;s withdrawal as
                                a member of Business Associate.
                            </p>

                            <p>
                                (b) Termination for Cause. Upon Covered
                                Entity&rsquo;s knowledge of a material breach,
                                as determined by Covered Entity, by Business
                                Associate under this Agreement, Covered Entity
                                shall provide an opportunity for Business
                                Associate to cure the breach or end the
                                violation. If Business Associate does not cure
                                the breach or end the violation within the
                                commercially reasonable time specified by the
                                Covered Entity, then Covered Entity may
                                terminate this Agreement. If Business Associate
                                has breached a material term of this Agreement
                                and cure is not possible, Covered Entity may
                                immediately terminate this Agreement.
                            </p>

                            <p>(c) Effect of Termination.</p>

                            <p>
                                (1) Except as provided in subparagraph (2) of
                                this paragraph, upon termination of this
                                Agreement, for any reason, Business Associate
                                shall, as directed by Covered Entity, return to
                                Covered Entity, or a third party designated in
                                writing by Covered Entity, or destroy all
                                Protected Health Information received from
                                Covered Entity, or created or received by
                                Business Associate on behalf of Covered Entity.
                                This provision shall apply to Protected Health
                                Information that is in the possession of
                                subcontractors or agents of Business Associate.
                                Business Associate shall retain no copies of the
                                Protected Health Information.
                            </p>

                            <p>
                                (2) In the event that Business Associate
                                determines that returning or destroying the
                                Protected Health Information is infeasible,
                                Business Associate shall provide to Covered
                                Entity notification of the conditions that make
                                return or destruction infeasible. Upon agreement
                                by Covered Entity that return or destruction of
                                the Protected Health Information is infeasible,
                                Business Associate shall extend the protections
                                of this Agreement to such Protected Health
                                Information and limit further uses and
                                disclosures of such Protected Health Information
                                to those purposes that make the return or
                                destruction infeasible, for so long as Business
                                Associate maintains such Protected Health
                                Information, which shall be the amount of time
                                required by statute, law or regulation.
                            </p>

                            <p>
                                Miscellaneous.
                                <br />
                                (a) Regulatory References. A reference in this
                                Agreement to a section in the Privacy or
                                Security Standards means the section as in
                                effect or as amended, and for which compliance
                                is required.
                            </p>

                            <p>
                                (b) Amendment. The parties agree to take such
                                action as is necessary to amend this Agreement
                                from time to time as is necessary for Covered
                                Entity to comply with the requirements of the
                                Privacy Standards and the Health Insurance
                                Portability and Accountability Act, Public Law
                                104-191 and other applicable laws relating to
                                the security or confidentiality of Protected
                                Health Information.
                            </p>

                            <p>
                                (c) Survival. The respective rights and
                                obligations of the parties under Section 7(c) of
                                this Agreement shall survive the termination of
                                this Agreement.
                            </p>

                            <p>
                                (d) Interpretation. Any ambiguity in this
                                Agreement shall be resolved in favor of a
                                meaning that permits Covered Entity to comply
                                with the Privacy Standards.
                            </p>

                            <p>
                                (e) Third Party Beneficiaries. Nothing express
                                or implied in this Agreement is intended to
                                confer, nor shall anything herein confer, upon
                                any person other than the parties and the
                                respective successors and assigns of the parties
                                any rights, remedies, obligations, or
                                liabilities whatsoever.
                            </p>

                            <p>
                                (f) Disclaimer. Covered Entity makes no warranty
                                or representation that compliance by Business
                                Associate with this Agreement, HIPAA or the
                                HIPAA Regulations will be adequate or
                                satisfactory for Business Associate&rsquo;s own
                                purposes. Business Associate is solely
                                responsible for all decisions made by Business
                                Associate regarding the safeguarding of
                                Protected Health Information.
                            </p>

                            <p>
                                (g) Choice of Law. This Agreement shall be
                                governed by the laws of the State of New York
                                without regard to conflict of laws principles
                                thereof.
                            </p>

                            <p>
                                (h) Severability. If any section or portion of
                                this Agreement shall be determined to be
                                invalid, such determination shall not affect the
                                enforceability or validity of the remainder of
                                this Agreement.
                            </p>

                            <p>
                                (i) No Waiver. No waiver of a breach of any
                                provision of this Agreement shall be construed
                                to be a waiver of any breach of any other
                                provision of this Agreement or of any later
                                breach of the same provision. No delay in action
                                with regard to any breach of any provision of
                                this Agreement shall be construed to be a waiver
                                of such breach.
                            </p>

                            <p>
                                (j) Notices. Any notices to be given hereunder
                                to a party shall be made via certified U.S. Mail
                                or express courier to such party&rsquo;s address
                                given below.
                            </p>

                            <p>If to Covered Entity, to:</p>

                            <p>&#8194;&#8194;&#8194;&#8194;&#8194;</p>

                            <p>&#8194;&#8194;&#8194;&#8194;&#8194;</p>

                            <p>&#8194;&#8194;&#8194;&#8194;&#8194;</p>

                            <p>If to Business Associate, to:</p>

                            <p>CBHS, INC.</p>

                            <p>70 Hatfield Ln, Suite 205</p>

                            <p>Goshen, NY 10924</p>

                            <p>
                                IN WITNESS WHEREOF, the undersigned have caused
                                this Agreement to be executed as of the ____ day
                                of ________ 2019.
                            </p>

                            <p>
                                Covered Entity:
                                &#8194;&#8194;&#8194;&#8194;&#8194;
                            </p>

                            <p>By:</p>

                            <p>Name:</p>

                            <p>Title:</p>

                            <p>Business Associate: CBHS, INC.</p>

                            <p>By:</p>

                            <p>Name:</p>

                            <p>Title:</p>

                            <p>
                                QUALIFIED SERVICE ORGANIZATION / BUSINESS
                                ASSOCIATE AGREEMENT
                            </p>

                            <p>
                                THIS Agreement is made by and between
                                &#8194;&#8194;&#8194;&#8194;&#8194;
                                (&ldquo;Covered Entity&rdquo;), and CBHS, Inc.
                                (&ldquo;Business Associate&rdquo;).
                            </p>

                            <p>
                                WHEREAS, Business Associate provides, or intends
                                to provide, certain services to Covered Entity
                                related to certain population health
                                initiatives, grant funding requirements,
                                outcomes management, quality improvement and
                                other population health-related goals; and
                            </p>

                            <p>
                                WHEREAS, in connection with such services,
                                Business Associate may create, use or disclose
                                for or on behalf of Covered Entity certain
                                individually identifiable protected health
                                information (&ldquo;PHI&rdquo;) relating to
                                individuals served by the Covered Entity that is
                                subject to protection under the Health Insurance
                                Portability and Accountability Act of 1996
                                (HIPAA) as amended, including Subpart D of the
                                Health Information Technology for Economic and
                                Clinical Health Act (HITECH) of Title XIII,
                                Division A of the American Recovery and
                                Reinvestment Act of 2009, and related
                                regulations, 45 CFR Parts 160 and 164 (the HIPAA
                                Privacy and Security Rules); the Federal
                                Confidentiality Law, 42 USC &sect;&sect; 290dd-2
                                and underlying regulations 42 CFR Part 2
                                (&ldquo;Part 2&rdquo;); and
                            </p>

                            <p>
                                WHEREAS, by reason of such activities, the
                                parties believe that Business Associate is a
                                &ldquo;business associate&rdquo; of Covered
                                Entity, as such term is defined in 45 CFR
                                160.103 and Qualified Service Organization under
                                42 CFR 2.12(c)(4); and
                            </p>

                            <p>
                                WHEREAS, Covered Entity and Business Associate
                                wish to comply in all respects with the
                                requirements of Part 2, HIPAA and HITECH,
                                including requirements applicable to the
                                relationship between a covered entity and its
                                business associates;
                            </p>

                            <p>NOW, THEREFORE, the parties agree as follows:</p>

                            <p>
                                Definitions:
                                <br />
                                Terms used, but not otherwise defined, in this
                                Agreement shall have the same meaning as those
                                terms in 45 CFR &sect;&sect;160.103, 164.103,
                                and 164.501.
                            </p>

                            <p>
                                &ldquo;Breach&rdquo; shall have the same meaning
                                as the term &ldquo;breach&rdquo; in section
                                13400 of the HITECH Act and guidance issued by
                                the US Department of Health and Human Services
                                and shall include the unauthorized acquisition,
                                use, or disclosure of Protected Health
                                Information that compromises the privacy or
                                security of such information.
                                <br />
                                &ldquo;Covered Entity&rdquo; shall mean
                                &#8194;&#8194;&#8194;&#8194;&#8194;.
                                <br />
                                &ldquo;Confidentiality Rule&rdquo; shall mean
                                the Federal Confidentiality regulation 42 CFR
                                Part 2.
                                <br />
                                &ldquo;Designated Record Set&rdquo; shall have
                                the same meaning as the term &ldquo;designated
                                record set&rdquo; in 45 CFR 164.501.
                                <br />
                                &ldquo;Disclose&rdquo; or
                                &ldquo;Disclosure&rdquo; shall have the same
                                meaning as the terms &ldquo;disclose&rdquo; and
                                &ldquo;disclosure&rdquo; in 42 CFR 2.11.
                                <br />
                                &ldquo;Individual&rdquo; shall have the same
                                meaning as the term &ldquo;individual&rdquo; in
                                45 CFR 160.103 and shall include a person who
                                qualifies as a personal representative in
                                accordance with 45 CFR 164.502(g).
                                <br />
                                &ldquo;Privacy Rule&rdquo; shall mean the
                                Standards for Privacy of Individually
                                Identifiable Health Information at 45 CFR part
                                160 and part 164, subparts A and E.
                                <br />
                                &ldquo;Protected Health Information&rdquo; shall
                                have the same meaning as the term
                                &ldquo;protected health information&rdquo; in 45
                                CFR 160.103, limited to the information created
                                or received by Business Associate from or on
                                behalf of Covered Entity in connection with the
                                Business Associate&rsquo;s performance of this
                                agreement.
                                <br />
                                &ldquo;Qualified Service Organization
                                Agreement&rdquo; shall have the same meaning as
                                defined in 42 CFR 2.12(c)(4).
                                <br />
                                &ldquo;Required By Law&rdquo; shall have the
                                same meaning as the term &ldquo;required by
                                law&rdquo; in 45 CFR 164.103.
                                <br />
                                &ldquo;Secretary&rdquo; shall mean the Secretary
                                of the Department of Health and Human Services
                                or his or her designee.
                                <br />
                                &ldquo;Security Rule&rdquo; shall mean the
                                Security Standards for the Protection of
                                Electronic Protected Health Information at 45
                                CFR Parts 160 and 164, subparts A and C.
                                <br />
                                &ldquo;Unsecured Protected Health
                                Information&rdquo; shall mean Protected Health
                                Information that is not secured through the use
                                of a technology or methodology specified by the
                                Secretary in guidance, or as otherwise defined
                                in section 13402(h) of the HITECH Act.
                                <br />
                                Obligations and Activities of Business
                                Associate:
                                <br />
                                Business Associate agrees to not use or further
                                disclose Protected Health Information other than
                                as Required by Law, or as otherwise permitted or
                                as required by this Agreement.
                                <br />
                                Business Associate agrees to use appropriate
                                safeguards to prevent use or disclosure of the
                                Protected Health Information other than as
                                provided for by this Agreement and to implement
                                administrative, physical, and technical
                                safeguards that reasonably and appropriately
                                protect the confidentiality, integrity, and
                                availability of any electronic Protected Health
                                Information that it creates, receives,
                                maintains, or transmits on behalf of Covered
                                Entity pursuant to this Agreement in accordance
                                with 42 CFR Part 2 (the Confidentiality Rule)
                                and 45 CFR 164 (the HIPAA Security Rule).
                                Business Associate agrees to fully comply with
                                the responsibilities of Business Associates as
                                set forth in sections 13401 and 13404 of the
                                HITECH Act.
                                <br />
                                Business Associate agrees to mitigate, to the
                                extent practicable, any harmful effect that is
                                known to Business Associate of a use or
                                disclosure of Protected Health Information by
                                Business Associate in violation of the
                                requirements of this Agreement.
                                <br />
                                Business Associate agrees to report to Covered
                                Entity any use or disclosure of the Protected
                                Health Information not provided for by this
                                Agreement. Further, Business Associate agrees to
                                report to Covered Entity any security incident,
                                including a breach of Unsecured Protected Health
                                Information as defined by the Security Rule, of
                                which it becomes aware. In the event of such a
                                breach:
                                <br />
                                Business Associate shall promptly notify Covered
                                Entity of the breach when it is discovered. A
                                breach is considered discovered on the first day
                                on which Business Associate knows or should have
                                known of such breach. Such notification shall
                                identify the Individuals, and their contact
                                information, whose Unsecured Protected Health
                                Information has, or is reasonably believed to
                                have, been the subject of the breach. Business
                                Associate shall provide additional information
                                concerning such breach to Covered Entity as
                                requested.
                                <br />
                                Covered Entity or Business Associate, as
                                determined by Covered Entity, shall promptly
                                notify individuals about a breach of their
                                Unsecured Protected Health Information as soon
                                as possible but not later than 60 calendar days
                                after discovery of the Breach, except where a
                                law enforcement official determines that a
                                notification would impede a criminal
                                investigation or cause damage to national
                                security. Notification shall be in a form and
                                format prescribed by Covered Entity and shall
                                meet the requirements of section 13402 of the
                                HITECH Act.
                                <br />
                                Business Associate agrees to ensure that any
                                agent, including a subcontractor, to whom it
                                provides Protected Health Information received
                                from, or created or received by Business
                                Associate on behalf of Covered Entity, agrees to
                                the same restrictions and conditions that apply
                                through this Agreement to Business Associate
                                with respect to such information.
                                Notwithstanding the preceding language of this
                                subsection, Business Associate acknowledges that
                                the Protected Health Information received from
                                Covered Entity, or created by Business
                                Associate, is covered by 42 CFR Part 2 and
                                therefore Business Associate is specifically
                                prohibited from disclosing such information to
                                agents or subcontractors without specific
                                written consent of the subject individual.
                                <br />
                                Business Associate agrees to provide access, at
                                the request of Covered Entity, and in the time
                                and manner designated by Covered Entity, to
                                Protected Health Information in a Designated
                                Record Set, to Covered Entity or, as directed by
                                Covered Entity, to an Individual in order to
                                meet the requirements under 45 CFR 164.524, if
                                the Business Associate has protected health
                                information in a designated record set.
                                <br />
                                Business Associate agrees to make any amendments
                                to Protected Health Information in a Designated
                                Record Set that the Covered Entity directs or
                                agrees to pursuant to 45 CFR 164.526 at the
                                request of Covered Entity or an Individual, and
                                in the time and manner designated by Covered
                                Entity, if Business Associate has protected
                                health information in a designated record set.
                                <br />
                                Business Associate agrees to make internal
                                practices, books, and records relating to the
                                use and disclosure of Protected Health
                                Information received from, or created or
                                received by, Business Associate on behalf of
                                Covered Entity available to the Covered Entity,
                                or at the request of the Covered Entity to the
                                Secretary, in a time and manner designated by
                                the Covered Entity or the Secretary, for
                                purposes of the Secretary&rsquo;s determining
                                Covered Entity&rsquo;s compliance with the
                                Privacy Rule.
                                <br />
                                Business Associate agrees to document such
                                disclosures of Protected Health Information and
                                information related to such disclosures as would
                                be required for Covered Entity to respond to a
                                request by an Individual for an accounting of
                                disclosures of Protected Health Information in
                                accordance with 45 CFR 164.528.
                                <br />
                                Business Associate agrees to provide to Covered
                                Entity or an Individual, in the time and manner
                                designated by Covered Entity, information
                                collected in accordance with Section (2)(i) of
                                this Agreement, to permit Covered Entity to
                                respond to a request by an Individual for an
                                accounting of disclosures of Protected Health
                                Information in accordance with 45 CFR 164.528.
                                <br />
                                Business Associate hereby acknowledges and
                                agrees that Covered Entity has notified Business
                                Associate that it is required to comply with the
                                confidentiality, disclosure and re-disclosure
                                requirements of Mental Hygiene Law Sections
                                32.17, 33.13 and 33.16 to the extent such
                                requirements may be applicable.
                                <br />
                                Business Associate shall be directly responsible
                                for full compliance with the relevant
                                requirements of the Confidentiality, Privacy and
                                the Security Rules to the same extent that
                                Covered Entity is responsible for compliance
                                with such Rules. Business Associate acknowledges
                                that it is subject to civil and criminal
                                penalties for violations of such provisions in
                                the same manner as if Covered Entity violated
                                such provisions.
                                <br />
                                Business Associate agrees to resist any efforts
                                in judicial proceedings to obtain access to the
                                Protected Health Information except as expressly
                                provided for in the regulations governing the
                                Confidentiality of Alcohol and Drug Abuse
                                Patient Records, 42 CFR Part 2.
                                <br />
                                Permitted Uses and Disclosures by Business
                                Associate:
                                <br />
                                (a) Covered Entity and Business Associate hereby
                                agree that this Agreement constitutes a
                                Qualified Service Organization Agreement
                                (&ldquo;QSOA&rdquo;) as required by 42 CFR Part
                                2. Accordingly, information obtained by Business
                                Associate relating to individuals who may have
                                been diagnosed as needing, or who have received,
                                chemical dependence treatment services shall be
                                maintained and used only for the purposes
                                intended under this Agreement and in conformity
                                with all applicable provisions of 42 USC &sect;
                                290dd-2 and the underlying federal regulations,
                                42 CFR Part 2. Accordingly, except as otherwise
                                limited in this Agreement, Business Associate
                                may use or disclose Protected Health Information
                                to perform functions, activities, or services
                                for, or on behalf of, Covered Entity provided
                                that such use or disclosure would not violate
                                the Confidentiality or Privacy Rules if done by
                                Covered Entity.
                            </p>

                            <p>
                                (b) Except as otherwise limited in this
                                Agreement, Business Associate may use Protected
                                Health Information for the proper management and
                                administration of the Business Associate or to
                                carry out the legal responsibilities of the
                                Business Associate.
                            </p>

                            <p>
                                (c) Except as otherwise limited in this
                                Agreement, Business Associate may disclose
                                Protected Health Information for the proper
                                management and administration of the Business
                                Associate, provided that disclosures are
                                required by law, or Business Associate obtains
                                reasonable assurances from the person to whom
                                the information is disclosed that it will remain
                                confidential and will be used or further
                                disclosed only as required by law or for the
                                purpose for which it was disclosed to the
                                person, and the person notifies the Business
                                Associate of any instances of which it is aware
                                in which the confidentiality of the information
                                has been breached. Notwithstanding the preceding
                                language of this subsection, Business Associate
                                acknowledges that the Protected Health
                                Information received from Covered Entity, or
                                created by Business Associate, is covered by 42
                                CFR Part 2 and therefore Business Associate is
                                specifically prohibited from disclosing such
                                information to agents or subcontractors without
                                specific written consent of the subject
                                individual.
                            </p>

                            <p>
                                (d) Business Associate may use Protected Health
                                Information to provide Data Aggregation services
                                to Covered Entity as permitted by 45 CFR
                                164.504(e)(2)(i)(B). Data aggregation includes
                                the combining of protected information created
                                or received by a business associate through its
                                activities under this contract with other
                                information gained from other sources.
                            </p>

                            <p>
                                Obligations of Covered Entity:
                                <br />
                                Covered Entity shall notify Business Associate
                                of any limitation(s) in the Notice of Privacy
                                Practices Covered Entity produces in accordance
                                with 45 CFR &sect;164.520, to the extent that
                                such limitation may affect Business
                                Associate&rsquo;s use or disclosure of Protected
                                Health Information.
                                <br />
                                Covered Entity shall notify Business Associate
                                of any changes in, or revocation of, permission
                                by an Individual to use or disclose Protected
                                Health Information, to the extent that such
                                changes may affect Business Associate&rsquo;s
                                permitted or required uses and disclosures.
                                <br />
                                Covered Entity shall notify Business Associate
                                of any restriction to the use or disclosure of
                                Protected Health Information that Covered Entity
                                has agreed to in accordance with 45 CFR
                                &sect;164.522, to the extent that such
                                restriction may affect Business
                                Associate&rsquo;s use or disclosure of Protected
                                Health Information.
                                <br />
                                Permissible Requests by Covered Entity:
                                <br />
                                Covered Entity shall not request Business
                                Associate to use or disclose Protected Health
                                Information in any manner that would not be
                                permissible under the Privacy Rule if done by
                                Covered Entity. Covered Entity may permit
                                Business Associate to use or disclose Protected
                                Health Information for data aggregation or
                                management and administrative activities of
                                Business Associate, if the agreement defining
                                the parties&rsquo; business relationship
                                includes provisions for same.
                            </p>

                            <p>
                                Remedies in Event of Breach:
                                <br />
                                Business Associate hereby agrees that any
                                violation of this Agreement may cause
                                irreparable harm to the Covered Entity. As such,
                                in the event of breach of any provision of this
                                Agreement, Covered Entity shall be entitled to
                                enjoin and restrain Business Associate from any
                                continued violation, and/or may seek specific
                                performance, without bond, security or necessity
                                of demonstrating actual damages.
                            </p>

                            <p>
                                Consideration:
                                <br />
                                Business Associate acknowledges that the
                                covenants and assurances it has made in the
                                Agreement shall be relied upon by Covered Entity
                                in choosing to continue or commence a business
                                relationship with Business Associate.
                            </p>

                            <p>
                                Term and Termination:
                                <br />
                                Term. The Term of this Agreement shall be
                                effective as of the Effective Date of the
                                agreement defining the parties&rsquo; business
                                relationship and shall not terminate until all
                                of the Protected Health Information provided by
                                Covered Entity to Business Associate, or created
                                or received by Business Associate on behalf of
                                Covered Entity is destroyed or returned to
                                Covered Entity or, if it is infeasible to return
                                or destroy Protected Health Information,
                                protections are extended to such information in
                                accordance with the termination provisions in
                                this section.
                                <br />
                                Termination for Cause. Upon Covered
                                Entity&rsquo;s knowledge of a material breach by
                                Business Associate, Covered Entity shall provide
                                Business Associate with an opportunity to cure
                                the breach and then terminate this Agreement and
                                any other agreement between Covered Entity and
                                Business Associate if Business Associate does
                                not cure the breach within the time period
                                specified by Covered Entity.
                                <br />
                                Effect of Termination.
                                <br />
                                Except as provided in paragraph (2) of this
                                section, upon termination of this Agreement for
                                any reason, Business Associate shall return or
                                destroy all Protected Health Information
                                received from Covered Entity or created or
                                received by Business Associate on behalf of
                                Covered Entity. This provision shall apply to
                                Protected Health Information that is in the
                                possession of subcontractors or agents of
                                Business Associate. Business Associate shall
                                retain no copies of the Protected Health
                                Information.
                                <br />
                                In the event that Business Associate determines
                                that returning or destroying the Protected
                                Health Information is infeasible, Business
                                Associate shall provide to Covered Entity
                                notification of the conditions that make return
                                or destruction infeasible. Upon mutual agreement
                                of the Parties that return or destruction of
                                Protected Health Information is infeasible,
                                Business Associate shall extend the protection
                                of this Agreement to such Protected Health
                                Information and limit further uses and
                                disclosures of such Protected Health Information
                                to those purposes that make the return or
                                destruction infeasible, for so long as Business
                                Associate maintains such Protected Health
                                Information.
                                <br />
                                Miscellaneous:
                                <br />
                                Regulatory References. A reference in this
                                Agreement to a section in the Confidentiality or
                                Privacy Rule means the section as in effect or
                                as amended, and for which compliance is
                                required.
                                <br />
                                Agreement. The Parties agree to take such action
                                as is necessary to amend this Agreement from
                                time to time as is necessary for Covered Entity
                                to comply with the requirements of the
                                Confidentiality or Privacy Rule and the Health
                                Insurance Portability and Accountability Act,
                                Public Law 104-191.
                                <br />
                                Survival. The respective rights and obligations
                                of Business Associate under Section 6 of this
                                Agreement shall survive the termination of this
                                Agreement, as shall the rights of access and
                                inspection of Covered Entity.
                                <br />
                                Interpretation. Any ambiguity in this Agreement
                                shall be resolved in favor of a meaning that
                                permits Covered Entity to comply with the
                                Confidentiality and Privacy Rules.
                                <br />
                                Law Governing Conflicts:
                                <br />
                                This Agreement shall be enforced and construed
                                in accordance with the laws of the State of New
                                York. Jurisdiction of any litigation with
                                respect to this Agreement shall be in New York,
                                with venue in a court of competent jurisdiction
                                in Westchester County.
                            </p>

                            <p>Covered Entity:</p>

                            <p>&#8194;&#8194;&#8194;&#8194;&#8194;</p>

                            <p>Print Name:</p>

                            <p>Signature:</p>

                            <p>Title:</p>

                            <p>Date:</p>

                            <p>Business Associate:</p>

                            <p>CBHS, Inc.</p>

                            <p>Print Name:</p>

                            <p>Signature:</p>

                            <p>Title:</p>

                            <p>Date:</p>
                        </Col>
                    </Row>
                </Card>
            </Content>
        )
    }
}

export default Privacy
