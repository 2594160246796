import React, { Component } from 'react'
import axios from 'axios'
import { Card, Col, Row, Button, Table, Typography, Tooltip, Modal, message, Divider, Popconfirm } from 'antd'
import { DownloadOutlined, EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'

import DocsHistory from './docs-history'


const { Text } = Typography

class Documentation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            docsList: []
        }
    }

    async componentDidMount () {
        await this.fetchDocs()
    }

    fetchDocs = async () => {
        const req = {
            referral_id: this.props.original._id,
            user_type: this.props.user.user_type
        }
        const res = await axios.post("/api/referrals/docs_list", req)
        this.setState({
            docsList: res.data
        })
    }

    deleteDoc = async (uniqueKey, id, referralId) => {
        await axios
        .post('/api/referrals/docs_delete', { uniqueKey, id, referralId })
        .then(async () => {
            message.loading('Action in progress...', 0.5)
            await this.fetchDocs()
        })
        .then(() => {
            message.success('Document deleted')
        })
        .catch((err) => console.log(err)) 
    }

    authorizedDocs = () => {
        let docs_list = {
            consented: [],
            no_consent: []
        }
        const { docsList } = this.state
        docsList.forEach((doc) => {
            docs_list['consented'].unshift(doc)
            if (doc.category === 'Demographics' || doc.category === 'Referral') {
                docs_list['no_consent'].unshift(doc)
            }
        })
        if (this.props.consentedAgencies.includes(this.props.user.user_type)) {
            return docs_list.consented
        } else {
            return docs_list.no_consent
        }
    }

    info = (record) => {
        Modal.info({
            style: { paddingLeft: 0 },
            maskClosable: true,
            title: (
                <h2>
                    {record.fileName}
                </h2>
            ),
            content: <DocsHistory row={record} />,
            width: '75%',
            destroyOnClose: true,
        })
        // hack to scroll top the modal
        setTimeout(() => document.getElementsByClassName('ant-modal-wrap')[0].scrollTo(0, 0), 100)
    }

    downloadFile = async (record) => {
        const options = {
            params: {
                Key: record.uniqueKey,
            }
        }   

        delete axios.defaults.headers.common['Authorization']

        await axios
            .get("/api/uploader/generate-get-url-docs", options)
            .then(async (res) => {
                const { getURL } = res.data
                Modal.info({
                    title: 'Continue Download?',
                    okText:"Cancel",
                    content:
                    <Row style={{ marginBottom: '1em', marginTop: '1em', textAlign: 'center' }}>
                        <Col>
                            <div>
                                <p style={{ paddingTop: '1em' }}>
                                    <b>By clicking this button, you understand that once you download ConnexRX data, we cannot ensure it will be kept secure.</b> You are attempting to download a file which
                                    includes protected health information (“PHI”). We secure the data on ConnexRX servers using encryption and other technical, physical, and procedural safeguards. You
                                    understand that once you download the data, we cannot guarantee the security of that downloaded data file.
                                </p>
                                <p>
                                    You must have a valid Use and Disclose reason for accessing and downloading this PHI. IF YOU DO NOT have a valid, legal reason for downloading this information, you make
                                    liable for civil penalties and/or criminal charges. PROCEED WITH CAUTION AND UNDER NOTICE.
                                </p>
                                <br />
                                <Button
                                    href={getURL}
                                    type="secondary"
                                    size="small"
                                    shape="round"
                                    target="_blank"
                                    download={true}
                                >
                                <DownloadOutlined /> Download
                                </Button>
                            </div>
                        </Col>
                    </Row>
                })
                axios.defaults.headers.common['Authorization'] = localStorage.jwtToken
            })
            .catch((err) => {
                console.log(err)
                message.error("Failed to get file" + err)
            })
    }

    formatColumns = () => {
        const columns = [
            ["Name", "fileName", 300],
            ["Tab", "tab", 150],
            ["Category", "category", 150],
            ["Recipients", "recipients", 400],
            ["Message", "message", 350],
            ["Priority", "priority", 150],
            ["Added On", "addedOn", 150],
            ["Added By", "addedBy", 250]
        ]
        const actionColumns = [ 
            {
                title: "Actions",
                key: "actions",
                width: 150,
                fixed: 'right',
                render: (text, record) => (
                    <span>
                        <React.Fragment>
                            <Tooltip title="Download">
                                <DownloadOutlined 
                                    onClick={() => {
                                        this.downloadFile(record)
                                    }}
                                />
                            </Tooltip>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider type="vertical" />
                            <Tooltip title="History">
                                <SearchOutlined 
                                    onClick={() => {
                                        console.log('this is the original data', record)
                                        this.info(record)
                                    }}
                                />
                            </Tooltip>
                        </React.Fragment>
                        {this.props.user.email === record.addedBy.substring(record.addedBy.lastIndexOf(" ") +1) && (
                            <React.Fragment>
                                <Divider type="vertical" />
                                <Tooltip title="Edit">
                                    <EditOutlined
                                        onClick={() => {
                                            this.props.history.push(`/edit-documentation/${record._id}`)
                                        }}
                                    />
                                </Tooltip>
                            </React.Fragment>
                        )}
                        {this.props.user.user_type === "CBHS" && (
                            <React.Fragment>
                                <Divider type="vertical" />
                                <Tooltip title="Delete">
                                <Popconfirm title="Sure to delete?" onConfirm={() => {
                                        this.deleteDoc(record.uniqueKey, record._id, record.referral[0])
                                    }}>
                                        <DeleteOutlined />
                                </Popconfirm>
                                </Tooltip>
                            </React.Fragment>
                        )}
                    </span>
                )
            }
        ]
        const preppedColumns = columns.map((col) => this.makeColumns(col))
        return [...preppedColumns, ...actionColumns]
    }

    makeColumns = (col) => {
        return {
            title: col[0],
            dataIndex: col[1],
            key: col[1],
            width: col[2],
            render: (text) => {
                if (col[1] === 'recipients' && text.length > 0) {
                    text = text.map((recip, index) => {
                        if (index < text.length - 1) {
                            return recip + ', '
                        } else {
                            return recip
                        }
                    })
                    return <Text>{text}</Text>
                } else if (col[1] === 'recipients' && text.length === 0) {
                    return <Text>Notification not sent</Text>
                } else if (col[1] === 'priority' && text === true) {
                    text = 'High Priority'
                    return <Text>{text}</Text>
                } else if (col[1] === 'priority' && text === false) {
                    text = 'Low Priority'
                    return <Text>{text}</Text>
                } else if (col[1] === 'message' && text === null) {
                    return <Text>No message added</Text>
                }
                return <Text>{text}</Text>
            }
        }
    }

    render() {
        const { original } = this.props
        const docsToShow = this.authorizedDocs()
        return (
            <React.Fragment>
                <Card title="Add Documentation" bordered={false} style={{ width: '-webkit-fill-available', marginBottom: '1em' }}>
                    <Row gutter={[16, 24]} style={{ justifyContent: 'center' }}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                           <Button  
                                style={{ width: '-webkit-fill-available'}}
                                onClick={() =>
                                    this.props.history.push(`/documentation/${original._id}`)
                                }>
                                Add Documentation
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <Card title="View Documentation" bordered={false} style={{ width: '-webkit-fill-available'}}>
                    <Col span={24}>
                        <Table 
                            dataSource={docsToShow} 
                            columns={this.formatColumns()} 
                            scroll={{ x: 1500, y: 700 }}
                        />
                    </Col>
                </Card>
            </React.Fragment>
        )
    }
}

export default Documentation
