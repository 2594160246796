import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import '@ant-design/compatible/assets/index.css'
import MaskedInput from 'antd-mask-input'
import { Breadcrumb, Button, Card, Divider, Form, Input, Layout, Menu, message, Select, Typography, Row, Col } from 'antd'
import { isValidPhone } from '../../../utils/helpers'
import setAuthToken from '../../../utils/setAuthToken'
import { logoutUser, changeUsername } from '../../../core/actions/authActions'

const isEmpty = require('is-empty')
const { Title } = Typography
const { Content } = Layout
const { Option } = Select

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { md: 24, lg: 6 },
}

class Account extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: 'basic',
            verification: false,
            phone: '',
            is_twofactorauth_enabled: this.props.auth.user.is_twofactorauth_enabled,
            hash: null,
            twofactor_method: this.props.auth.user.twofactor_method,
            input_twofactor_disabled: false,
            input_selectmethod_disabled: false,
        }
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {
            this.handleChangeTab({ key: 'security' })
        }
    }

    handleChangeTab = (e) => {
        this.setState({ tab: e.key })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const response = await axios.post('/api/users/changepassword', {
                    id: this.props.auth.user.id,
                    password: values.password,
                    password2: values.password2,
                })
                if (response.status === 200) {
                    this.setState({ success: true })
                }
            }
        })
    }

    handleAccountDeactivation = async () => {
        if (isEmpty(this.state.deactivateEmail)) {
            message.error('Please enter your email in the field..', 2.5)
        } else {
            const response = await axios.post('/api/users/deactivate_account', {
                user_id: this.props.auth.user.id,
                email: this.state.deactivateEmail,
            })
            if (response.status === 200 && response.data.message === 'Success') {
                this.setState({ success: true, deactivateEmail: null })
                this.props.logoutUser()
                this.props.history.push('/login')
            } else {
                message.error(response.data.message, 2.5)
                this.setState({ deactivateEmail: null })
            }
        }
    }

    /*
    Updating User Info Form Functions
    */
    basicSettingsOnFinish = async (values) => {
        const request_body = {
            id: this.props.auth.user.id,
            name: values.name,
        }
        try {
            await axios
                    .post('/api/users/updateinfo', request_body)
                    .then((res) => {
                        message.success('Info updated successfully')
                        this.props.changeUsername(res.data.name)
                        const { token } = res.data
                        localStorage.setItem('jwtToken', token)
                        setAuthToken(token)
                    })
        } catch (err) {
            message.error('Failed to update info: ' + err)
        }
    }

    basicSettingsOnFinishFailed = (errorInfo) => {
        message.error('Please fill out all required fields.')
        console.log(errorInfo)
    }

    /*
    Updating User Password Form Functions
    */
    securitySettingsOnFinish = async (values) => {
        const request_body = {
            id: this.props.auth.user.id,
            password: values.password,
            password2: values.password2,
        }
        try {
            await axios.post('/api/users/changepassword', request_body)
            message.success('Updated password successfully. Please re-login to see changes.')
        } catch (err) {
            message.error('Failed to update password: ' + err)
            // console.log(err)
        }
    }

    securitySettingsOnFinishFailed = (errorInfo) => {
        message.error('Please fill out all required fields.')
        console.log(errorInfo)
    }

    twoFactorSettingsOnFinish = async (values) => {
        const twofactor_method = values.twofactor_method.value
        let request_body = {
            id: this.props.auth.user.id,
            twofactor_method: twofactor_method,
        }

        if (twofactor_method === 'sms') {
            if (!isValidPhone(values.phone)) {
                return message.error('Please enter correct U.S. phone number!')
            }
            request_body = Object.assign(request_body, { phone: values.phone })
        }

        await axios
            .post('/api/users/twofactorauth', request_body)
            .then(() => {
                message.success("Two factor method updated")
            })
            .catch((err) => console.error(err)) 
    }

    securitySettingsOnFinishFailed = (errorInfo) => {
        message.error('Please fill out all required fields.')
        console.log(errorInfo)
    }

    twoFactorAuthValidatorOnFinish = async (values) => {
        const request_body = {
            id: this.props.auth.user.id,
            is_twofactorauth_enabled: this.state.is_twofactorauth_enabled,
            twofactor_method: this.state.twofactor_method,
            phone: this.state.phone,
            hash: this.state.hash,
            otpcode: values.otpcode,
        }

        try {
            const response = await axios.post('/api/users/verifyotpmethod', request_body)
            if (response.data.success) {
                this.setState({ verification: false, phone: '', hash: null, input_selectmethod_disabled: false, input_twofactor_disabled: false })
                message.success('Two Factor Authentication is successfully set up. Please re-login to see changes.')
            } else {
                message.error('Please enter the correct code')
            }
        } catch (err) {
            message.error('Validation code failed: ' + err)
            console.log(err)
        }
    }

    twoFactorAuthValidatorOnFailed = (errorInfo) => {
        message.error('Please fill out all required fields.')
        console.log(errorInfo)
    }

    handleTwoFactorMethod = (obj) => {
        this.setState({ twofactor_method: obj.value })
    }

    cancelTwoFactorForm = () => {
        this.setState({ verification: false, phone: '', hash: null, input_selectmethod_disabled: false, input_twofactor_disabled: false })
    }

    /*
    Deactivate account Form Functions
    */
    deactivateSettingsOnFinish = async (values) => {
        const request_body = {
            user_id: this.props.auth.user.id,
            email: values.email,
        }
        try {
            await axios.post('/api/users/deactivate_account', request_body)
            message.success('Account has been deactivated!')
            this.props.logoutUser()
            this.props.history.push('/login')
        } catch (err) {
            message.error('Failed to update update password:' + err)
            console.log(err)
        }
    }

    deactivateSettingsOnFinishFailed = (errorInfo) => {
        message.error('Please fill out all required fields.')
        console.log(errorInfo)
    }

    render() {
        const { tab } = this.state
        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <Content style={{ width: '100%' }}>
                    <div style={{ marginBottom: '16px', width: '100%' }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>User</Breadcrumb.Item>
                            <Breadcrumb.Item>Account</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </Content>

                <Card
                    bodyStyle={{
                        padding: 0,
                        display: 'flex',
                        width: '-webkit-fill-available',
                        overflow: 'auto',
                    }}>
                    <div style={{ width: 256 }}>
                        <Menu
                            onClick={this.handleChangeTab}
                            style={{
                                minHeight: '50em',
                            }}
                            // defaultSelectedKeys={['basic']}
                            mode="inline">
                            <Menu.ItemGroup>
                                <Menu.Item key="basic">Basic Settings</Menu.Item>
                                <Menu.Item key="security">Security Settings</Menu.Item>
                                <Menu.Item key="deactivate">Deactivate Account</Menu.Item>
                            </Menu.ItemGroup>
                        </Menu>
                    </div>
                    <div style={{ flex: '1 1', padding: '24px 40px' }}>
                        {tab === 'basic' && (
                            <React.Fragment>
                                <Title
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: 500,
                                        lineHeight: '28px',
                                    }}>
                                    Basic Settings
                                </Title>
                                <div
                                    style={{
                                        display: 'flex',
                                        paddingTop: '12px',
                                    }}>
                                    <div
                                        style={{
                                            width: '100%',
                                        }}>
                                        <Form {...layout} name="basic" onFinish={this.basicSettingsOnFinish} onFinishFailed={this.basicSettingsOnFinishFailed}>
                                            <Form.Item
                                                label="Name"
                                                name="name"
                                                initialValue={this.props.auth.user.name}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your name!',
                                                    },
                                                ]}>
                                                <Input/>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Update
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                        {tab === 'security' && (
                            <React.Fragment>
                                <Title
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: 500,
                                        lineHeight: '28px',
                                    }}>
                                    Choose your preferred two-factor authentication method
                                </Title>
                                <div
                                    style={{
                                        display: 'flex',
                                        paddingTop: '12px',
                                    }}>
                                    <div
                                        style={{
                                            width: '100%',
                                        }}>
                                        <Form {...layout} name="twoFactorAuthForm" onFinish={this.twoFactorSettingsOnFinish} onFinishFailed={this.twoFactorSettingsOnFinishFailed}>
                                            <Row gutter={[16, 16]}></Row>
                                            <Form.Item label="Select Method" name="twofactor_method" initialValue={{ value: this.state.twofactor_method }}>
                                                <Select labelInValue style={{ width: 120 }} onChange={this.handleTwoFactorMethod} disabled={this.state.input_selectmethod_disabled}>
                                                    <Option value="email">Email</Option>
                                                    <Option value="sms">SMS</Option>
                                                </Select>
                                            </Form.Item>
                                            {this.state.twofactor_method === 'sms' && (
                                                <Form.Item
                                                    label="Mobile number"
                                                    name="phone"
                                                    hasFeedback
                                                    initialValue={this.props.auth.user.phone}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your U.S phone number!',
                                                        },
                                                    ]}>
                                                    {/* <Input addonBefore="+1" /> */}
                                                    <MaskedInput
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        addonBefore="+1"
                                                        mask="(111) 111-1111"
                                                        placeholder="(XXX) XXXX-XXXX"
                                                    />
                                                </Form.Item>
                                            )}
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" style={{ marginTop: '20px' }}>
                                                        Update
                                                    </Button>
                                                </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                                <Divider />
                                <Title
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: 500,
                                        lineHeight: '28px',
                                    }}>
                                    Update password
                                </Title>
                                <div
                                    style={{
                                        display: 'flex',
                                        paddingTop: '12px',
                                    }}>
                                    <div
                                        style={{
                                            width: '100%',
                                        }}>
                                        <Form {...layout} name="basic" onFinish={this.securitySettingsOnFinish} onFinishFailed={this.securitySettingsOnFinishFailed}>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} sm={24} md={12} lg={7}>
                                                    <Form.Item
                                                        label="New Password"
                                                        name="password"
                                                        labelAlign="left"
                                                        style={{ flexDirection: 'column' }}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your email!',
                                                            },
                                                        ]}>
                                                        <Input.Password style={{width: '300%'}}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={7}>
                                                    <Form.Item
                                                        label="Confirm Password"
                                                        name="password2"
                                                        labelAlign="left"
                                                        style={{ flexDirection: 'column' }}
                                                        dependencies={['password']}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please confirm your password!',
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(rule, value) {
                                                                    if (!value || getFieldValue('password') === value) {
                                                                        return Promise.resolve()
                                                                    }
                                                                    return Promise.reject('The two passwords that you entered do not match!')
                                                                },
                                                            }),
                                                        ]}>
                                                        <Input.Password style={{width: '300%'}}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Update
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                        {tab === 'deactivate' && (
                            <React.Fragment>
                                <Title
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: 500,
                                        lineHeight: '28px',
                                    }}>
                                    Deactivate Account
                                </Title>
                                <div
                                    style={{
                                        display: 'flex',
                                        paddingTop: '12px',
                                    }}>
                                    <div
                                        style={{
                                            width: '100%',
                                        }}>
                                        <Form {...layout} name="basic" onFinish={this.deactivateSettingsOnFinish} onFinishFailed={this.deactivateSettingsOnFinishFailed}>
                                            <Form.Item
                                                label="Enter Your Email Address to Confirm"
                                                name="email"
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your email!',
                                                    },
                                                ]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Deactivate
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </Card>
            </Content>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
})

export default connect(mapStateToProps, { logoutUser, changeUsername })(Account)
