import React, { Component } from 'react'
import { CSVLink } from 'react-csv'
import Moment from 'moment'
import axios from 'axios'
import { Breadcrumb, Button, Card, Col, DatePicker, Divider, Form, Input, Layout, Tooltip, Modal, Row, Select, Spin, Table, TreeSelect, Typography, message } from 'antd'
import { SearchOutlined, DownloadOutlined, StarOutlined, UndoOutlined, ExclamationCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { agencies, referral_keys_readable, report_keys_readable, referral_needs, registered_project_reports, report_projection_options, shared_care_counties, date_format} from '../../../utils/constants'
import './reports.scss'
import SavedQuery from './saved-query'

const Content = Layout
const { Option } = Select
const { Text } = Typography
const { RangePicker } = DatePicker
const layout = {
    labelCol: { xs: 24, sm: 4 },
    wrapperCol: { xs: 24, sm: 18 },
}

const agenciesList = agencies.filter((item) => item !== 'None')


const defaultTreeValue = ['nano_id', 'last_name', 'first_name', 'dob']

const { SHOW_CHILD } = TreeSelect

const defaultInitialStateValues = {
    formInitialValues: {
        ['maxLimit']: 10,
        ['selectedFields']: defaultTreeValue,
    },
    loading: false,
    targetKeys: defaultTreeValue,
    sourceKeys: [],
    selectedKeys: [],
    tableColumns: [],
    tableData: [],
    csvColumns: [],
    csvData: [],
    tableDataSize: 0,
    dateRangeSelected: '',
    firstContactRangeDateSelected: '',
    disabledSaveButton: true,
    inputTitleValue: '',
    modalVisible: false,
    confirmLoading: false,
    currentFormValues: {},
    toggleFetchList: false,
    disabledDownloadButton: true,
    queryList: [],
    err: null,
    sourceDataTree: [],
    selectedColumnsKeys: [],
    readableProjectionKeys: {},
}
class Reports extends Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            ...defaultInitialStateValues,
        }
    }

    componentDidMount() {
        this.projectionFieldsInit(report_projection_options)
    }

    handleResetFilters = () => {
        this.formRef.current.resetFields()
        this.setState({ ...defaultInitialStateValues })
        this.projectionFieldsInit(report_projection_options)
    }

    handleResetFilters = () => {
        this.formRef.current.resetFields()
        this.setState({ ...defaultInitialStateValues })
        this.projectionFieldsInit(report_projection_options)
    }

    loading = (toggle = false) => {
        this.setState({ loading: toggle })
    }

    projectionFieldsInit = (report_projection_options) => {
        let sourceDataTree = []
        let readableProjectionKeys = {}
        for (const category of report_projection_options) {
            for (const field in category) {
                let parentKey = field
                let parentObject = {
                    title: parentKey,
                    value: parentKey,
                    key: parentKey,
                }
                let childrenTree = []
                for (const [key, value] of Object.entries(category[field])) {
                    childrenTree.push({
                        title: value,
                        value: key,
                        key: key,
                    })
                    Object.assign(readableProjectionKeys, { [key]: value })
                }
                sourceDataTree.push(Object.assign({}, parentObject, { children: childrenTree }))
            }
        }

        this.setState({ sourceDataTree, readableProjectionKeys })
    }

    fetchQueryList = (formValues) => {
        var ignoredList = ['rangeDate', 'firstContactRangeDate']
        var queryList = []
        for (const [key, value] of Object.entries(formValues)) {
            if (ignoredList.includes(key)) continue
            else if (typeof value === 'undefined') continue
            else if (Array.isArray(value)) {
                let arrayValues = value
                if (key === 'selectedFields') {
                    arrayValues = value.map((field) => {
                        return referral_keys_readable[field]
                    })
                }
                queryList.push({ key: report_keys_readable[key], value: arrayValues.join(', ') })
            } else {
                queryList.push({ key: report_keys_readable[key], value: value })
            }
        }
        this.setState({ queryList })
    }

    fetchTable = (responseData = []) => {
        let tableColumns = []
        let csvColumns = []
        const columnKeys = this.state.selectedColumnsKeys
        this.loading(true)
        if (columnKeys && columnKeys.length > 0) {
            for (const key of columnKeys) {
                tableColumns.push({
                    title: this.state.readableProjectionKeys[key],
                    dataIndex: key,
                })
                csvColumns.push({
                    label: this.state.readableProjectionKeys[key],
                    key: key,
                })
            }
        }

        let tableData = responseData
        let tableDataSize = 0

        if (tableData.length > 0) {
            tableDataSize = tableData.length
            tableData.map((row, i) => (row['key'] = i))
        }

        tableData.forEach((referral) => {
            if (referral['assessment_contact_date']) {
                referral['assessment_contact_date'] = Moment(referral['assessment_contact_date']).format(date_format)
            }
        })

        this.setState({ csvColumns, csvData: tableData, tableColumns, tableData, tableDataSize })
        this.loading(false)
    }

    onFinish = async (inputValues) => {
        let rangeDateObj = {}
        let firstContactRangeDateObj = {}

        if ((inputValues.selectedFields && inputValues.selectedFields.length === 0) || inputValues.selectedFields === undefined) {
            inputValues['selectedFields'] = defaultTreeValue
        } else {
            // flatten the children fields
            const sourceData = this.state.sourceDataTree
            let flattenSourceData = []
            const categoryKeys = []
            for (const category of sourceData) {
                categoryKeys.push(category.value)
            }
            for (const field of inputValues['selectedFields']) {
                if (categoryKeys.includes(field)) {
                    const result = sourceData.find((item) => {
                        return item.value === field
                    })
                    if ((result && result.length > 0) || result !== undefined) {
                        const nodeKey = result.children.map((item) => item.key)
                        flattenSourceData.push(nodeKey)
                    }
                } else {
                    flattenSourceData.push(field)
                }
            }
            inputValues['selectedFields'] = flattenSourceData.flat()
        }
        this.setState({ selectedColumnsKeys: inputValues['selectedFields'] })

        if (typeof inputValues['rangeDate'] !== 'undefined' && inputValues['rangeDate'] !== null) {
            rangeDateObj = {
                startDate: inputValues['rangeDate'][0].format('YYYY-MM-DD'),
                endDate: inputValues['rangeDate'][1].format('YYYY-MM-DD'),
            }
            this.setState({ dateRangeSelected: `${inputValues['rangeDate'][0].format('MM/DD/YYYY')} - ${inputValues['rangeDate'][1].format('MM/DD/YYYY')}` })
        }

        if (typeof inputValues['firstContactRangeDate'] !== 'undefined' && inputValues['firstContactRangeDate'] !== null) {
            firstContactRangeDateObj = {
                firstContactStartDate: inputValues['firstContactRangeDate'][0].format('YYYY-MM-DD'),
                firstContactEndDate: inputValues['firstContactRangeDate'][1].format('YYYY-MM-DD'),
            }
            this.setState({ firstContactRangeDateSelected: `${inputValues['firstContactRangeDate'][0].format('MM/DD/YYYY')} - ${inputValues['firstContactRangeDate'][1].format('MM/DD/YYYY')}` })
        }

        let values = {
            ...inputValues,
            ...rangeDateObj,
            ...firstContactRangeDateObj,
        }

        this.setState({ currentFormValues: values, disabledSaveButton: true, disabledDownloadButton: false })

        // don't make AJAX call if everything is undefined
        if (values['county'] === undefined && values['firstContactRangeDate'] === undefined && values['needs'] === undefined && values['project'] === undefined && values['rangeDate'] === undefined && values['referralAgency'] === undefined) {
            return message.error('Enter search criteria')
        }

        try {
            const APIresponse = await axios.post('/api/reports/referral', values)
            //console.log(APIresponse)
            this.fetchTable(APIresponse.data)
        } catch (error) {
            this.setState({
                err: error.response,
            })
        }

        this.setState({ disabledSaveButton: false })
    }

    handleTitleModal = () => {
        this.setState({ modalTitleVisible: true })
    }

    handleInputTitleChange = (e) => {
        this.setState({ inputTitleValue: e.target.value })
    }

    handleSaveQuery = async () => {
        this.setState({ confirmLoading: true })

        const values = {
            title: this.state.inputTitleValue,
            saved_query: this.state.currentFormValues,
            user_id: this.props.auth.user.id,
            created_on: Moment().format('MM-DD-YYYY').toString(),
        }

        try {
            await axios.post('/api/reports/saveQuery', values)
            this.setState({ toggleFetchList: true })
        } catch (error) {
            this.setState({
                err: error.response,
                toggleFetchList: false,
            })
        }

        setTimeout(() => {
            this.setState({ modalTitleVisible: false, inputTitleValue: '', confirmLoading: false })
        }, 2000)
    }

    handleCancel = () => {
        this.setState({ modalTitleVisible: false, inputTitleValue: '' })
    }

    handleToggleFetchList = (toggle) => {
        this.setState({ toggleFetchList: toggle })
    }

    handleLoadSavedQuery = async (savedQueryId) => {
        const values = {
            savedQueryId: savedQueryId,
        }
        try {
            const APIresponse = await axios.post('/api/reports/getSavedQueryById', values)
            const { saved_query } = APIresponse.data
            this.formRef.current.setFieldsValue({
                project: saved_query.project,
                rangeDate: Array.isArray(saved_query.rangeDate) ? [Moment(saved_query.rangeDate[0]), Moment(saved_query.rangeDate[1])] : null,
                referralAgency: saved_query.referralAgency,
                county: saved_query.county,
                needs: saved_query.needs,
                firstContactRangeDate: Array.isArray(saved_query.firstContactRangeDate) ? [Moment(saved_query.firstContactRangeDate[0]), Moment(saved_query.firstContactRangeDate[1])] : null,
                selectedFields: saved_query.selectedFields,
            })

            this.setState({ targetKeys: saved_query.selectedFields })
            this.setState({ disabledDownloadButton: true, disabledSaveButton: true })
        } catch (error) {
            this.setState({
                err: error.response,
            })
        }
    }

    handleDeleteSavedQuery = async (savedQueryId) => {
        const values = {
            savedQueryId: savedQueryId,
        }
        try {
            const APIresponse = await axios.post('/api/reports/deleteSavedQueryById', values)
            const { data } = APIresponse
            //console.log(data)
            this.setState({ toggleFetchList: true })
        } catch (error) {
            this.setState({
                err: error.response,
            })
        }
    }

    onFinishFailed = (err) => {
        console.log(err)
    }

    projectOptions = () => {
        const children = []
        registered_project_reports.forEach((project) => children.push(<Option key={project}>{project}</Option>))
        return children
    }

    agencyOptions = () => {
        const children = []
        agenciesList.forEach((agency) => children.push(<Option key={agency}>{agency}</Option>))
        return children
    }

    countyOptions = () => {
        const children = []
        shared_care_counties.forEach((county) => children.push(<Option key={county}>{county}</Option>))
        return children
    }

    needOptions = () => {
        const children = []
        referral_needs.forEach((need) => children.push(<Option key={need}>{need}</Option>))
        return children
    }

    showDownloadConfirm = () => {
        Modal.info({
            title: 'Do you want to Download CSV?',
            content: (
                <Row style={{ marginBottom: '1em', marginTop: '1em', textAlign: 'center' }}>
                    <Col>
                        <div>
                            <p style={{ paddingTop: '1em' }}>
                                <b>By clicking this button, you understand that once you download ConnexRX data, we cannot ensure it will be kept secure.</b> You are attempting to download a file
                                which includes protected health information (“PHI”). We secure the data on ConnexRX servers using encryption and other technical, physical, and procedural safeguards.
                                You understand that once you download the data, we cannot guarantee the security of that downloaded data file.
                            </p>
                            <p>
                                You must have a valid Use and Disclose reason for accessing and downloading this PHI. IF YOU DO NOT have a valid, legal reason for downloading this information, you
                                make liable for civil penalties and/or criminal charges. PROCEED WITH CAUTION AND UNDER NOTICE.
                            </p>
                            <br />
                            <CSVLink
                                headers={this.state.csvColumns}
                                data={this.state.csvData}
                                filename={'Report-' + Moment().format('MM-DD-YYYY') + '-' + Math.floor(Math.random() * 1000 + 1) + '.csv'}
                                target="_blank">
                                <Button type="primary" icon={<DownloadOutlined />} title="Please disable your ads blocker to download the file" size="large">
                                    Continue Download
                                </Button>
                            </CSVLink>
                        </div>
                    </Col>
                </Row>
            ),
            okText: 'Close',
            maskClosable: true,
            destroyOnClose: true,
        })
    }

    addAllProjects = () => {
        this.formRef.current.setFieldsValue({
            project: registered_project_reports,
        })
    }

    addAllAgencies = () => {
        this.formRef.current.setFieldsValue({
            referralAgency: agenciesList,
        })
    }

    addAllCounties = () => {
        this.formRef.current.setFieldsValue({
            county: shared_care_counties,
        })
    }

    addAllNeeds = () => {
        this.formRef.current.setFieldsValue({
            needs: referral_needs,
        })
    }

    render() {
        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <Content style={{ width: '100%' }}>
                    <div style={{ marginBottom: '16px', width: '100%' }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>Referrals</Breadcrumb.Item>
                            <Breadcrumb.Item>Generate Report</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row gutter={[24, 16]}>
                        <Col span={18}>
                            <Card
                                bodyStyle={{
                                    padding: 26,
                                    width: '100%',
                                }}
                                title="Create a Report">
                                <Text>Generate a custom report after setting your search criteria</Text>
                                <Divider>Set Filters</Divider>
                                <Form
                                    {...layout}
                                    ref={this.formRef}
                                    name="reportFormRef"
                                    initialValues={this.state.formInitialValues}
                                    style={{
                                        width: '100%',
                                        marginTop: '2em',
                                    }}
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    requiredMark={false}>
                                    <Form.Item
                                        label="Date Received"
                                        name="rangeDate"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please input an date range!',
                                            },
                                        ]}
                                        style={{ marginLeft: '2.2rem' }}>
                                        <RangePicker />
                                    </Form.Item>
                                    <Row>
                                        <Col xs={24} md={24} lg={1}>
                                            <Button type="primary" shape="circle" onClick={this.addAllProjects}>
                                                All
                                            </Button>
                                        </Col>
                                        <Col xs={24} md={24} lg={22}>
                                            <Form.Item
                                                label="Project"
                                                name="project"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'Project is required',
                                                    },
                                                ]}>
                                                <Select mode="multiple" allowClear clearIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" />} >
                                                    {this.projectOptions()}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} md={24} lg={1}>
                                            <Button type="primary" shape="circle" onClick={this.addAllAgencies}>
                                                All
                                            </Button>
                                        </Col>
                                        <Col xs={24} md={24} lg={22}>
                                            <Form.Item
                                                label="Agency"
                                                name="referralAgency"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'Agency is required',
                                                    },
                                                ]}>
                                                <Select mode="multiple" allowClear clearIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" />} >
                                                    {this.agencyOptions()}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} md={24} lg={1}>
                                            <Button type="primary" shape="circle" onClick={this.addAllCounties}>
                                                All
                                            </Button>
                                        </Col>
                                        <Col xs={24} md={24} lg={22}>
                                            <Form.Item
                                                label="County"
                                                name="county"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'County is required',
                                                    },
                                                ]}>
                                                <Select mode="tags" allowClear tokenSeparators={[',']} clearIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" />}>
                                                    {this.countyOptions()}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24} lg={1}>
                                            <Tooltip title="Counties not included in the dropdown can be added by typing into the field using the following format: capitalized first letter, correct spelling, no leading or trailing spaces, and followed directly by a comma (e.g. Albany,)">
                                                <ExclamationCircleTwoTone twoToneColor="#fa8c16" />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} md={24} lg={1}>
                                            <Button type="primary" shape="circle" onClick={this.addAllNeeds}>
                                                All
                                            </Button>
                                        </Col>
                                        <Col xs={24} md={24} lg={22}>
                                            <Form.Item
                                                label="Needs"
                                                name="needs"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'Need is required',
                                                    },
                                                ]}>
                                                <Select mode="multiple" allowClear clearIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" />}>
                                                    {this.needOptions()}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {<Form.Item
                                        label="First Contact Date"
                                        name="firstContactRangeDate"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please input an date range!',
                                            },
                                        ]}
                                        style={{ marginLeft: '2.2rem' }}>
                                        <RangePicker />
                                    </Form.Item>}

                                    <Divider>Choose Columns</Divider>
                                    <Row gutter={[24, 24]}>
                                        <Col xs={{ span: 24 }}>
                                            <Form.Item label="Choose Columns" name="selectedFields">
                                                <TreeSelect
                                                    treeData={this.state.sourceDataTree}
                                                    value={this.state.targetKeys}
                                                    treeCheckable={true}
                                                    showCheckedStrategy={SHOW_CHILD}
                                                    placeholder="Please select"
                                                    onChange={(ids) => {
                                                        this.setState({ targetKeys: ids })
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Divider>Actions</Divider>
                                    <Form.Item
                                        wrapperCol={{
                                            xs: { span: 24, offset: 0 },
                                            sm: { span: 16, offset: 4 },
                                        }}>
                                        <Button
                                            style={{ marginRight: '1em' }}
                                            type="default"
                                            disabled={this.state.disabledSaveButton}
                                            onClick={() => this.handleTitleModal()}
                                            size="large"
                                            icon={<StarOutlined />}>
                                            Save Filter
                                        </Button>
                                        <Button style={{ marginRight: '1em' }} type="primary" htmlType="submit" size="large" icon={<SearchOutlined />} disabled={this.state.loading}>
                                            Generate Report
                                        </Button>
                                        <Button type="default" onClick={() => this.handleResetFilters()} size="large" icon={<UndoOutlined />}>
                                            Reset Filters
                                        </Button>
                                        <Modal
                                            title="Input query title"
                                            visible={this.state.modalTitleVisible}
                                            onOk={() => this.handleSaveQuery()}
                                            confirmLoading={this.state.confirmLoading}
                                            onCancel={() => this.handleCancel()}>
                                            <p>
                                                <Input placeholder="i.e. Last month new referrals" onChange={(e) => this.handleInputTitleChange(e)} value={this.state.inputTitleValue} maxLength={40} />
                                            </p>
                                        </Modal>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <SavedQuery
                                userId={this.props.auth.user.id}
                                toggleFetchList={this.state.toggleFetchList}
                                handleToggleFetchList={this.handleToggleFetchList}
                                handleLoadSavedQuery={this.handleLoadSavedQuery}
                                handleDeleteSavedQuery={this.handleDeleteSavedQuery}
                            />
                        </Col>
                    </Row>

                    <Row gutter={[24, 16]}>
                        <Col span={24}>
                            <Card
                                bodyStyle={{
                                    padding: 26,
                                    width: '100%',
                                }}
                                title="Results">
                                <Text>A preview will appear below after a report is generated</Text>
                                <Divider>Results</Divider>
                                <Spin spinning={this.state.loading} size="large">
                                    <Table
                                        style={{
                                            marginTop: '2em',
                                        }}
                                        columns={this.state.tableColumns}
                                        dataSource={this.state.tableData}
                                        pagination={{
                                            pageSize: 50,
                                            showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} items`,
                                        }}
                                        size="default"
                                        rowKey="_id"
                                        tableLayout="auto"
                                        scroll={{ x: 'max-content', y: 700 }}
                                    />
                                </Spin>
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={[24, 16]}>
                        <Col span={24}>
                            <Card
                                bodyStyle={{
                                    padding: 26,
                                    width: '100%',
                                }}
                                title="Download Report">
                                <Button
                                    disabled={this.state.disabledDownloadButton}
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    title="Please disable your ads blocker to download the file"
                                    size="large"
                                    onClick={() => this.showDownloadConfirm()}>
                                    Download CSV
                                </Button>
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Content>
        )
    }
}

export default Reports
