import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import { Col, Row, Select, Input, Checkbox } from 'antd'
import MaskedInput from 'antd-mask-input'
import { emergency_contact_relationship } from '../../../../utils/constants'
const { Option } = Select


class EmergencyContact extends Component {
    constructor() {
        super()
        this.state = {
            emergency_contact: false,
        }
    }

    render() {
        const { getFieldDecorator, original, clientDetailsDisabled } = this.props
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={24} lg={6}>
                        <Checkbox
                            checked={this.state.emergency_contact}
                            style={{paddingBottom: '20px'}}
                            disabled={clientDetailsDisabled}
                            onClick={() => {
                                this.setState({ emergency_contact: !this.state.emergency_contact })
                            }}>
                            Check to add an emergency contact
                        </Checkbox>
                    </Col>
                </Row>
                {this.state.emergency_contact && (
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`Emergency Contact First Name`}>
                                {getFieldDecorator(`emergency_contact_first_name`, {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ],
                                    initialValue: original.emergency_contact_first_name,
                                })(
                                    <Input
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        disabled={clientDetailsDisabled}
                                        placeholder=" "
                                        style={{
                                            width: '100%',
                                        }}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`Emergency Contact Last Name`}>
                                {getFieldDecorator(`emergency_contact_last_name`, {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ],
                                    initialValue: original.emergency_contact_last_name,
                                })(
                                    <Input
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        disabled={clientDetailsDisabled}
                                        placeholder=" "
                                        style={{
                                            width: '100%',
                                        }}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`Emergency Contact Phone`}>
                                {getFieldDecorator(`emergency_contact_phone`, {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ],
                                    initialValue: original.emergency_contact_phone,
                                })(
                                    <MaskedInput
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        style={{
                                            width: '100%',
                                        }}
                                        mask="(111) 111-1111"
                                        disabled={clientDetailsDisabled}
                                        placeholder=" "
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`Emergency Contact Relationship`}>
                                {getFieldDecorator(`emergency_contact_relationship`, {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ],
                                    initialValue: original.emergency_contact_relationship,
                                })(
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        showSearch
                                        placeholder=" "
                                        optionFilterProp="children"
                                        defaultActiveFirstOption={true}
                                        disabled={clientDetailsDisabled}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {emergency_contact_relationship.sort().map((county) => (
                                            <Option key={county}>{county}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </>
        )
    }
}

export default EmergencyContact