import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Link } from 'react-router-dom'
import MaskedInput from 'antd-mask-input'
import styled, { css } from 'styled-components'
import { detailedDiff } from 'deep-object-diff'
import { StepBackwardOutlined, DeleteOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Alert, BackTop, Breadcrumb, Card, Col, Layout, Row, Result, Checkbox, Select, Modal, Button, Input, DatePicker, Popover, TimePicker, TreeSelect, Statistic, Switch, Typography } from 'antd'
import {
    address_type,
    referral_statuses,
    referral_statuses_cbhc_mvp,
    agencies,
    genders,
    ethnicity,
    languages_list,
    countys_list,
    insurance_types,
    insurance_plans,
    availablility,
    date_format,
    time_format,
    county_zip_codes,
    referral_sources,
    referral_needs,
    referral_titles,
    harp_values,
    health_home_facility,
    care_management_facility,
    engagement_contact_type,
    engagement_number_of_contacts,
} from '../../../utils/constants'
import { isValidPhone, checkMomentDate, checkMomentTime } from '../../../utils/helpers'
import CareManagement from './care-management/care-management'
import Losd from './losd/losd'
import './edit-referral.scss'
import EmergencyContact from './emergency_contact/emergency_contact'

const { Content } = Layout
const { TextArea } = Input
const { Option } = Select
const { SHOW_PARENT } = TreeSelect
const { confirm } = Modal
const { Title } = Typography

const CustomCheckbox = styled(Checkbox)`
    ${(props) =>
        props.backgroundColor &&
        css`
            & .ant-checkbox-checked .ant-checkbox-inner {
                background-color: ${props.backgroundColor};
                border-color: ${props.backgroundColor};
            }
        `}
`

function showConfirm() {
    confirm({
        title: 'Do you want to return to dashboard?',
        content: 'Unsaved input will be lost',
        onOk() {
            window.location.replace('')
        },
        onCancel() {},
    })
}

class EditReferral extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submit_button_loading: false,
            submit_button_disabled: false,
            referral_submitted: false,
            err: null,
            original: {},
            id: null,
            nano_id: null,
            apptDateCheck: false,
            firstContactCheck: true,
            consent: true,
            skip_consent: false,
            currentAddressType: null,
            addressRequiredFields: {
                address_1: true,
                address_2: true,
                city: true,
                state: true,
                zip: true,
                county: true,
                phone: true,
            },
            showShelterNameField: false,
            Uninsured: false,
            health_home_enrolled: false,
            care_management_enrolled: false,
            show_guardian: false,
            daysLapsed: 0,
            mailing_address: false,
            client_details_disabled: true,
            emergency_contact: false,
        }
    }

    async componentDidMount() {
        // to do: make this into a fetchReferral function that is called after referral is submitted to refresh state with new data 
        const referral_id = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        const response = await axios.post('/api/referrals/find_referral', {
            referral_id: referral_id,
        })

        response.data[0].dob = response.data[0].dob.replace(/-/g, '/').replace(/T.+/, '')
        response.data[0].veteran = response.data[0].veteran === 'true'
        response.data[0].active_duty = response.data[0].active_duty === 'true'

        this.setState({
            original: response.data[0],
            id: referral_id,
            nano_id: response.data[0].nano_id,
            consent: response.data[0].consent,
            consent_date: response.data[0].consent_date,
            agency_selected: response.data[0].referral_agency,
            health_home_enrolled: response.data[0].health_home_enrolled,
            care_management_enrolled: response.data[0].care_management_enrolled,
            show_guardian: response.data[0].is_guardian === 'Y' ? true : false,
        })
        if (response.data[0].assessment_contact_date) {
            this.setState({
                firstContactCheck: false,
                firstContactDate: response.data[0].assessment_contact_date,
            })
        }
        
        const address_type = response.data[0].hasOwnProperty('address_type') ? response.data[0]['address_type'] : 'Primary Residence'

        this.handleAddressTypeOnChange(address_type)

        // handle initial days lapsed value
        let last_pcp_followup_date = response.data[0].hasOwnProperty('last_pcp_followup_date') ? checkMomentDate(response.data[0]['last_pcp_followup_date']) : ''

        this.handleDayslapsed(last_pcp_followup_date)
    }

    handleClientDetailsToggle = () => {
        this.setState({
            client_details_disabled: !this.state.client_details_disabled,
        })
    }

    onCheckboxChange(e, checkboxName) {
        this.setState({ [checkboxName]: e.target.checked })
    }

    handleZipCodeCounty = (e) => {
        e.preventDefault()
        this.props.form.setFieldsValue({
            county: county_zip_codes[e.target.value],
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.setState({
            submit_button_loading: true,
        })
        let original_values = {}

        this.props.form
            .validateFields(async (err, values) => {
                if (!err) {
                    if (values['referral_agency'] !== 'None' && values['referral_agency'] !== 'CBHS') {
                        original_values['new_date_assigned'] = moment().format(date_format)
                    }
                    if (values['referral_status'] === 'No Show' || values['referral_status'] === 'Cancelled') {
                        values['appointment_date'] = undefined
                    }
                    values['id'] = this.state.id
                    values['nano_id'] = this.state.nano_id

                    // for date assigned checking, whether need to change the date assigned or not in the backend
                    original_values['original_referral_agency'] = this.state.original.referral_agency || ''
                    original_values['original_date_assigned'] = this.state.original.date_assigned || ''
                    original_values['original_address'] = {
                        address_1: this.state.original.address_1,
                        city: this.state.original.city,
                        state: this.state.original.state,
                        zip: this.state.original.zip
                    }
                    original_values['validAddress'] = this.state.original.validAddress

                    // normalize date to string
                    const date_fields = [
                        'appointment_date',
                        'last_annual_physical_date',
                        'last_pcp_followup_date',
                        'dob',
                        'insurance_1_effective_date',
                        'insurance_1_expiration_date',
                        'insurance_2_effective_date',
                        'insurance_2_expiration_date',
                        'insurance_3_effective_date',
                        'insurance_3_expiration_date',
                        'qm_1_date',
                        'qm_2_date',
                        'qm_3_date',
                        'qm_4_date',
                        'qm_5_date',
                        'qm_6_date',
                        'health_home_enrollment_date',
                        'care_management_enrollment_date',
                        'engagement_date',
                        'contact_date',
                        'assessment_date_approved',
                        'assessment_contact_date',
                        'assessment_date_completed',
                        'assessment_date_submitted'
                    ]
                    date_fields.forEach((field) => {
                        if (values[field] !== '' && values[field] !== undefined && values[field] !== null) {
                            values[field] = moment(values[field]).format(date_format)
                        }
                        // delete the property if it's blank. so reporting filter will work 
                        if (values[field] === '') {
                            delete values[field]
                        }
                    })

                    // normalize time to string
                    const time_fields = ['contact_time', 'engagement_time', 'appointment_time', 'last_annual_physical_time', 'last_pcp_followup_time']
                    time_fields.forEach((field) => {
                        if (values[field] !== '' && values[field] !== undefined && values[field] !== null) {
                            values[field] = moment(values[field]).format(time_format)
                        }
                    })

                    // console.log('values',values)

                    var orig_referral = { ...this.state.original }
                    for (const prop in values) {
                        if (!Object.getOwnPropertyNames(orig_referral).includes(prop) && values[prop] === undefined) {
                            orig_referral[prop] = undefined
                        } else if (!Object.getOwnPropertyNames(orig_referral).includes(prop)) {
                            orig_referral[prop] = ''
                        }
                    }
                    orig_referral['mailing_state'] = values['mailing_state']

                    values['number_cancelled'] = orig_referral.number_cancelled || 0
                    values['number_noshows'] = orig_referral.number_noshows || 0

                    if (this.state.original.referral_status !== values['referral_status']) {
                        if (values['referral_status'] === 'Cancelled') values['number_cancelled'] += 1
                        if (values['referral_status'] === 'No Show') values['number_noshows'] += 1
                    }
 
                    values['consent'] = this.state.consent
                    values['consent_date'] = moment().format(date_format)
                    values['user_id'] = this.props.auth.user.id
                    values['project'] = this.state.original.project
                    values['date_received'] = this.state.original.date_received

                    if (values['is_guardian'] === true) {
                        values['is_guardian'] = 'Y'
                    } else {
                        values['is_guardian'] = 'N'
                    }

                    var changes = detailedDiff(orig_referral, values)
                    changes['timestamp'] = moment().format(date_format)
                    changes['user'] = this.props.auth.user.name + ' <' + this.props.auth.user.email + '>'

                    if (orig_referral.history) {
                        orig_referral.history.unshift(changes)
                        values['history'] = orig_referral.history
                    } else {
                        values['history'] = [changes]
                    }

                    // add some original values for comparison in backend
                    values['original_values'] = original_values

                    axios
                        .post('/api/referrals/update', values)
                        .then((response) => {
                            this.setState({
                                submit_button_loading: false,
                                submit_button_disabled: true,
                                referral_submitted: true,
                                referral_response: response.data,
                                err: null,
                            })
                        })
                        .catch((error) => {
                            this.setState({
                                submit_button_loading: false,
                                submit_button_disabled: false,
                                err: error.response,
                            })
                        })
                } else {
                    this.setState({
                        submit_button_loading: false,
                        submit_button_disabled: false,
                        err: err,
                    })
                    console.log(err)
                }
            })
            .catch((err) => console.log(err))
    }

    clearForm = () => {
        window.location.reload()
    }

    updateStatusCheck = (e) => {
        if (e === 'Scheduled') {
            this.setState({ apptDateCheck: true })
        } else {
            this.setState({ apptDateCheck: false })
        }
    }

    disabledDate = (current) => {
        return current < moment(this.state.firstContactDate).startOf('day')
    }

    checkConsentAgency = (e) => {
        if (e !== this.state.original.referral_agency) {
            this.setState({
                consent: false,
                consent_date: null,
            })
        } else {
            this.setState({
                consent: true,
                consent_date: this.state.original.consent_date,
            })
        }
    }

    handleAddressTypeOnChange = (e) => {
        this.setState({ currentAddressType: e })

        const requiredFields = address_type[e].required_fields

        if (e === 'Shelter') {
            this.setState({ showShelterNameField: true })
        } else {
            this.setState({ showShelterNameField: false })
        }

        if (requiredFields.length === 0) {
            this.setState({
                addressRequiredFields: {
                    address_1: false,
                    address_2: false,
                    city: false,
                    state: false,
                    zip: false,
                    county: false,
                    phone: false,
                },
            })
        } else if (requiredFields[0] === 'all') {
            this.setState({
                addressRequiredFields: {
                    address_1: true,
                    address_2: true,
                    city: true,
                    state: true,
                    zip: true,
                    county: true,
                    phone: true,
                },
            })
        } else {
            this.setState({
                addressRequiredFields: {
                    address_1: requiredFields.includes('address_1') ? true : false,
                    address_2: requiredFields.includes('address_2') ? true : false,
                    city: requiredFields.includes('city') ? true : false,
                    state: requiredFields.includes('state') ? true : false,
                    zip: requiredFields.includes('zip') ? true : false,
                    county: requiredFields.includes('county') ? true : false,
                    phone: requiredFields.includes('phone') ? true : false,
                },
            })
        }
    }

    showGuardianInfo = (getFieldDecorator, original) => {
        if (this.state.original.project && original.project.includes('COVID') && this.state.show_guardian === true) {
            return (
                <Row gutter={[16, 16]}>
                    <Col xs={24} lg={12}>
                        <Form.Item label={`Guardian Last Name`}>
                            {getFieldDecorator(`guardian_last_name`, {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Input something!',
                                    },
                                ],
                                initialValue: original.guardian_last_name,
                            })(
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder="Doe"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label={`Guardian First Name`}>
                            {getFieldDecorator(`guardian_first_name`, {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Input something!',
                                    },
                                ],
                                initialValue: original.guardian_first_name,
                            })(
                                <Input
                                    disabled={this.state.client_details_disabled}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder="Doe"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <p>Please answer the remaining questions on behalf of the individual in your care. You will be contacted for further information.</p>
                    </Col>
                </Row>
            )
        }
    }

    handleDayslapsed = (e) => {
        let selectedDate = e
        let now = moment()

        if (selectedDate === null || selectedDate === '') {
            this.setState({ daysLapsed: 0 })
            return
        }

        let days = Math.floor(moment.duration(now.diff(selectedDate)).asDays())

        if (selectedDate < now) {
            this.setState({ daysLapsed: days })
        } else {
            this.setState({ daysLapsed: 0 })
        }
    }

    handleDataErrorMessage = (err) => {
        if (err.data) {
            if (err.data.type === 'project_missing') {
                return (
                    <React.Fragment>
                        <Row gutter={[16, 16]} style={{ marginTop: '1em', paddingBottom: '5px' }}>
                            <Col xs={24}>
                                <Alert message={err.data.message} description=" " type="error" showIcon />{' '}
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            } else if (err && err.data) {
                return (
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <Alert message={err.data.message} description="There was an error processing your request." type="error" showIcon />
                            </Col>
                        </Row>
                        <br />
                    </React.Fragment>
                )
            }
        }
    }

    render() {
        const tProps = {
            treeData: availablility,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            placeholder: 'please select days and times available',
            style: {
                width: '100%',
            },
        }

        const { getFieldDecorator } = this.props.form
        const { submit_button_loading, submit_button_disabled, referral_submitted, original, consent, skip_consent, err } = this.state

        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <BackTop />
                <Card style={{ marginBottom: '16px', width: '100%' }}>
                    <div style={{ marginBottom: '16px', width: '100%' }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>{original.project} Form</Breadcrumb.Item>
                            <Breadcrumb.Item>Update {original.project} Form</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Title level={2}>Edit {original.project} Form</Title>
                    <p>
                        You are currently editing form for{' '}
                        {original && (
                            <span>
                                <strong>
                                    {original.first_name} {original.last_name} (Id: {original.nano_id})
                                </strong>
                            </span>
                        )}{' '}
                    </p>
                </Card>
                {referral_submitted ? (
                    <React.Fragment>
                        <Row
                            gutter={[16, 16]}
                            style={{
                                border: 'solid 1px #e4e4e4',
                                background: '#ffffffd6',
                                borderRadius: '5px',
                            }}>
                            <Col xs={24}>
                                <Result
                                    status="success"
                                    title="Successfully Edited Referral!"
                                    subTitle={<span></span>}
                                    extra={[
                                        <Link to="/dashboard" key="dash">
                                            <Button type="primary" key="dashboard">
                                                Go to Dashboard
                                            </Button>
                                        </Link>,
                                        <Button key="editagain" onClick={() => window.location.reload()}>
                                            Edit Again
                                        </Button>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Row style={{ marginBottom: '1em' }}>
                            <Col span={24}>
                                <Button type="primary" onClick={showConfirm} style={{ marginRight: '5px' }}>
                                    <StepBackwardOutlined />
                                    Back to Dashboard
                                </Button>
                                <Button type="primary" onClick={() => window.location.reload()} style={{ float: 'right' }} danger>
                                    <DeleteOutlined />
                                    Reset Form
                                </Button>
                            </Col>
                        </Row>
                        <br></br>
                        {err && this.handleDataErrorMessage(err)}

                        {!consent && skip_consent === false && (
                            <React.Fragment>
                                <Alert
                                    message="Client Consent"
                                    description="Below, please attest that all necessary consents have been signed by this client in order to coordinate care and services in compliance with all relevant State and Federal laws and regulation."
                                    type="warning"
                                    showIcon
                                />
                                <br />
                            </React.Fragment>
                        )}

                        <Form name="edit-referral" onSubmit={this.handleSubmit} hideRequiredMark={true}>
                            {/* Assignment Status */}
                            <Card title="Assignment Status" bordered={false}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} lg={12}>
                                        <Popover placement="bottom" content="Changing status to `Cancelled` or `No Show` will have the appointment date deleted on update.">
                                            <Form.Item label={`Referral Status`}>
                                                {getFieldDecorator(`referral_status`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.referral_status,
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select a referral status"
                                                        optionFilterProp="children"
                                                        onChange={this.updateStatusCheck}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {original.project && original.project.includes('CBHC-MVP')
                                                            ? referral_statuses_cbhc_mvp.map((referral) => <Option key={referral}>{referral}</Option>)
                                                            : referral_statuses.map((referral) => <Option key={referral}>{referral}</Option>)}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Popover>
                                    </Col>

                                    <Col xs={24} lg={12}>
                                        <Form.Item label={`Assign To Agency`}>
                                            {getFieldDecorator(`referral_agency`, {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.referral_agency,
                                            })(
                                                <Select
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    placeholder="select an agency"
                                                    optionFilterProp="children"
                                                    onChange={(e) => {
                                                        this.checkConsentAgency(e)
                                                        this.setState({
                                                            agency_selected: e,
                                                        })
                                                    }}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {agencies.sort().map((agency) => (
                                                        <Option key={agency}>{agency}</Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            {/* End Assignment Status */}
                            <br />
                            {/* Referral Details */}
                            {original.project && !original.project.includes('CBHC-MVP') && (
                                <React.Fragment>
                                    <Card title="Referral Information" bordered={false}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Referral Source`}>
                                                    {getFieldDecorator(`referral_source`, {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.referral_source,
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="select a referral source"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {referral_sources.sort().map((source) => (
                                                                <Option key={source}>{source}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Referral Needs`}>
                                                    {getFieldDecorator(`referral_need`, {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.referral_need,
                                                    })(
                                                        <Select
                                                            mode="multiple"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            placeholder="select referral needs">
                                                            {referral_needs.sort().map((need) => (
                                                                <Option key={need}>{need}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Referral Source Email`}>
                                                    {getFieldDecorator(`referral_source_email`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.referral_source_email,
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            placeholder=" "
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Referral Source Name`}>
                                                    {getFieldDecorator(`referral_source_name`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.referral_source_name,
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            placeholder=" "
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={original.project && original.project.includes('COVID') ? 'Title' : 'Referral Source Title'}>
                                                    {getFieldDecorator(`referral_title`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.referral_title,
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="select a title"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {referral_titles.sort().map((title) => (
                                                                <Option key={title}>{title}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Referral Source Phone`}>
                                                    {getFieldDecorator(`referral_source_phone`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                            {
                                                                validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                            },
                                                        ],
                                                        initialValue: original.referral_source_phone,
                                                    })(
                                                        <MaskedInput
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            mask="(111) 111-1111"
                                                            placeholder=" "
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24}>
                                                <Form.Item label={`Referral Details`}>
                                                    {getFieldDecorator(`additional_details`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.additional_details,
                                                    })(<TextArea placeholder="please input any additional details about referral here" rows={3} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>

                                    <br />
                                </React.Fragment>
                            )}
                            {/* End Referral Details */}
                            {/* Care management */}
                            {(original.project && original.project.includes('TOC')) || (original.project && original.project.includes('COVID')) ? (
                                <React.Fragment>
                                    <CareManagement getFieldDecorator={getFieldDecorator} currentProject={original.project && original.project} original={original} />
                                    <br />
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                            {/* End Care management */}

                            {/* Health Home */}
                            {original.project && original.project.includes('CBHC-MVP') && (
                                <React.Fragment>
                                    <Card title="Health Home" bordered={false}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Health Home Enrolled?`}>
                                                    {getFieldDecorator(`health_home_enrolled`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                        initialValue: original.health_home_enrolled,
                                                    })(
                                                        <Switch
                                                            checkedChildren="Enrolled"
                                                            unCheckedChildren="No"
                                                            checked={this.state.health_home_enrolled}
                                                            onClick={() => {
                                                                this.setState({
                                                                    health_home_enrolled: !this.state.health_home_enrolled,
                                                                })
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={16}>
                                                <Form.Item label={`Health Home Facility`}>
                                                    {getFieldDecorator(`health_home_facility`, {
                                                        rules: [
                                                            {
                                                                required: this.state.health_home_enrolled,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.health_home_facility,
                                                    })(
                                                        <Select
                                                            disabled={!this.state.health_home_enrolled}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    health_home_facility: e,
                                                                })
                                                            }
                                                            showSearch
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            placeholder="Select a facility"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {health_home_facility.map((facility) => (
                                                                <Option key={facility}>{facility}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={24}>
                                                <Form.Item label="Health Home Enrollment Date">
                                                    {getFieldDecorator(`health_home_enrollment_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.health_home_enrollment_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Care Management Enrolled?`}>
                                                    {getFieldDecorator(`care_management_enrolled`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                        initialValue: original.care_management_enrolled,
                                                    })(
                                                        <Switch
                                                            checkedChildren="Enrolled"
                                                            unCheckedChildren="No"
                                                            checked={this.state.care_management_enrolled}
                                                            onClick={() => {
                                                                this.setState({
                                                                    care_management_enrolled: !this.state.care_management_enrolled,
                                                                })
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={16}>
                                                <Form.Item label={`Care Management Facility`}>
                                                    {getFieldDecorator(`care_management_facility`, {
                                                        rules: [
                                                            {
                                                                required: this.state.care_management_enrolled,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.care_management_facility,
                                                    })(
                                                        <Select
                                                            disabled={!this.state.care_management_enrolled}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    care_management_facility: e,
                                                                })
                                                            }
                                                            showSearch
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            placeholder="Select a facility"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {care_management_facility.map((facility) => (
                                                                <Option key={facility}>{facility}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={24}>
                                                <Form.Item label="Care Management Enrollment Date">
                                                    {getFieldDecorator(`care_management_enrollment_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.care_management_enrollment_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <br />
                                </React.Fragment>
                            )}
                            {/* End Health Home */}

                            {/* Outreach and Engagement */}
                            {original.project && original.project.includes('CBHC-MVP') && (
                                <React.Fragment>
                                    <Card title="Outreach and Engagement" bordered={false}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Contact Type`}>
                                                    {getFieldDecorator(`engagement_contact_type`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="Select contact type"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {engagement_contact_type.sort().map((source) => (
                                                                <Option key={source}>{source}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label="Contact Date">
                                                    {getFieldDecorator(`engagement_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label="Contact Time">
                                                    {getFieldDecorator(`engagement_time`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                    })(
                                                        <TimePicker
                                                            showTime={{
                                                                use12Hours: true,
                                                                format: time_format,
                                                            }}
                                                            format={time_format}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Number of Contacts`}>
                                                    {getFieldDecorator(`engagement_number_of_contacts`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="Select number of contacts"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {engagement_number_of_contacts.map((source) => (
                                                                <Option key={source}>{source}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24}>
                                                <Form.Item label={`Contact Details`}>
                                                    {getFieldDecorator(`engagement_contact_details`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                    })(<TextArea placeholder="please input any notes about contact details" rows={3} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <br />
                                </React.Fragment>
                            )}
                            {/* Outreach and Engagement */}

                            <React.Fragment>
                                {/* Set Appointment */}
                                <Card title={original.project && original.project.includes('CBHC-MVP') ? 'PCP Set Appointment Date and Time' : 'Set Appointment Date and Time'} bordered={false}>
                                    <Row gutter={[16, 16]}>
                                        {original.project && !original.project.includes('CBHC-MVP') && (
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={original.project && original.project.includes('CBHC-MVP') ? 'Upcoming PCP Appointment Date' : 'Appointment Availability'}>
                                                    {getFieldDecorator(`appointment_availability`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.appointment_availability,
                                                    })(<TreeSelect {...tProps} />)}
                                                </Form.Item>
                                            </Col>
                                        )}
                                        <Col xs={24} lg={12}>
                                            <Form.Item label={`Appointment Date`}>
                                                {getFieldDecorator(`appointment_date`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: checkMomentDate(original.appointment_date),
                                                })(<DatePicker format={date_format} />)}
                                                {getFieldDecorator(`appointment_time`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: checkMomentTime(original.appointment_time),
                                                })(
                                                    <TimePicker
                                                        showTime={{
                                                            use12Hours: true,
                                                            format: time_format,
                                                        }}
                                                        format={time_format}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {original.project && original.project.includes('CBHC-MVP') && (
                                            <React.Fragment>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="Last Annual Physical Date">
                                                        {getFieldDecorator(`last_annual_physical_date`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                            initialValue: checkMomentDate(original.last_annual_physical_date),
                                                        })(<DatePicker format={date_format} />)}
                                                        {getFieldDecorator(`last_annual_physical_time`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                            initialValue: checkMomentTime(original.last_annual_physical_time),
                                                        })(
                                                            <TimePicker
                                                                showTime={{
                                                                    use12Hours: true,
                                                                    format: time_format,
                                                                }}
                                                                format={time_format}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="Last PCP Follow-up Date">
                                                        {getFieldDecorator(`last_pcp_followup_date`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                            initialValue: checkMomentDate(original.last_pcp_followup_date),
                                                        })(<DatePicker onChange={(e) => this.handleDayslapsed(e)} format={date_format} />)}
                                                        {getFieldDecorator(`last_pcp_followup_time`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                            initialValue: checkMomentTime(original.last_pcp_followup_time),
                                                        })(
                                                            <TimePicker
                                                                showTime={{
                                                                    use12Hours: true,
                                                                    format: time_format,
                                                                }}
                                                                format={time_format}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Statistic valueStyle={{ fontSize: '18px' }} title="Days Lapsed from last PCP appointment" value={this.state.daysLapsed} suffix=" days" />
                                                </Col>
                                            </React.Fragment>
                                        )}
                                        <Col xs={24}>
                                            <Form.Item label={`Notes`}>
                                                {getFieldDecorator(`notes`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.notes,
                                                })(<TextArea placeholder="please input any notes about referral here" rows={3} />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                                {/*  */}
                                <br />
                            </React.Fragment>
                            <React.Fragment>
                                {/* Client Details */}
                                <Card
                                    title="Client Information"
                                    bordered={false}
                                    extra={<Switch checkedChildren="Locked" unCheckedChildren="Unlocked" checked={this.state.client_details_disabled} onChange={this.handleClientDetailsToggle} />}>
                                    {original.project && original.project.includes('COVID') && (
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={24}>
                                                <Form.Item label={`Check here if you are answering on behalf of a minor or as a legal guardian`}>
                                                    {getFieldDecorator(`is_guardian`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                        initialValue: original.is_guardian === 'Y' ? true : false,
                                                        valuePropName: 'checked',
                                                    })(
                                                        <CustomCheckbox
                                                            disabled={this.state.client_details_disabled}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    show_guardian: e.target.checked,
                                                                })
                                                            }></CustomCheckbox>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}

                                    {this.showGuardianInfo(getFieldDecorator, original)}

                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Date of Birth`}>
                                                {getFieldDecorator(`dob`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: checkMomentDate(original.dob),
                                                })(
                                                    <DatePicker
                                                        disabled={this.state.client_details_disabled}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder="select date"
                                                        format={date_format}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Last Name`}>
                                                {getFieldDecorator(`last_name`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.last_name,
                                                })(
                                                    <Input
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="Doe"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`First Name`}>
                                                {getFieldDecorator(`first_name`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.first_name,
                                                })(
                                                    <Input
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="John"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Middle Name`}>
                                                {getFieldDecorator(`middle_name`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.middle_name,
                                                })(
                                                    <Input
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder=" "
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`CIN / Medicaid #`}>
                                                {getFieldDecorator(`cin`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.cin,
                                                })(
                                                    <Input
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Gender`}>
                                                {getFieldDecorator(`gender`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.gender,
                                                })(
                                                    <Select
                                                        disabled={this.state.client_details_disabled}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select a gender"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {genders.sort().map((gender) => (
                                                            <Option key={gender}>{gender}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Language`}>
                                                {getFieldDecorator(`language`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.language,
                                                })(
                                                    <Select
                                                        disabled={this.state.client_details_disabled}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select a language"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {languages_list.sort().map((language) => (
                                                            <Option key={language}>{language}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Ethnicity`}>
                                                {getFieldDecorator(`ethnicity`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.ethnicity,
                                                })(
                                                    <Select
                                                        disabled={this.state.client_details_disabled}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder=" "
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {ethnicity.sort().map((ethnicity) => (
                                                            <Option key={ethnicity}>{ethnicity}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Email`}>
                                                {getFieldDecorator(`email_client`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.email_client,
                                                })(
                                                    <Input
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Active Duty`}>
                                                {getFieldDecorator(`active_duty`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.active_duty,
                                                    checked: original.active_duty,
                                                    valuePropName: 'checked',
                                                })(<Switch disabled={this.state.client_details_disabled} />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Veteran`}>
                                                {getFieldDecorator(`veteran`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.veteran,
                                                    checked: original.veteran,
                                                    valuePropName: 'checked',
                                                })(<Switch disabled={this.state.client_details_disabled} />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={6}>
                                            <Checkbox
                                                disabled={this.state.client_details_disabled}
                                                style={{ paddingBottom: '20px' }}
                                                checked={this.state.mailing_address}
                                                onClick={() => {
                                                    this.setState({ mailing_address: !this.state.mailing_address })
                                                }}>
                                                Check to add a mailing address
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    {this.state.mailing_address && (
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Mailing Address 1`}>
                                                    {getFieldDecorator(`mailing_address_1`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.mailing_address_1,
                                                    })(
                                                        <Input
                                                            disabled={this.state.client_details_disabled}
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            placeholder="99 Alphabet Rd"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Mailing Address 2`}>
                                                    {getFieldDecorator(`mailing_address_2`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            disabled={this.state.client_details_disabled}
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            placeholder=" "
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Mailing City`}>
                                                    {getFieldDecorator(`mailing_city`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            disabled={this.state.client_details_disabled}
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            placeholder="New York"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Mailing State`}>
                                                    {getFieldDecorator(`mailing_state`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: 'New York',
                                                    })(
                                                        <Select
                                                            disabled={this.state.client_details_disabled}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="New York"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            <Option value="New York">New York</Option>
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Mailing ZIP`}>
                                                    {getFieldDecorator(`mailing_zip`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            disabled={this.state.client_details_disabled}
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            onChange={(e) => this.handleZipCodeCounty(e)}
                                                            placeholder="11223"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={12}>
                                            <Form.Item label={`Address Type`}>
                                                {getFieldDecorator(`address_type`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.address_type || 'Primary Residence',
                                                })(
                                                    <Select
                                                        disabled={this.state.client_details_disabled}
                                                        onChange={(e) => {
                                                            this.handleAddressTypeOnChange(e)
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="Primary Residence"
                                                        optionFilterProp="children"
                                                        defaultActiveFirstOption={true}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {Object.keys(address_type).map((key) => (
                                                            <Option key={key}>{key}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {this.state.showShelterNameField === true && (
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Shelter Name`}>
                                                    {getFieldDecorator(`shelter_name`, {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.shelter_name,
                                                    })(
                                                        <Input
                                                            disabled={this.state.client_details_disabled}
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        )}
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Address Validity`}>
                                                {getFieldDecorator(`address_valid`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                    ],
                                                    initialValue: original.address_valid,
                                                    valuePropName: 'checked',
                                                })(
                                                    <CustomCheckbox disabled={this.state.client_details_disabled} backgroundColor="red">
                                                        Unsuccessful
                                                    </CustomCheckbox>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={12}>
                                            <Form.Item label={`Address 1`}>
                                                {getFieldDecorator(`address_1`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.address_1,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.address_1,
                                                })(
                                                    <Input
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="99 Alphabet Rd"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                            <Form.Item label={`Address 2`}>
                                                {getFieldDecorator(`address_2`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.address_2,
                                                })(
                                                    <Input
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder=" "
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`City`}>
                                                {getFieldDecorator(`city`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.city,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.city,
                                                })(
                                                    <Input
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="New York"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`State`}>
                                                {getFieldDecorator(`state`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.state,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.state,
                                                })(
                                                    <Select
                                                        disabled={this.state.client_details_disabled}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="New York"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        <Option value="New York">New York</Option>
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`ZIP`}>
                                                {getFieldDecorator(`zip`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.zip,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.zip,
                                                })(
                                                    <Input
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="11223"
                                                        onChange={(e) => this.handleZipCodeCounty(e)}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`County`}>
                                                {getFieldDecorator(`county`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.county,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.county,
                                                })(
                                                    <Select
                                                        disabled={this.state.client_details_disabled}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="Westchester"
                                                        optionFilterProp="children"
                                                        defaultActiveFirstOption={true}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {countys_list.sort().map((county) => (
                                                            <Option key={county}>{county}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />

                                    {original.project && original.project.includes('TOC') && (
                                        <EmergencyContact getFieldDecorator={getFieldDecorator} original={original} clientDetailsDisabled={this.state.client_details_disabled} />
                                    )}

                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Primary Phone`}>
                                                {getFieldDecorator(`phone`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.phone,
                                                        },
                                                        {
                                                            validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                        },
                                                    ],
                                                    initialValue: original.phone,
                                                })(
                                                    <MaskedInput
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        mask="(111) 111-1111"
                                                        placeholder="(123) 456-7890"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Validity`}>
                                                {getFieldDecorator(`primary_phone_valid`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                    ],
                                                    initialValue: original.primary_phone_valid,
                                                    valuePropName: 'checked',
                                                })(
                                                    <CustomCheckbox disabled={this.state.client_details_disabled} backgroundColor="red">
                                                        Unsuccessful
                                                    </CustomCheckbox>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Secondary Phone`}>
                                                {getFieldDecorator(`phone_secondary`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                        {
                                                            validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                        },
                                                    ],
                                                    initialValue: original.phone_secondary,
                                                })(
                                                    <MaskedInput
                                                        disabled={this.state.client_details_disabled}
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        mask="(111) 111-1111"
                                                        placeholder=" "
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Validity`}>
                                                {getFieldDecorator(`secondary_phone_valid`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                    ],
                                                    initialValue: original.secondary_phone_valid,
                                                    valuePropName: 'checked',
                                                })(
                                                    <CustomCheckbox disabled={this.state.client_details_disabled} backgroundColor="red">
                                                        Unsuccessful
                                                    </CustomCheckbox>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Okay to leave message?`}>
                                                {getFieldDecorator(`leave_message`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.leave_message,
                                                })(
                                                    <Select
                                                        disabled={this.state.client_details_disabled}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        placeholder="Yes"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        <Option value="Yes">Yes</Option>
                                                        <Option value="No">No</Option>
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                                {/*  */}
                            </React.Fragment>
                            <br />
                            {/* Insurance Information */}
                            <Card title="Insurance Information" bordered={false}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} lg={8}>
                                        <Form.Item label={`Insurance Type 1`}>
                                            {getFieldDecorator(`insurance_1`, {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_1,
                                            })(
                                                <Select
                                                    style={{ width: '100%' }}
                                                    showSearch
                                                    placeholder="select insurance type"
                                                    optionFilterProp="children"
                                                    onChange={(option) => {
                                                        if (option === 'Uninsured') {
                                                            this.setState({
                                                                Uninsured: true,
                                                            })
                                                        } else {
                                                            this.setState({
                                                                Uninsured: false,
                                                            })
                                                        }
                                                    }}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {insurance_types.sort().map((insurance) => (
                                                        <Option key={insurance}>{insurance}</Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item label={`Insurance Plan`}>
                                            {getFieldDecorator(`insurance_1_plan`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_1_plan,
                                            })(
                                                <Select
                                                    style={{ width: '100%' }}
                                                    showSearch
                                                    disabled={this.state.Uninsured}
                                                    placeholder="select insurance plan"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {insurance_plans.sort().map((plan) => (
                                                        <Option key={plan}>{plan}</Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={4}>
                                        <Form.Item label={`Policy Number`}>
                                            {getFieldDecorator(`insurance_1_policy_number`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_1_policy_number,
                                            })(
                                                <Input
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    style={{ width: '100%' }}
                                                />,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={4}>
                                        <Form.Item label={`Group Number`}>
                                            {getFieldDecorator(`insurance_1_group_number`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_1_group_number,
                                            })(
                                                <Input
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    style={{ width: '100%' }}
                                                />,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    {original.project && original.project.includes('CBHC-MVP') && (
                                        <React.Fragment>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label="Effective Date">
                                                    {getFieldDecorator(`insurance_1_effective_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.insurance_1_effective_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={14}>
                                                <Form.Item label="Expiration Date">
                                                    {getFieldDecorator(`insurance_1_expiration_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.insurance_1_expiration_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                        </React.Fragment>
                                    )}
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} lg={8}>
                                        <Form.Item label={`Insurance Type 2`}>
                                            {getFieldDecorator(`insurance_2`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_2,
                                            })(
                                                <Select
                                                    style={{ width: '100%' }}
                                                    showSearch
                                                    placeholder="select insurance type"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {insurance_types.sort().map((insurance) => (
                                                        <Option key={insurance}>{insurance}</Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item label={`Insurance Plan`}>
                                            {getFieldDecorator(`insurance_2_plan`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_2_plan,
                                            })(
                                                <Select
                                                    style={{ width: '100%' }}
                                                    showSearch
                                                    placeholder="select insurance plan"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {insurance_plans.sort().map((plan) => (
                                                        <Option key={plan}>{plan}</Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={4}>
                                        <Form.Item label={`Policy Number`}>
                                            {getFieldDecorator(`insurance_2_policy_number`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_2_policy_number,
                                            })(
                                                <Input
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    style={{ width: '100%' }}
                                                />,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={4}>
                                        <Form.Item label={`Group Number`}>
                                            {getFieldDecorator(`insurance_2_group_number`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_2_group_number,
                                            })(
                                                <Input
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    style={{ width: '100%' }}
                                                />,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    {original.project && original.project.includes('CBHC-MVP') && (
                                        <React.Fragment>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label="Effective Date">
                                                    {getFieldDecorator(`insurance_2_effective_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.insurance_2_effective_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={14}>
                                                <Form.Item label="Expiration Date">
                                                    {getFieldDecorator(`insurance_2_expiration_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.insurance_2_expiration_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                        </React.Fragment>
                                    )}
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} lg={8}>
                                        <Form.Item label={`Insurance Type 3`}>
                                            {getFieldDecorator(`insurance_3`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_3,
                                            })(
                                                <Select
                                                    style={{ width: '100%' }}
                                                    showSearch
                                                    placeholder="select insurance type"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {insurance_types.sort().map((insurance) => (
                                                        <Option key={insurance}>{insurance}</Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item label={`Insurance Plan`}>
                                            {getFieldDecorator(`insurance_3_plan`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_3_plan,
                                            })(
                                                <Select
                                                    style={{ width: '100%' }}
                                                    showSearch
                                                    placeholder="select insurance plan"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {insurance_plans.sort().map((plan) => (
                                                        <Option key={plan}>{plan}</Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={4}>
                                        <Form.Item label={`Policy Number`}>
                                            {getFieldDecorator(`insurance_3_policy_number`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_3_policy_number,
                                            })(
                                                <Input
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    style={{ width: '100%' }}
                                                />,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={4}>
                                        <Form.Item label={`Group Number`}>
                                            {getFieldDecorator(`insurance_3_group_number`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Input something!',
                                                    },
                                                ],
                                                initialValue: original.insurance_3_group_number,
                                            })(
                                                <Input
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    style={{ width: '100%' }}
                                                />,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    {original.project && original.project.includes('CBHC-MVP') && (
                                        <React.Fragment>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label="Effective Date">
                                                    {getFieldDecorator(`insurance_3_effective_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.insurance_3_effective_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={14}>
                                                <Form.Item label="Expiration Date">
                                                    {getFieldDecorator(`insurance_3_expiration_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.insurance_3_expiration_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                        </React.Fragment>
                                    )}
                                </Row>
                                {original.project && original.project.includes('COVID') && (
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={8}>
                                            <Form.Item label={`HARP`}>
                                                {getFieldDecorator(`assessment_harp`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.assessment_harp,
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select enrolled or eligible"
                                                        optionFilterProp="children"
                                                        // onChange={onChange}
                                                        // onFocus={onFocus}
                                                        // onBlur={onBlur}
                                                        // onSearch={onSearch}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {harp_values.sort().map((harp) => (
                                                            <Option key={harp}>{harp}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                            </Card>
                            {/*  */}

                            <br />

                            {/* Assessment Information */}
                            {original.project && !original.project.includes('CMO') && !original.project.includes('COVID') && !original.project.includes('CBHC-MVP') ? (
                                <Card title="Assessment Information" bordered={false}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={8}>
                                            <Form.Item label={`HARP`}>
                                                {getFieldDecorator(`assessment_harp`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: original.assessment_harp,
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select enrolled or eligible"
                                                        optionFilterProp="children"
                                                        // onChange={onChange}
                                                        // onFocus={onFocus}
                                                        // onBlur={onBlur}
                                                        // onSearch={onSearch}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {harp_values.sort().map((harp) => (
                                                            <Option key={harp}>{harp}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={8}>
                                            <Form.Item label={`First Contact Date`}>
                                                {getFieldDecorator(`assessment_contact_date`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: checkMomentDate(original.assessment_contact_date),
                                                })(
                                                    <DatePicker
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                firstContactCheck: false,
                                                                firstContactDate: e,
                                                            })
                                                        }
                                                        placeholder="select date"
                                                        format={date_format}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={8}>
                                            <Form.Item label={`Assessment Date Completed`}>
                                                {getFieldDecorator(`assessment_date_completed`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: checkMomentDate(original.assessment_date_completed),
                                                })(
                                                    <DatePicker
                                                        disabled={this.state.firstContactCheck}
                                                        disabledDate={this.disabledDate}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder="select date"
                                                        format={date_format}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {original.project && original.project.includes('HCBS') ? (
                                            <Losd getFieldDecorator={getFieldDecorator} state={this.state} original={original} disabledDate={this.disabledDate} checkMomentDate={checkMomentDate} />
                                        ) : (
                                            ''
                                        )}
                                    </Row>
                                </Card>
                            ) : (
                                ''
                            )}
                            {/* End Assessment Information */}

                            {/*  Quality Metris */}
                            {original.project && original.project.includes('CBHC-MVP') && (
                                <React.Fragment>
                                    <Card title="Quality Metrics" bordered={false}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_1_checkbox`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                        valuePropName: 'checked',
                                                        initialValue: original.qm_1_checkbox,
                                                    })(
                                                        <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_1_checkbox')}>
                                                            Diabetes Screen for people with Schizophrenia or Bipolar Disorder
                                                        </CustomCheckbox>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_1_date`, {
                                                        rules: [
                                                            {
                                                                required: this.state.qm_1_checkbox,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.qm_1_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_2_checkbox`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                        valuePropName: 'checked',
                                                        initialValue: original.qm_2_checkbox,
                                                    })(
                                                        <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_2_checkbox')}>
                                                            Potential Avoidable ED Utilization
                                                        </CustomCheckbox>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_2_date`, {
                                                        rules: [
                                                            {
                                                                required: this.state.qm_2_checkbox,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.qm_2_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_3_checkbox`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                        valuePropName: 'checked',
                                                        initialValue: original.qm_3_checkbox,
                                                    })(
                                                        <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_3_checkbox')}>
                                                            Cervical Cancer Screening (CCS)
                                                        </CustomCheckbox>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_3_date`, {
                                                        rules: [
                                                            {
                                                                required: this.state.qm_3_checkbox,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.qm_3_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_4_checkbox`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                        valuePropName: 'checked',
                                                        initialValue: original.qm_4_checkbox,
                                                    })(
                                                        <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_4_checkbox')}>
                                                            Comprehensive Diabetes Care Eye Exam (CDC-Eye)
                                                        </CustomCheckbox>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_4_date`, {
                                                        rules: [
                                                            {
                                                                required: this.state.qm_4_checkbox,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.qm_4_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_5_checkbox`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                        valuePropName: 'checked',
                                                        initialValue: original.qm_5_checkbox,
                                                    })(
                                                        <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_5_checkbox')}>
                                                            Asthma Medication Ratio (AMR)
                                                        </CustomCheckbox>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_5_date`, {
                                                        rules: [
                                                            {
                                                                required: this.state.qm_5_checkbox,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.qm_5_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_6_checkbox`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                        valuePropName: 'checked',
                                                        initialValue: original.qm_6_checkbox,
                                                    })(
                                                        <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_6_checkbox')}>
                                                            Colorectal Cancer Screening (COL)
                                                        </CustomCheckbox>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label="">
                                                    {getFieldDecorator(`qm_6_date`, {
                                                        rules: [
                                                            {
                                                                required: this.state.qm_6_checkbox,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: checkMomentDate(original.qm_6_date),
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </React.Fragment>
                            )}
                            {/* End Quality Metrics */}

                            {/* Treatment Contact Information && Referral/Encounter Note */}
                            {original.project && original.project.includes('CMO') && (
                                <React.Fragment>
                                    <Card title="Treatment Contact Information" bordered={false}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`Agency Therapist`}>
                                                    {getFieldDecorator(`agency_therapist`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.agency_therapist,
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`Phone Number`}>
                                                    {getFieldDecorator(`phone_agency_therapist`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                            {
                                                                validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                            },
                                                        ],
                                                        initialValue: original.phone_agency_therapist,
                                                    })(
                                                        <MaskedInput
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            mask="(111) 111-1111"
                                                            placeholder="(123) 456-7890"
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`Email`}>
                                                    {getFieldDecorator(`email_agency_therapist`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.email_agency_therapist,
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`Agency Prescribing Clinician`}>
                                                    {getFieldDecorator(`agency_prescribing_clinician`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.agency_prescribing_clinician,
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`Phone Number`}>
                                                    {getFieldDecorator(`phone_agency_prescribing_clinician`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                            {
                                                                validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                            },
                                                        ],
                                                        initialValue: original.phone_agency_prescribing_clinician,
                                                    })(
                                                        <MaskedInput
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            mask="(111) 111-1111"
                                                            placeholder="(123) 456-7890"
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`Email`}>
                                                    {getFieldDecorator(`email_agency_prescribing_clinician`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.email_agency_prescribing_clinician,
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`Primary Care Provider`}>
                                                    {getFieldDecorator(`primary_care_provider`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.primary_care_provider,
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`Phone Number`}>
                                                    {getFieldDecorator(`phone_primary_care_provider`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                            {
                                                                validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                            },
                                                        ],
                                                        initialValue: original.phone_primary_care_provider,
                                                    })(
                                                        <MaskedInput
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            mask="(111) 111-1111"
                                                            placeholder="(123) 456-7890"
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`Email`}>
                                                    {getFieldDecorator(`email_primary_care_provider`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.email_primary_care_provider,
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <br />
                                    <Card title="Referral/Encounter Note" bordered={false}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24}>
                                                <Form.Item label={`Referral/Encounter Note`}>
                                                    {getFieldDecorator(`referral_encounter_note`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: original.referral_encounter_note,
                                                    })(<TextArea placeholder="Enter note here" rows={3} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </React.Fragment>
                            )}
                            {/* End Treatment Contact Information && Referral/Encounter Note */}

                            <br />

                            <React.Fragment>
                                <Card title="Consent">
                                    <Row>
                                        <Col>
                                            <Form.Item>
                                                {getFieldDecorator(`consent`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Consent required!',
                                                        },
                                                    ],
                                                    initialValue: this.state.consent,
                                                })(
                                                    <Checkbox
                                                        checked={consent}
                                                        onClick={() => {
                                                            this.setState({
                                                                consent: !this.state.consent,
                                                            })
                                                            this.setState({
                                                                consent_date: moment().format(date_format),
                                                            })
                                                        }}>
                                                        {' '}
                                                        I attest that all necessary consents have been signed or obtained verbally in order to coordinate care and provide services for this client in
                                                        compliance with all relevant State and Federal laws and regulations.
                                                    </Checkbox>,
                                                )}
                                            </Form.Item>
                                            {consent === true && (
                                                <p>
                                                    <i>Timestamp: {this.state.consent_date}</i>
                                                </p>
                                            )}
                                        </Col>
                                    </Row>
                                </Card>
                                <br />
                            </React.Fragment>

                            <React.Fragment>
                                <Alert
                                    style={{ paddingBottom: 0 }}
                                    description={
                                        <Form.Item>
                                            {getFieldDecorator(`skip_consent`, {
                                                rules: [
                                                    {
                                                        required: false,
                                                    },
                                                ],
                                            })(
                                                <Checkbox
                                                    onClick={() => {
                                                        this.setState({
                                                            skip_consent: !this.state.skip_consent,
                                                            consent: false,
                                                            consent_date: null,
                                                        })
                                                    }}>
                                                    {' '}
                                                    Click here to skip consent and fill out client details anyways.
                                                </Checkbox>,
                                            )}
                                        </Form.Item>
                                    }
                                    type="error"
                                />
                                <br />
                            </React.Fragment>

                            <br />
                            <Row>
                                <Col xs={24}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            block
                                            size="large"
                                            htmlType="submit"
                                            loading={submit_button_loading}
                                            disabled={submit_button_disabled || (!this.state.consent && !this.state.skip_consent)}>
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </React.Fragment>
                )}
            </Content>
        )
    }
}

export default Form.create({ name: 'edit-referral' })(EditReferral)
