import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { Layout } from 'antd'

import Sider from '../sider'
import Footer from '../footer/footer'
import Header from '../header/header'

const ProtectedRoute = ({ component: Component, auth, ...rest }) => {
    const [collapsed, setCollapsed] = useState(false)
    const [currentProject, setProject] = useState(localStorage.getItem('currentProject'))
    const setCurrentProject = (project) => {
        setProject(project)
        localStorage.setItem('currentProject', project)
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                auth.isAuthenticated === true ? (
                    <Layout className="site-layout" style={{ minHeight: '100vh' }}>
                        <Sider {...props} auth={auth} collapsed={collapsed} currentProject={currentProject} />
                        <Layout>
                            <Header {...props} collapsed={collapsed} setCollapsed={setCollapsed} auth={auth} currentProject={currentProject} />
                            <Component {...props} auth={auth} setCurrentProject={(project) => setCurrentProject(project)} />
                            <Footer />
                        </Layout>
                    </Layout>
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    )
}

ProtectedRoute.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})

export default connect(mapStateToProps)(ProtectedRoute)
