import React, { Component } from 'react'
import moment from 'moment'
import axios from 'axios'
import '@ant-design/compatible/assets/index.css'
import {
    Alert,
    Input,
    Button,
    Card,
    Form,
    Radio,
    Layout,
    message,
    Typography,
    Select,
    DatePicker,
} from 'antd'
import { withTranslation } from 'react-i18next'

import { date_format, time_format, countys_list, county_zip_codes, } from '../../../utils/constants'

const { Option } = Select
const { Title } = Typography
const Content = Layout
const layout = {
    labelCol: { xs: 24, sm: 18, offset: 4 },
    wrapperCol: { xs: 24, sm: 18, md: 16, offset: 4 },
}

class CovidSurvey extends Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            response_submitted: false,
            response_response: {},
            err: null,
            is_guardian: 'N',
            stateAbbreviation: 'NY',
            state: 'New York'
        }
    }

    onFinish = async (values) => {
       try {
            const data = {
                processed: 0,
                origin: 'web',
                date_submitted: moment().format(date_format),
                time_submitted: moment().format(time_format),
                is_guardian: values.guardian,
                guardian_first_name:
                    values.guardian === 'Y'
                        ? values.guardian_first_name
                        : 'N/A',
                guardian_last_name:
                    values.guardian === 'Y' ? values.guardian_last_name : 'N/A',
                first_name:
                    values.guardian === 'N'
                        ? values.first_name
                        : 'Reach out to Guardian',
                last_name:
                    values.guardian === 'N'
                        ? values.last_name
                        : 'Reach out to Guardian',
                birthdate: moment(values.birthdate).format(date_format),
                zipcode: values.zipcode,
                phone_number: values.phone_number,
                email: values.email,
                state: this.state.stateAbbreviation,
                city: values.city,
                counties: values.county,
                gender: values.gender,
                transportation: values.transportation,
                childcare: values.childcare,
                food: values.food,
                housing: values.housing,
                employment: values.employment,
                finances: values.finances,
                medical: values.medical,
                relationships: values.relationships,
                isolation: values.isolation,
                safety: values.safety,
                depressed: values.depressed,
                substances: values.substances,
                easy: values.easy,
            }

            const { i18n } = this.props
            let phoneNum = values.phone_number
            let referral_phone =
                '(' +
                phoneNum.substring(0, 3) +
                ')' +
                ' ' +
                phoneNum.substring(4, 7) +
                '-' +
                phoneNum.substring(8)
            let referralGender = values.gender.toUpperCase()
            switch (referralGender) {
                case 'M':
                    referralGender = 'Male'
                    break
                case 'F':
                    referralGender = 'Female'
                    break
                case 'NB':
                    referralGender = 'Gender Non-Conforming'
                    break
                default:
                    break
            }
            const referral_data = {
                date_received: moment().format(date_format),
                is_guardian: values.guardian,
                guardian_first_name:
                    values.guardian === 'Y'
                        ? values.guardian_first_name
                        : 'N/A',
                guardian_last_name:
                    values.guardian === 'Y' ? values.guardian_last_name : 'N/A',
                first_name:
                    values.guardian === 'N'
                        ? values.first_name
                        : 'Reach out to Guardian',
                last_name:
                    values.guardian === 'N'
                        ? values.last_name
                        : 'Reach out to Guardian',
                dob: moment(values.birthdate).format(date_format),
                zip: values.zipcode,
                phone: referral_phone,
                email: values.email,
                city: values.city,
                county: values.county,
                state: this.state.state,
                gender: referralGender,
                referral_need: this.getConcerns(values),
                project: 'COVID',
                referral_status: 'Not Assigned',
                referral_source: 'CBHS Website',
                referral_agency: 'CBHS',
                language: i18n.language === 'en' ? 'English' : 'Spanish',
                appointment_availability: [],
                number_cancelled: 0,
                number_noshows: 0,
                contact_needs: [],
                contact_type: [],
                referral_title: 'Self-Referral',
            }

            axios
                .post('/api/responses/create_referral', referral_data)
                .then((response) => {
                    this.setState({
                        response_response: response.data,
                        response_submitted: true,
                        err: null,
                    })
                })
                .catch((error) => {
                    this.setState({
                        err: error.response,
                    })
                })

            axios
                .post('/api/responses/create', data)
                .then((response) => {
                    this.setState({
                        response_response: response.data,
                        response_submitted: true,
                        err: null,
                    })
                })
                .catch((error) => {
                    this.setState({
                        err: error.response,
                    })
                })
            message.success('Completed survey successfully.')
        } catch (err) {
            message.error('There was an error processing the survey.')
        }
    }


    onFinishFailed = () => {
        message.error('Please make sure all fields are filled out.')
    }

    getConcerns(values) {
        var concerns = []
        if (
            values.transportation &&
            this.isYes(values.transportation.toUpperCase())
        ) {
            concerns.push('Transportation')
        }
        if (values.childcare && this.isYes(values.childcare.toUpperCase())) {
            concerns.push('Childcare')
        }
        if (values.food && this.isYes(values.food.toUpperCase())) {
            concerns.push('Food')
        }
        if (values.housing && this.isYes(values.housing.toUpperCase())) {
            concerns.push('Housing')
        }
        if (values.employment && this.isYes(values.employment.toUpperCase())) {
            concerns.push('Employment')
        }
        if (values.finances && this.isYes(values.finances.toUpperCase())) {
            concerns.push('Finances')
        }
        if (values.medical && this.isYes(values.medical.toUpperCase())) {
            concerns.push('Medical')
        }
        if (
            (values.relationships &&
                this.isYes(values.relationships.toUpperCase())) ||
            (values.isolation && this.isYes(values.isolation.toUpperCase()))
        ) {
            concerns.push('Social')
        }
        if (values.safety && this.isYes(values.safety.toUpperCase())) {
            concerns.push('Safety')
        }
        if (
            (values.depressed && this.isYes(values.depressed.toUpperCase())) ||
            (values.substances && this.isYes(values.substances.toUpperCase()))
        ) {
            concerns.push('Behavioral Health')
        }
        return concerns
    }

    isYes = (category) => {
        return category === 'Y'
    }

    onLangChange = (e) => {
        const { i18n } = this.props
        i18n.changeLanguage(e.target.value)
    }

    onGuardianChange = (e) => {
        this.setState({
            is_guardian: e.target.value,
        })
    }

    handleZipCodeCounty = (e) => {
        e.preventDefault()
        this.formRef.current.setFieldsValue({
            county: county_zip_codes[e.target.value],
        })
    }

    render() {
        const { response_submitted } = this.state
        const { t, i18n } = this.props
        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <Content style={{ width: '100%' }}>
                    <Card
                        style={{
                            borderRadius: '10px',
                            padding: '10px 15px',
                        }}
                        bordered={false}>
                        <Card
                            bordered={false}
                            style={{
                                width: '15%',
                                margin: 'auto',
                            }}
                            cover={
                                <img
                                    alt="CBHS"
                                    src="/assets/img/logo-cbhs.png"
                                />
                            }
                        />

                        <Title level={3} align="middle" style={{paddingBottom: '15px'}}>
                            CONNEXRX COVID SURVEY
                        </Title>
                        {response_submitted === true ? (
                            <Alert message="Thank you!" type="success" />
                        ) : (
                            <Form
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                                ref={this.formRef}
                                name="basic"
                                layout="vertical"
                                {...layout}>
                                <Form.Item
                                    colon={false}
                                    label="What language do you prefer?"
                                    name="language"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}>
                                    <Radio.Group
                                        onChange={this.onLangChange}>
                                        <Radio value={'en'}>English</Radio>
                                        <Radio value={'es'}>Spanish</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label={t('guardian_question')}
                                    name="guardian"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group
                                        onChange={this.onGuardianChange}>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {this.state.is_guardian === 'N' ? (
                                    <Form.Item
                                        label={t('first_name_question')}
                                        name="first_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: (
                                                    <p>
                                                        {t(
                                                            'first_name_question',
                                                        )}
                                                    </p>
                                                ),
                                            },
                                        ]}>
                                        <Input
                                            placeholder={t(
                                                'first_name_placeholder',
                                            )}
                                            size="large"
                                        />
                                    </Form.Item>
                                ) : (
                                    <Form.Item
                                        label={t(
                                            'guardian_first_name_question',
                                        )}
                                        name="guardian_first_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: (
                                                    <p>
                                                        {t(
                                                            'guardian_first_name_question',
                                                        )}
                                                    </p>
                                                ),
                                            },
                                        ]}>
                                        <Input
                                            placeholder={t(
                                                'guardian_first_name_placeholder',
                                            )}
                                            size="large"
                                        />
                                    </Form.Item>
                                )}
                                {this.state.is_guardian === 'N' ? (
                                    <Form.Item
                                        name="last_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: (
                                                    <p>
                                                        {t(
                                                            'last_name_question',
                                                        )}
                                                    </p>
                                                ),
                                            },
                                        ]}
                                        label={t('last_name_question')}>
                                        <Input
                                            placeholder={t(
                                                'last_name_placeholder',
                                            )}
                                            size="large"
                                        />
                                    </Form.Item>
                                ) : (
                                    <Form.Item
                                        label={t('guardian_last_name_question')}
                                        name="guardian_last_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: (
                                                    <p>
                                                        {t(
                                                            'guardian_last_name_question',
                                                        )}
                                                    </p>
                                                ),
                                            },
                                        ]}>
                                        <Input
                                            placeholder={t(
                                                'guardian_last_name_placeholder',
                                            )}
                                            size="large"
                                        />
                                    </Form.Item>
                                )}
                                {this.state.is_guardian === 'N' ? (
                                    <Form.Item
                                        name="phone_number"
                                        rules={[
                                            {
                                                required: true,
                                                pattern: /\d{3}-\d{3}-\d{4}/,
                                                message: (
                                                    <p>{t('phone_invalid')}</p>
                                                ),
                                            },
                                        ]}
                                        label={t('phone_question')}>
                                        <Input
                                            placeholder={t('phone_placeholder')}
                                            size="large"
                                        />
                                    </Form.Item>
                                ) : (
                                    <Form.Item
                                        name="phone_number"
                                        rules={[
                                            {
                                                required: true,
                                                pattern: /\d{3}-\d{3}-\d{4}/,
                                                message: (
                                                    <p>{t('phone_invalid')}</p>
                                                ),
                                            },
                                        ]}
                                        label={t('guardian_phone_question')}>
                                        <Input
                                            placeholder={t('phone_placeholder')}
                                            size="large"
                                        />
                                    </Form.Item>
                                )}
                                {this.state.is_guardian === 'N' ? (
                                    <Form.Item
                                        name="email"
                                        label={t('email_question')}
                                        rules={[
                                            {
                                                required: true,
                                                type: 'email',
                                                message: (
                                                    <p>{t('email_invalid')}</p>
                                                ),
                                            },
                                        ]}>
                                        <Input
                                            placeholder={t('email_placeholder')}
                                            size="large"
                                        />
                                    </Form.Item>
                                ) : (
                                    <Form.Item
                                        name="email"
                                        label={t('guardian_email_question')}
                                        rules={[
                                            {
                                                required: true,
                                                type: 'email',
                                                message: (
                                                    <p>{t('email_invalid')}</p>
                                                ),
                                            },
                                        ]}>
                                        <Input
                                            placeholder={t('email_placeholder')}
                                            size="large"
                                        />
                                    </Form.Item>
                                )}
                                {this.state.is_guardian === 'Y' ? (
                                    <Form.Item>
                                        <p>
                                            {t('guardian_message')}
                                        </p>
                                    </Form.Item>
                                ) : (
                                    null
                                )}
                                <Form.Item
                                    name="birthdate"
                                    label={t('birthdate_question')}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Date of Birth Required',
                                        },
                                    ]}>
                                    <DatePicker />
                                </Form.Item>
                                <Form.Item
                                    name="city"
                                    label={t('city_question')}
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('city_question')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Input
                                        placeholder={t('city_placeholder')}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                        name="zipcode"
                                        rules={[
                                            {
                                                required: true,
                                                min: 5,
                                                max: 5,
                                                message: 'ZIP must be 5 digits'
                                            },
                                        ]}
                                        label={t('zipcode_question')}>
                                       <Input
                                            onKeyPress={(e) => {
                                                e.key === 'Enter' && e.preventDefault()
                                            }}
                                            onChange={(e) => this.handleZipCodeCounty(e)}
                                            placeholder="11223"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                </Form.Item>
                                <Form.Item
                                    name="county"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'County Required',
                                        },
                                    ]}
                                    label={t('county_question')}>
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Westchester"
                                        optionFilterProp="children"
                                        defaultActiveFirstOption={true}>
                                        {countys_list.sort().map((county) => (
                                            <Option key={county}>{county}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>    
                                <Form.Item
                                    name="gender"
                                    label={t('gender_question')}
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Select
                                        placeholder={t('select_option')}
                                        size="large">
                                        <Option value="M">
                                            {t('gender_male')}
                                        </Option>
                                        <Option value="F">
                                            {t('gender_female')}
                                        </Option>
                                        <Option value="NB">
                                            {t('gender_nb')}
                                        </Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="transportation"
                                    colon={false}
                                    label={t('transportation_question')}
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    name="childcare"
                                    colon={false}
                                    label={t('childcare_question')}
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    name="food"
                                    colon={false}
                                    label={t('food_question')}
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    name="housing"
                                    colon={false}
                                    label={t('housing_question')}
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    name="employment"
                                    label={t('employment_question')}
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t('finances_question')}
                                    name="finances"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t('medical_question')}
                                    name="medical"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t('relationships_question')}
                                    name="relationships"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t('isolation_question')}
                                    name="isolation"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t('safety_question')}
                                    name="safety"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t('depressed_question')}
                                    name="depressed"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t('substances_question')}
                                    name="substances"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t('easy_question')}
                                    name="easy"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <p>{t('select_option')}</p>
                                            ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={'Y'}>{t('yes')}</Radio>
                                        <Radio value={'N'}>{t('no')}</Radio>
                                        <Radio value={'S'}>{t('skip')}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large">
                                        {t('submit')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </Card>
                </Content>
            </Content>
        )
    }
}

export default withTranslation()(CovidSurvey)
