import React, { Component } from 'react'
import axios from 'axios'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Card, Result } from 'antd';

import './reset-password.scss'

class ResetPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validLink: false,
            confirmDirty: false,
            user_id: null,
            hash: null,
            formSuccess: false,
            errorMessage: '',
        }
    }

    componentDidMount() {
        const url = this.props.location.pathname.split('/')

        const hash = url[2]
        const user_id = url[3]

        this.setState({ user_id: user_id, hash: hash })

        axios
            .post('/api/users/resetpassword/validate', {
                hash: hash,
                user_id: user_id,
            })
            .then((resp) => {
                if (resp.status === 200) {
                    const { valid, message } = resp.data
                    if (valid === true) {
                        this.setState({
                            validLink: true,
                            errorMessage: message,
                        })
                    } else {
                        this.setState({
                            validLink: false,
                            errorMessage: message,
                        })
                    }
                }

                //openNotificationSuccess(resp.data.user, resp.data.user_type)
            })
            .catch((err) => {
                this.setState({ validLink: false })
                console.log(err)
            })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const data = {
                    user_id: this.state.user_id,
                    hash: this.state.hash,
                    password: values.password,
                    password2: values.password2,
                }
                axios
                    .post('/api/users/resetpassword/submit', data)
                    .then((res) => {
                        if (res.data.ok) {
                            this.setState({ formSuccess: true })
                        } else {
                            this.setState({
                                formSuccess: false,
                            })
                        }
                    })
                    .catch((err) => {
                        // show error
                    })
            }
        })
    }

    handleConfirmBlur = (e) => {
        const { value } = e.target
        this.setState({ confirmDirty: this.state.confirmDirty || !!value })
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props
        if (value && value !== form.getFieldValue('password')) {
            callback('The passwords that you entered do not match')
        } else {
            callback()
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true })
        }
        callback()
    }

    goToLogin = (e) => {
        window.location.href = '/'
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <React.Fragment>
                {this.state.formSuccess === true ? (
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{
                            minHeight: '80vh',
                        }}>
                        <Result
                            title="Your password has been updated"
                            extra={
                                <Button
                                    type="primary"
                                    key="console"
                                    onClick={(e) => this.goToLogin()}>
                                    Go to login page
                                </Button>
                            }
                        />
                    </Row>
                ) : this.state.validLink === false ? (
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{
                            minHeight: '80vh',
                        }}>
                        <Result
                            title={this.state.errorMessage}
                            extra={
                                <Button
                                    type="primary"
                                    key="console"
                                    onClick={(e) => this.goToLogin()}>
                                    Back to login page
                                </Button>
                            }
                        />
                    </Row>
                ) : (
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{
                            minHeight: '80vh',
                        }}>
                        <Col xs={24} sm={24} md={12} lg={10} xl={6}>
                            <Card
                                style={{
                                    borderRadius: '10px',
                                    padding: '10px 15px',
                                }}
                                bordered={false}>
                                <Row>
                                    <Col span={24}>
                                        <Card
                                            // title="CBHS Care Connections"
                                            bordered={false}
                                            headStyle={{
                                                fontSize: 25,
                                                paddingLeft: 0,
                                            }}
                                            style={{
                                                width: '40%',
                                                margin: 'auto',
                                            }}
                                            cover={
                                                <img
                                                    alt="CBHS"
                                                    src="/assets/img/logo.png"
                                                />
                                            }
                                            bodyStyle={{
                                                paddingBottom: 5,
                                            }}
                                        />
                                        <p>Please enter your new password.</p>
                                        <Form onSubmit={this.handleSubmit}>
                                            <input
                                                type="hidden"
                                                value="autocompleteoff"
                                            />
                                            <Form.Item hasFeedback>
                                                {getFieldDecorator('password', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                'Please input your password!',
                                                        },
                                                        {
                                                            validator: this
                                                                .validateToNextPassword,
                                                        },
                                                    ],
                                                })(
                                                    <Input.Password
                                                        size="large"
                                                        placeholder="Password"
                                                        autoComplete="new-password"
                                                    />,
                                                )}
                                            </Form.Item>
                                            <Form.Item hasFeedback>
                                                {getFieldDecorator(
                                                    'password2',
                                                    {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please confirm your password!',
                                                            },
                                                            {
                                                                validator: this
                                                                    .compareToFirstPassword,
                                                            },
                                                        ],
                                                    },
                                                )(
                                                    <Input.Password
                                                        size="large"
                                                        onBlur={
                                                            this
                                                                .handleConfirmBlur
                                                        }
                                                        placeholder="Confirm Password"
                                                        autoComplete="confirm-password"
                                                    />,
                                                )}
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    size="large"
                                                    style={{
                                                        width: '100%',
                                                    }}>
                                                    Submit new password
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                )}
            </React.Fragment>
        )
    }
}

export default Form.create({ name: 'reset_password' })(ResetPassword)
