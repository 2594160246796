import React from 'react'
import { Card, Col, Row, Statistic, Tooltip } from 'antd'

import { InfoCircleOutlined } from '@ant-design/icons'

const ReferralCards = (props) => {
    const Rows = {
        one: [
            ['Total Referrals', 'all', 'All referrals stored for current project'],
            ['Not Assigned', 'not_assigned', 'Referrals with field `Assign to Agency` as `Not Assigned` or `CBHS`'],
            ['Unscheduled', 'unscheduled', 'Referrals with field `Referral Status` as `Not Scheduled`'],
            ['Scheduled', 'scheduled', 'Referrals with field `Referral Status` as `Scheduled`'],
        ],
        two: [
            ['Rescheduled', 'rescheduled', 'Referrals with field `Referral Status` as `Rescheduled`'],
            ['Completed', 'completed', 'Referrals with field `Referral Status` as `Completed`'],
            ['Cancelled', 'cancelled', 'Referrals with field `Referral Status` as Cancelled`'],
            ['No Shows', 'no_shows', 'Referrals with field `Referral Status` as `No Show`'],
        ],
        three: [
            ['Left Message', 'left_message', 'Referrals with field `Referral Status` as `Left Message`'],
            [
                'Unsuccessful Connection',
                'unsuccessful_connection',
                'Referrals with field `Referral Status` as `Did Not Respond to Outreach` or `Not Eligible For Services` or `Individual Declined Services` and one of `address, primary phone, secondary phone validity is unsuccessful`',
            ],
            ['Assessment Contact Not Made', 'assessment_not_contacted', 'Referrals with field `First Contact Date` as empty'],
            ['Assessments Not Completed', 'assessment_not_completed', 'Referrals with field `Assesesment Date Completed` as empty'],
        ],
        cbhc_mvp: [
            ['Total Referrals', 'all', 'All referrals stored for current project'],
            [
                'Unsuccessful Connection',
                'unsuccessful_connection',
                'Referrals with field `Referral Status` as `Did Not Respond to Outreach` or `Not Eligible For Services` or `Individual Declined Services` and one of `address, primary phone, secondary phone validity is unsuccessful`',
            ],
            ['Left Message', 'left_message', 'Referrals with field `Referral Status` as `Left Message`'],
            ['Declined', 'declined', 'Referrals with field `Referral Status` as `Declined`'],
            ['Outreach Attempted', 'outreach_attempted', 'Referrals with field `Referral Status` as `Outreach Attempted`'],
            ['Outreach Completed', 'outreach_completed', 'Referrals with field `Referral Status` as `Outreach Completed`'],
            ['No Outreach', 'no_outreach', 'Referrals with field `Referral Status` as `No Outreach`'],
        ],
        shared_care: [['Total Referrals', 'all', 'All referrals stored for current project']],
    }

    const generateCardRow = (statuses) => {
        const CardRows = statuses.map((status) => {
            if (status[1] === '') {
                return (
                    <Col xs={12} sm={6} key={status}>
                        <Card className="dashboard--card-style" style={{ marginBottom: '15px' }} hoverable bordered={false}>
                            <Statistic
                                valueStyle={{ color: 'white' }}
                                title={
                                    <div style={{ color: 'white' }}>
                                        <span>{status[0]}</span>
                                        <Tooltip title="">
                                            <InfoCircleOutlined style={{ float: 'right' }} />
                                        </Tooltip>
                                    </div>
                                }
                                value=""
                                precision={0}
                            />
                        </Card>
                    </Col>
                )
            } else {
                return (
                    <Col xs={12} sm={6} key={status}>
                        <Card className="dashboard--card-style" style={{ marginBottom: '15px' }} onClick={() => props.statusChanger(status[1])} hoverable bordered={false}>
                            <Statistic
                                valueStyle={{ color: 'white' }}
                                title={
                                    <div style={{ color: 'white' }}>
                                        <span>{status[0]}</span>
                                        <Tooltip title={status[2]}>
                                            <InfoCircleOutlined style={{ float: 'right' }} />
                                        </Tooltip>
                                    </div>
                                }
                                value={props.referrals[status[1]].length}
                                precision={0}
                            />
                        </Card>
                    </Col>
                )
            }
        })

        return <Row gutter={[{ xs: 8, sm: 14, md: 20, lg: 16 }, 15]}>{CardRows}</Row>
    }

    const render = () => {
        if (props.currentProject === 'CBHC-MVP') {
            return generateCardRow(Rows.cbhc_mvp)
        } else if (props.currentProject === 'SHARED-CARE') {
            return generateCardRow(Rows.shared_care)
        } else {
            return (
                <React.Fragment>
                    {generateCardRow(Rows.one)}
                    {generateCardRow(Rows.two)}
                    {generateCardRow(Rows.three)}
                </React.Fragment>
            )
        }
    }

    return <div style={{ margin: '20px 16px 0' }}>{render()}</div>
}

export default ReferralCards
