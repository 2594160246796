import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import MaskedInput from 'antd-mask-input'
import { Card, Col, Row, Select, Input, DatePicker, TimePicker } from 'antd'
import {
    contact_needs,
    contact_type,
    date_format,
    number_of_contacts,
    agencies
} from '../../../../utils/constants'
const { Option } = Select
const { TextArea } = Input

const agenciesWithoutNone = agencies.filter((item) => item !== 'None')

class CareManagement extends Component {
    render() {
        const { getFieldDecorator, currentProject } = this.props
        return (
            <>
                <Card
                    title={
                        currentProject === 'COVID'
                            ? 'Referral Follow-up'
                            : 'Care Management'
                    }
                    bordered={false}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`Contact Needs`}>
                                {getFieldDecorator(`contact_needs`, {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Input  something!',
                                        },
                                    ],
                                })(
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="select contact needs">
                                        {contact_needs.sort().map((need) => (
                                            <Option key={need}>{need}</Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`Contact Type`}>
                                {getFieldDecorator(`contact_type`, {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Input  something!',
                                        },
                                    ],
                                })(
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="select contact type">
                                        {contact_type.sort().map((type) => (
                                            <Option key={type}>{type}</Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={6}>
                            <Form.Item label={`Contact Date`}>
                                {getFieldDecorator(`contact_date`)(
                                    <DatePicker
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="select date"
                                        format={date_format}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={6}>
                            <Form.Item label={`Contact Time`}>
                                {getFieldDecorator(`contact_time`)(
                                    <TimePicker
                                        use12Hours
                                        format="h:mm a"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="select time"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`Number of Contacts`}>
                                {getFieldDecorator(`number_of_contacts`)(
                                    <Select placeholder="select number of contacts">
                                        {number_of_contacts.map((number) => (
                                            <Option key={number}>
                                                {number}
                                            </Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label={`Contact Details`}>
                                {getFieldDecorator(`contact_details`, {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Input something!',
                                        },
                                    ],
                                })(<TextArea placeholder="" rows={3} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item label={`Care Manager First Name`}>
                                {getFieldDecorator(`toc_cm_first`, {
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(
                                    <Input
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        placeholder="CM First"
                                        style={{
                                            width: '100%',
                                        }}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} >
                            <Form.Item label={`Care Manager Last Name`}>
                                {getFieldDecorator(`toc_cm_last`, {
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(
                                    <Input
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        placeholder="CM Last"
                                        style={{
                                            width: '100%',
                                        }}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} >
                            <Form.Item label={`Care Manager Phone`}>
                                {getFieldDecorator(`toc_cm_phone`, {
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(
                                    <MaskedInput
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        style={{
                                            width: '100%',
                                        }}
                                        mask="(111) 111-1111"
                                        placeholder="(123) 456-7890"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item label={`Care Manager Email`}>
                                {getFieldDecorator(`toc_cm_email`, {
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(
                                    <Input
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' && e.preventDefault()
                                        }}
                                        placeholder="email@provider.com"
                                        style={{
                                            width: '100%',
                                        }}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} >
                            <Form.Item label={`Care Manager Agency`}>
                                {getFieldDecorator(`toc_cm_agency`)(
                                    <Select placeholder="select an agency">
                                        {agenciesWithoutNone.sort().map((number) => (
                                            <Option key={number}>{number}</Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </>
        )
    }
}

export default CareManagement
