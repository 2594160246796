import React from 'react'
import { Button, Row, Col, Typography, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

const { Dragger } = Upload
const { Title, Text } = Typography

const UploadDocumentation = (props) => {
    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Title level={2} style={{paddingTop: '15px'}}>Upload File</Title>
                    <Text>
                        Select the file to upload
                    </Text>
                </Col>
            </Row>
            <Dragger {...props.draggerProps} style={{marginTop: '15px', marginBottom: '30px'}} >

                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    Click here and choose a file
                </p>
                <p className="ant-upload-hint">
                    PDF, Word, and Excel accepted
                </p>
            </Dragger>
            <br />
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Button
                        type="primary"
                        onClick={props.next}
                        style={{marginRight: '5px'}}
                        >
                        Next
                    </Button>
                    <Button
                        type="secondary"
                        onClick={props.back}
                        style={{marginRight: '5px'}}
                        >
                        Back
                    </Button>
                    {props.fileList.length === 1 && (
                        <Button
                            type="secondary"
                            onClick={props.preview}
                            >
                            Preview
                        </Button>
                    )}
                    <Button
                        type="danger"
                        onClick={props.reset}
                        style={{float: 'right'}}
                        >
                        Reset
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default UploadDocumentation 

