import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { List, Card, Typography, Divider, Button } from 'antd'
import { DeleteFilled, StarOutlined } from '@ant-design/icons'

const { Text } = Typography

function SavedQuery(props) {
    const { userId, toggleFetchList, handleToggleFetchList, handleLoadSavedQuery, handleDeleteSavedQuery } = props
    const [savedQuery, setSavedQuery] = useState([])
    const [initFetchList, setInitFetchList] = useState(true)

    const fetchList = async () => {
        if (initFetchList || toggleFetchList) {
            const responseAPI = await axios.post('/api/reports/getSavedQueryList', { user_id: userId })
            setSavedQuery(responseAPI.data)
            handleToggleFetchList(false)

            if (initFetchList) setInitFetchList(false)
        }
    }

    useEffect(() => {
        fetchList()
    }, [toggleFetchList])

    return (
        <>
            <Card
                bodyStyle={{
                    padding: 26,
                    width: '100%',
                }}
                title="Saved">
                <Text>Load the report settings</Text>
                <Divider />
                <List
                    itemLayout="horizontal"
                    dataSource={savedQuery}
                    renderItem={(key) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<StarOutlined />}
                                title={
                                    <Button
                                        style={{ padding: 0, margin: 0, height: '1.5em' }}
                                        type="link"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            handleLoadSavedQuery(key._id)
                                        }}>
                                        {key.title}
                                    </Button>
                                }
                                description={
                                    <>
                                        {key.created_on} <DeleteFilled title="Delete this filter" onClick={() => handleDeleteSavedQuery(key._id)} style={{ marginLeft: 5 }} />
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </>
    )
}

export default SavedQuery
