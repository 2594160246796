import axios from 'axios'
import setAuthToken from '../../utils/setAuthToken'
import jwt_decode from 'jwt-decode'

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING, UPDATE_USERNAME } from './types'

const TWILIO_ERROR_CODE = {
    60203: 'Max request for authentication code has reached. Please try again later.',
}

// Register User
export const registerUser = (userData, history) => (dispatch) => {
    axios
        .post('/api/users/register', userData)
        .then((res) => history.push('/login'))
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            }),
        )
}

export const renewSession = () => (dispatch) => {
    axios.post('/api/users/renewsession').then((res) => {
        const { token } = res.data
        localStorage.setItem('jwtToken', token)
        setAuthToken(token)
        const decoded = jwt_decode(token)
        console.log('renewSession', decoded)
        dispatch(setCurrentUser(decoded))
    })
}

// Login - get user token
export const loginUser = (userData, setShowOtp, setErrorMessage) => (dispatch) => {
    axios
        .post('/api/users/login', userData)
        .then((res) => {
            if (res.data.otp === true && res.data.success === false) {
                // show otp page
                setShowOtp(true)
                return false
            } else if (res.data.otp === false && res.data.success === true) {
                // normal login
                // Save to localStorage
                // Set token to localStorage
                const { token } = res.data
                localStorage.setItem('jwtToken', token)
                // Set token to Auth header
                setAuthToken(token)
                // Decode token to get user data
                const decoded = jwt_decode(token)
                // set current project
                const project = decoded.projects[0] || null
                // set localstorage for projectview
                localStorage.setItem('currentProject', project)
                // Set current user
                dispatch(setCurrentUser(decoded))
            } else {
                // just throw error
                throw new Error(res)
            }
        })
        .catch((err) => {
            const res = err.response.data
            if (res.success === false && TWILIO_ERROR_CODE.hasOwnProperty(res.error.code)) {
                setErrorMessage(TWILIO_ERROR_CODE[res.error.code])
                console.log(TWILIO_ERROR_CODE[res.error.code])
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data,
                })
            }
        })
}

// Login OTP - Validate user OTP and get user token
export const loginUserWithOTP = (user, otpcode, setErrorMessage) => (dispatch) => {
    axios
        .post('/api/users/verifyotp', {
            email: user.email,
            password: user.password,
            otpcode: otpcode,
        })
        .then((res) => {
            if (res.data.success === true) {
                // Save to localStorage
                // Set token to localStorage
                const { token } = res.data
                localStorage.setItem('jwtToken', token)
                // Set token to Auth header
                setAuthToken(token)
                // Decode token to get user data
                const decoded = jwt_decode(token)
                // set current project
                const project = decoded.projects[0] || null
                // set localstorage for projectview
                localStorage.setItem('currentProject', project)
                // Set current user
                dispatch(setCurrentUser(decoded))
            } else {
                setErrorMessage(res.data.error)
                console.log('Error otp')
            }
        })
        .catch((err) => {
            const res = err.response.data
            if (res.success === false && TWILIO_ERROR_CODE.hasOwnProperty(res.error.code)) {
                setErrorMessage(TWILIO_ERROR_CODE[res.error.code])
                console.log(TWILIO_ERROR_CODE[res.error.code])
            } else if (res.error !== 'undefined') {
                setErrorMessage(res.data.error)
                console.log('Error otp')
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data,
                })
            }
        })
}

// Set logged in user
export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    }
}

// User loading
export const setUserLoading = () => {
    return {
        type: USER_LOADING,
    }
}

// Log user out
export const logoutUser = () => (dispatch) => {
    // Remove token from local storage
    // Remove current project
    localStorage.clear()

    // Remove auth header for future requests
    setAuthToken(false)
    // Set current user to empty object {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}))
}

export const changeUsername = (name) => {
    return {
        type: UPDATE_USERNAME,
        payload: name,
    }
}
