import React, { Component } from 'react'
import { Card, Col, Checkbox, DatePicker, Form, Input, Row, Select, Switch, TimePicker } from 'antd'
import { ExclamationCircleTwoTone } from '@ant-design/icons'
import { referral_needs, contact_type, date_format, contact_duration, outcome_of_intervention } from '../../../../../../utils/constants'
import styled, { css } from 'styled-components'

const { Option } = Select
const { TextArea } = Input

const CustomCheckbox = styled(Checkbox)`
    ${(props) =>
        props.backgroundColor &&
        css`
            & .ant-checkbox-checked .ant-checkbox-inner {
                background-color: ${props.backgroundColor};
                border-color: ${props.backgroundColor};
            }
        `}
`

class PeerServices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contact_made: false,
        }
    }

    render() {
        return (
            <React.Fragment>
                <Card title="Contact Made?" bordered={false} style={{ width: '-webkit-fill-available', marginBottom: '1em' }}>
                    <Row gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <span>
                                <Form.Item
                                    label="Made Contact?"
                                    name="peer_services_contact_made"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    initialValue="No"
                                    labelAlign="left"
                                    style={{ flexDirection: 'row' }}>
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        // checked={this.state.health_home_enrolled}
                                        onClick={() => {
                                            this.setState({
                                                contact_made: !this.state.contact_made,
                                            })
                                        }}
                                    />
                                </Form.Item>
                            </span>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item
                                name="peer_services_left_message"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                valuePropName="checked"
                                label="Left Message?"
                                labelCol={{ span: 12 }}>
                                <CustomCheckbox disabled={this.state.contact_made} backgroundColor="#044268"></CustomCheckbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title="Contact Information" bordered={false} style={{ width: '-webkit-fill-available' }}>
                    <Row gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Form.Item
                                name="peer_services_contact_needs"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Contact Needs"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}>
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch
                                    mode="multiple"
                                    placeholder="Select contact needs"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {referral_needs.sort().map((need) => (
                                        <Option key={need}>{need}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="peer_services_contact_type"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Contact Type(s)"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}>
                                <Select
                                    style={{
                                        width: '100%',
                                    }}
                                    showSearch
                                    mode="multiple"
                                    placeholder="Select contact type(s)"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {contact_type.sort().map((need) => (
                                        <Option key={need}>{need}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="peer_services_contact_date"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Contact Date"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}>
                                <DatePicker
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Form.Item
                                name="peer_services_contact_time"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Contact Time"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}>
                                <TimePicker
                                    use12Hours
                                    format="h:mm a"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select time"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="peer_services_number_of_contacts"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Number of Contacts"
                                tooltip="This field will automatically increment when contact is made"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}>
                                <Input disabled="true" placeholder="0" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="peer_services_contact_duration"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Contact Duration"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}>
                                <Select placeholder="Select contact duration">
                                    {contact_duration.map((number) => (
                                        <Option key={number}>{number}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={24} lg={23}>
                            <Form.Item
                                name="peer_services_contact_details"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Contact Details"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}>
                                <TextArea placeholder="Enter contact details" rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={7}>
                            <Form.Item
                                name="peer_services_appointment_date"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Appointment Date"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}>
                                <DatePicker
                                    style={{
                                        width: '100%',
                                    }}
                                    format={date_format}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={7} xl={{ span: 7, offset: 1 }}>
                            <Form.Item
                                name="peer_services_appointment_time"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                label="Appointment Time"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}>
                                <TimePicker
                                    use12Hours
                                    format="h:mm a"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Select time"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title="Outcome of Intervention/Service" bordered={false} style={{ width: '-webkit-fill-available', marginTop: '1em' }}>
                    <Row gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={12} lg={15}>
                            <Form.Item
                                name="peer_services_outcome_of_intervention"
                                rules={[
                                    {
                                        required: this.state.contact_made,
                                        message: 'Outcome is required when contact is made',
                                    },
                                ]}
                                tooltip={{
                                    title: 'This field is required when contact is made',
                                    icon: <ExclamationCircleTwoTone twoToneColor="#eb2f96" />,
                                }}
                                label="Outcome"
                                labelAlign="left"
                                style={{ flexDirection: 'column' }}>
                                <Select placeholder="Select outcome of intervention" style={{ flexDirection: 'column' }}>
                                    {outcome_of_intervention.map((outcome) => (
                                        <Option key={outcome}>{outcome}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title="Quality Metrics" bordered={false} style={{ width: '-webkit-fill-available', marginTop: '1em' }}>
                    <Row gutter={[16, 24]}>
                        <Col xs={24} sm={25} md={12} lg={12}>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_antipsych_med_schizo"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Adherence to Antipsychotic Medication for Person with Schizophrenia</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_antipsych_med_schizo_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_adult_bmi"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Adult BMI Assessment</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_adult_bmi_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_ambulance"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Adult Access to Preventative/Ambulatory Health Services</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_ambulance_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_antidepressant"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Antidepressant Medication Management</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_antidepressant_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_amr"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Asthma Medication Ratio</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_amr_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_breast_cancer_screening"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Breast Cancer Screening</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_breast_cancer_screening_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_cardiovascular"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Cardiovascular Monitoring for People with Cardiovascular Disease and Schizophrenia</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_cardiovascular_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_cervical_cancer_screening"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Cervical Cancer Screening</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_cervical_cancer_screening_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_col"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Colorectal Cancer Screening</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_col_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_csc_eye"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Comprehensive Diabetes Care Eye Exam</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_csc_eye_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_continuity"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Continuity of Care for Inpatient Detox or Rehabilitation to Lower Level of Care</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_continuity_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_diabetes_monitoring"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Diabetes Monitoring for People with Diabetes and Schizophrenia</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_diabetes_monitoring_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_diabetes_screening_schizo_bipolar"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Diabetes Screening For People with Schizophrenia or Bipolar Disorder on Antipsychotic Medication</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_diabetes_screening_schizo_bipolar_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_diverted"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Diverted from Emergency Department Services</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_diverted_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_emergency_dept_follow_up"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Emergency Department Follow-up</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_emergency_dept_follow_up_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_health_home"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Enrolled in a Health Home</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_health_home_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_seven_day_hospital_follow_up"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Follow-up After Hospitalization for Mental Illness (Within 7 Days)</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_seven_day_hospital_follow_up_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_thirty_day_hospital_follow_up"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Follow-up After Hospitalization for Mental Illness (Within 30 Days)</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_thirty_day_hospital_follow_up_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_adhd"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Follow-up Care for Children Prescribed ADHD</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_adhd_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_engagement"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Initiation & Engagement of Alcohol and Other Drug Dependence Treatment</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_engagement_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_mat"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Initation and Utilization of MAT for OUD</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_mat_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_tobacco"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Medical Assistance with Smoking and Tobacco Use Cessation</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_tobacco_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_anti_psych"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Metabolic Monitoring on Anti-Psychotic Medication</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_anti_psych_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_phq9"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>PHQ9 to Monitor Depression Symptoms for Adolescents and Adults</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_phq9_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_psychosocial"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Use of First Line Psychosocial Care</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_psychosocial_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_well_child_visit_3_18"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Well Child Visit 3-18 Months</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_child_visit_3_18_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col xs={6} sm={6} md={6} lg={12}>
                                    <Form.Item
                                        name="scf_well_child_first_30"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        initialValue={false}>
                                        <Checkbox style={{ padding: '12px 8px' }}>Well Child First 30 Months</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={10}>
                                    <Form.Item
                                        name="scf_well_child_first_30_date"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        labelAlign="left"
                                        style={{ flexDirection: 'column', width: '100%', padding: '12px 8px' }}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </React.Fragment>
        )
    }
}

export default PeerServices
