import React from 'react'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import Moment from 'moment'

import { Button, Col, Layout, Modal, Row, Select } from 'antd'
import { DownloadOutlined, FormOutlined } from '@ant-design/icons'
import { csvDataFormatter } from '../../../../utils/csv'

const { Content } = Layout
const { Option } = Select

function showDownloadConfirm(props) {
    Modal.info({
        title: 'Do you want to Download CSV?',
        content: (
            <Row style={{ marginBottom: '1em', marginTop: '1em', textAlign: 'center' }}>
                <Col>
                    <div>
                        <p style={{ paddingTop: '1em' }}>
                            <b>By clicking this button, you understand that once you download ConnexRX data, we cannot ensure it will be kept secure.</b> You are attempting to download a file which
                            includes protected health information (“PHI”). We secure the data on ConnexRX servers using encryption and other technical, physical, and procedural safeguards. You
                            understand that once you download the data, we cannot guarantee the security of that downloaded data file.
                        </p>
                        <p>
                            You must have a valid Use and Disclose reason for accessing and downloading this PHI. IF YOU DO NOT have a valid, legal reason for downloading this information, you make
                            liable for civil penalties and/or criminal charges. PROCEED WITH CAUTION AND UNDER NOTICE.
                        </p>
                        <br />
                        <CSVLink
                            headers={csvDataFormatter(props.preparedCsvData, props.currentProject)[0]}
                            data={csvDataFormatter(props.preparedCsvData, props.currentProject)[1]}
                            filename={props.userType + '-' + props.currentProject + '-referrals-' + Moment().format('MM-DD-YYYY') + '.csv'}
                            target="_blank">
                            <Button type="primary" icon={<DownloadOutlined />} title="Please disable your ads blocker to download the file" size="large">
                                Continue Download
                            </Button>
                        </CSVLink>
                    </div>
                </Col>
            </Row>
        ),
        okText: 'Close',
        maskClosable: true,
        destroyOnClose: true,
    })
}

const ProjectHeader = (props) => {
    const CurrentProjects = props.userProjects.map((proj) => {
        return (
            <Option key={proj} value={proj}>
                {proj}
            </Option>
        )
    })

    return (
        <Content
            style={{
                margin: '20px 16px 0',
                flex: 'none',
            }}>
            <Row>
                <Col span={4} style={{ borderRadius: '12px' }}>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select Project to View"
                        optionFilterProp="children"
                        onSelect={(value) => props.setCurrentProject(value)}
                        defaultValue={props.currentProject}>
                        {CurrentProjects}
                    </Select>
                </Col>
                <Col xs={24} sm={{ span: 10, offset: 2 }} md={{ span: 8, offset: 6 }} lg={{ span: 4, offset: 12 }} style={{ right: '5px' }}>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        title="Please disable your ads blocker to download the file"
                        size="medium"
                        block
                        style={{ borderRadius: '10px' }}
                        ghost
                        onClick={() => showDownloadConfirm(props)}>
                        Download CSV
                    </Button>
                </Col>
                <Col xs={24} sm={8} md={6} lg={4} style={{ textAlign: 'right' }}>
                    <Link to={`/addreferral/${props.currentProject}`}>
                        <Button type="primary" style={{ borderRadius: '10px' }} size="medium" block icon={<FormOutlined />}>
                            Add Referral
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Content>
    )
}

export default ProjectHeader

// By clicking this box, you understand that once you download ConnexRX data, we cannot ensure it will be kept secure. We secure the data on ConnexRX servers using encryption and other technical, physical, and procedural safeguards. You understand that once you download the data, we cannot guarantee the security of that downloaded data file.
