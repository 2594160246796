import React from 'react'

import { Button, Col, Row, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';

const { Title, Text } = Typography

const ConfirmDataEdit = (props) => {
    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} style={{ marginBottom: '15px' }}>
                    <Title level={2} style={{ paddingTop: '15px' }}>
                        Confirm Changes
                    </Title>
                    <Text>Click the save button to confirm the edit; or go back </Text>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Button type="primary" onClick={props.onFinish} icon={props.loading ? <LoadingOutlined /> : null} style={{marginTop: '30px', marginRight: '5px'}}>
                        Save
                    </Button>
                    <Button
                        type="secondary"
                        onClick={props.back}>
                        Back
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ConfirmDataEdit