import React from 'react'
import { Link } from 'react-router-dom'
import { Layout } from 'antd'

const { Footer } = Layout

const CustomFooter = () => {
    return (
        <Footer className="footer">
            CBHS Care Connections ©2021 |{' '}
            <Link to="/privacy">Privacy Policy</Link> |{' '}
            <Link to="/terms">Terms and Conditions</Link>
        </Footer>
    )
}

export default CustomFooter
