import React, { Component } from 'react'
import axios from 'axios'
import { Breadcrumb, Card, Col, Row, Layout, List } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'

const Content = Layout

class UserActivity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userActivity: [],
        }
    }

    async componentDidMount() {
        const user_email = this.props.location.pathname.substring(
            this.props.location.pathname.lastIndexOf('/') + 1,
        )
        const activities = await axios.post('/api/users/useractivity', {
            email: user_email,
        })
        this.setState({
            userActivity: activities.data.reverse(),
            userEmail: user_email
        })
    }

    render() {
        
        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <Content style={{ width: '100%' }}>
                    <div style={{ marginBottom: '16px', width: '100%' }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>User</Breadcrumb.Item>
                            <Breadcrumb.Item>Activity</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Card
                        bodyStyle={{
                            padding: 26,
                            width: '100%',
                        }}
                        title={this.state.userEmail}>
                        <Row gutter={[16, 16]}>
                            <Col xs={{ span: 24 }}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.userActivity}
                                    pagination
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={"User log in"}
                                                description={item.loggedInOn}
                                            />
                                            <p><UserAddOutlined /></p>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Content>
            </Content>
        )
    }
}

export default UserActivity
