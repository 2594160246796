import React, { Component } from 'react'
import { Alert, Card, Col, Checkbox, Row } from 'antd'

class Consent extends Component {
    render() {
        return (
            <Card title="Consent" bordered={false} style={{ width: '-webkit-fill-available', marginTop: '1em' }}>
                <Row gutter={[16, 16]}>
                    {!this.props.skip_consent && (
                        <Col xs={24} md={24} lg={24}>
                            <Checkbox onChange={(e) => this.props.handleConsentChange(e)} defaultChecked={this.props.this_agency_consents}>
                                {' '}
                                I attest that all necessary consents have been signed or obtained verbally in order to coordinate care and provide services for this client in compliance with all
                                relevant State and Federal laws and regulations.
                            </Checkbox>
                        </Col>
                    )}

                    {!this.props.consent && (
                        <Col xs={24} md={24} lg={24}>
                            <Alert
                                type="error"
                                description={
                                    <Checkbox onChange={(e) => this.props.handleSkipConsentChange(e)} defaultChecked={this.props.skip_consent}>Click here to skip consent.</Checkbox>
                                }> 
                            </Alert>
                        </Col>
                    )}
                </Row>
            </Card>
        )
    }
}

export default Consent
