import React from 'react'

import { DownloadOutlined } from '@ant-design/icons'
import { Button, Row, Col, Popconfirm, Typography } from 'antd'
import { ExclamationCircleTwoTone  } from '@ant-design/icons'

const { Title, Text } = Typography

const DownloadFields = (props) => {
    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} style={{marginBottom: '5px'}}>
                    <Title level={2} style={{paddingTop: '15px'}}>
                        Download Allowed Values for {props.project}
                    </Title>
                    <Text >
                        The upload has restrictions on which values can be submitted for certain columns (these match the dropdowns on the form) <ExclamationCircleTwoTone twoToneColor="#eb2f96" />  
                    </Text>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 10 }}>
                    <a
                        href={`/${props.project.toLowerCase()}_allowed_field_values.csv`}
                        target="_blank"
                        rel="noopener noreferrer"
                        download>
                        <Button size="large" icon={<DownloadOutlined />} style={{marginBottom: '15px'}}>
                            Download allowed {props.project} values
                        </Button>
                    </a>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Button
                        type="primary"
                        disabled={!props.project}
                        onClick={props.next}
                        style={{marginRight: '5px'}}>
                        Next
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to start over?"
                        onConfirm={props.clear}>
                        <Button type="secondary" disabled={!props.project} >
                            Start Over
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default DownloadFields
