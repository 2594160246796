import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Link } from 'react-router-dom'
import MaskedInput from 'antd-mask-input'
import styled, { css } from 'styled-components'
import { DeleteOutlined, StepBackwardOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { detailedDiff } from 'deep-object-diff'
import '@ant-design/compatible/assets/index.css'
import { Alert, BackTop, Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Input, Layout, Modal, Row, Result, Select, Statistic, TimePicker, TreeSelect, Switch, Typography } from 'antd'
import {
    address_type,
    availablility,
    agencies,
    countys_list,
    date_format,
    date_time_format,
    time_format,
    genders,
    insurance_types,
    insurance_plans,
    languages_list,
    harp_values,
    referral_statuses,
    referral_statuses_cbhc_mvp,
    referral_sources,
    referral_needs,
    referral_titles,
    county_zip_codes,
    registered_project,
    health_home_facility,
    care_management_facility,
    engagement_contact_type,
    engagement_number_of_contacts,
    ethnicity,
} from '../../../utils/constants'
import { isValidPhone } from '../../../utils/helpers'
import './add-referral.scss'
import CareManagement from './care-management/care-management'
import Losd from './losd/losd'
import EmergencyContact from './emergency_contact/emergency_contact'

const { Content } = Layout
const { TextArea } = Input
const { Option } = Select
const { SHOW_PARENT } = TreeSelect
const { confirm } = Modal
const { Title } = Typography

const CustomCheckbox = styled(Checkbox)`
    ${(props) =>
        props.backgroundColor &&
        css`
            & .ant-checkbox-checked .ant-checkbox-inner {
                background-color: ${props.backgroundColor};
                border-color: ${props.backgroundColor};
            }
        `}
`

function showConfirm() {
    confirm({
        title: 'Do you want to return to dashboard?',
        content: 'Unsaved input will be lost',
        onOk() {
            window.location.replace('')
        },
        onCancel() {},
    })
}
class AddReferral extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submit_button_loading: false,
            submit_button_disabled: false,
            referral_submitted: false,
            referral_response: {},
            err: null,
            apptDateCheck: false,
            firstContactCheck: true,
            consent: false,
            skip_consent: false,
            default_agency: 'CBHS',
            currentAddressType: null,
            addressRequiredFields: {
                address_1: true,
                address_2: true,
                city: true,
                state: true,
                zip: true,
                county: true,
                phone: true,
            },
            showShelterNameField: false,
            Uninsured: false,
            show_guardian: false,
            health_home_enrolled: false,
            care_management_enrolled: false,
            daysLapsed: 0,
            mailing_address: false,
            currentProject: [],
            duplicate_skip: false,
            emergency_contact: false,
        }
    }

    componentDidMount() {
        this.setState({
            agency_selected: this.defaultAgency(),
        })
        this.validateProject()
    }

    onCheckboxChange(e, checkboxName) {
        this.setState({ [checkboxName]: e.target.checked })
    }

    validateProject = () => {
        const url_project = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)

        const user_project = this.props.auth.user.projects

        // if addreferral/:project url empty then redirect to the index 0 project of user
        if (url_project === 'addreferral') {
            if (typeof user_project[0] === 'undefined') {
                return this.props.history.push('/dashboard')
            } else {
                return this.props.history.push('/addreferral/' + user_project[0])
            }
        }

        // if project url is not registered in the constant registered project then throw out
        if (!registered_project.includes(url_project)) {
            this.props.history.push('/nomatch')
        }

        // if url project is not in user allowed project then throw out
        if (!user_project.includes(url_project)) {
            this.props.history.push('/nomatch')
        }

        this.setState({ currentProject: url_project })
    }

    handleDataErrorMessage = (err) => {
        if (err.data) {
            if (err.data.type === 'duplicate') {
                return (
                    <React.Fragment>
                        <Row gutter={[16, 16]} style={{ marginTop: '1em', paddingBottom: '5px' }}>
                            <Col xs={24}>
                                <Alert message={err.data.message} description=" " type="error" showIcon />
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            } else if (err.data.message) {
                return (
                    <React.Fragment>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <Alert message={err.data.message} description="There was an error processing your request." type="error" showIcon />
                            </Col>
                        </Row>
                        <br />
                    </React.Fragment>
                )
            }
        }
    }

    handleZipCodeCounty = (e) => {
        e.preventDefault()
        this.props.form.setFieldsValue({
            county: county_zip_codes[e.target.value],
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()

        this.setState({
            submit_button_loading: true,
        })
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (values['referral_agency'] !== 'None' && values['referral_agency'] !== 'CBHS') {
                    values['date_assigned'] = moment().format(date_format)
                }
                values['date_received'] = moment().format(date_format)
                values['user_id'] = this.props.auth.user.id

                if (values['referral_status'] === 'No Show') {
                    values['number_noshows'] = 1
                }

                if (values['referral_status'] === 'Cancelled') {
                    values['number_cancelled'] = 1
                }

                // normalize date and time to string
                const date_time_fields = ['appointment_date', 'last_annual_physical_date', 'last_pcp_followup_date']
                date_time_fields.forEach((field) => {
                    if (values[field] !== '' && values[field] !== undefined && values[field] !== null) {
                        values[field] = moment(values[field]).format(date_time_format)
                    }
                })

                // normalize date to string
                const date_fields = [
                    'dob',
                    'insurance_1_effective_date',
                    'insurance_1_expiration_date',
                    'insurance_2_effective_date',
                    'insurance_2_expiration_date',
                    'insurance_3_effective_date',
                    'insurance_3_expiration_date',
                    'qm_1_date',
                    'qm_2_date',
                    'qm_3_date',
                    'qm_4_date',
                    'qm_5_date',
                    'qm_6_date',
                    'health_home_enrollment_date',
                    'care_management_enrollment_date',
                    'engagement_date',
                ]
                date_fields.forEach((field) => {
                    if (values[field] !== '' && values[field] !== undefined && values[field] !== null) {
                        values[field] = moment(values[field]).format(date_format)
                    }
                })

                // normalize time to string
                const time_fields = ['contact_time', 'engagement_time']
                time_fields.forEach((field) => {
                    if (values[field] !== '' && values[field] !== undefined && values[field] !== null) {
                        values[field] = moment(values[field]).format(time_format)
                    }
                })

                values['project'] = this.state.currentProject && [this.state.currentProject]

                // if (values['additional_details']) {
                //     values['history'] = [
                //         {
                //             timestamp: moment().format(date_format),
                //             user: this.props.auth.user.name + ' <' + this.props.auth.user.email + '>',
                //             added: {
                //                 additional_details: values['additional_details'],
                //             },
                //         },
                //     ]
                // }

                if (values['is_guardian'] === true) {
                    values['is_guardian'] = 'Y'
                } else {
                    values['is_guardian'] = 'N'
                }

                var changes = detailedDiff({}, values)
                changes['timestamp'] = moment().format(date_format)
                changes['user'] = this.props.auth.user.name + ' <' + this.props.auth.user.email + '>'
                values['history'] = [changes]
                values['duplicate_skip'] = this.state.duplicate_skip

                axios
                    .post('/api/referrals/create', values)
                    .then((response) => {
                        this.setState({
                            submit_button_loading: false,
                            submit_button_disabled: true,
                            referral_response: response.data,
                            referral_submitted: true,
                            err: null,
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            submit_button_loading: false,
                            submit_button_disabled: false,
                            err: error.response,
                            // duplicate_skip: true,
                        })
                    })
            } else {
                this.setState({
                    submit_button_loading: false,
                    submit_button_disabled: false,
                    err: err,
                })
            }
        })
    }

    clearForm = () => {
        window.location.reload()
    }

    updateApptDateCheck = (e) => {
        if (e === 'Scheduled') {
            this.setState({ apptDateCheck: true })
        } else {
            this.setState({ apptDateCheck: false })
        }
    }

    defaultAgency = () => {
        if (this.props.auth.user.user_type === 'admin') {
            return 'CBHS'
        } else {
            return this.props.auth.user.user_type
        }
    }

    disabledDate = (current) => {
        return current < moment(this.state.firstContactDate).startOf('day')
    }

    handleAddressTypeOnChange = (e) => {
        this.setState({ currentAddressType: e })
        const requiredFields = address_type[e].required_fields

        if (e === 'Shelter') {
            this.setState({ showShelterNameField: true })
        } else {
            this.setState({ showShelterNameField: false })
        }

        if (requiredFields.length === 0) {
            this.setState({
                addressRequiredFields: {
                    address_1: false,
                    address_2: false,
                    city: false,
                    state: false,
                    zip: false,
                    county: false,
                    phone: false,
                },
            })
        } else if (requiredFields[0] === 'all') {
            this.setState({
                addressRequiredFields: {
                    address_1: true,
                    address_2: true,
                    city: true,
                    state: true,
                    zip: true,
                    county: true,
                    phone: true,
                },
            })
        } else {
            this.setState({
                addressRequiredFields: {
                    address_1: requiredFields.includes('address_1') ? true : false,
                    address_2: requiredFields.includes('address_2') ? true : false,
                    city: requiredFields.includes('city') ? true : false,
                    state: requiredFields.includes('state') ? true : false,
                    zip: requiredFields.includes('zip') ? true : false,
                    county: requiredFields.includes('county') ? true : false,
                    phone: requiredFields.includes('phone') ? true : false,
                },
            })
        }
    }

    showGuardianInfo = (getFieldDecorator) => {
        if (this.state.show_guardian === true) {
            return (
                <Row gutter={[16, 16]}>
                    <Col xs={24} lg={12}>
                        <Form.Item label={`Guardian Last Name`}>
                            {getFieldDecorator(`guardian_last_name`, {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Guardian last name is required',
                                    },
                                ],
                            })(
                                <Input
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder="Doe"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label={`Guardian First Name`}>
                            {getFieldDecorator(`guardian_first_name`, {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Guardian first name is required',
                                    },
                                ],
                            })(
                                <Input
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    placeholder="Doe"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <p>Please answer the remaining questions on behalf of the individual in your care. You will be contacted for further information.</p>
                    </Col>
                </Row>
            )
        }
    }

    handleDayslapsed = (e) => {
        let selectedDate = e
        let now = moment()

        if (selectedDate === null || selectedDate === '') {
            this.setState({ daysLapsed: 0 })
            return
        }

        let days = Math.floor(moment.duration(now.diff(selectedDate)).asDays())

        if (selectedDate < now) {
            this.setState({ daysLapsed: days })
        } else {
            this.setState({ daysLapsed: 0 })
        }
        return
    }

    render() {
        const tProps = {
            treeData: availablility,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            placeholder: 'please select days and times available',
            style: {
                width: '100%',
            },
        }
        const { getFieldDecorator } = this.props.form
        const { submit_button_loading, submit_button_disabled, referral_submitted, referral_response, err } = this.state

        const lowerCaseProject = this.state.currentProject.toString().toLowerCase()

        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <BackTop />
                <div style={{ marginBottom: '16px', width: '100%' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Referrals</Breadcrumb.Item>
                        <Breadcrumb.Item>Add Referral</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {referral_submitted ? (
                    <React.Fragment>
                        <Row
                            style={{
                                border: 'solid 1px #e4e4e4',
                                background: '#ffffffd6',
                                borderRadius: '5px',
                            }}>
                            <Col xs={24}>
                                <Result
                                    status="success"
                                    title="Successfully Added New Referral!"
                                    subTitle={
                                        <span>
                                            Name: {referral_response.first_name} {referral_response.last_name} <br />
                                            ID number: {referral_response.nano_id}
                                        </span>
                                    }
                                    extra={[
                                        <Link to="/dashboard" key="dash">
                                            <Button type="primary" key="dashboard">
                                                Go to Dashboard
                                            </Button>
                                        </Link>,
                                        <Button
                                            key="backtoadd"
                                            onClick={() =>
                                                this.setState({
                                                    submit_button_loading: false,
                                                    submit_button_disabled: false,
                                                    referral_submitted: false,
                                                    referral_response: {},
                                                    err: null,
                                                    apptDateCheck: false,
                                                    firstContactCheck: true,
                                                    consent: false,
                                                })
                                            }>
                                            Add Another Referral
                                        </Button>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {err && this.handleDataErrorMessage(err)}
                        <Title level={2}>Add {this.state.currentProject} Referral</Title>
                        <p>Input values and submit to create a new {lowerCaseProject} referral</p>

                        <Form name="add-referral" onSubmit={this.handleSubmit}>
                            <Row style={{ marginBottom: '1em' }}>
                                <Col span={24}>
                                    <Button type="primary" onClick={showConfirm}>
                                        <StepBackwardOutlined />
                                        Back to Dashboard
                                    </Button>
                                    <Button type="primary" onClick={() => window.location.reload()} style={{ float: 'right' }} danger>
                                        <DeleteOutlined />
                                        Clear Form
                                    </Button>
                                </Col>
                            </Row>
                            <br />

                            {/* Assignment Status */}
                            <Card title="Assignment Status" bordered={false}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={12}>
                                        <Form.Item label={`Referral Status`}>
                                            {getFieldDecorator(`referral_status`, {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Referral status is required',
                                                    },
                                                ],
                                                initialValue: this.state.currentProject && this.state.currentProject.includes('CBHC-MVP') ? '' : 'Not Scheduled',
                                            })(
                                                <Select
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    showSearch
                                                    placeholder="select a referral status"
                                                    optionFilterProp="children"
                                                    onChange={this.updateApptDateCheck}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    this.state.currentProject &&{' '}
                                                    {this.state.currentProject.includes('CBHC-MVP')
                                                        ? referral_statuses_cbhc_mvp.map((referral) => <Option key={referral}>{referral}</Option>)
                                                        : referral_statuses.map((referral) => <Option key={referral}>{referral}</Option>)}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col xs={12}>
                                        <Form.Item label={`Assign To Agency`}>
                                            {getFieldDecorator(`referral_agency`, {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Agency is required',
                                                    },
                                                ],
                                                initialValue: this.defaultAgency(),
                                            })(
                                                <Select
                                                    onChange={(e) =>
                                                        this.setState({
                                                            agency_selected: e,
                                                        })
                                                    }
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    placeholder="select an agency"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {agencies.sort().map((agency) => (
                                                        <Option key={agency}>{agency}</Option>
                                                    ))}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            {/* End Assignment Status */}
                            <br />
                            {/* Referral Information */}
                            {this.state.currentProject && !this.state.currentProject.includes('CBHC-MVP') && (
                                <React.Fragment>
                                    <Card title="Referral Information" bordered={false}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Referral Source`}>
                                                    {getFieldDecorator(`referral_source`, {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Referral source is required',
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="select a referral source"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {referral_sources.sort().map((source) => (
                                                                <Option key={source}>{source}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Referral Needs`}>
                                                    {getFieldDecorator(`referral_need`, {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Referral needs is required',
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            mode="multiple"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            placeholder="select referral needs">
                                                            {referral_needs.sort().map((need) => (
                                                                <Option key={need}>{need}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Referral Source Email`}>
                                                    {getFieldDecorator(`referral_source_email`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Referral source email is required',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            placeholder="john.doe@gmail.com"
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Referral Source Name`}>
                                                    {getFieldDecorator(`referral_source_name`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Referral source name is required',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            placeholder="John Doe"
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={this.state.currentProject.includes('COVID') ? 'Title' : 'Referral Source Title'}>
                                                    {getFieldDecorator(`referral_title`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="select a title"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {referral_titles.sort().map((title) => (
                                                                <Option key={title}>{title}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Referral Source Phone`}>
                                                    {getFieldDecorator(`referral_source_phone`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                            {
                                                                validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                            },
                                                        ],
                                                    })(
                                                        <MaskedInput
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            mask="(111) 111-1111"
                                                            placeholder="(123) 456-7890"
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24}>
                                                <Form.Item label={`Referral Details`}>
                                                    {getFieldDecorator(`additional_details`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(<TextArea placeholder="please input any additional details about referral here" rows={3} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <br />
                                </React.Fragment>
                            )}
                            {/* End Referral Details */}
                            {/* Care management */}
                            {this.state.currentProject.includes('TOC') || this.state.currentProject.includes('COVID') ? (
                                <React.Fragment>
                                    <CareManagement currentProject={this.state.currentProject} getFieldDecorator={getFieldDecorator} />
                                    <br />
                                </React.Fragment>
                            ) : (
                                ''
                            )}

                            {/* End Care management */}
                            {/* Health Home */}
                            {this.state.currentProject.includes('CBHC-MVP') && (
                                <React.Fragment>
                                    <Card title="Health Home" bordered={false}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Health Home Enrolled?`}>
                                                    {getFieldDecorator(`health_home_enrolled`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                    })(
                                                        <Switch
                                                            checkedChildren="Enrolled"
                                                            unCheckedChildren="No"
                                                            onClick={() => {
                                                                this.setState({
                                                                    health_home_enrolled: !this.state.health_home_enrolled,
                                                                })
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={16}>
                                                <Form.Item label={`Health Home Facility`}>
                                                    {getFieldDecorator(`health_home_facility`, {
                                                        rules: [
                                                            {
                                                                required: this.state.health_home_enrolled,
                                                                message: 'Health home facility is required',
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            disabled={!this.state.health_home_enrolled}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    health_home_facility: e,
                                                                })
                                                            }
                                                            showSearch
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            placeholder="Select a facility"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {health_home_facility.map((facility) => (
                                                                <Option key={facility}>{facility}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={24}>
                                                <Form.Item label="Health Home Enrollment Date">
                                                    {getFieldDecorator(`health_home_enrollment_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Care Management Enrolled?`}>
                                                    {getFieldDecorator(`care_management_enrolled`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                    })(
                                                        <Switch
                                                            checkedChildren="Enrolled"
                                                            unCheckedChildren="No"
                                                            onClick={() => {
                                                                this.setState({
                                                                    care_management_enrolled: !this.state.care_management_enrolled,
                                                                })
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={16}>
                                                <Form.Item label={`Care Management Facility`}>
                                                    {getFieldDecorator(`care_management_facility`, {
                                                        rules: [
                                                            {
                                                                required: this.state.care_management_enrolled,
                                                                message: 'Care management facility is required',
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            disabled={!this.state.care_management_enrolled}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    care_management_facility: e,
                                                                })
                                                            }
                                                            showSearch
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            placeholder="Select a facility"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {care_management_facility.map((facility) => (
                                                                <Option key={facility}>{facility}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={24}>
                                                <Form.Item label="Care Management Enrollment Date">
                                                    {getFieldDecorator(`care_management_enrollment_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <br />
                                </React.Fragment>
                            )}
                            {/* End Health Home */}

                            {/* Outreach and Engagement */}
                            {this.state.currentProject.includes('CBHC-MVP') && (
                                <React.Fragment>
                                    <Card title="Outreach and Engagement" bordered={false}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Contact Type`}>
                                                    {getFieldDecorator(`engagement_contact_type`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="Select contact type"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {engagement_contact_type.sort().map((source) => (
                                                                <Option key={source}>{source}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label="Contact Date">
                                                    {getFieldDecorator(`engagement_date`, {
                                                        rules: [
                                                            {
                                                                required: this.state.engagement_date,
                                                                message: 'Contact date is required',
                                                            },
                                                        ],
                                                    })(<DatePicker format={date_format} />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label="Contact Time">
                                                    {getFieldDecorator(`engagement_time`, {
                                                        rules: [
                                                            {
                                                                required: this.state.engagement_time,
                                                                message: 'Contact time is required',
                                                            },
                                                        ],
                                                    })(
                                                        <TimePicker
                                                            showTime={{
                                                                use12Hours: true,
                                                                format: time_format,
                                                            }}
                                                            format={time_format}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Number of Contacts`}>
                                                    {getFieldDecorator(`engagement_number_of_contacts`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="Select number of contacts"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {engagement_number_of_contacts.map((source) => (
                                                                <Option key={source}>{source}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24}>
                                                <Form.Item label={`Contact Details`}>
                                                    {getFieldDecorator(`engagement_contact_details`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(<TextArea placeholder="please input any notes about contact details" rows={3} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <br />
                                </React.Fragment>
                            )}
                            {/* Outreach and Engagement */}

                            <React.Fragment>
                                {/* Set Appointment */}
                                <Card title={this.state.currentProject.includes('CBHC-MVP') ? 'PCP Set Appointment Date and Time' : 'Set Appointment Date and Time'} bordered={false}>
                                    <Row gutter={[16, 16]}>
                                        {this.state.currentProject && !this.state.currentProject.includes('CBHC-MVP') && (
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Appointment Availability`}>
                                                    {getFieldDecorator(`appointment_availability`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(<TreeSelect {...tProps} />)}
                                                </Form.Item>
                                            </Col>
                                        )}
                                        <Col xs={24} lg={12}>
                                            <Form.Item label={this.state.currentProject.includes('CBHC-MVP') ? 'Upcoming PCP Appointment Date' : 'Appointment Date'}>
                                                {getFieldDecorator(`appointment_date`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(<DatePicker format={date_format} />)}
                                                {getFieldDecorator(`appointment_time`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <TimePicker
                                                        showTime={{
                                                            use12Hours: true,
                                                            format: time_format,
                                                        }}
                                                        format={time_format}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {this.state.currentProject.includes('CBHC-MVP') && (
                                            <React.Fragment>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="Last Annual Physical Date">
                                                        {getFieldDecorator(`last_annual_physical_date`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                        {getFieldDecorator(`last_annual_physical_time`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(
                                                            <TimePicker
                                                                showTime={{
                                                                    use12Hours: true,
                                                                    format: time_format,
                                                                }}
                                                                format={time_format}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="Last PCP Follow-up Date">
                                                        {getFieldDecorator(`last_pcp_followup_date`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(<DatePicker onChange={(e) => this.handleDayslapsed(e)} format={date_format} />)}
                                                        {getFieldDecorator(`last_pcp_followup_time`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(
                                                            <TimePicker
                                                                showTime={{
                                                                    use12Hours: true,
                                                                    format: time_format,
                                                                }}
                                                                format={time_format}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Statistic valueStyle={{ fontSize: '18px' }} title="Days Lapsed from last PCP appointment" value={this.state.daysLapsed} suffix=" days" />
                                                </Col>
                                            </React.Fragment>
                                        )}
                                        <Col xs={24}>
                                            <Form.Item label={`Notes`}>
                                                {getFieldDecorator(`notes`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(<TextArea placeholder="please input any notes about referral here" rows={3} />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </React.Fragment>
                            {/*  */}
                            <br />
                            <React.Fragment>
                                {/* Client Details */}
                                <Card title="Client Information" bordered={false}>
                                    {this.state.currentProject.includes('COVID') && (
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={24}>
                                                <Form.Item label={`Check here if you are answering on behalf of a minor or as a legal guardian`}>
                                                    {getFieldDecorator(`is_guardian`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                            },
                                                        ],
                                                        valuePropName: 'checked',
                                                    })(
                                                        <CustomCheckbox
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    show_guardian: e.target.checked,
                                                                })
                                                            }></CustomCheckbox>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}

                                    {this.showGuardianInfo(getFieldDecorator)}

                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Date of Birth`}>
                                                {getFieldDecorator(`dob`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'DOB is required',
                                                        },
                                                    ],
                                                })(
                                                    <DatePicker
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder="select date of birth"
                                                        format={date_format}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Last Name`}>
                                                {getFieldDecorator(`last_name`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Last name is required',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="Doe"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`First Name`}>
                                                {getFieldDecorator(`first_name`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'First name is required',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="John"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Middle Name`}>
                                                {getFieldDecorator(`middle_name`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="Van"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`CIN / Medicaid #`}>
                                                {getFieldDecorator(`cin`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Gender`}>
                                                {getFieldDecorator(`gender`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Gender is required',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select a gender"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {genders.sort().map((gender) => (
                                                            <Option key={gender}>{gender}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Language`}>
                                                {getFieldDecorator(`language`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Language is required',
                                                        },
                                                    ],
                                                    initialValue: 'English',
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select a language"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {languages_list.sort().map((language) => (
                                                            <Option key={language}>{language}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Ethnicity`}>
                                                {getFieldDecorator(`ethnicity`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select an ethnicity"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {ethnicity.sort().map((ethnicity) => (
                                                            <Option key={ethnicity}>{ethnicity}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Email`}>
                                                {getFieldDecorator(`email_client`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Active Duty`}>
                                                {getFieldDecorator(`active_duty`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: false,
                                                })(<Switch />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Veteran`}>
                                                {getFieldDecorator(`veteran`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                    initialValue: false,
                                                })(<Switch />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={6}>
                                            <Checkbox
                                                checked={this.state.mailing_address}
                                                style={{ paddingBottom: '20px' }}
                                                onClick={() => {
                                                    this.setState({ mailing_address: !this.state.mailing_address })
                                                }}>
                                                Check to add a mailing address
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    {this.state.mailing_address && (
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Mailing Address 1`}>
                                                    {getFieldDecorator(`mailing_address_1`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            placeholder="99 Alphabet Rd"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Form.Item label={`Mailing Address 2`}>
                                                    {getFieldDecorator(`mailing_address_2`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            placeholder="99 Alphabet Rd"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Mailing City`}>
                                                    {getFieldDecorator(`mailing_city`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            placeholder="New York"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Mailing State`}>
                                                    {getFieldDecorator(`mailing_state`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                        initialValue: 'New York',
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="New York"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            <Option value="New York">New York</Option>
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Mailing Zip`}>
                                                    {getFieldDecorator(`mailing_zip`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            onChange={(e) => this.handleZipCodeCounty(e)}
                                                            placeholder="11223"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={12}>
                                            <Form.Item label={`Address Type`}>
                                                {getFieldDecorator(`address_type`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Address type is required',
                                                        },
                                                    ],
                                                    initialValue: 'Primary Residence',
                                                })(
                                                    <Select
                                                        onChange={(e) => {
                                                            this.handleAddressTypeOnChange(e)
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="Primary Residence"
                                                        optionFilterProp="children"
                                                        defaultActiveFirstOption={true}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {Object.keys(address_type).map((key) => (
                                                            <Option key={key}>{key}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {this.state.showShelterNameField === true && (
                                            <Col xs={24} lg={6}>
                                                <Form.Item label={`Shelter Name`}>
                                                    {getFieldDecorator(`shelter_name`, {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Shelter name is required',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            onKeyPress={(e) => {
                                                                e.key === 'Enter' && e.preventDefault()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        )}
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Address Validity`}>
                                                {getFieldDecorator(`address_valid`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                    ],
                                                    valuePropName: 'checked',
                                                })(<CustomCheckbox backgroundColor="red">Unsuccessful</CustomCheckbox>)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={12}>
                                            <Form.Item label={`Address 1`}>
                                                {getFieldDecorator(`address_1`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.address_1,
                                                            message: 'Address 1 is required',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="99 Alphabet Rd"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                            <Form.Item label={`Address 2`}>
                                                {getFieldDecorator(`address_2`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="APT 1234"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`City`}>
                                                {getFieldDecorator(`city`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.city,
                                                            message: 'City is required',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        placeholder="New York"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`State`}>
                                                {getFieldDecorator(`state`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.state,
                                                            message: 'State is required',
                                                        },
                                                    ],
                                                    initialValue: 'New York',
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="New York"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        <Option value="New York">New York</Option>
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`ZIP`}>
                                                {getFieldDecorator(`zip`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.zip,
                                                            message: 'ZIP is required',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        onChange={(e) => this.handleZipCodeCounty(e)}
                                                        placeholder="11223"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`County`}>
                                                {getFieldDecorator(`county`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.county,
                                                            message: 'County is required',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="Westchester"
                                                        optionFilterProp="children"
                                                        defaultActiveFirstOption={true}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {countys_list.sort().map((county) => (
                                                            <Option key={county}>{county}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                    {this.state.currentProject.includes('TOC') && <EmergencyContact getFieldDecorator={getFieldDecorator} />}
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Primary Phone`}>
                                                {getFieldDecorator(`phone`, {
                                                    rules: [
                                                        {
                                                            required: this.state.addressRequiredFields.phone,
                                                            message: 'Phone number is required',
                                                        },
                                                        {
                                                            validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                        },
                                                    ],
                                                })(
                                                    <MaskedInput
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        mask="(111) 111-1111"
                                                        placeholder="(123) 456-7890"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Validity`}>
                                                {getFieldDecorator(`primary_phone_valid`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                    ],
                                                    valuePropName: 'checked',
                                                })(<CustomCheckbox backgroundColor="red">Unsuccessful</CustomCheckbox>)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Secondary Phone`}>
                                                {getFieldDecorator(`phone_secondary`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                        {
                                                            validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                        },
                                                    ],
                                                })(
                                                    <MaskedInput
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        mask="(111) 111-1111"
                                                        placeholder="(123) 456-7890"
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Validity`}>
                                                {getFieldDecorator(`secondary_phone_valid`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                    ],
                                                    valuePropName: 'checked',
                                                })(<CustomCheckbox backgroundColor="red">Unsuccessful</CustomCheckbox>)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={6}>
                                            <Form.Item label={`Okay to leave message?`}>
                                                {getFieldDecorator(`leave_message`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        placeholder="Yes"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        <Option value="Yes">Yes</Option>
                                                        <Option value="No">No</Option>
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                                {/*  */}
                                <br />
                                {/* Insurance Information */}
                                <Card title="Insurance Information" bordered={false}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={8}>
                                            <Form.Item label={`Insurance Type 1`}>
                                                {getFieldDecorator(`insurance_1`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Insurance type 1 is required',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select insurance type"
                                                        optionFilterProp="children"
                                                        onChange={(option) => {
                                                            if (option === 'Uninsured') {
                                                                this.setState({
                                                                    Uninsured: true,
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    Uninsured: false,
                                                                })
                                                            }
                                                        }}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {insurance_types.sort().map((insurance) => (
                                                            <Option key={insurance}>{insurance}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={8}>
                                            <Form.Item label={`Insurance Plan`}>
                                                {getFieldDecorator(`insurance_1_plan`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        disabled={this.state.Uninsured}
                                                        placeholder="select insurance plan"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {insurance_plans.sort().map((plan) => (
                                                            <Option key={plan}>{plan}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={4}>
                                            <Form.Item label={`Policy Number`}>
                                                {getFieldDecorator(`insurance_1_policy_number`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={4}>
                                            <Form.Item label={`Group Number`}>
                                                {getFieldDecorator(`insurance_1_group_number`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {this.state.currentProject.includes('CBHC-MVP') && (
                                            <React.Fragment>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label="Effective Date">
                                                        {getFieldDecorator(`insurance_1_effective_date`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={14}>
                                                    <Form.Item label="Expiration Date">
                                                        {getFieldDecorator(`insurance_1_expiration_date`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                            </React.Fragment>
                                        )}
                                    </Row>
                                    <Row gutter={[12]}>
                                        <Col xs={24} lg={8}>
                                            <Form.Item label={`Insurance Type 2`}>
                                                {getFieldDecorator(`insurance_2`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select insurance type"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {insurance_types.sort().map((insurance) => (
                                                            <Option key={insurance}>{insurance}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={8}>
                                            <Form.Item label={`Insurance Plan`}>
                                                {getFieldDecorator(`insurance_2_plan`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select insurance plan"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {insurance_plans.sort().map((plan) => (
                                                            <Option key={plan}>{plan}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={4}>
                                            <Form.Item label={`Policy Number`}>
                                                {getFieldDecorator(`insurance_2_policy_number`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={4}>
                                            <Form.Item label={`Group Number`}>
                                                {getFieldDecorator(`insurance_2_group_number`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {this.state.currentProject.includes('CBHC-MVP') && (
                                            <React.Fragment>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label="Effective Date">
                                                        {getFieldDecorator(`insurance_2_effective_date`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={14}>
                                                    <Form.Item label="Expiration Date">
                                                        {getFieldDecorator(`insurance_2_expiration_date`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                            </React.Fragment>
                                        )}
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={8}>
                                            <Form.Item label={`Insurance Type 3`}>
                                                {getFieldDecorator(`insurance_3`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select insurance type"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {insurance_types.sort().map((insurance) => (
                                                            <Option key={insurance}>{insurance}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={8}>
                                            <Form.Item label={`Insurance Plan`}>
                                                {getFieldDecorator(`insurance_3_plan`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        showSearch
                                                        placeholder="select insurance plan"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {insurance_plans.sort().map((plan) => (
                                                            <Option key={plan}>{plan}</Option>
                                                        ))}
                                                    </Select>,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={4}>
                                            <Form.Item label={`Policy Number`}>
                                                {getFieldDecorator(`insurance_3_policy_number`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={4}>
                                            <Form.Item label={`Group Number`}>
                                                {getFieldDecorator(`insurance_3_group_number`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Input something!',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {this.state.currentProject.includes('CBHC-MVP') && (
                                            <React.Fragment>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label="Effective Date">
                                                        {getFieldDecorator(`insurance_3_effective_date`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={14}>
                                                    <Form.Item label="Expiration Date">
                                                        {getFieldDecorator(`insurance_3_expiration_date`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                            </React.Fragment>
                                        )}
                                    </Row>
                                    {this.state.currentProject.includes('COVID') && (
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`HARP`}>
                                                    {getFieldDecorator(`assessment_harp`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="select enrolled or eligible"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {harp_values.sort().map((harp) => (
                                                                <Option key={harp}>{harp}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                </Card>
                                {/*  */}
                                <br />
                                {/* Assessment Information */}
                                {this.state.currentProject &&
                                !this.state.currentProject.includes('CMO') &&
                                !this.state.currentProject.includes('COVID') &&
                                !this.state.currentProject.includes('CBHC-MVP') ? (
                                    <Card title="Assessment Information" bordered={false}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`HARP`}>
                                                    {getFieldDecorator(`assessment_harp`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            showSearch
                                                            placeholder="select enrolled or eligible"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {harp_values.sort().map((harp) => (
                                                                <Option key={harp}>{harp}</Option>
                                                            ))}
                                                        </Select>,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`First Contact Date`}>
                                                    {getFieldDecorator(`assessment_contact_date`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <DatePicker
                                                            placeholder="select date"
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    firstContactCheck: false,
                                                                    firstContactDate: e,
                                                                })
                                                            }
                                                            format={date_format}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item label={`Assessment Date Completed`}>
                                                    {getFieldDecorator(`assessment_date_completed`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Input something!',
                                                            },
                                                        ],
                                                    })(
                                                        <DatePicker
                                                            placeholder="select date"
                                                            disabled={this.state.firstContactCheck}
                                                            disabledDate={this.disabledDate}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            format={date_format}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            {/* LOSD */}
                                            {this.state.currentProject.includes('HCBS') || (this.state.currentProject && this.state.currentProject.includes('CMO')) ? (
                                                <Losd getFieldDecorator={getFieldDecorator} state={this.state} disabledDate={this.disabledDate} />
                                            ) : (
                                                <React.Fragment />
                                            )}
                                            {/* END LOSD */}
                                        </Row>
                                    </Card>
                                ) : (
                                    ''
                                )}
                                {/* End Assessment Information */}
                                {/*  Quality Metris */}
                                {this.state.currentProject.includes('CBHC-MVP') && (
                                    <React.Fragment>
                                        <Card title="Quality Metrics" bordered={false}>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_1_checkbox`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                },
                                                            ],
                                                            valuePropName: 'checked',
                                                            initialValue: false,
                                                        })(
                                                            <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_1_checkbox')}>
                                                                Diabetes Screen for people with Schizophrenia or Bipolar Disorder
                                                            </CustomCheckbox>,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_1_date`, {
                                                            rules: [
                                                                {
                                                                    required: this.state.qm_1_checkbox,
                                                                    message: 'QM required',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_2_checkbox`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                },
                                                            ],
                                                            valuePropName: 'checked',
                                                            initialValue: false,
                                                        })(
                                                            <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_2_checkbox')}>
                                                                Potential Avoidable ED Utilization
                                                            </CustomCheckbox>,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_2_date`, {
                                                            rules: [
                                                                {
                                                                    required: this.state.qm_2_checkbox,
                                                                    message: 'QM required',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_3_checkbox`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                },
                                                            ],
                                                            valuePropName: 'checked',
                                                            initialValue: false,
                                                        })(
                                                            <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_3_checkbox')}>
                                                                Cervical Cancer Screening (CCS)
                                                            </CustomCheckbox>,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_3_date`, {
                                                            rules: [
                                                                {
                                                                    required: this.state.qm_3_checkbox,
                                                                    message: 'QM required',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_4_checkbox`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                },
                                                            ],
                                                            valuePropName: 'checked',
                                                            initialValue: false,
                                                        })(
                                                            <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_4_checkbox')}>
                                                                Comprehensive Diabetes Care Eye Exam (CDC-Eye)
                                                            </CustomCheckbox>,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_4_date`, {
                                                            rules: [
                                                                {
                                                                    required: this.state.qm_4_checkbox,
                                                                    message: 'QM required',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_5_checkbox`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                },
                                                            ],
                                                            valuePropName: 'checked',
                                                            initialValue: false,
                                                        })(
                                                            <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_5_checkbox')}>
                                                                Asthma Medication Ratio (AMR)
                                                            </CustomCheckbox>,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_5_date`, {
                                                            rules: [
                                                                {
                                                                    required: this.state.qm_5_checkbox,
                                                                    message: 'QM required',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_6_checkbox`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                },
                                                            ],
                                                            valuePropName: 'checked',
                                                            initialValue: false,
                                                        })(
                                                            <CustomCheckbox backgroundColor="red" onClick={(e) => this.onCheckboxChange(e, 'qm_6_checkbox')}>
                                                                Colorectal Cancer Screening (COL)
                                                            </CustomCheckbox>,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Form.Item label="">
                                                        {getFieldDecorator(`qm_6_date`, {
                                                            rules: [
                                                                {
                                                                    required: this.state.qm_6_checkbox,
                                                                    message: 'QM required',
                                                                },
                                                            ],
                                                        })(<DatePicker format={date_format} />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </React.Fragment>
                                )}
                                {/* End Quality Metrics */}
                                {/* Treatment Contact Information & Referral/Encounter Note */}
                                {this.state.currentProject.includes('CMO') && (
                                    <React.Fragment>
                                        <Card title="Treatment Contact Information" bordered={false}>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label={`Agency Therapist`}>
                                                        {getFieldDecorator(`agency_therapist`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(
                                                            <Input
                                                                onKeyPress={(e) => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label={`Phone Number`}>
                                                        {getFieldDecorator(`phone_agency_therapist`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                },
                                                                {
                                                                    validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                                },
                                                            ],
                                                        })(
                                                            <MaskedInput
                                                                onKeyPress={(e) => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                mask="(111) 111-1111"
                                                                placeholder="(123) 456-7890"
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label={`Email`}>
                                                        {getFieldDecorator(`email_agency_therapist`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(
                                                            <Input
                                                                onKeyPress={(e) => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label={`Agency Prescribing Clinician`}>
                                                        {getFieldDecorator(`agency_prescribing_clinician`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(
                                                            <Input
                                                                onKeyPress={(e) => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label={`Phone Number`}>
                                                        {getFieldDecorator(`phone_agency_prescribing_clinician`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                },
                                                                {
                                                                    validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                                },
                                                            ],
                                                        })(
                                                            <MaskedInput
                                                                onKeyPress={(e) => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                mask="(111) 111-1111"
                                                                placeholder="(123) 456-7890"
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label={`Email`}>
                                                        {getFieldDecorator(`email_agency_prescribing_clinician`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(
                                                            <Input
                                                                onKeyPress={(e) => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label={`Primary Care Provider`}>
                                                        {getFieldDecorator(`primary_care_provider`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(
                                                            <Input
                                                                onKeyPress={(e) => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label={`Phone Number`}>
                                                        {getFieldDecorator(`phone_primary_care_provider`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                },
                                                                {
                                                                    validator: (_, value) => (isValidPhone(value) ? Promise.resolve() : Promise.reject('Min 10 digit phone number!')),
                                                                },
                                                            ],
                                                        })(
                                                            <MaskedInput
                                                                onKeyPress={(e) => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                mask="(111) 111-1111"
                                                                placeholder="(123) 456-7890"
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={8}>
                                                    <Form.Item label={`Email`}>
                                                        {getFieldDecorator(`email_primary_care_provider`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(
                                                            <Input
                                                                onKeyPress={(e) => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <br />
                                        <Card title="Referral/Encounter Note" bordered={false}>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24}>
                                                    <Form.Item label={`Referral/Encounter Note`}>
                                                        {getFieldDecorator(`referral_encounter_note`, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: 'Input something!',
                                                                },
                                                            ],
                                                        })(<TextArea placeholder="Enter note here" rows={3} />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </React.Fragment>
                                )}
                                {/* End Treatment Contact Information & Referral/Encounter Note */}
                            </React.Fragment>

                            <br />

                            {this.state.skip_consent === false && (
                                <React.Fragment>
                                    <Card title="Consent">
                                        <Row>
                                            <Col>
                                                <Form.Item>
                                                    {getFieldDecorator(`consent`, {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Consent required!',
                                                            },
                                                        ],
                                                    })(
                                                        <Checkbox
                                                            value={new Date(Date.now)}
                                                            onClick={() => {
                                                                this.setState({
                                                                    consent: !this.state.consent,
                                                                })
                                                            }}>
                                                            {' '}
                                                            I attest that all necessary consents have been signed or obtained verbally in order to coordinate care and provide services for this client
                                                            in compliance with all relevant State and Federal laws and regulations.
                                                        </Checkbox>,
                                                    )}
                                                </Form.Item>
                                                {this.state.consent === true && (
                                                    <p>
                                                        <i>Timestamp: {moment().format(date_format)}</i>
                                                    </p>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                    <br />
                                </React.Fragment>
                            )}

                            {this.state.consent === false && (
                                <React.Fragment>
                                    <Alert
                                        style={{ paddingBottom: 0 }}
                                        description={
                                            <Form.Item>
                                                {getFieldDecorator(`skip_consent`, {
                                                    rules: [
                                                        {
                                                            required: false,
                                                        },
                                                    ],
                                                })(
                                                    <Checkbox
                                                        onClick={() => {
                                                            this.setState({
                                                                skip_consent: !this.state.skip_consent,
                                                                consent: false,
                                                                consent_date: null,
                                                            })
                                                        }}>
                                                        {' '}
                                                        Click here to skip consent.
                                                    </Checkbox>,
                                                )}
                                            </Form.Item>
                                        }
                                        type="error"
                                    />
                                    <br />
                                </React.Fragment>
                            )}

                            <br />
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <Form.Item>
                                        <Button type="primary" size="large" htmlType="submit" block loading={submit_button_loading} disabled={submit_button_disabled}>
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </React.Fragment>
                )}
            </Content>
        )
    }
}

export default Form.create({ name: 'add_referral' })(AddReferral)
