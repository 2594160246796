import React, { Component } from 'react'
import axios from 'axios'
import { Button, Breadcrumb, Card, Col, Form, Input, Layout, message, Row, Select, Typography } from 'antd'
import { ContactsOutlined } from '@ant-design/icons'
import { agencies, registered_project, shared_care_access } from '../../../utils/constants'

const Content = Layout
const { Option } = Select
const { Title, Text } = Typography
const layout = {
    labelCol: { xs: 24, sm: 7 },
    wrapperCol: { xs: 24, sm: 18, md: 10 },
}

class Invite extends Component {
    constructor(props) {
        super(props)
        this.state = {
            agency: null,
            email: null,
            inviteResponse: {},
            selectedProjects: [],
            currProj: [],
        }
    }

    onFinish = async (values) => {
        try {
            await axios.post('/api/users/invite', values)
            message.success('User invited successfully! A registration email has been sent to them')
        } catch (err) {
            message.error('Failed to invite user: ' + err.response.data.message)
        }
    }

    onFinishFailed = () => {
        message.error('Please fill out all of the fields')
    }

    render() {
        agencies.shift() // removes none from list
        return (
            <Content style={{ margin: '20px 16px 0' }}>
                <Content style={{ width: '100%' }}>
                    <div style={{ marginBottom: '16px', width: '100%' }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>User</Breadcrumb.Item>
                            <Breadcrumb.Item>Invite</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Card
                        bodyStyle={{
                            padding: 26,
                            width: '100%',
                        }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={{ span: 24 }} sm={{ offset: 7, span: 10 }}>
                                <Title level={2}>
                                    Invite a new user to ConnexRX
                                </Title>
                            </Col>
                        </Row>

                        <Form
                            {...layout}
                            style={{
                                marginTop: '16px',
                                width: '100%',
                                marginTop: '10px',
                            }}
                            name="basic"
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter an email address',
                                    },
                                ]}>
                                <Input placeholder="email@provider.com" />
                            </Form.Item>
                            <Form.Item
                                label="Agency"
                                name="user_type"
                                initialValue={this.props.auth.user.agency}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select an agency',
                                    },
                                ]}>
                                <Select placeholder="Select an agency" allowClear>
                                    {agencies.sort().map((agency) => (
                                        <Option key={agency}>{agency}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Project"
                                name="projects"
                                initialValue={this.props.auth.user.agency}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select a project',
                                    },
                                ]}>
                                <Select
                                    mode="multiple"
                                    placeholder="Select projects"
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={(proj) => this.setState({ currProj: proj })}>
                                    {registered_project.map((item) => (
                                        <Select.Option key={item} value={item}>
                                            {item}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {/* {this.state.currProj.includes('SHARED-CARE') && (
                                <Form.Item label="Shared Care Tabs" name="shared_care_access">
                                    <Select
                                        mode="multiple"
                                        placeholder="Leave blank for demographics only"
                                        style={{
                                            width: '100%',
                                        }}>
                                        {shared_care_access.map((item) => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )} */}

                            <Form.Item
                                wrapperCol={{
                                    xs: { span: 24 },
                                    sm: { offset: 7 },
                                }}>
                                <Button type="primary" htmlType="submit">
                                    Send Invitation
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Content>
            </Content>
        )
    }
}

export default Invite
