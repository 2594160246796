import React, { Component } from 'react'
import axios from 'axios'

import { Breadcrumb, Col, Card, Layout, Row, Divider, Table, Tooltip, Typography, Popconfirm, message } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const { Title } = Typography
const { Content } = Layout

class ManageAgencies extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    async componentDidMount() {
        await this.fetchAgencies()
    }

    fetchAgencies = async () => {
        const response = await axios.post('/api/agencies/list')
        this.setState({ agencies: response.data })
    }

    async deleteUser(id) {
        await axios
            .post('/api/agencies/delete_agency', {
                _id: id,
            })
            .then(async (resp) => {
                message.loading('Action in progress..')
            })
            .then(() => {
                this.fetchAgencies()
                message.success('Agency deleted!', 2.5)
            })
            .catch((err) => {
                message.error('Error Occured')
            })
    }

    render() {
        const columns = [
            {
                title: 'Agency',
                dataIndex: 'agency',
                key: 'agency',
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: 'Zip',
                dataIndex: 'zip',
                key: 'zip',
            },
            {
                title: 'Contact',
                dataIndex: 'contact',
                key: 'contact',
            },
            {
                title: 'Action',
                key: 'action',
                width: 120,
                render: (text, record) => (
                    <span>
                        <Tooltip title="Edit">
                            <EditOutlined
                                onClick={() =>
                                    this.props.history.push(`/agencies/${record._id}`, {
                                        id: record._id,
                                    })
                                }
                            />
                        </Tooltip>
                        <Divider type="vertical" />
                        <Tooltip title="Delete">
                            <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteUser(record._id)}>
                                <DeleteOutlined />
                            </Popconfirm>
                        </Tooltip>
                    </span>
                ),
            },
        ]

        return (
            <React.Fragment>
                <div>
                    <Card style={{ margin: '20px 16px', width: '100%' }}>
                        <div style={{ marginBottom: '16px', width: '100%' }}>
                            <Breadcrumb>
                                <Breadcrumb.Item>User</Breadcrumb.Item>
                                <Breadcrumb.Item>Agencies</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Title level={2}>Manage Agencies</Title>
                        <p>Use the actions to edit an agency's information or delete it from the list</p>
                    </Card>
                    <Content style={{ margin: '20px 16px 0' }}>
                        <Row gutter={[12, 24]}>
                            <Col span={24}>
                                <Table
                                    columns={columns}
                                    dataSource={this.state.agencies}
                                    pagination={{
                                        pageSize: 50,
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                    }}
                                    scroll={{ x: 1000, y: 500 }}
                                    size="default"
                                    rowKey="row_id"
                                />
                            </Col>
                        </Row>
                    </Content>
                </div>
            </React.Fragment>
        )
    }
}

export default ManageAgencies
