import React, { Component } from 'react'
import axios from 'axios'
import { UserOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Alert, Input, Button, Row, Col, Card, Typography } from 'antd'

import './forgot-password.scss'

const { Title } = Typography

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validUser: null,
            confirmDirty: false,
            user: null,
            isSubmitted: false,
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const data = { email: values.email }
                axios
                    .post('/api/users/resetpassword', data)
                    .then((res) => {
                        const { message } = res.data
                        if (message === 'ok') {
                            this.setState({ isSubmitted: true })
                        }
                    })
                    .catch((err) => {
                        // show error
                        console.log(err)
                    })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <React.Fragment>
                <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{
                        minHeight: '80vh',
                    }}>
                    <Col xs={24} sm={24} md={12} lg={10} xl={6}>
                        <Card
                            style={{
                                borderRadius: '10px',
                                padding: '10px 15px',
                            }}
                            bordered={false}>
                            <Row>
                                <Col span={24}>
                                    <Card
                                        // title="Forgot Password"
                                        bordered={false}
                                        headStyle={{
                                            fontSize: 25,
                                            paddingLeft: 0,
                                        }}
                                        style={{
                                            width: '40%',
                                            margin: 'auto',
                                        }}
                                        bodyStyle={{
                                            paddingBottom: 5,
                                        }}
                                    />

                                    <Title level={3}>
                                        Reset your password?
                                    </Title>
                                    <p>
                                        Enter the email address associated with your account
                                    </p>
                                    {this.state.isSubmitted === true ? (
                                        <Alert
                                            message="You've been sent an email to reset your password"
                                            type="success"
                                        />
                                    ) : (
                                        <Form
                                            onSubmit={this.handleSubmit}
                                            className="forgot-password-form">
                                            <Form.Item>
                                                {getFieldDecorator('email', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                'Enter your email address',
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        prefix={
                                                            <UserOutlined
                                                                style={{
                                                                    fontSize: 13,
                                                                }}
                                                            />
                                                        }
                                                        placeholder="Email"
                                                        size="large"
                                                    />,
                                                )}
                                            </Form.Item>

                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="login-form-button"
                                                    size="large">
                                                    Continue
                                                </Button>
                                                Back to
                                                <a
                                                    type="link"
                                                    href="/#/"
                                                    style={{
                                                        padding: '0 0 0 5px',
                                                    }}>
                                                    Login
                                                </a>
                                            </Form.Item>
                                        </Form>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default Form.create({ name: 'forgot_password' })(ForgotPassword)
