import React, { Component } from 'react'
import { Card, Col, Form, Input, Row, TreeSelect } from 'antd'

import { availablility } from '../../../../../utils/constants'

const { SHOW_PARENT } = TreeSelect
const { TextArea } = Input

class AppointmentAvailability extends Component {
    render() {
        const tProps = {
            treeData: availablility,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            placeholder: 'Select client availability',
            style: {
                width: '100%',
            },
        }

        return (
            <Card title="Appointment Availability" bordered={false} style={{ width: '-webkit-fill-available', marginTop: '1em' }}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={23}>
                        <Form.Item
                            name="appointment_availability"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Appointment Availability"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <TreeSelect {...tProps} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={23}>
                        <Form.Item
                            name="notes"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            label="Notes"
                            labelAlign="left"
                            style={{ flexDirection: 'column' }} >
                            <TextArea placeholder="Enter appointment notes" rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        )
    }
}

export default AppointmentAvailability
