import React, { useState } from 'react'

import { Button, Row, Col, Popconfirm, Typography, Table } from 'antd'
import { ExclamationCircleTwoTone } from '@ant-design/icons'

const { Title, Text } = Typography

const CheckDuplicates = (props) => {
    const [selectionType] = useState('checkbox')
    let columns = [
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Date of Birth',
            dataIndex: 'dob',
            key: 'dob',
        },
    ]

    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} style={{ marginBottom: '5px' }}>
                    <Title level={2} style={{ paddingTop: '15px' }}>
                        Check for Duplicates
                    </Title>
                    <Text>
                        If a referral already exists for this project in the system with the same first name, last name, date of birth as a row in the upload, it will appear here. Remove these rows in
                        the upload and try again <ExclamationCircleTwoTone twoToneColor="#eb2f96" />
                    </Text>
                </Col>
            </Row>

            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Table columns={columns} dataSource={props.duplicates} pagination={false} bordered scroll={{ x: 700, y: 400 }} />
                </Col>
            </Row>

            <br />

            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Button type="primary" disabled={!props.project} onClick={props.next} style={{ marginRight: '5px' }} disabled={props.numOfDuplicates}>
                        Next
                    </Button>
                    <Popconfirm title="Are you sure you want to start over?" onConfirm={props.clear}>
                        <Button type="secondary" disabled={!props.project}>
                            Start Over
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default CheckDuplicates
