import React, { useState, useEffect } from 'react'

import { Button, Row, Col, Typography , Select } from 'antd'

const { Title, Text } = Typography
const { Option } = Select

const tabOptions = [
    "Demographics",
    "Care Management",
    "Peer Services",
    "Primary Care",
    "Mental Health",
    "Substance Use",
]

const categoryOptions = [
    "Referral",
    "Demographics", 
    "Medical History", 
    "Psychosocial Assessment", 
    "Psychiatric Evaluation", 
    "Discharge Summary", 
    "Progress Notes",
    "Treatment Plan", 
    "Screenings",
    "Lab Results",
    "Other"
]

const limitedCategoryOptions = [
    "Referral",
    "Demographics",
]

const categorySelectBuilder = (options, props) => {
    return (
        <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }}>
                <Select 
                    onChange={e => props.handleCategoryChange(e)}
                    defaultValue={props.category}
                    placeholder="Choose a category" 
                    style={{ width: '95%', marginBottom: "30px" }} >
                    {options.map((category) => (
                        <Option key={category}>{category}</Option>
                    ))}
                </Select>
            </Col>
        </Row>
    )
}

const ChooseCategoryEdit = (props) => {
    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Title level={2} style={{paddingTop: '15px'}}>Documents</Title>

                    <Text>
                        Edit information about the upload
                    </Text>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Select 
                        onChange={e => props.handleTabChange(e)}
                        defaultValue={props.tab}
                        placeholder="Choose a tab" 
                        style={{ width: '95%', marginTop: '15px', marginBottom: "15px" }}>
                        {tabOptions.map((tab) => (
                            <Option key={tab}>{tab}</Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            {props.tab === "Demographics" ? categorySelectBuilder(limitedCategoryOptions, props) : categorySelectBuilder(categoryOptions, props)}
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Button
                        type="primary"
                        onClick={props.next}
                        style={{marginRight: '5px'}}>
                        Next
                    </Button>
                    <Button
                        type="secondary"
                        onClick={props.backToReferral}>
                        Back to referral
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ChooseCategoryEdit
