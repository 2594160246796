import React from 'react'

import { Button, Row, Col, Popconfirm, Typography } from 'antd'
import { DownloadOutlined, ExclamationCircleTwoTone  } from '@ant-design/icons'

const { Title, Text } = Typography

const DownloadSample = (props) => {
    const downloadSampleCsv = () => {
        return (
            <a
                href={`/cbhs_sample_upload_${props.project.toLowerCase()}.csv`}
                target="_blank"
                rel="noopener noreferrer"
                download>
                <Button icon={<DownloadOutlined />} size="large">
                    Download Sample {props.project} CSV
                </Button>
            </a>
        )
    }

    return (
        <React.Fragment>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} style={{marginBottom: '5px'}}>
                    <Title level={2} style={{paddingTop: '15px'}}>Download the Sample CSV</Title>
                    <Text>
                        Download this template for the selected project and add the data you want to upload to it <ExclamationCircleTwoTone twoToneColor="#eb2f96" />
                    </Text>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} style={{marginBottom: '15px'}}>{downloadSampleCsv()}</Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }}>
                    <Button
                        type="primary"
                        disabled={!props.project}
                        onClick={props.next}
                        style={{marginRight: '5px'}}>
                        Next
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to start over?"
                        onConfirm={props.clear}>
                        <Button type="secondary" disabled={!props.project} >
                            Start Over
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default DownloadSample
