import React, { Component } from 'react'
import { Button } from 'antd'

import Otp from './otp/otp'
import Timer from './timer/timer'

export default class TwoFactorAuth extends Component {
    constructor(props) {
        super(props)
        this.state = {
            otp: '',
            disableResendOTP: true,
            resetTimer: false,
        }
    }

    handleDisableResendOTP = (status) => {
        this.setState({ disableResendOTP: status })
    }

    handleResetTimer = (status) => {
        this.setState({ resetTimer: status })
    }

    render() {
        return (
            <>
                <h3 style={{ color: '#9f9f9f' }}>2FA</h3>

                <h3>Enter Code</h3>
                <p>A two-factor authentication code has been sent to your email or phone number (depending on your preference)</p>

                <div style={{ marginTop: 20 }}>
                    <div>
                        <Otp otp={this.state.otp} setOtp={(val) => this.setState({ otp: val })} />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <span>Didn't receive it? </span>
                        <span>
                            <Button
                                disabled={this.state.disableResendOTP}
                                type="link"
                                onClick={(e) => {
                                    this.handleDisableResendOTP(true)
                                    this.handleResetTimer(true)
                                    this.props.handleResendOTP(e)
                                }}
                                style={{ textTransform: 'none', fontSize: 15 }}>
                                Request a new code{' '}
                            </Button>
                            <Timer resetTimer={this.state.resetTimer} handleResetTimer={this.handleResetTimer} handleDisableResendOTP={this.handleDisableResendOTP} />
                        </span>
                    </div>
                    <div>
                        <Button
                            block
                            disabled={this.state.otp.length !== 6}
                            type="primary"
                            size="large"
                            style={{
                                color: 'white',
                                textTransform: 'none',
                                marginTop: 20,
                            }}
                            onClick={() => {
                                this.props.handleOTPSubmit(this.state.otp)
                            }}>
                            Verify
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}
