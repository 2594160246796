import moment from 'moment'
import { date_format, time_format } from './constants'

/**
 * To check whether the given data is empty or not
 * Return Bool
 * @param {*} data
 */
export function isEmpty(data) {
    return !data || 0 === data.length ? true : false
}

/**
 * To return the needed spreadsheet format for the referral
 * Return Bool
 * @param {Array} referralsData
 * @param {String} currentProject
 * @returns {Array} [header, referral list]
 */
export function csvDataFormatter(referralsData, currentProject) {
    switch (currentProject) {
        case 'HCBS': {
            const csvReferralHeader = [
                { label: 'ID', key: 'nano_id' },
                { label: 'CIN / Medicaid #', key: 'cin' },
                { label: 'Date Referral was Received', key: 'date' },
                { label: 'Need', key: 'referral_need' },
                { label: 'Insurance type', key: 'insurance_1' },
                { label: 'Insurance plan', key: 'insurance_1_plan' },
                { label: 'Last Name', key: 'last_name' },
                { label: 'First Name', key: 'first_name' },
                { label: 'Middle Name', key: 'middle_name' },
                { label: 'DOB', key: 'dob' },
                { label: 'Gender', key: 'gender' },
                { label: 'Ethnicity', key: 'ethnicity' },
                { label: 'Language', key: 'language' },
                { label: 'Address', key: 'address_1' },
                { label: 'Address 2', key: 'address_2' },
                { label: 'City', key: 'city' },
                { label: 'State', key: 'state' },
                { label: 'County', key: 'county' },
                { label: 'Zip', key: 'zip' },
                { label: 'Phone', key: 'phone' },
                { label: 'Source', key: 'referral_source' },
                { label: 'Email', key: 'referral_source_email' },
                { label: 'Assigned Date', key: 'date_assigned' },
                { label: 'Agency', key: 'referral_agency' },
                { label: 'HARP', key: 'assessment_harp' },
                { label: 'First Contact Date', key: 'assessment_contact_date' },
                {
                    label: 'Assessment Date Completed',
                    key: 'assessment_date_completed',
                },
                {
                    label: 'Date LOSD Submitted to MCO',
                    key: 'assessment_date_submitted',
                },
                { label: 'Days Lapsed', key: 'time_lapsed' },
                { label: 'Status', key: 'referral_status' },
                { label: 'Appt Date', key: 'appointment_date' },
                { label: 'Appt Time', key: 'appointment_time' },
            ]
            const newList = referralsData.map((item) => {
                for (var key of Object.keys(item)) {
                    if (item[key] === 'None') {
                        item[key] = ''
                    }
                }
                const data = {}
                data['nano_id'] = item.nano_id
                data['cin'] = item.cin
                data['date'] = item.date_received && moment(item.date_received).format(date_format)
                data['referral_need'] = item.referral_need
                data['insurance_1'] = item.insurance_1
                data['insurance_1_plan'] = item.insurance_1_plan
                data['last_name'] = item.last_name
                data['first_name'] = item.first_name
                data['middle_name'] = item.middle_name
                data['dob'] = moment(item.dob).format(date_format)
                data['gender'] = item.gender
                data['ethnicity'] = item.ethnicity
                data['language'] = item.language
                data['address_1'] = item.address_1
                data['address_2'] = item.address_2
                data['city'] = item.city
                data['state'] = item.state
                data['county'] = item.county
                data['zip'] = item.zip
                data['phone'] = item.phone
                data['referral_source'] = item.referral_source
                data['referral_source_email'] = item.referral_source_email
                data['date_assigned'] = item.date_assigned
                data['referral_agency'] = item.referral_agency
                data['assessment_harp'] = item.assessment_harp
                data['assessment_contact_date'] = item.assessment_contact_date && moment(item.assessment_contact_date_string).format(date_format)
                data['assessment_date_completed'] = item.assessment_date_completed_string && moment(item.assessment_date_completed_string).format(date_format)
                data['assessment_date_submitted'] = item.assessment_date_submitted_string && moment(item.assessment_date_submitted_string).format(date_format)
                data['time_lapsed'] = item.time_lapsed
                data['referral_status'] = item.referral_status

                data['appointment_date'] = item.appointment_date && moment(item.appointment_date).format(date_format)
                data['appointment_time'] = item.appointment_time && moment(item.appointment_time).format(time_format)
                return data
            })
            return [csvReferralHeader, newList]
        }

        case 'TOC': {
            const csvReferralHeader = [
                { label: 'ID', key: 'nano_id' },
                { label: 'CIN / Medicaid #', key: 'cin' },
                { label: 'Date Referral was Received', key: 'date' },
                { label: 'Need', key: 'referral_need' },
                { label: 'Insurance type', key: 'insurance_1' },
                { label: 'Insurance plan', key: 'insurance_1_plan' },
                { label: 'Last Name', key: 'last_name' },
                { label: 'First Name', key: 'first_name' },
                { label: 'Middle Name', key: 'middle_name' },
                { label: 'DOB', key: 'dob' },
                { label: 'Gender', key: 'gender' },
                { label: 'Ethnicity', key: 'ethnicity' },
                { label: 'Language', key: 'language' },
                { label: 'Address', key: 'address_1' },
                { label: 'Address 2', key: 'address_2' },
                { label: 'City', key: 'city' },
                { label: 'State', key: 'state' },
                { label: 'County', key: 'county' },
                { label: 'Zip', key: 'zip' },
                { label: 'Phone', key: 'phone' },
                { label: 'Source', key: 'referral_source' },
                { label: 'Email', key: 'referral_source_email' },
                { label: 'Assigned Date', key: 'date_assigned' },
                { label: 'Agency', key: 'referral_agency' },
                { label: 'HARP', key: 'assessment_harp' },
                { label: 'First Contact Date', key: 'assessment_contact_date' },
                {
                    label: 'Assessment Date Completed',
                    key: 'assessment_date_completed',
                },
                {
                    label: 'Date LOSD Submitted to MCO',
                    key: 'assessment_date_submitted',
                },
                { label: 'Days Lapsed', key: 'time_lapsed' },
                { label: 'Status', key: 'referral_status' },
                { label: 'Contact Type', key: 'contact_type' },
                { label: 'Contact Need', key: 'contact_needs' },
                { label: 'Appt Date', key: 'appointment_date' },
                { label: 'Appt Time', key: 'appointment_time' },
            ]
            const newList = referralsData.map((item) => {
                for (var key of Object.keys(item)) {
                    if (item[key] === 'None') {
                        item[key] = ''
                    }
                }
                const data = {}
                data['nano_id'] = item.nano_id
                data['cin'] = item.cin
                data['date'] = item.date_received && moment(item.date_received).format(date_format)
                data['referral_need'] = item.referral_need
                data['insurance_1'] = item.insurance_1
                data['insurance_1_plan'] = item.insurance_1_plan
                data['last_name'] = item.last_name
                data['first_name'] = item.first_name
                data['middle_name'] = item.middle_name
                data['dob'] = moment(item.dob).format(date_format)
                data['gender'] = item.gender
                data['ethnicity'] = item.ethnicity
                data['language'] = item.language
                data['address_1'] = item.address_1
                data['address_2'] = item.address_2
                data['city'] = item.city
                data['state'] = item.state
                data['county'] = item.county
                data['zip'] = item.zip
                data['phone'] = item.phone
                data['referral_source'] = item.referral_source
                data['referral_source_email'] = item.referral_source_email
                data['date_assigned'] = item.date_assigned
                data['referral_agency'] = item.referral_agency
                data['assessment_harp'] = item.assessment_harp
                data['assessment_contact_date'] = item.assessment_contact_date && moment(item.assessment_contact_date_string).format(date_format)
                data['assessment_date_completed'] = item.assessment_date_completed_string && moment(item.assessment_date_completed_string).format(date_format)
                data['assessment_date_submitted'] = item.assessment_date_submitted_string && moment(item.assessment_date_submitted_string).format(date_format)
                data['time_lapsed'] = item.time_lapsed
                data['referral_status'] = item.referral_status
                data['contact_type'] = item.contact_type
                data['contact_needs'] = item.contact_needs
                data['appointment_date'] = item.appointment_date && moment(item.appointment_date).format(date_format)
                data['appointment_time'] = item.appointment_time && moment(item.appointment_time).format(time_format)
                return data
            })
            return [csvReferralHeader, newList]
        }

        case 'CMO': {
            const csvReferralHeader = [
                { label: 'ID', key: 'nano_id' },
                { label: 'CIN / Medicaid #', key: 'cin' },
                { label: 'Date Referral was Received', key: 'date' },
                { label: 'Need', key: 'referral_need' },
                { label: 'Insurance type', key: 'insurance_1' },
                { label: 'Insurance plan', key: 'insurance_1_plan' },
                { label: 'Last Name', key: 'last_name' },
                { label: 'First Name', key: 'first_name' },
                { label: 'Middle Name', key: 'middle_name' },
                { label: 'DOB', key: 'dob' },
                { label: 'Gender', key: 'gender' },
                { label: 'Ethnicity', key: 'ethnicity' },
                { label: 'Language', key: 'language' },
                { label: 'Address', key: 'address_1' },
                { label: 'Address 2', key: 'address_2' },
                { label: 'City', key: 'city' },
                { label: 'State', key: 'state' },
                { label: 'County', key: 'county' },
                { label: 'Zip', key: 'zip' },
                { label: 'Phone', key: 'phone' },
                { label: 'Source', key: 'referral_source' },
                { label: 'Email', key: 'referral_source_email' },
                { label: 'Assigned Date', key: 'date_assigned' },
                { label: 'Agency', key: 'referral_agency' },
                { label: 'HARP', key: 'assessment_harp' },
                { label: 'First Contact Date', key: 'assessment_contact_date' },
                {
                    label: 'Assessment Date Completed',
                    key: 'assessment_date_completed',
                },
                {
                    label: 'Date LOSD Submitted to MCO',
                    key: 'assessment_date_submitted',
                },
                { label: 'Days Lapsed', key: 'time_lapsed' },
                { label: 'Status', key: 'referral_status' },
                { label: 'Appt Date', key: 'appointment_date' },
                { label: 'Appt Time', key: 'appointment_time' },
            ]
            const newList = referralsData.map((item) => {
                for (var key of Object.keys(item)) {
                    if (item[key] === 'None') {
                        item[key] = ''
                    }
                }
                const data = {}
                data['nano_id'] = item.nano_id
                data['cin'] = item.cin
                data['date'] = item.date_received && moment(item.date_received).format(date_format)
                data['referral_need'] = item.referral_need
                data['insurance_1'] = item.insurance_1
                data['insurance_1_plan'] = item.insurance_1_plan
                data['last_name'] = item.last_name
                data['first_name'] = item.first_name
                data['middle_name'] = item.middle_name
                data['dob'] = moment(item.dob).format(date_format)
                data['gender'] = item.gender
                data['ethnicity'] = item.ethnicity
                data['language'] = item.language
                data['address_1'] = item.address_1
                data['address_2'] = item.address_2
                data['city'] = item.city
                data['state'] = item.state
                data['county'] = item.county
                data['zip'] = item.zip
                data['phone'] = item.phone
                data['referral_source'] = item.referral_source
                data['referral_source_email'] = item.referral_source_email
                data['date_assigned'] = item.date_assigned
                data['referral_agency'] = item.referral_agency
                data['assessment_harp'] = item.assessment_harp
                data['assessment_contact_date'] = item.assessment_contact_date && moment(item.assessment_contact_date_string).format(date_format)
                data['assessment_date_completed'] = item.assessment_date_completed_string && moment(item.assessment_date_completed_string).format(date_format)
                data['assessment_date_submitted'] = item.assessment_date_submitted_string && moment(item.assessment_date_submitted_string).format(date_format)
                data['time_lapsed'] = item.time_lapsed
                data['referral_status'] = item.referral_status

                data['appointment_date'] = item.appointment_date && moment(item.appointment_date).format(date_format)
                data['appointment_time'] = item.appointment_time && moment(item.appointment_time).format(time_format)
                return data
            })
            return [csvReferralHeader, newList]
        }

        case 'COVID': {
            const csvReferralHeader = [
                { label: 'ID', key: 'nano_id' },
                { label: 'CIN / Medicaid #', key: 'cin' },
                { label: 'Date Referral was Received', key: 'date' },
                { label: 'Need', key: 'referral_need' },
                { label: 'Insurance type', key: 'insurance_1' },
                { label: 'Insurance plan', key: 'insurance_1_plan' },
                { label: 'Last Name', key: 'last_name' },
                { label: 'First Name', key: 'first_name' },
                { label: 'Middle Name', key: 'middle_name' },
                { label: 'DOB', key: 'dob' },
                { label: 'Gender', key: 'gender' },
                { label: 'Ethnicity', key: 'ethnicity' },
                { label: 'Language', key: 'language' },
                { label: 'Address', key: 'address_1' },
                { label: 'Address 2', key: 'address_2' },
                { label: 'City', key: 'city' },
                { label: 'State', key: 'state' },
                { label: 'County', key: 'county' },
                { label: 'Zip', key: 'zip' },
                { label: 'Phone', key: 'phone' },
                { label: 'Source', key: 'referral_source' },
                { label: 'Email', key: 'referral_source_email' },
                { label: 'Assigned Date', key: 'date_assigned' },
                { label: 'Agency', key: 'referral_agency' },
                { label: 'HARP', key: 'assessment_harp' },
                { label: 'First Contact Date', key: 'assessment_contact_date' },
                {
                    label: 'Assessment Date Completed',
                    key: 'assessment_date_completed',
                },
                {
                    label: 'Date LOSD Submitted to MCO',
                    key: 'assessment_date_submitted',
                },
                { label: 'Days Lapsed', key: 'time_lapsed' },
                { label: 'Status', key: 'referral_status' },
                { label: 'Contact Type', key: 'contact_type' },
                { label: 'Contact Need', key: 'contact_needs' },
                { label: 'Appt Date', key: 'appointment_date' },
                { label: 'Appt Time', key: 'appointment_time' },
            ]
            const newList = referralsData.map((item) => {
                for (var key of Object.keys(item)) {
                    if (item[key] === 'None') {
                        item[key] = ''
                    }
                }
                const data = {}
                data['nano_id'] = item.nano_id
                data['cin'] = item.cin
                data['date'] = item.date_received && moment(item.date_received).format(date_format)
                data['referral_need'] = item.referral_need
                data['insurance_1'] = item.insurance_1
                data['insurance_1_plan'] = item.insurance_1_plan
                data['last_name'] = item.last_name
                data['first_name'] = item.first_name
                data['middle_name'] = item.middle_name
                data['dob'] = moment(item.dob).format(date_format)
                data['gender'] = item.gender
                data['ethnicity'] = item.ethnicity
                data['language'] = item.language
                data['address_1'] = item.address_1
                data['address_2'] = item.address_2
                data['city'] = item.city
                data['state'] = item.state
                data['county'] = item.county
                data['zip'] = item.zip
                data['phone'] = item.phone
                data['referral_source'] = item.referral_source
                data['referral_source_email'] = item.referral_source_email
                data['date_assigned'] = item.date_assigned
                data['referral_agency'] = item.referral_agency
                data['assessment_harp'] = item.assessment_harp
                data['assessment_contact_date'] = item.assessment_contact_date && moment(item.assessment_contact_date_string).format(date_format)
                data['assessment_date_completed'] = item.assessment_date_completed_string && moment(item.assessment_date_completed_string).format(date_format)
                data['assessment_date_submitted'] = item.assessment_date_submitted_string && moment(item.assessment_date_submitted_string).format(date_format)
                data['time_lapsed'] = item.time_lapsed
                data['referral_status'] = item.referral_status
                data['contact_type'] = item.contact_type
                data['contact_needs'] = item.contact_needs
                data['appointment_date'] = item.appointment_date && moment(item.appointment_date).format(date_format)
                data['appointment_time'] = item.appointment_time && moment(item.appointment_time).format(time_format)
                return data
            })
            return [csvReferralHeader, newList]
        }
        case 'CBHC-MVP': {
            const csvReferralHeader = [
                { label: 'ID', key: 'nano_id' },
                { label: 'CIN / Medicaid #', key: 'cin' },
                { label: 'Date Referral was Received', key: 'date' },
                { label: 'Agency', key: 'referral_agency' },
                { label: 'Health Home Enrolled', key: 'health_home_enrolled' },
                { label: 'Health Home Facility', key: 'health_home_facility' },
                { label: 'Health Home Enrollment Date', key: 'health_home_enrollment_date' },
                {
                    label: 'Care Management Enrolled',
                    key: 'care_management_enrolled',
                },
                {
                    label: 'Care Management Facility',
                    key: 'care_management_facility',
                },
                { label: 'Care Management Enrollment Date', key: 'care_management_enrollment_date' },
                { label: 'Engagement Contact Type', key: 'engagement_contact_type' },
                { label: 'Contact Date', key: 'engagement_date' },
                { label: 'Contact Time', key: 'engagement_time' },
                { label: 'Number of Contacts', key: 'engagement_number_of_contacts' },
                { label: 'Contact Details', key: 'engagement_contact_details' },
                { label: 'Upcoming PCP Appt Date', key: 'appointment_date' },
                { label: 'Upcoming PCP Appt Time', key: 'appointment_time' },
                {
                    label: 'Last Annual Physical Date',
                    key: 'last_annual_physical_date',
                },
                {
                    label: 'Last PCP Follow-up Date',
                    key: 'last_pcp_followup_date',
                },
                { label: 'Insurance type', key: 'insurance_1' },
                { label: 'Insurance plan', key: 'insurance_1_plan' },
                {
                    label: 'Insurance 1 Effective Date',
                    key: 'insurance_1_effective_date',
                },
                {
                    label: 'Insurance 1 Expiration Date',
                    key: 'insurance_1_expiration_date',
                },
                { label: 'Last Name', key: 'last_name' },
                { label: 'First Name', key: 'first_name' },
                { label: 'Middle Name', key: 'middle_name' },
                { label: 'DOB', key: 'dob' },
                { label: 'Gender', key: 'gender' },
                { label: 'Ethnicity', key: 'ethnicity' },
                { label: 'Language', key: 'language' },
                { label: 'Address', key: 'address_1' },
                { label: 'Address 2', key: 'address_2' },
                { label: 'City', key: 'city' },
                { label: 'State', key: 'state' },
                { label: 'County', key: 'county' },
                { label: 'Zip', key: 'zip' },
                { label: 'Phone', key: 'phone' },
                { label: 'Source', key: 'referral_source' },
                { label: 'Email', key: 'referral_source_email' },
                { label: 'Assigned Date', key: 'date_assigned' },
                { label: 'Days Lapsed', key: 'time_lapsed' },
                { label: 'QM 1 Checkbox', key: 'qm_1_checkbox' },
                { label: 'QM 1 Date', key: 'qm_1_date' },
                { label: 'QM 2 Checkbox', key: 'qm_2_checkbox' },
                { label: 'QM 2 Date', key: 'qm_2_date' },
                { label: 'QM 3 Checkbox', key: 'qm_3_checkbox' },
                { label: 'QM 3 Date', key: 'qm_3_date' },
                { label: 'QM 4 Checkbox', key: 'qm_4_checkbox' },
                { label: 'QM 4 Date', key: 'qm_4_date' },
                { label: 'QM 5 Checkbox', key: 'qm_5_checkbox' },
                { label: 'QM 5 Date', key: 'qm_5_date' },
                { label: 'QM 6 Checkbox', key: 'qm_6_checkbox' },
                { label: 'QM 6 Date', key: 'qm_6_date' },
            ]
            const newList = referralsData.map((item) => {
                for (var key of Object.keys(item)) {
                    if (item[key] === 'None') {
                        item[key] = ''
                    }
                }
                const data = {}
                data['nano_id'] = item.nano_id
                data['cin'] = item.cin
                data['date'] = item.date_received && moment(item.date_received).format(date_format)
                data['referral_agency'] = item.referral_agency
                data['health_home_enrolled'] = item.health_home_enrolled
                data['health_home_facility'] = item.health_home_facility
                data['health_home_enrollment_date'] = item.health_home_enrollment_date
                data['care_management_enrolled'] = item.care_management_enrolled
                data['care_management_facility'] = item.care_management_facility
                data['care_management_enrollment_date'] = item.care_management_enrollment_date
                data['engagement_contact_type'] = item.engagement_contact_type
                data['engagement_date'] = item.engagement_date
                data['engagement_time'] = item.engagement_time
                data['engagement_number_of_contacts'] = item.engagement_number_of_contacts
                data['engagement_contact_details'] = item.engagement_contact_details
                data['appointment_date'] = item.appointment_date && moment(item.appointment_date).format(date_format)
                data['appointment_time'] = item.appointment_time && moment(item.appointment_time).format(time_format)
                data['last_annual_physical_date'] = item.last_annual_physical_date
                data['last_pcp_followup_date'] = item.last_pcp_followup_date
                data['insurance_1'] = item.insurance_1
                data['insurance_1_plan'] = item.insurance_1_plan
                data['insurance_1_effective_date'] = item.insurance_1_effective_date
                data['insurance_1_expiration_date'] = item.insurance_1_expiration_date
                data['last_name'] = item.last_name
                data['first_name'] = item.first_name
                data['middle_name'] = item.middle_name
                data['dob'] = moment(item.dob).format(date_format)
                data['gender'] = item.gender
                data['ethnicity'] = item.ethnicity
                data['language'] = item.language
                data['address_1'] = item.address_1
                data['address_2'] = item.address_2
                data['city'] = item.city
                data['state'] = item.state
                data['county'] = item.county
                data['zip'] = item.zip
                data['phone'] = item.phone
                data['referral_source'] = item.referral_source
                data['referral_source_email'] = item.referral_source_email
                data['date_assigned'] = item.date_assigned
                data['time_lapsed'] = item.time_lapsed
                data['qm_1_checkbox'] = item.qm_1_checkbox
                data['qm_1_date'] = item.qm_1_date
                data['qm_2_checkbox'] = item.qm_2_checkbox
                data['qm_2_date'] = item.qm_2_date
                data['qm_3_checkbox'] = item.qm_3_checkbox
                data['qm_3_date'] = item.qm_3_date
                data['qm_4_checkbox'] = item.qm_4_checkbox
                data['qm_4_date'] = item.qm_4_date
                data['qm_5_checkbox'] = item.qm_5_checkbox
                data['qm_5_date'] = item.qm_5_date
                data['qm_6_checkbox'] = item.qm_6_checkbox
                data['qm_6_date'] = item.qm_6_date
                return data
            })
            return [csvReferralHeader, newList]
        }

        case 'SHARED-CARE': {
            const csvReferralHeader = [
                { label: 'ID', key: 'nano_id' },
                { label: 'CIN / Medicaid #', key: 'cin' },
                { label: 'Date Referral was Received', key: 'date' },
                { label: 'Insurance type', key: 'insurance_1' },
                { label: 'Insurance plan', key: 'insurance_1_plan' },
                { label: 'Last Name', key: 'last_name' },
                { label: 'First Name', key: 'first_name' },
                { label: 'Middle Name', key: 'middle_name' },
                { label: 'DOB', key: 'dob' },
                { label: 'Gender', key: 'gender' },
                { label: 'Ethnicity', key: 'ethnicity' },
                { label: 'Language', key: 'language' },
                { label: 'Address', key: 'address_1' },
                { label: 'Address 2', key: 'address_2' },
                { label: 'City', key: 'city' },
                { label: 'State', key: 'state' },
                { label: 'County', key: 'county' },
                { label: 'Zip', key: 'zip' },
                { label: 'Phone', key: 'phone' },
                { label: 'Source', key: 'referral_source' },
                { label: 'Outcomes', key: 'outcomes' },
                { label: 'Programs', key: 'programs' },
            ]
            const newList = referralsData.map((item) => {
                for (var key of Object.keys(item)) {
                    if (item[key] === 'None') {
                        item[key] = ''
                    }
                }
                const data = {}
                data['nano_id'] = item.nano_id
                data['cin'] = item.cin
                data['date'] = item.date_received && moment(item.date_received).format(date_format)
                data['insurance_1'] = item.insurance_1
                data['insurance_1_plan'] = item.insurance_1_plan
                data['last_name'] = item.last_name
                data['first_name'] = item.first_name
                data['middle_name'] = item.middle_name
                data['dob'] = moment(item.dob).format(date_format)
                data['gender'] = item.gender
                data['ethnicity'] = item.ethnicity
                data['language'] = item.language
                data['address_1'] = item.address_1
                data['address_2'] = item.address_2
                data['city'] = item.city
                data['state'] = item.state
                data['county'] = item.county
                data['zip'] = item.zip
                data['phone'] = item.phone
                data['referral_source'] = item.referral_source

                var outcomes_list = ''
                for (var key2 of Object.keys(item.outcomes)) {
                    var obj2 = item.outcomes[key2]
                    var entry2 = '[' + obj2.outcomeName + ' - ' + obj2.outcomeEffectiveDate + ']'
                    outcomes_list += entry2
                }
                data['outcomes'] = outcomes_list

                var programs_list = ''
                for (var key3 of Object.keys(item.programs)) {
                    var obj3 = item.programs[key3]
                    var entry3 =
                        '[PROGRAM: ' +
                        obj3.program +
                        ', AGENCY ASSIGNED: ' +
                        obj3.referral_agency +
                        ', REFERRAL STATUS: ' +
                        (obj3.referral_status ? obj3.referral_status : 'None added') +
                        ', REFERRAL NEED(S): ' +
                        (obj3.referral_needs ? obj3.referral_needs : 'None added') +
                        '] '
                    programs_list += entry3
                }
                data['programs'] = programs_list

                return data
            })
            return [csvReferralHeader, newList]
        }

        default:
            return [[], []]
    }
}
